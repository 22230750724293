import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { apiService } from '../../../common/apiCallService';
import { useNavigate } from 'react-router-dom';

export default function MaintenancePage() {
    const imageBaseUrl = useSelector((state) => state.config.image_base_url)
    const apiUrl = useSelector((state) => state.config.api_url)
    const navigate = useNavigate()

    const checkMaintenance = async () => {
        try {
            apiService(
                (window.location.hostname === 'localhost' ? apiUrl : '') +
                '/restapi/get-settings',
                {
                },
                (data) => {
                    if (!data[0].value) {
                        navigate("/")
                    }
                },
            )

        } catch (err) {
            console.error("Error fetching maintenance setting:", err);
        }
    };

    useEffect(() => {
        const navigationType = performance.getEntriesByType("navigation")[0]?.type;

        if (navigationType === "reload") {
            checkMaintenance();
        } else if (typeof navigationType === "undefined" && window.performance.navigation.type === 1) {
          // Fallback for older browsers
          checkMaintenance();
        }
    }, []);

    return (
        <div>
        <div className="not-found">
            <div className="flex flex-col justify-center items-center h-screen text-white">
                <div className="hidden sm:flex justify-end w-full md:mr-32 xs:mr-[-80px]">
                    <img
                        src={`${imageBaseUrl}/adminUi/notFoundFrame.svg`}
                        alt="Frame"
                    />
                </div>
                <div className="flex flex-col items-center w-full sm:w-[560px] min-w-0 pl-4 pr-4 ">
                    <img
                        className="w-[187.5px] mb-8"
                        src={`${imageBaseUrl}/landingpage/light-logo.svg`}
                        alt="Frame"
                    />
                    <div className="w-full h-[340px] px-8 py-10 bg-white/10 rounded-3xl border-[1px] border-white/20 flex-col justify-center items-center gap-2.5 inline-flex">
                        <div className="h-[251px] flex-col justify-start items-center gap-8 flex">
                            <div className="w-16 h-16 relative">
                                <img
                                    className="w-[187.5px] mb-8"
                                    src={`${imageBaseUrl}/adminUi/maintenance.svg`}
                                    alt="Frame"
                                />
                            </div>
                            <div className="self-stretch h-[120px] flex-col justify-center items-center flex">
                                <div className="self-stretch text-center text-white text-2xl xs:text-3xl font-medium md:w-[424px] w-[300px] leading-[38px]">
                                    Juno is under planned maintenance
                                </div>
                                <div className="self-stretch text-center text-[#d1d1d6] text-xl xs:text-2xl mt-3 sm:mt-2 font-normal leading-loose">
                                    We'll be back online shortly.
                                </div>
                            </div>
                            <div className="w-6 h-[3px] relative bg-[#fec84a]" />
                        </div>
                    </div>
                </div>
                <div className="hidden sm:flex justify-start w-full md:ml-32 sm:ml-[-120px] mt-8">
                    <img
                        src={`${imageBaseUrl}/adminUi/notFoundFrame.svg`}
                        alt="Frame"
                    />
                </div>
            </div>
        </div>
    </div>
    )
}
