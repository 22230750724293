import React from 'react'
import { useSelector } from 'react-redux'
export default function JunoAmCommitment() {
    const image_base_url = useSelector((state) => state.config.image_base_url)

    return (
        <section className="jnAm-cmt jnAm-section">
            <div className="jnAm-cmt-container">
                <div className="content-container">
                    <div className="jnAm-cmt-top">
                        <p className="jnAm-title black-color">
                            Our Commitment To You
                        </p>
                        <img
                            className="jnAm-cmt-top-img"
                            src={`${image_base_url}/juno-am/commitment.webp`}
                        />
                    </div>
                </div>
            </div>
            <div className="content-container">
                <div className="jnAm-cmt-grid">
                    <div className="jnAm-cmt-block">
                        <p className="jnAm-cmt-block-num">01</p>
                        <div className="line"></div>
                        <p className="jnAm-cmt-block-text">
                            Personalised service
                        </p>
                    </div>
                    <div className="jnAm-cmt-block">
                        <p className="jnAm-cmt-block-num">02</p>
                        <div className="line"></div>
                        <p className="jnAm-cmt-block-text">Competitive fees</p>
                    </div>
                    <div className="jnAm-cmt-block">
                        <p className="jnAm-cmt-block-num">03</p>
                        <div className="line"></div>
                        <p className="jnAm-cmt-block-text">
                            Enhanced security <br /> and safeguarding
                        </p>
                    </div>
                    <div className="jnAm-cmt-block">
                        <p className="jnAm-cmt-block-num">04</p>
                        <div className="line"></div>
                        <p className="jnAm-cmt-block-text">
                            Dedicated account <br />
                            management
                        </p>
                    </div>
                    <div className="jnAm-cmt-block">
                        <p className="jnAm-cmt-block-num">05</p>
                        <div className="line"></div>
                        <p className="jnAm-cmt-block-text">
                            Global coverage <br />
                            and partners
                        </p>
                    </div>
                    <div className="jnAm-cmt-block">
                        <p className="jnAm-cmt-block-num">06</p>
                        <div className="line"></div>
                        <p className="jnAm-cmt-block-text">
                            Seamless settlement
                        </p>
                    </div>
                </div>
            </div>
        </section>
    )
}
