import React from 'react'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'

const NotFound = () => {
    const imageBaseUrl = useSelector((state) => state.config.image_base_url)
    const navigate = useNavigate()
    return (
        <div>
            <div className="not-found">
                <div className="flex flex-col justify-center items-center h-screen text-white">
                    <div className="hidden sm:flex justify-end w-full md:mr-32 xs:mr-[-80px]">
                        <img
                            src={`${imageBaseUrl}/adminUi/notFoundFrame.svg`}
                            alt="Frame"
                        />
                    </div>
                    <p className="sm:text-[180px] text-[120px] text-center text-white font-bold sm:leading-[180px] leading-[100px]">
                        404
                    </p>
                    <p className="text-center text-white/90 sm:text-5xl text-3xl font-medium leading-[60px]">
                        Page Not Found
                    </p>
                    <div className="sm:w-[522px] w-[90vw] mt-6 mx-4 sm:text-xl px-2 text-center text-white/75 text-base">
                        Sorry, the page you’re looking for does not exist or has
                        been moved. Please go back to the Home page.
                    </div>
                    <div
                        className=" bg-opacity-0 text-white mt-6 border-[1px] px-6 py-3 rounded-[40px] w-44 text-center cursor-pointer"
                        onClick={() => navigate('/')}
                    >
                        Go back Home
                    </div>
                    <div className="hidden sm:flex justify-start w-full md:ml-32 sm:ml-[-120px]">
                        <img
                            src={`${imageBaseUrl}/adminUi/notFoundFrame.svg`}
                            alt="Frame"
                        />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default NotFound
