import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import {
    fiatCurrencyList,
    getCurrencySymbol,
    toFixedTrunc,
} from '../../../helpers'

export default function WithdrawConfirm({
    selectedCurrency,
    formData,
    attachments,
}) {
    const image_base_url = useSelector((state) => state.config.image_base_url)
    const currencyIcon = fiatCurrencyList.filter(
        (icons) => icons.text === selectedCurrency[0]
    )
    const [expanded, setExpanded] = useState(false)
    const [showAttachments, setShowAttachments] = useState(false)

    const toggleExpanded = () => {
        setExpanded((prevExpanded) => !prevExpanded)
    }

    return (
        <div className="transConfirm">
            {!showAttachments ? (
                <div>
                    <div className="transConfirm__top">
                        <div className="transConfirm__heading">
                            <p className="transConfirm__title">
                                Bank account information:
                            </p>
                            {attachments && attachments.length > 0 && (
                                <div
                                    className="transConfirm__attachment"
                                    onClick={() => setShowAttachments(true)}
                                >
                                    <svg
                                        width="17"
                                        height="18"
                                        viewBox="0 0 17 18"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <path
                                            d="M16.0788 8.74667L9.18625 15.6392C8.34187 16.4836 7.19664 16.9579 6.0025 16.9579C4.80836 16.9579 3.66314 16.4836 2.81875 15.6392C1.97437 14.7948 1.5 13.6496 1.5 12.4554C1.5 11.2613 1.97437 10.1161 2.81875 9.27167L9.71125 2.37917C10.2742 1.81625 11.0377 1.5 11.8338 1.5C12.6298 1.5 13.3933 1.81625 13.9563 2.37917C14.5192 2.94209 14.8354 3.70558 14.8354 4.50167C14.8354 5.29776 14.5192 6.06125 13.9563 6.62417L7.05625 13.5167C6.91689 13.656 6.75144 13.7666 6.56935 13.842C6.38726 13.9174 6.19209 13.9563 5.995 13.9563C5.79791 13.9563 5.60275 13.9174 5.42066 13.842C5.23857 13.7666 5.07312 13.656 4.93375 13.5167C4.79439 13.3773 4.68384 13.2119 4.60841 13.0298C4.53299 12.8477 4.49417 12.6525 4.49417 12.4554C4.49417 12.2583 4.53299 12.0632 4.60841 11.8811C4.68384 11.699 4.79439 11.5335 4.93375 11.3942L11.3013 5.03417"
                                            stroke="#475467"
                                            strokeWidth="1.4"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                        />
                                    </svg>
                                    <span> +{attachments.length} </span>
                                </div>
                            )}
                        </div>
                        <div className="transConfirm__amount">
                            <img
                                src={`${image_base_url}${currencyIcon && currencyIcon[0].img
                                    }`}
                                width="24px"
                                height="24px"
                            />
                            <p className="jncModal__text">
                                {getCurrencySymbol(selectedCurrency[0])}
                                {toFixedTrunc(formData.amount, 2)}
                            </p>
                        </div>
                    </div>
                    <div className="transConfirm__details">
                        <p className="transConfirm__details-value mb">
                            {formData.beneficiaryName || '-'}
                        </p>
                        <p className="transConfirm__details-label">
                            {formData.beneficiaryAddress || '-'}
                        </p>
                        <div className="inner-divider"></div>
                        <div className="transConfirm__details-group mb">
                            <p className="transConfirm__details-label">
                                Sort code:
                            </p>
                            <p className="transConfirm__details-value">
                                {formData.sortCode || '-'}
                            </p>
                        </div>
                        <div className="transConfirm__details-group">
                            <p className="transConfirm__details-label">
                                Account number:
                            </p>
                            <p className="transConfirm__details-value">
                                {formData.beneficiaryAccountNumber || '-'}
                            </p>
                        </div>
                        <div className="inner-divider"></div>
                        <div className="transConfirm__details-group mb">
                            <p className="transConfirm__details-label">
                                {' '}
                                SWIFT:{' '}
                            </p>
                            <p className="transConfirm__details-value">
                                {formData.swift || '-'}
                            </p>
                        </div>
                        <div className="transConfirm__details-group">
                            <p className="transConfirm__details-label">IBAN:</p>
                            <p className="transConfirm__details-value">
                                {formData.iban || '-'}
                            </p>
                        </div>
                        <div className="inner-divider"></div>
                        <div
                            className={` ${expanded ? 'showMore' : 'showless'}`}
                        >
                            <div className="transConfirm__details-group mb">
                                <p className="transConfirm__details-label">
                                    Bank name:
                                </p>
                                <p className="transConfirm__details-value">
                                    {formData.bankName || '-'}
                                </p>
                            </div>
                            <div className="transConfirm__details-group">
                                <p className="transConfirm__details-label">
                                    Bank address:
                                </p>
                                <p className="transConfirm__details-value">
                                    {formData.bankAddress || '-'}
                                </p>
                            </div>
                            <div className="inner-divider"></div>
                            <div className="transConfirm__details-group">
                                <p className="transConfirm__details-label">
                                    Reference:
                                </p>
                                <p className="transConfirm__details-value">
                                    {formData.reference || '-'}
                                </p>
                            </div>
                            <div className="inner-divider"></div>
                            <div className="transConfirm__details-group">
                                <p className="transConfirm__details-label">
                                    Additional info:
                                </p>
                                <p className="transConfirm__details-value">
                                    {formData.additionalBankDetails || '-'}
                                </p>
                            </div>
                            <div className="inner-divider"></div>
                        </div>
                        <button
                            className="showMore-btn"
                            onClick={toggleExpanded}
                            type="button"
                        >
                            Show {expanded ? 'less' : 'more'} information
                        </button>
                    </div>
                </div>
            ) : (
                <div className="transConfirm-attachment">
                    <div className="transConfirm-attachment-heading">
                        <svg
                            width="14"
                            height="14"
                            viewBox="0 0 14 14"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                            onClick={() => setShowAttachments(false)}
                        >
                            <path
                                d="M3.52051 7.83301L8.18717 12.4997L6.99967 13.6663L0.333008 6.99967L6.99967 0.333008L8.18717 1.49967L3.52051 6.16634H13.6663V7.83301H3.52051Z"
                                fill="#344054"
                            />
                        </svg>
                        <p className="transConfirm__title">Attachments:</p>
                    </div>
                    <div className="">
                        {attachments.map((item) => (
                            <div className="transConfirm-attachment-file">
                                <svg
                                    width="36"
                                    height="36"
                                    viewBox="0 0 36 36"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <rect
                                        width="36"
                                        height="36"
                                        rx="7.2"
                                        fill="#EAECF0"
                                    />
                                    <path
                                        d="M19.5 10.7021V13.8C19.5 14.22 19.5 14.4301 19.5817 14.5905C19.6536 14.7316 19.7684 14.8464 19.9095 14.9183C20.0699 15 20.28 15 20.7 15H23.7979M24 16.4912V21.9C24 23.1601 24 23.7902 23.7548 24.2715C23.539 24.6948 23.1948 25.0391 22.7715 25.2548C22.2902 25.5 21.6601 25.5 20.4 25.5H15.6C14.3399 25.5 13.7098 25.5 13.2285 25.2548C12.8052 25.0391 12.461 24.6948 12.2452 24.2715C12 23.7902 12 23.1601 12 21.9V14.1C12 12.8399 12 12.2098 12.2452 11.7285C12.461 11.3052 12.8052 10.961 13.2285 10.7452C13.7098 10.5 14.3399 10.5 15.6 10.5H18.0088C18.5592 10.5 18.8343 10.5 19.0933 10.5622C19.3229 10.6173 19.5423 10.7082 19.7436 10.8316C19.9707 10.9707 20.1653 11.1653 20.5544 11.5544L22.9456 13.9456C23.3347 14.3347 23.5293 14.5293 23.6684 14.7564C23.7918 14.9577 23.8827 15.1771 23.9378 15.4067C24 15.6657 24 15.9408 24 16.4912Z"
                                        stroke="#667085"
                                        strokeWidth="1.44"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                    />
                                </svg>
                                <span>{item.name}</span>
                            </div>
                        ))}
                    </div>
                </div>
            )}
        </div>
    )
}
