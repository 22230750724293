import React, { useEffect, useState } from 'react'
import { Dialog, DialogActions, DialogContent } from '@mui/material'
import { useSelector } from 'react-redux'
import JncDialogTitle from './JncDialogTitle'
import JncButton from '../global/JncButton'
import { defaultCurrenciesToDisplay } from '../../../helpers'

export default function ManageCurrenciesModal({ onClose, open, balancesData }) {
    const image_base_url = useSelector((state) => state.config.image_base_url)
    const [selectedCurrencies, setSelectedCurrencies] = useState(
        defaultCurrenciesToDisplay
    )
    const [alertMsg, setAlertMsg] = useState('')
    const [isDisable, setIsDisable] = useState(false)

    const currencies = balancesData.map((x) => {
        return {
            currencyShortName: x.currencyShortName,
            currencyIcon: x.currencyIcon,
            isActive: selectedCurrencies.includes(x.currencyShortName),
        }
    })

    const selectCurrency = (currency) => {
        setSelectedCurrencies((prev) => {
            const newCurrencies = prev.includes(currency)
                ? prev.filter((x) => x !== currency)
                : [...prev, currency]

            const isSingleCurrency = newCurrencies.length === 0

            setAlertMsg(
                isSingleCurrency
                    ? 'At least one currency must be selected'
                    : '',
            )
            setIsDisable(isSingleCurrency ? true : false)

            return newCurrencies
        })
    }

    function checkIsSelected(currency) {
        return selectedCurrencies.includes(currency)
    }

    function saveChanges() {
        localStorage.setItem(
            'manageCurrencies',
            JSON.stringify(selectedCurrencies),
        )
        onClose()
    }

    useEffect(() => {
        const storedSelected = localStorage.getItem('manageCurrencies')
        if (storedSelected && JSON.parse(storedSelected).length !== 0)
            setSelectedCurrencies(JSON.parse(storedSelected))
    }, [])

    return (
        <Dialog className="jncModal" onClose={onClose} open={open}>
            <JncDialogTitle title="Manage currencies" onClose={onClose} />
            <DialogContent dividers>
                <div className="jncModal__body">
                    <p className="jncModal__text">
                        Select or deselect one or multiple balance below you
                        would like to see within dashboard.
                    </p>
                    <div className="jncManageModal">
                        {currencies.map((x) => (
                            <button
                                onClick={() =>
                                    selectCurrency(x.currencyShortName)
                                }
                                key={x.currencyShortName}
                                className={`jncManageModal__curr ${x.isActive || checkIsSelected(x.currencyShortName) ? 'is-active' : ''}`}
                            >
                                {(x.isActive ||
                                    checkIsSelected(x.currencyShortName)) && (
                                        <svg
                                            width="23"
                                            height="22"
                                            viewBox="0 0 23 22"
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg"
                                        >
                                            <rect
                                                x="0.5"
                                                width="22"
                                                height="22"
                                                rx="11"
                                                fill="#32D583"
                                            />
                                            <path
                                                d="M11.6885 4.96182C13.3128 4.982 14.8626 5.64661 15.9969 6.80945C17.1312 7.97229 17.7571 9.5381 17.7369 11.1624C17.7168 12.7868 17.0522 14.3365 15.8893 15.4708C14.7265 16.6051 13.1607 17.2311 11.5363 17.2109C9.91201 17.1907 8.36223 16.5261 7.22792 15.3632C6.09362 14.2004 5.46771 12.6346 5.48789 11.0103C5.50806 9.38594 6.17268 7.83616 7.33551 6.70186C8.49835 5.56755 10.0642 4.94164 11.6885 4.96182ZM10.8345 12.2852L9.49085 10.9069C9.44269 10.8575 9.38526 10.8181 9.32186 10.7909C9.25847 10.7637 9.19033 10.7493 9.12136 10.7484C9.05238 10.7475 8.98391 10.7603 8.91985 10.7859C8.8558 10.8115 8.79742 10.8495 8.74804 10.8976C8.64831 10.9949 8.59131 11.1278 8.58958 11.2671C8.58785 11.4064 8.64153 11.5407 8.73881 11.6404L10.4497 13.3943C10.4977 13.444 10.5551 13.4836 10.6185 13.5109C10.6819 13.5383 10.7501 13.5528 10.8191 13.5536C10.8882 13.5545 10.9567 13.5417 11.0208 13.5159C11.0849 13.4902 11.1432 13.452 11.1925 13.4036L14.8243 9.86002C14.8743 9.81207 14.9143 9.75468 14.9421 9.69117C14.9698 9.62766 14.9847 9.55929 14.9859 9.48999C14.9871 9.4207 14.9745 9.35186 14.949 9.28744C14.9234 9.22302 14.8854 9.1643 14.837 9.11466C14.7886 9.06502 14.7309 9.02545 14.6672 8.99823C14.6035 8.97101 14.535 8.95667 14.4657 8.95605C14.3964 8.95543 14.3276 8.96854 14.2634 8.99462C14.1992 9.02069 14.1408 9.05923 14.0916 9.10799L10.8345 12.2852Z"
                                                fill="white"
                                            />
                                        </svg>
                                    )}
                                <img
                                    src={`${image_base_url}${x.currencyIcon}`}
                                />
                                <p data-e2e={x.currencyShortName}>
                                    {x.currencyShortName}
                                </p>
                            </button>
                        ))}
                    </div>
                </div>
                {alertMsg && (
                    <div className={`alert alert-danger`} role="alert">
                        {alertMsg}
                    </div>
                )}
            </DialogContent>
            <DialogActions>
                <div
                    data-e2e="save-btn"
                    className="jncModal__btns justify-end w-100"
                >
                    <JncButton
                        dataE2e="apply-icon"
                        onClickCall={saveChanges}
                        disabled={isDisable}
                        text="Apply"
                        isFullOnMobile={true}
                    />
                </div>
            </DialogActions>
        </Dialog>
    )
}
