import { useSelector } from 'react-redux'
import { apiService } from '../../../../common/apiCallService'
import JunoClientActionModal from '../table/JunoClientActionModal'
import CopyButton from "../../components/global/CopyButton"
import Icon from '../../icons/Icon'
import JncButton from './JncButton'
import { useState } from 'react'

export const JncConfirm = (props) => {
    const { onClose, open, title, content, dialogClassName, actionClassName, data, handleConfirm } = props

    const dailogConfirmContent = () => {
        return (
            <>
                <div className="jncModal__resp pt-1">
                    <Icon id="warningIcon" />
                    <p className="jn-addressdetails-confirm-title">{title}</p>
                    <p className="jn-addressdetails-confirm-content">{content}</p>
                    <hr></hr>
                    <div className="jn-addressdetails-confirm-header">
                        <div className="overflow-hidden">
                            <p className="jn-addressdetails-confirm-address">
                                {data}
                            </p>
                        </div>
                        <CopyButton
                            className="jn-addressdetails-confirmCopy"
                            copyValue={data}
                        />
                    </div>
                </div>
            </>
        )
    }
    const dialogConfirmAction = () => {
        return (
            <div className="jncModal__resp w-full" >
                <div className="jncModal__btns">
                    <JncButton
                        dataE2e="cancel-btn"
                        text="Cancel"
                        onClickCall={onClose}
                        isOutlinedStyle={true}
                    />
                    <JncButton
                        onClickCall={() => {
                            handleConfirm()
                            onClose()
                        }}
                        text="Update"
                    />
                </div>
            </div>
        )
    }
    return (
        <JunoClientActionModal
            dialogClassName={dialogClassName}
            onClose={onClose}
            states={{
                start: true,
                confirm: false,
            }}
            open={open}
            dialogContent={dailogConfirmContent()}
            dialogActions={dialogConfirmAction()}
            actionClassName={actionClassName}
        ></JunoClientActionModal>
    )
}