import React from 'react'
import LandingLayout from '../../client/layouts/LandingLayout'

const cookiesTable = [
    [
        '_dc_gtm_UA-73948331-1',
        'Third party (Google Tag Manager)',
        '10 minutes',
        'Functionality (site analytics)',
        'Visitor ID data used to distinguish visitors.',
    ],
    [
        '_ga',
        'Third party (Google Analytics)',
        '2 years',
        'Functionality (site analytics)',
        'Visitor ID data used to distinguish visitors.',
    ],
    [
        '_gat',
        'Third party (Google Analytics)',
        '1 minute',
        'Functionality (limiting request rate)',
        'Visitor ID data used to limit request rate, to minimise bandwidth impact.',
    ],
    [
        '_gid',
        'Third party (Google Analytics)',
        '24 hours',
        'Functionality (site analytics)',
        'Visitor ID data used to distinguish visitors.',
    ],
    [
        'ai_user',
        'Third party (Microsoft)',
        '1 year',
        'Functionality (Performance)',
        'Visitor ID data used to distinguish visitors.',
    ],
    [
        'catAccCookies',
        'First Party',
        '30 days',
        'Functionality (cookie consent)',
        'Visitor ID data used to record acceptance that the site uses cookies.',
    ],
    [
        'exp_csrf_token',
        'Third party (CMS)',
        'I hour',
        'Functionality (Cross Site Request Forgery)',
        'Visitor ID data used to confirm the submission from the site is genuine and protect against hacking.',
    ],
    [
        'exp_last_activity',
        'Third party (CMS)',
        '1 year',
        'Functionality (site activity)',
        'Visitor ID data used to identify the date of the user’s last activity on the site.',
    ],
    [
        'exp_tracker',
        'Third party (CMS)',
        'Session',
        'Functionality (site activity)',
        'Visitor ID data used to track pages visited on the site.',
    ],
    [
        'has_js',
        'Third party (Google Analytics)',
        'Session',
        'Functionality (site analytics)',
        'Visitor ID data used to distinguish visitors.',
    ],
    [
        'id',
        'Third party (DoubleClick)',
        '2 years',
        'Functionality (advertising)',
        'Visitor ID data used in connection with advertising.',
    ],
    [
        'Typekit by Adobe',
        'Third party (Adobe)',
        '2 years',
        'Widget, Font system',
        'Enables display of fonts',
    ],
    [
        'cookie_consent',
        'First party',
        '1 week',
        'Functionality (cookies)',
        'Recording the user’s consent to the dropping and reading of cookies.',
    ],
    [
        'cookie_consent_time',
        'First party',
        '1 week',
        'Functionality (cookies)',
        'Recording the user’s consent to the dropping and reading of cookies.',
    ],
]

export default function JunoCookiesPage() {
    return (
        <LandingLayout>
            <div className="bg-black w-full">
                <div className="flex items-center justify-center max-w-[1280px] xl:mx-auto h-full px-[16px] md:px-[24px] lg:px-[80px] pt-[154px] lg:pt-[206px] pb-[48px] lg:pb-[150px]">
                    <div>
                        <h1 className="text-[36px] lg:text-[52px] font-medium pb-[32px] lg:pb-[64px]">
                            Cookies
                        </h1>
                        <p className="text-[16px] lg:text-[18px] font-normal pb-[24px] leading-normal">
                            When you visit or interact with our site, services,
                            applications, tools or messages, we or our
                            authorized service providers and third parties may
                            use cookies and other similar technologies. These
                            technologies allow our sites, services,
                            applications, and tools to store relevant
                            information in your browser or device. Our
                            authorized service providers and third parties may
                            place cookies on your device via our services.
                        </p>
                        <p className="text-[16px] lg:text-[18px] font-normal leading-normal">
                            Service providers are companies that help us with
                            various aspects of our business, such as site
                            operations, services, applications, advertisements
                            and tools. We use some authorized service providers
                            to help us to serve you relevant ads on our services
                            and other places on the internet. These service
                            providers may also place cookies on your device via
                            our services (third party cookies). They may also
                            collect information that helps them identify your
                            device, such as IP-address or other unique or device
                            identifiers.
                        </p>
                    </div>
                </div>
            </div>
            <div className=" bg-[#F4F4F5]">
                <div className="max-w-[1280px] xl:mx-auto flex flex-col items-start justify-center gap-[40px] text-[#3F3F46] pt-[48px] lg:pt-[72px] pb-[64px] lg:pb-[96px] font-medium text-[16px] lg:text-[18px]">
                    <>
                        <div className="self-stretch relative px-[16px] md:px-[24px] xl:px-0">
                            <h2 className="text-[36px] font-medium">
                                1. What are cookies?
                            </h2>
                        </div>
                        <div className="self-stretch flex flex-col items-start justify-center gap-[32px] xl:pl-[48px] px-[16px] md:px-[24px]">
                            <div className="flex flex-col gap-[24px]">
                                <p>
                                    The specific names and types of the cookies,
                                    web beacons, and other similar technologies
                                    we use may change from time to time. In
                                    order to help you better understand this
                                    Policy and our use of such technologies we
                                    have provided the following limited
                                    terminology and definitions:
                                </p>
                                <p>
                                    Cookies are text files placed on your
                                    computer to collect standard Internet log
                                    information andvisitor behaviour
                                    information. When you visit our websites, we
                                    may collect information from
                                    youautomatically through cookies or similar
                                    technology. There are several types of
                                    cookies:
                                </p>
                            </div>
                            <>
                                <ul className="list-disc flex flex-col items-start justify-center gap-[24px] pl-[48px]">
                                    <li>
                                        Session cookies expire at the end of
                                        your browser session and allow us to
                                        link your actions during that browser
                                        session. They remain on your device only
                                        for the duration of the browser session.
                                    </li>
                                    <li>
                                        Persistent cookies are stored on your
                                        device in between browser sessions,
                                        allowing us to remember your preferences
                                        or actions across multiple sites. They
                                        remain on your device for a longer
                                        period.
                                    </li>
                                    <li>
                                        First-party cookies are set by the site
                                        you are visiting.
                                    </li>
                                    <li>
                                        Third-party cookies are set by
                                        third-party sites separate from the site
                                        you are visiting.
                                    </li>
                                </ul>
                            </>
                            <div className="flex flex-col gap-[24px]">
                                <p>
                                    In addition, we may also use web beacons (or
                                    clear GIFs) and other similar technologies
                                    in addition to, or in combination with,
                                    cookies. A web beacon is typically a
                                    transparent graphic image (usually 1 pixel x
                                    1 pixel) that is placed on a website or in
                                    an email and it helps us to understand the
                                    behaviour of visitors to our Site.
                                </p>
                                <p>
                                    More information on all aspects of cookies
                                    and web beacons can be found on
                                    www.allaboutcookies.org. Please note that we
                                    have no affiliation with, and are not
                                    responsible for, this third-party website.
                                </p>
                            </div>
                        </div>
                    </>

                    <>
                        <div className="self-stretch relative px-[16px] md:px-[24px] xl:px-0">
                            <h2 className="text-[36px] font-medium">
                                2. How do we use cookies?
                            </h2>
                        </div>
                        <div className="self-stretch flex flex-col items-start justify-center gap-[32px] xl:pl-[48px] px-[16px] md:px-[24px]">
                            <p>
                                We use cookies in a range of ways to collect
                                information and improve your experience on our
                                website. They are either necessary for the
                                functioning of our services, help us improve our
                                performance and/or give you extra
                                functionalities. The information collected by
                                cookies we use includes those such as:
                            </p>
                            <ul className="list-disc flex flex-col items-start justify-center gap-[24px] pl-[48px]">
                                <li>
                                    Technical Usage Data. Information about
                                    response time for web pages, download errors
                                    and date and time when you access the
                                    service, such as your IP address, statistics
                                    regarding how pages are loaded or viewed,
                                    the websites you visited before coming to
                                    the site and other usage and browsing
                                    information collected through Cookie.
                                </li>
                                <li>
                                    Information from your device. Information
                                    about your language settings, IP address,
                                    browser ID, device ID, cookie preferences,
                                    time zone, operating system, platform,
                                    screen resolution and similar information
                                    about your device settings, and data
                                    collected from cookies.
                                </li>
                                <li>
                                    Location Information. Information from
                                    IP-based geolocation such as latitude and
                                    longitude data, and Global Positioning
                                    System (GPS) information when you give us
                                    permission through your device settings.
                                </li>
                            </ul>
                            <p>
                                Our uses of cookies fall into the following
                                general categories:
                            </p>
                            <ul className="list-disc flex flex-col items-start justify-center gap-[24px] pl-[48px]">
                                <li>
                                    <b>Essential.</b> Some cookies are necessary
                                    for the operation of our site, services,
                                    applications, and tools. This includes
                                    technologies that allow you access to our
                                    sites, services, applications, and tools;
                                    that are required to identify irregular site
                                    behaviour, prevent fraudulent activity and
                                    improve security; or that allow you to make
                                    use of our functions such as shopping-carts,
                                    saved search, or similar functions;
                                </li>
                                <li>
                                    <b>Performance.</b> We may use cookies to
                                    assess the performance of our websites,
                                    applications, services, and tools, including
                                    as part of our analytic practices to help us
                                    understand how our visitors use our
                                    websites, determine if you have interacted
                                    with our messaging, determine whether you
                                    have viewed an item or link, or to improve
                                    our website content, applications, services,
                                    or tools;
                                </li>
                                <li>
                                    <b>Functionality.</b> We may use cookies
                                    that allow us to offer you enhanced
                                    functionality when accessing or using our
                                    sites, services, applications, or tools.
                                    This may include identifying you when you
                                    sign into our sites or keeping track of your
                                    specified preferences, interests, or past
                                    items viewed, what language you prefer and
                                    location you are in so that we may enhance
                                    the presentation of content on our sites.
                                </li>
                            </ul>
                        </div>
                    </>

                    <>
                        <div className="self-stretch relative px-[16px] md:px-[24px] xl:px-0">
                            <h2 className="text-[36px] font-medium">
                                3. Third Party Cookies.
                            </h2>
                        </div>
                        <div className="self-stretch flex flex-col items-start justify-center gap-[32px] xl:pl-[48px] px-[16px] md:px-[24px]">
                            <p>
                                We may work with third-party companies, commonly
                                known as service providers, who are authorized
                                to place third-party cookies or similar
                                technologies for storing information on our site
                                or in our services, applications, and tools with
                                our permission. These service providers help us
                                to provide you with a better, faster, and safer
                                experience.
                            </p>
                            <p>
                                These service providers may use these
                                technologies to help us deliver our own content
                                and advertising and compile anonymous site
                                metrics and analytics. We do not permit any of
                                these service providers to collect any of your
                                personal information on our sites or in our
                                services, applications, or tools for their own
                                purposes. These service providers are subject to
                                confidentiality agreements with us and other
                                legal restrictions on their use or collection of
                                any personal information. Third party cookies
                                are covered by the third-parties’ privacy
                                policy.
                            </p>
                            <p>
                                The Site uses Google Analytics, a web analytics
                                service provided by Google, Inc. (“Google”).
                                Google Analytics uses “cookies”, which are text
                                files placed on your device, to help our Site
                                analyse how users use the site.
                            </p>
                            <p>
                                The information generated by the cookie about
                                your use of our Site (including your IP address)
                                will be transmitted to and stored by Google on
                                servers in the United States.
                            </p>
                            <p>
                                Google will use this information for the purpose
                                of evaluating your use of our Site, compiling
                                reports on usage activity for service operators
                                and providing other services relating to Site
                                activity and internet usage. Google may also
                                transfer this information to third parties where
                                required to do so by law, or where such third
                                parties process the information on Google’s
                                behalf. Google will not associate your IP
                                address with any other data held by Google. You
                                may refuse the use of cookies by selecting the
                                appropriate settings on your browser, however,
                                please note that if you do this you may not be
                                able to use the full functionality of our Site.
                                By using our Site you consent to the processing
                                of data about you by Google in the manner and
                                for the purposes set out above.​ For more
                                information please visit
                                http://www.google.com/analytics/.
                            </p>
                            <p>
                                An overview of the cookies used on our Site is
                                set out in the following table.
                            </p>
                        </div>

                        <div className="pt-[48px] overflow-x-scroll text-[14px] lg:text-[18px]">
                            <div className=" rounded-lg border border-gray-500">
                                <table className="table-fixed max-w-[1280px] border-collapse border border-gray-500 bg-gray-100 bg-opacity-85 whitespace-normal">
                                    <thead>
                                        <tr className="font-semibold">
                                            <th className="px-[24px] py-[12px] lg:!p-[24px] xl:min-w-[256px] whitespace-normal border border-gray-500">
                                                Name
                                            </th>
                                            <th className="px-[24px] py-[12px] lg:!p-[24px] xl:min-w-[256px] whitespace-normal border border-gray-500">
                                                First Party or Third Party?
                                            </th>
                                            <th className="px-[24px] py-[12px] lg:!p-[24px] xl:min-w-[256px] whitespace-normal border border-gray-500">
                                                Duration
                                            </th>
                                            <th className="px-[24px] py-[12px] lg:!p-[24px] xl:min-w-[256px] whitespace-normal border border-gray-500">
                                                Purpose
                                            </th>
                                            <th className="px-[24px] py-[12px] lg:!p-[24px] xl:min-w-[256px] whitespace-normal border border-gray-500">
                                                Types of data collected
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {cookiesTable.map((row, index) => (
                                            <tr key={index}>
                                                {row.map((value, index) => (
                                                    <td
                                                        key={index}
                                                        className="px-[24px] py-[12px] lg:!p-[24px] xl:min-w-[256px] whitespace-normal border border-gray-500"
                                                    >
                                                        {value}
                                                    </td>
                                                ))}
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </>

                    <>
                        <div className="self-stretch relative px-[16px] md:px-[24px] xl:px-0">
                            <h2 className="text-[36px] font-medium">
                                4. How to manage cookies.
                            </h2>
                        </div>
                        <div className="self-stretch flex flex-col items-start justify-center gap-[32px] xl:pl-[48px] px-[16px] md:px-[24px]">
                            <p>
                                You can set your browser not to accept cookies.
                                However, in a few cases, some of our website
                                features may not function as a result.
                            </p>
                            <p>
                                Most internet browsers are set to automatically
                                accept cookies. Depending on your browser, you
                                can set your browser to warn you before
                                accepting cookies, or you can set it to refuse
                                them. Please refer to the ‘help’ button (or
                                similar) on your browser to learn more about how
                                you can do this.
                            </p>
                            <p>
                                Disabling cookies may impact your experience on
                                our Site. If you use different devices to access
                                our Site, you will need to ensure that each
                                browser of each device is set to your cookie
                                preference.
                            </p>
                            <p>
                                More information on how to manage cookies is
                                available from
                                http://www.allaboutcookies.org/manage-cookies/.
                                Please note that we have no affiliation with,
                                and are not responsible for, this third-party
                                website.
                            </p>
                        </div>
                    </>

                    <>
                        <div className="self-stretch relative px-[16px] md:px-[24px] xl:px-0">
                            <h2 className="text-[36px] font-medium">
                                5. Privacy policies of other websites.
                            </h2>
                        </div>
                        <div className="self-stretch flex flex-col items-start justify-center gap-[32px] xl:pl-[48px] px-[16px] md:px-[24px]">
                            <p>
                                Our website may contain links to other websites.
                                Our privacy policy applies only to our website,
                                so if you click on a link to another website,
                                you should read their privacy policy.
                            </p>
                        </div>
                    </>

                    <>
                        <div className="self-stretch relative px-[16px] md:px-[24px] xl:px-0">
                            <h2 className="text-[36px] font-medium">
                                6. Changes to our privacy policy.
                            </h2>
                        </div>
                        <div className="self-stretch flex flex-col items-start justify-center gap-[32px] xl:pl-[48px] px-[16px] md:px-[24px]">
                            <p>
                                We keep our privacy policy under regular review
                                and place any updates on this website. This
                                privacy policy was last updated on 26 April
                                2023.
                            </p>
                        </div>
                    </>

                    <>
                        <div className="self-stretch relative px-[16px] md:px-[24px] xl:px-0">
                            <h2 className="text-[36px] font-medium">
                                How to contact us
                            </h2>
                        </div>
                        <div className="self-stretch flex flex-col items-start justify-center gap-[32px] xl:pl-[48px] px-[16px] md:px-[24px]">
                            <p>
                                If you have any questions about our privacy
                                policy, the data we hold on you, or you would
                                like to exercise one of your data protection
                                rights, please do not hesitate to contact us.
                            </p>
                            <>
                                <p>Email us at: support@junomoney.com</p>
                                <p>
                                    Or write to us: Suite #229, 6030 88ST NW,
                                    Edmonton, Alberta, Canada, T6E6G4.
                                </p>
                            </>
                        </div>
                    </>
                </div>
            </div>
        </LandingLayout>
    )
}
