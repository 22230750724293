import React, { useState } from 'react'

export default function BalancesTableActions(props) {
    const {
        setTransferModalState,
        setDepositModalState,
        setWithdrawModalState,
        setPaymentOutModalState,
        setFxModalState,
        row,
    } = props

    return (
        <div className="jncTable-actions">
            <button
                type="button"
                onClick={setDepositModalState}
                className="jncTable-actions-btn"
            >
                <span>Deposit</span>
                <svg
                    data-e2e={`${row.currencyShortName}-deposit-btn`}
                    width="14"
                    height="14"
                    viewBox="0 0 14 14"
                    fill="none"
                >
                    <path
                        d="M7.00033 1.16675V12.8334M1.16699 7.00008H12.8337"
                        stroke="#344054"
                        strokeWidth="1.6"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                    />
                </svg>
            </button>
            <button
                type="button"
                onClick={setWithdrawModalState}
                className="jncTable-actions-btn"
            >
                <span>Withdraw</span>
                <svg
                    data-e2e={`${row.currencyShortName}-withdraw-btn`}
                    width="12"
                    height="16"
                    viewBox="0 0 12 16"
                    fill="none"
                >
                    <path
                        d="M6 14.6666V1.33325M6 1.33325L1 6.33325M6 1.33325L11 6.33325"
                        stroke="#344054"
                        strokeWidth="1.6"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                    />
                </svg>
            </button>
            <button
                type="button"
                onClick={setPaymentOutModalState}
                className="jncTable-actions-btn"
            >
                <span>Third-party payment</span>
                <svg
                    data-e2e={`${row.currencyShortName}-payout-btn`}
                    width="20"
                    height="16"
                    viewBox="0 0 20 16"
                    fill="none"
                >
                    <path
                        d="M13.3337 12.0001L15.8337 9.50008M15.8337 9.50008L18.3337 12.0001M15.8337 9.50008V14.5001M18.3337 5.33341H1.66699M18.3337 7.00008V3.83342C18.3337 2.9 18.3337 2.43328 18.152 2.07677C17.9922 1.76316 17.7372 1.50819 17.4236 1.34841C17.0671 1.16675 16.6004 1.16675 15.667 1.16675H4.33366C3.40024 1.16675 2.93353 1.16675 2.57701 1.3484C2.2634 1.50819 2.00844 1.76316 1.84865 2.07676C1.66699 2.43328 1.66699 2.89999 1.66699 3.83342V10.1667C1.66699 11.1002 1.66699 11.5669 1.84865 11.9234C2.00844 12.237 2.2634 12.492 2.57701 12.6518C2.93353 12.8334 3.40024 12.8334 4.33366 12.8334H10.0003"
                        stroke="#344054"
                        strokeWidth="1.6"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                    />
                </svg>
            </button>
            <button
                type="button"
                onClick={setFxModalState}
                className="jncTable-actions-btn"
            >
                <span>FX</span>
                <p data-e2e={`${row.currencyShortName}-fx-btn`}>FX</p>
            </button>
            <button
                type="button"
                onClick={setTransferModalState}
                className="jncTable-actions-btn"
            >
                <span>Transfer</span>
                <svg
                    data-e2e={`${row.currencyShortName}-transfer-btn`}
                    width="16"
                    height="18"
                    viewBox="0 0 16 18"
                    fill="none"
                >
                    <path
                        d="M1.33301 13.1667H14.6663M14.6663 13.1667L11.333 9.83333M14.6663 13.1667L11.333 16.5M14.6663 4.83333H1.33301M1.33301 4.83333L4.66634 1.5M1.33301 4.83333L4.66634 8.16667"
                        stroke="#344054"
                        strokeWidth="1.6"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                    />
                </svg>
            </button>
        </div>
    )
}
