import React from 'react'
import { useSelector } from 'react-redux'
import {
    fiatCurrencyList,
    getCurrencySymbol,
    toFixedTrunc,
} from '../../../helpers'

export default function TransferConfirm({
    balance,
    formData,
    selectedMerchant,
}) {
    const image_base_url = useSelector((state) => state.config.image_base_url)
    const currencyIcon = fiatCurrencyList.filter(
        (icons) => icons.text === balance.currencyShortName
    )

    return (
        <div className="transConfirm">
            <div className="transConfirm__top">
                <div className="transConfirm__heading">
                    <p className="transConfirm__title">Transfer information:</p>
                </div>
                <div className="transConfirm__amount">
                    <img
                        src={`${image_base_url}${
                            currencyIcon && currencyIcon[0].img
                        }`}
                        width="24px"
                        height="24px"
                    />
                    <p className="jncModal__text">
                        {getCurrencySymbol(balance.currencyShortName)}
                        {toFixedTrunc(formData.amount, 2)}
                    </p>
                </div>
            </div>
            <div className="transConfirm__details">
                {!selectedMerchant && (
                    <p className="transConfirm__details-value">
                        {formData.email}
                    </p>
                )}
                {selectedMerchant && (
                    <p className="transConfirm__details-value flex items-center gap-2">
                        <img
                            src={selectedMerchant.merchantLogo}
                            alt="merchantLogo"
                            width={32}
                            height={32}
                            className="rounded-full"
                        />
                        <h1 className="">{selectedMerchant.merchantName}</h1>
                    </p>
                )}
                <div className="inner-divider"></div>
                <div className="transConfirm__details-group">
                    <p className="transConfirm__details-label">Reference:</p>
                    <p className="transConfirm__details-value">
                        {formData.reference || '-'}
                    </p>
                </div>
            </div>
        </div>
    )
}