import React from 'react'
import { NavLink } from 'react-router-dom'

export default function JncAuthBottom() {
    return (
        <div className="jncAuth__bottom">
            <p>2024 Juno Money</p>
            <NavLink to="">Privacy Policy</NavLink>
        </div>
    )
}
