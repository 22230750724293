import React, { useEffect } from 'react'
import JncLayout from '../layouts/JncLayout'
import JncTransactions from '../tables/transactions/JncTransactions'
import { useSelector } from 'react-redux'
import { useJunoCommonFunctions } from '../../helpers'
import JncCryptoTransaction from '../tables/transactions/JncCryptoTransaction'
import JunoClientSnackbar from '../components/global/JunoClientSnackbar'

export default function JunoClientTransactionsPage() {
    const { getBalances, getSavedAccounts } = useJunoCommonFunctions()
    const clientData = useSelector((state) => state.juno.clientData)
    const activeCurrencyPage = useSelector(
        (state) => state.juno.activeCurrencyPage
    )

    useEffect(() => {
        if (clientData) {
            getBalances()
            getSavedAccounts()
        }
    }, [clientData])

    return (
        <JncLayout>
            <div className="jncPage">
                {activeCurrencyPage === "crypto" ?
                    <JncCryptoTransaction showTablePagination={true} />
                    :
                    <JncTransactions showTablePagination={true} />
                }
            </div>
            <JunoClientSnackbar />
        </JncLayout>
    )
}
