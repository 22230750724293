import React from 'react'
import { icons } from './icons'

export default function Icon({ id, className }) {
    return (
        <div
            className={`svg-block ${className}`}
            data-e2e={id}
            dangerouslySetInnerHTML={{ __html: icons[id] }}
        ></div>
    )
}
