import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { Dialog, DialogActions, DialogContent } from '@mui/material'
import JncDialogTitle from '../../components/modals/JncDialogTitle'
import JncButton from '../../components/global/JncButton'
import { format } from 'date-fns'
import JncDayPicker from '../global/JncDayPicker'
import { useJunoCommonFunctions } from '../../../helpers'

const JncProfileDetailsModal = ({
    onClose,
    open,
    handleChangePasswordSection,
    isChangePasswordSection,
    alertMsg,
    accountData,
    accountPassword,
    handleInputPasswordChange,
    handleInputChange,
    onChangeDob,
    updateUserDetails,
    isSuccess,
    isLoading,
}) => {
    const clientData = useSelector((state) => state.juno.clientData)
    const { useWindowWidth } = useJunoCommonFunctions()
    const windowWidth = useWindowWidth()

    return (
        <Dialog
            className="jncModal jncProfileModal"
            scroll={'paper'}
            aria-labelledby="scroll-dialog-title"
            onClose={onClose}
            fullScreen={windowWidth < 768}
            open={open}
        >
            <JncDialogTitle
                onClose={onClose}
                title={'Edit personal information'}
            />
            <DialogContent dividers>
                <div
                    className={`jncModal__body  ${windowWidth > 768 && 'jncProfileModalBody'
                        }`}
                >
                    <div className="savedAccounts">
                        <div className="savedAccounts-tabs">
                            <button
                                type="button"
                                className={`${!isChangePasswordSection && 'active'
                                    }`}
                                onClick={() =>
                                    handleChangePasswordSection(false)
                                }
                                data-e2e="acccount-details"
                            >
                                Personal info
                            </button>
                            <button
                                type="button"
                                className={`${isChangePasswordSection && 'active'
                                    }`}
                                onClick={() =>
                                    handleChangePasswordSection(true)
                                }
                                data-e2e="change-password"
                            >
                                Password
                            </button>
                        </div>
                    </div>

                    {isChangePasswordSection && (
                        <div className={`jnA-account-password`}>
                            {alertMsg && (
                                <div
                                    className={`alert ${isSuccess
                                        ? 'alert-success'
                                        : 'alert-danger'
                                        }`}
                                    role="alert"
                                >
                                    {alertMsg}
                                </div>
                            )}
                            <div className="jncProfile__field ">
                                <p className="jncModal__label">
                                    Current Password
                                </p>
                                <div className="">
                                    <input
                                        type="password"
                                        className="jncInput"
                                        value={accountPassword.oldPassword}
                                        onChange={handleInputPasswordChange}
                                        name="oldPassword"
                                        data-e2e="enter-old-password"
                                    />
                                </div>
                            </div>
                            <div className="jncProfile__field ">
                                <p className="jncModal__label">New Password</p>
                                <div className="">
                                    <input
                                        type="password"
                                        data-e2e="enter-new-password"
                                        className="jncInput"
                                        value={accountPassword.newPassword}
                                        onChange={handleInputPasswordChange}
                                        name="newPassword"
                                    />
                                </div>
                            </div>
                            <div className="jncProfile__field  no-border">
                                <p className="jncModal__label">
                                    Confirm Password
                                </p>
                                <div className="">
                                    <input
                                        type="password"
                                        className="jncInput"
                                        data-e2e="confirm-new-password"
                                        value={accountPassword.confirmPassword}
                                        onChange={handleInputPasswordChange}
                                        name="confirmPassword"
                                    />
                                </div>
                            </div>
                        </div>
                    )}

                    {!isChangePasswordSection && clientData && (
                        <div className={`jncProfile__fields`}>
                            {alertMsg && !isSuccess && (
                                <div
                                    className={`jnA-account-password-msg ${alertMsg ? 'active' : ''
                                        } ${isSuccess ? 'isSuccess' : 'isAlert'}`}
                                    role="alert"
                                >
                                    {alertMsg}
                                </div>
                            )}
                            <div
                                data-e2e="accountNumber"
                                className="jncProfile__field "
                            >
                                <p className="jncModal__label">
                                    Account Number
                                </p>
                                <div className="">
                                    <input
                                        type="text"
                                        className="jncInput"
                                        value={clientData.accountNumber}
                                        readOnly
                                        disabled
                                    />
                                </div>
                            </div>
                            <div className="jncProfile__field ">
                                <p className="jncModal__label">
                                    Account holder name
                                </p>
                                <div className="">
                                    <input
                                        type="text"
                                        data-e2e="account-holder-name"
                                        className="jncInput"
                                        value={accountData.name}
                                        onChange={handleInputChange}
                                        name="name"
                                    />
                                </div>
                            </div>
                            <div className="jncProfile__field jncProfile__field-dob ">
                                <p className="jncModal__label">Birthday</p>
                                <JncDayPicker
                                    formattedDate={
                                        accountData.dob
                                            ? format(
                                                new Date(
                                                    accountData.dob.toString()
                                                ),
                                                'dd/MM/y'
                                            )
                                            : '-'
                                    }
                                    selected={accountData.dob}
                                    onSelect={onChangeDob}
                                />
                            </div>
                            <div className="jncProfile__field ">
                                <p className="jncModal__label">Phone number</p>
                                <div className="">
                                    <input
                                        type="text"
                                        className="jncInput"
                                        data-e2e="phone-number"
                                        value={accountData.phoneNumber}
                                        onChange={handleInputChange}
                                        name="phoneNumber"
                                    />
                                </div>
                            </div>
                            <div className="jncProfile__field ">
                                <p className="jncModal__label">Email</p>
                                <div className="">
                                    <input
                                        type="email"
                                        data-e2e="enter-email"
                                        className="jncInput"
                                        value={clientData.clientEmail}
                                        readOnly
                                        disabled
                                    />
                                </div>
                            </div>
                            <div className="jncProfile__field ">
                                <p className="jncModal__label">Address</p>
                                <div className="">
                                    <input
                                        type="text"
                                        className="jncInput"
                                        data-e2e="enter-address"
                                        value={accountData.address}
                                        onChange={handleInputChange}
                                        name="address"
                                    />
                                </div>
                            </div>
                            <div className="jncProfile__field ">
                                <p className="jncModal__label">City</p>
                                <div className="">
                                    <input
                                        type="text"
                                        data-e2e="enter-city"
                                        className="jncInput"
                                        value={accountData.city}
                                        onChange={handleInputChange}
                                        name="city"
                                    />
                                </div>
                            </div>
                            <div className="jncProfile__field ">
                                <p className="jncModal__label">Postcode</p>
                                <div className="">
                                    <input
                                        type="text"
                                        className="jncInput"
                                        data-e2e="enter-post-code"
                                        value={accountData.postCode}
                                        onChange={handleInputChange}
                                        name="postCode"
                                    />
                                </div>
                            </div>
                            <div className="jncProfile__field ">
                                <p className="jncModal__label">Country</p>
                                <div className="">
                                    <input
                                        type="text"
                                        className="jncInput"
                                        data-e2e="enter-country"
                                        value={accountData.country}
                                        onChange={handleInputChange}
                                        name="country"
                                    />
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            </DialogContent>
            <DialogActions>
                <div className="w-100 jncModal__btns justify-end">
                    <JncButton
                        isFullOnMobile={true}
                        loading={isLoading}
                        onClickCall={updateUserDetails}
                        dataE2e={'Save'}
                        text={'Save'}
                    />
                </div>
            </DialogActions>
        </Dialog>
    )
}

export default JncProfileDetailsModal
