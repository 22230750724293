import React, { useState } from 'react'
import { Divider } from '@mui/material';
import { useSelector } from 'react-redux';
import JunoLogin from '../global/JunoLogin'
import { useNavigate } from 'react-router-dom';
import FadeUp from './FadeUp';
import CustomButton from './CustomButton';

const Footer = () => {
    const imageBaseUrl = useSelector((state) => state.config.image_base_url)
    const navigate = useNavigate()
    const [showLoginModal, setShowLoginModal] = useState(false)
    const headerLogo = sessionStorage.getItem("logo")
    const imageBaseURl = useSelector((state) => state.config.image_base_url)

    const footerLinks = [
        { id: 1, title: "Contact Us", link: "/contact-us" },
        { id: 2, title: "Cookie Policy", link: "/juno-cookies" },
        { id: 3, title: "Privacy Policy", link: "/juno-privacy-policy" },
        { id: 4, title: "Terms and Conditions", link: "/juno-terms" }

    ]

    return (
        <div className='bg-[#FAFAFA]'>
            <div className='px-3 sm:!px-6 py-16 lg:!px-[150px] lg:!py-[100px] max-w-[1280px] xl:!px-0 mx-auto '>
                <FadeUp>

                    <div className='sm:w-[600px] mb-[120px] lg:mb-[150px]'>
                        <h2 className='text-4xl sm:text-[56px] lg:text-[62px] text-brand-black leading-none'>Time is money. <br /> Save both.  </h2>
                        <p className='sm:text-lg lg:text-xl text-brand-gray my-[30px]'>Juno Money can take the pressure off you, as either a high net worth individual managing a personal portfolio or a corporate client running a thriving business. Get in touch with us to understand how our accomplished and highly skilled team of experts can support you to achieve your financial goals.</p>

                        <CustomButton
                            className="w-[120px] lg:w-36"
                            title="log in"
                            onClick={() => {
                                setShowLoginModal(true)
                            }}
                        />
                    </div>
                    <div className='flex flex-col lg:flex-row !gap-5 lg:gap-6'>
                        {footerLinks.map((option) => <p onClick={() => navigate(option.link)} className='text-brand-black text-xl cursor-pointer' data-e2e={option.title}>{option.title}</p>)}
                    </div>
                    {headerLogo && headerLogo !== 'null' ?
                        <img src={headerLogo} alt="logo" className='!mt-10 mb-6 lg:my-6' width={350} height="auto" />
                        :
                        <img className='!mt-10 mb-6 lg:my-6' src={`${imageBaseURl}/landingpage/dark-logo.svg`} alt="logo" />
                    }
                    <p className='font-light text-brand-gray text-xs lg:text-base'>© Juno Pay Ltd (trading as Juno Money) {new Date().getFullYear()} | All rights reserved</p>
                </FadeUp>
                <Divider color='#D1D1D6' className='!my-6 lg:!my-8' />
                <p className='text-xs lg:text-sm text-brand-gray'>Juno Pay Ltd (trading as Juno Money) - (Company Number: 2024801421), with its registered office at Suite #229, 6030 88ST NW, Edmonton, Alberta, T6E6G4, Canada. Juno Pay Ltd is authorised by FINTRAC as a Money Service Business (MSB No. M23335654).</p>
                {showLoginModal &&
                    <JunoLogin
                        show={true}
                        closeModal={() => setShowLoginModal(false)}
                    />
                }
            </div>
        </div>
    )
}

export default Footer