import React from 'react'
import DialogTitle from '@mui/material/DialogTitle'
import ClearIcon from '@mui/icons-material/Clear'

export default function JncDialogTitle({ title, onClose,className }) {
    return (
        <DialogTitle id="scroll-dialog-title" className="jncModal__header">
            <div className={`jncModal__title ${className}`}>{title}</div>
            <button onClick={onClose} type="button" className="jncModal__close">
                <ClearIcon />
            </button>
        </DialogTitle>
    )
}
