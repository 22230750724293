import React from 'react'

export default function AdditionalUserActions(props) {
    const {
        row,
        handleUpdateUser,
        setUserModal,
        readOnly,
        setUserEditData,
        setIsEdit,
        setUpdateEmail,
    } = props

    return (
        <React.Fragment>
            <div className="jncTable-actions">
                <button
                    className={`${readOnly && 'jnc-btn-disable'} jncTable-actions-btn`}
                    onClick={() => handleUpdateUser(row, 'delete')}
                    disabled={readOnly}
                >
                    <span>Delete</span>
                    <svg
                        data-e2e="delete"
                        width="40"
                        height="40"
                        viewBox="0 0 40 40"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                            d="M26.6667 9.99967V8.66634C26.6667 6.7995 26.6667 5.86608 26.3034 5.15304C25.9838 4.52583 25.4738 4.0159 24.8466 3.69632C24.1336 3.33301 23.2002 3.33301 21.3333 3.33301H18.6667C16.7998 3.33301 15.8664 3.33301 15.1534 3.69632C14.5262 4.0159 14.0162 4.52583 13.6966 5.15304C13.3333 5.86608 13.3333 6.7995 13.3333 8.66634V9.99967M16.6667 19.1663V27.4997M23.3333 19.1663V27.4997M5 9.99967H35M31.6667 9.99967V28.6663C31.6667 31.4666 31.6667 32.8667 31.1217 33.9363C30.6423 34.8771 29.8774 35.642 28.9366 36.1214C27.8671 36.6663 26.4669 36.6663 23.6667 36.6663H16.3333C13.5331 36.6663 12.1329 36.6663 11.0634 36.1214C10.1226 35.642 9.35767 34.8771 8.8783 33.9363C8.33333 32.8667 8.33333 31.4666 8.33333 28.6663V9.99967"
                            stroke="#D92D20"
                            stroke-width="2.6"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                        />
                    </svg>
                </button>
                <button
                    onClick={() => {
                        setUserModal(true)
                        setUserEditData(row)
                        setIsEdit(true)
                        setUpdateEmail(row.clientEmail)
                    }}
                    className={`${readOnly && 'jnc-btn-disable'} jncTable-actions-btn`}
                    disabled={readOnly}
                >
                    <span>Edit</span>
                    <svg
                        data-e2e="edit"
                        width="18"
                        height="18"
                        viewBox="0 0 18 18"
                        fill="none"
                    >
                        <path
                            d="M13.9999 7.33326L10.6666 3.99993M1.08325 16.9166L3.90356 16.6032C4.24813 16.5649 4.42042 16.5458 4.58146 16.4937C4.72433 16.4474 4.86029 16.3821 4.98566 16.2994C5.12696 16.2062 5.24954 16.0836 5.49469 15.8385L16.4999 4.83326C17.4204 3.91279 17.4204 2.4204 16.4999 1.49993C15.5795 0.579452 14.0871 0.579451 13.1666 1.49992L2.16136 12.5052C1.91621 12.7503 1.79363 12.8729 1.70045 13.0142C1.61778 13.1396 1.55243 13.2755 1.50618 13.4184C1.45405 13.5794 1.43491 13.7517 1.39662 14.0963L1.08325 16.9166Z"
                            stroke="#344054"
                            strokeWidth="1.6"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                        />
                    </svg>
                </button>
            </div>
        </React.Fragment>
    )
}
