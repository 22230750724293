import React, { useRef, useState } from 'react'
import FocusTrap from 'focus-trap-react'
import { usePopper } from 'react-popper'
import { DayPicker } from 'react-day-picker'

export default function JncDayPicker({ formattedDate, onSelect, selected }) {
    const popperRef = useRef(null)
    const buttonRef = useRef(null)
    const [popperElement, setPopperElement] = useState(null)
    const popper = usePopper(popperRef.current, popperElement, {
        placement: 'bottom-start',
    })
    const [isPopperOpen, setIsPopperOpen] = useState(false)
    const closePopper = () => setIsPopperOpen(false)
    const handleDateClick = () => setIsPopperOpen(true)

    function onDateSelect(date) {
        onSelect(date)
        setIsPopperOpen(false)
    }

    return (
        <div
            ref={popperRef}
            className="jncTable-inputs-group jncTable-inputs-btnGroup"
        >
            <button
                type="button"
                className="jncInput jncProfile__dob"
                aria-label="Select a date"
                onClick={handleDateClick}
                ref={buttonRef}
            >
                {formattedDate}
            </button>
            <svg
                className="jncTable-inputs-group-icon"
                width="12"
                height="7"
                viewBox="0 0 12 7"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    d="M0.69922 0.700049C0.882553 0.516715 1.11588 0.425049 1.39922 0.425049C1.68255 0.425049 1.91589 0.516715 2.09922 0.700049L5.99922 4.60005L9.89922 0.700048C10.0826 0.516715 10.3159 0.425049 10.5992 0.425049C10.8826 0.425049 11.1159 0.516715 11.2992 0.700048C11.4826 0.883382 11.5742 1.11671 11.5742 1.40005C11.5742 1.68338 11.4826 1.91672 11.2992 2.10005L6.69922 6.70005C6.59922 6.80005 6.49089 6.87105 6.37422 6.91305C6.25755 6.95505 6.13255 6.97572 5.99922 6.97505C5.86589 6.97505 5.74089 6.95405 5.62422 6.91205C5.50755 6.87005 5.39922 6.79938 5.29922 6.70005L0.69922 2.10005C0.515886 1.91672 0.424218 1.68338 0.424218 1.40005C0.424218 1.11672 0.515886 0.883382 0.69922 0.700049Z"
                    fill="#667085"
                />
            </svg>
            {isPopperOpen && (
                <FocusTrap
                    active
                    focusTrapOptions={{
                        initialFocus: false,
                        allowOutsideClick: true,
                        clickOutsideDeactivates: true,
                        onDeactivate: closePopper,
                        fallbackFocus: buttonRef.current,
                    }}
                >
                    <div
                        tabIndex={-1}
                        style={popper.styles.popper}
                        className="custom-date-picker dialog-sheet"
                        {...popper.attributes.popper}
                        ref={setPopperElement}
                        role="dialog"
                        aria-label="DayPicker calendar"
                    >
                        <DayPicker
                            mode="single"
                            captionLayout="dropdown"
                            initialFocus={isPopperOpen}
                            selected={selected}
                            onSelect={onDateSelect}
                            fromYear={1920}
                            toYear={2013}
                            modifiersClassNames={{
                                selected: 'selected-date',
                            }}
                        />
                    </div>
                </FocusTrap>
            )}
        </div>
    )
}
