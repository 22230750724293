import React from 'react'
import { NavLink } from 'react-router-dom'
import LandingLayout from '../../client/layouts/LandingLayout'

export default function JunoPolicyPage() {
    const scrollTo = (id) => {
        const element = document.getElementById(id)
        if (element) {
            const paddingTop = 90
            const offset = -paddingTop
            element.scrollIntoView({
                behavior: 'smooth',
                block: 'start',
                inline: 'nearest',
                offset: offset,
            })

            element.style.paddingTop = paddingTop + 'px'
        }
    }

    return (
        <LandingLayout>
            <div className="bg-black w-full">
                <div className="xl:w-[1280px] max-w-[1280px] xl:mx-auto h-full lg:h-[830px] px-[16px] md:px-[24px] lg:px-[80px] pt-[154px] lg:pt-[206px] pb-[48px] lg:pb-[150px]">
                    <div>
                        <h1 className="w-full text-[36px] lg:text-[52px] font-medium pb-[32px] lg:pb-[48px]">
                            Contents
                        </h1>
                        <div className="flex flex-col gap-[32px] lg:gap-[40px] p-[48px] w-full border border-[#ffffff4d] border-solid rounded-lg bg-[#ffffff0f]">
                            <h3 className="text-[16px] lg:text-[32px] font-normal">
                                Clause
                            </h3>
                            <div className="text-[16px] lg:text-[18px] font-normal leading-normal">
                                <ol className="list-decimal grid grid-cols-1 lg:grid-cols-2 lg:gap-[48px]">
                                    <div>
                                        <li>
                                            <p
                                                onClick={() =>
                                                    scrollTo('section-1')
                                                }
                                                className="text-[#6298FF] cursor-pointer"
                                            >
                                                Important information and who we
                                                are.
                                            </p>
                                        </li>
                                        <li>
                                            <p
                                                onClick={() =>
                                                    scrollTo('section-2')
                                                }
                                                className="text-[#6298FF] cursor-pointer"
                                            >
                                                The data we collect about you.
                                            </p>
                                        </li>
                                        <li>
                                            <p
                                                onClick={() =>
                                                    scrollTo('section-3')
                                                }
                                                className="text-[#6298FF] cursor-pointer"
                                            >
                                                How is your personal data
                                                collected?
                                            </p>
                                        </li>
                                        <li>
                                            <p
                                                onClick={() =>
                                                    scrollTo('section-4')
                                                }
                                                className="text-[#6298FF] cursor-pointer"
                                            >
                                                How we use your personal data?
                                            </p>
                                        </li>
                                        <li>
                                            <p
                                                onClick={() =>
                                                    scrollTo('section-5')
                                                }
                                                className="text-[#6298FF] cursor-pointer"
                                            >
                                                Disclosures of your personal
                                                data.
                                            </p>
                                        </li>
                                    </div>

                                    <div>
                                        <li>
                                            <p
                                                onClick={() =>
                                                    scrollTo('section-6')
                                                }
                                                className="text-[#6298FF] cursor-pointer"
                                            >
                                                International transfers.
                                            </p>
                                        </li>
                                        <li>
                                            <p
                                                onClick={() =>
                                                    scrollTo('section-7')
                                                }
                                                className="text-[#6298FF] cursor-pointer"
                                            >
                                                Data security.
                                            </p>
                                        </li>
                                        <li>
                                            <p
                                                onClick={() =>
                                                    scrollTo('section-8')
                                                }
                                                className="text-[#6298FF] cursor-pointer"
                                            >
                                                Data retention.
                                            </p>
                                        </li>
                                        <li>
                                            <p
                                                onClick={() =>
                                                    scrollTo('section-9')
                                                }
                                                className="text-[#6298FF] cursor-pointer"
                                            >
                                                Your legal rights.
                                            </p>
                                        </li>
                                        <li>
                                            <p
                                                onClick={() =>
                                                    scrollTo('section-10')
                                                }
                                                className="text-[#6298FF] cursor-pointer"
                                            >
                                                Glossary.
                                            </p>
                                        </li>
                                    </div>
                                </ol>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="bg-[#F4F4F5]">
                <div className="max-w-[1280px] xl:mx-auto flex flex-col items-start justify-center gap-[40px] text-[#3F3F46] pt-[48px] lg:pt-[72px] pb-[64px] lg:pb-[96px] font-medium text-[16px] lg:text-[18px]">
                    <>
                        <div className="self-stretch relative px-[16px] md:px-[24px] xl:px-0">
                            <h2
                                className="text-[36px] font-medium"
                                id="section-1"
                            >
                                1. Important information and who we are.
                            </h2>
                        </div>
                        <div className="self-stretch flex flex-col items-start justify-center gap-[32px] xl:pl-[48px] px-[16px] md:px-[24px]">
                            <div className="flex flex-col gap-[16px]">
                                <p>
                                    <b>Purpose of this privacy policy</b>
                                </p>
                                <div className="flex flex-col gap-[24px]">
                                    <p>
                                        This Privacy Policy (the “<b>Policy</b>
                                        ”) is provided by Juno (“
                                        <b>Juno Pay Ltd</b>”, “<b>Juno</b>” “
                                        <b>we</b>”, “<b>us</b>” and “<b>our</b>
                                        ”) and Juno is made up of different
                                        companies. When you first apply or use a
                                        Juno product or service, the Juno
                                        company whose Terms and Conditions
                                        govern such product or service and which
                                        you agree to is the controller and is
                                        responsible for your personal data.
                                        <br />
                                        <br />
                                        This Policy (together with our Terms and
                                        Conditions a copy of which is available
                                        on our Website (the “<b>T&Cs</b>”) and
                                        any additional terms incorporated by
                                        reference into the T&C’s, together our “
                                        <b>Terms of Use</b>”) applies to your
                                        use of:
                                    </p>
                                </div>
                            </div>

                            <ul className="list-disc flex flex-col items-start justify-center gap-[24px] pl-[48px]">
                                <li>
                                    The Juno Pay app (the “<span>App</span>
                                    ”);
                                </li>
                                <li>
                                    Our website at junomoney.com (the “
                                    <span>Website</span>”);
                                </li>
                                <li>
                                    Any of the services available to you through
                                    the App or Website (the “
                                    <span>Services</span>”).
                                </li>
                            </ul>

                            <div className="flex flex-col gap-[24px]">
                                <p>
                                    Juno Pay respects your privacy and is
                                    committed to protecting your personal data
                                    and this Policy aims to give you information
                                    on how Juno Pay collects and processes your
                                    personal data through your use of the App,
                                    Website, and our Services, as well as what
                                    rights you have in relation to your personal
                                    data.
                                </p>
                                <p>
                                    Please use the Glossary to understand the
                                    meaning of some of the terms used in this
                                    Policy. We may provide this notice in
                                    languages other than English. If there are
                                    any discrepancies between other language
                                    versions and the English language version,
                                    the English language version is
                                    authoritative.
                                </p>
                                <p>
                                    This Policy is not intended for children,
                                    and we do not knowingly collect data
                                    relating to children.
                                </p>
                                <p>
                                    If you have any questions about this Policy,
                                    including any requests to exercise any legal
                                    rights you have in relation to your personal
                                    data please contact us by email at
                                    support@junomoney.com or by using the
                                    contact details below.
                                </p>
                                <p>
                                    You have the right to make a complaint at
                                    any time to your local data protection
                                    authority. In Canada, this is the Office of
                                    the Privacy Commissioner of Canada
                                    <i>(https://www.priv.gc.ca/en)</i>. We
                                    would, however, appreciate the chance to
                                    deal with your concerns before you approach
                                    the local data protection authority so
                                    please contact us in the first instance.
                                </p>
                            </div>
                            <div className="flex flex-col gap-[16px]">
                                <p>
                                    <b>
                                        Changes to the privacy policy and your
                                        duty to inform us of changes
                                    </b>
                                </p>
                                <div className="flex flex-col gap-[24px]">
                                    <p>
                                        This Policy is kept under regular review
                                        and may be amended or updated from time
                                        to time to reflect changes in our
                                        practices with respect to the processing
                                        of personal data, or changes in
                                        applicable laws. We encourage you to
                                        read this Policy carefully, and to
                                        regularly check this page to review any
                                        changes we might make in accordance with
                                        the terms of this Policy. You can always
                                        find the latest version of this Policy
                                        here on this page.
                                    </p>
                                    <p>
                                        It is important that the personal data
                                        we hold about you is accurate and
                                        current. Please keep us informed if your
                                        personal data changes during your
                                        relationship with us.
                                    </p>
                                </div>
                            </div>
                            <div className="flex flex-col gap-[16px]">
                                <p>
                                    <b>Third-party links</b>
                                </p>
                                <p>
                                    This website may include links to
                                    third-party websites, plug-ins and
                                    applications. Clicking on those links or
                                    enabling those connections may allow third
                                    parties to collect or share data about you.
                                    We do not control these third-party websites
                                    and are not responsible for their privacy
                                    statements or practices. When you leave our
                                    website, we encourage you to read the
                                    privacy policy of every website you visit.
                                </p>
                            </div>
                        </div>
                    </>

                    <>
                        <div className="self-stretch relative px-[16px] md:px-[24px] xl:px-0">
                            <h2
                                className="text-[36px] font-medium"
                                id="section-2"
                            >
                                2. The data we collect about you.
                            </h2>
                        </div>
                        <div className="self-stretch flex flex-col items-start justify-center gap-[32px] xl:pl-[48px] px-[16px] md:px-[24px]">
                            <div className="flex flex-col gap-[24px]">
                                <p>
                                    Personal data, or personal information,
                                    means any information about an individual
                                    from which that person can be identified. It
                                    does not include data where the identity has
                                    been removed (anonymous data).
                                </p>
                                <p>
                                    We may collect, use, store and transfer
                                    different kinds of personal data about you
                                    which we have grouped together as follows:
                                </p>
                            </div>
                            <ul className="list-disc flex flex-col items-start justify-center gap-[24px] pl-[48px]">
                                <li>
                                    <b>Identity Data</b> includes first name,
                                    last name, title, date of birth, age,
                                    gender, nationality, language and country of
                                    residence and your image in photo or video
                                    form (where required as part of our
                                    Know-Your-Customer (“
                                    <b>KYC</b>”) checks to verify your
                                    identity). We also collect
                                    <b>formal Identity Data</b> which includes
                                    government issued identity documents such as
                                    identity cards, passports, driving license
                                    and/or any other form of government issued
                                    identity document.
                                </li>
                                <li>
                                    <b>Employment Data</b>: includes job title,
                                    employer, location, proof of salary and/or
                                    earnings.
                                </li>
                                <li>
                                    <b>Financial Data</b> includes bank account
                                    details such as account number and sort
                                    code, payment card details, invoices,
                                    payment records, transaction history and/or
                                    other relevant financial data.
                                </li>
                                <li>
                                    <b>Transaction Data</b> includes details
                                    about payments to and from your account and
                                    other details of transactions on our
                                    Services (such as details of sender and
                                    receiver (whether a user or a client),
                                    amounts, currencies, timing and date).
                                </li>
                                <li>
                                    <b>Technical Data</b> includes internet
                                    protocol (IP) address, your login data,
                                    browser type, settings and version, time
                                    zone setting and location, browser plug-in
                                    types and versions, operating system, device
                                    type and platform, and other technology
                                    information on the devices you use to access
                                    the App, Website and Services.
                                </li>
                                <li>
                                    <b>Profile Data</b> includes your account
                                    registration details, purchases or payments
                                    made by you, your interests, preferences,
                                    feedback and survey responses.
                                </li>
                                <li>
                                    <b>Usage Data</b> includes language
                                    settings, dates and times of connecting to
                                    the App and Website, usage statistics,
                                    settings, QR codes, location data, data on
                                    how you interreact with and use our features
                                    (such as page response times, download
                                    errors, length of visits to certain pages,
                                    page interaction information (such as
                                    scrolling and clicks), and methods used to
                                    browse away from the page) and other
                                    technical communications information related
                                    to interactions you have with the App,
                                    Website and Services.
                                </li>
                                <li>
                                    <b>Marketing and Communications Data</b>
                                    includes your preferences in receiving
                                    marketing from us and our third parties,
                                    your communication preferences, any records
                                    of our discussions if you contact us or we
                                    contact you.
                                </li>
                            </ul>
                            <div className="flex flex-col gap-[24px]">
                                <p>
                                    We also collect, use and share
                                    <b>Aggregated Data</b> such as statistical
                                    or demographic data for any purpose.
                                    Aggregated Data could be derived from your
                                    personal data but is not considered personal
                                    data in law as this data will not directly
                                    or indirectly reveal your identity. For
                                    example, we may aggregate your Usage Data to
                                    calculate the percentage of users accessing
                                    a specific App or Website feature. However,
                                    if we combine or connect Aggregated Data
                                    with your personal data so that it can
                                    directly or indirectly identify you, we
                                    treat the combined data as personal data
                                    which will be used in accordance with this
                                    Policy.
                                </p>
                                <p>
                                    We do not collect any of the following
                                    <b>Special Categories of Personal Data</b>
                                    about you (this includes details about your
                                    race or ethnicity, religious or
                                    philosophical beliefs, sex life, sexual
                                    orientation, political opinions, trade union
                                    membership, information about your health,
                                    and genetic and biometric data). We may
                                    collect information about your criminal
                                    convictions and offences from third parties
                                    such as official registers, fraud prevention
                                    agencies, databases and partners who help us
                                    to provide our Services and to comply with
                                    our anti-money laundering or sanctions
                                    screening obligations.
                                </p>
                            </div>
                            <div className="flex flex-col gap-[16px]">
                                <p>
                                    <span>
                                        If you fail to provide personal data
                                    </span>
                                </p>
                                <p>
                                    Where we need to collect personal data by
                                    law, or under the terms of a contract we
                                    have with you, and you fail to provide that
                                    data when requested, we may not be able to
                                    perform the contract we have or are trying
                                    to enter into with you (for example, to
                                    provide you with goods or services). In this
                                    case, we may have to cancel a product or
                                    service you have with us but we will notify
                                    you if this is the case at the time.
                                </p>
                            </div>
                        </div>
                    </>

                    <>
                        <div className="self-stretch relative px-[16px] md:px-[24px] xl:px-0">
                            <h2
                                className="text-[36px] font-medium"
                                id="section-3"
                            >
                                3. How is your personal data collected?
                            </h2>
                        </div>
                        <div className="self-stretch flex flex-col items-start justify-center gap-[32px] xl:pl-[48px] px-[16px] md:px-[24px]">
                            <p>
                                We use different methods to collect data from
                                and about you including through:
                            </p>

                            <ul className="list-disc flex flex-col items-start justify-center gap-[24px] pl-[48px]">
                                <li>
                                    <p className="mb">
                                        <b>Direct interactions.</b>
                                        You may give us your Identity, Contact
                                        and Financial Data by filling in forms
                                        or by corresponding with us by post,
                                        phone, email or otherwise. This includes
                                        personal data you provide when you:
                                    </p>
                                    <ul className="list-disc flex flex-col items-start justify-center gap-[24px] pl-[48px]">
                                        <li>
                                            Apply for our products or services;
                                        </li>
                                        <li>
                                            Create an account on our website;
                                        </li>
                                        <li>
                                            Request marketing to be sent to you;
                                        </li>
                                        <li>
                                            Enter a competition, promotion or
                                            survey;
                                        </li>
                                        <li>Give us feedback or contact us.</li>
                                    </ul>
                                </li>
                                <li>
                                    <b>
                                        Automated technologies or interactions.
                                    </b>
                                    As you interact with our website, we will
                                    automatically collect Technical and Usage
                                    Data about your equipment, browsing actions
                                    and patterns. We collect this personal data
                                    by using cookies and other similar
                                    technologies. Please see our
                                    <NavLink to="/juno-cookies">
                                        <i>Cookie Policy</i>
                                    </NavLink>
                                    for further details.
                                </li>
                                <li>
                                    <b>
                                        Third parties or publicly available
                                        sources.
                                    </b>
                                    We will receive personal data about you from
                                    various third parties and public sources
                                    such as credit reference agencies, financial
                                    or credit institutions, official registers
                                    and databases, fraud prevention agencies,
                                    identity service providers, global PEP and
                                    Sanctions watchlists and partners who help
                                    us to provide our Services and meet our
                                    legal obligations. We may also use publicly
                                    available sources such as social media
                                    websites or apps when conducting KYC checks
                                    on you in order to comply with our
                                    anti-money laundering or sanctions screening
                                    obligations.
                                </li>
                            </ul>
                        </div>
                    </>

                    <div>
                        <div className="self-stretch relative px-[16px] md:px-[24px] xl:px-0">
                            <h2
                                className="text-[36px] font-medium"
                                id="section-4"
                            >
                                4. How we use your personal data?
                            </h2>
                        </div>
                        <div className="self-stretch flex flex-col items-start justify-center gap-[32px] xl:pl-[48px] px-[16px] md:px-[24px]">
                            <p>
                                We will only use your personal data when the law
                                allows us to. Most commonly, we will use your
                                personal data in the following circumstances:
                            </p>

                            <ul className="list-disc flex flex-col items-start justify-center gap-[24px] pl-[48px]">
                                <li>
                                    Where we need to perform the contract, we
                                    are about to enter into or have entered into
                                    with you.
                                </li>
                                <li>
                                    Where it is necessary for our legitimate
                                    interests (or those of a third party) and
                                    your interests and fundamental rights do not
                                    override those interests.
                                </li>
                                <li>
                                    Where we need to comply with a legal
                                    obligation.
                                </li>
                                <li>
                                    Where you have given us your consent. (You
                                    have the right to withdraw consent to
                                    marketing at any time by contacting us).
                                </li>
                            </ul>

                            <p>
                                Please consult our Glossary to find out more
                                about the types of lawful basis that we will
                                rely on to process your personal data.
                            </p>
                            <div className="flex flex-col gap-[16px]">
                                <p>
                                    <b>
                                        Purposes for which we will use your
                                        personal data
                                    </b>
                                </p>
                                <div className="flex flex-col gap-[24px]">
                                    <p className="mb">
                                        We have set out below, in a table
                                        format, a description of all the ways we
                                        plan to use your personal data, and
                                        which of the legal bases we rely on to
                                        do so. We have also identified what our
                                        legitimate interests are where
                                        appropriate.
                                    </p>
                                    <p>
                                        Note that we may process your personal
                                        data for more than one lawful ground
                                        depending on the specific purpose for
                                        which we are using your data. Please
                                        contact us if you need details about the
                                        specific legal ground we are relying on
                                        to process your personal data where more
                                        than one ground has been set out in the
                                        table below.
                                    </p>
                                </div>
                            </div>

                            <div className="pt-[48px] overflow-x-scroll text-[14px] lg:text-[18px]">
                                <div className=" rounded-lg border border-gray-500">
                                    <table className="table-fixed max-w-[1280px] border-collapse border border-gray-500 bg-gray-100 bg-opacity-85 whitespace-normal">
                                        <thead>
                                            <tr className="font-semibold">
                                                <th className="px-[24px] py-[12px] lg:!p-[24px] xl:min-w-[256px] whitespace-normal border border-gray-500">
                                                    Purpose/Activity
                                                </th>
                                                <th className="px-[24px] py-[12px] lg:!p-[24px] xl:min-w-[256px] whitespace-normal border border-gray-500">
                                                    Type of data
                                                </th>
                                                <th className="px-[24px] py-[12px] lg:!p-[24px] xl:min-w-[256px] whitespace-normal border border-gray-500">
                                                    Lawful basis for processing
                                                    including basis of
                                                    legitimate interest
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td className="px-[24px] py-[12px] lg:!p-[24px] xl:min-w-[256px] whitespace-normal border border-gray-500">
                                                    <p>
                                                        <span>
                                                            Registration and
                                                            Legal Compliance
                                                        </span>
                                                    </p>
                                                    <br />
                                                    <p>
                                                        To register you as a new
                                                        customer, including:
                                                    </p>
                                                    <br />
                                                    <ol
                                                        style={{
                                                            listStyleType:
                                                                'lower-alpha',
                                                        }}
                                                        className="pl-[24px]"
                                                    >
                                                        <li>
                                                            To conduct KYC
                                                            checks to confirm
                                                            your identity and
                                                            eligibility for an
                                                            account with us.
                                                        </li>
                                                        <li>
                                                            To meet our
                                                            contractual and
                                                            legal obligations
                                                            relating to any
                                                            product or services
                                                            you use.
                                                        </li>
                                                    </ol>
                                                </td>
                                                <td className="px-[24px] py-[12px] lg:!p-[24px] xl:min-w-[256px] whitespace-normal border border-gray-500">
                                                    <ol
                                                        style={{
                                                            listStyleType:
                                                                'lower-alpha',
                                                        }}
                                                        className="pl-[24px]"
                                                    >
                                                        <li>Identity.</li>
                                                        <li>Contact.</li>
                                                    </ol>
                                                </td>
                                                <td className="px-[24px] py-[12px] lg:!p-[24px] xl:min-w-[256px] whitespace-normal border border-gray-500">
                                                    <ol
                                                        style={{
                                                            listStyleType:
                                                                'lower-alpha',
                                                        }}
                                                        className="pl-[24px]"
                                                    >
                                                        <li>
                                                            Performance of a
                                                            contract with you.
                                                        </li>
                                                        <li>
                                                            Necessary to comply
                                                            with a legal
                                                            obligation.
                                                        </li>
                                                        <li>
                                                            We have obtained
                                                            your prior consent
                                                            to the Processing.
                                                        </li>
                                                    </ol>
                                                </td>
                                            </tr>

                                            <tr>
                                                <td className="px-[24px] py-[12px] lg:!p-[24px] xl:min-w-[256px] whitespace-normal border border-gray-500">
                                                    <p>
                                                        <span>
                                                            Provision of Sites,
                                                            Apps, and Services
                                                        </span>
                                                    </p>
                                                    <br />
                                                    <p>
                                                        To provide our App,
                                                        Website and Services to
                                                        you including:
                                                    </p>
                                                    <br />
                                                    <ol
                                                        style={{
                                                            listStyleType:
                                                                'lower-alpha',
                                                        }}
                                                        className="pl-[24px]"
                                                    >
                                                        <li>
                                                            Making and managing
                                                            payments, fees and
                                                            charges.
                                                        </li>
                                                        <li>
                                                            To collect and
                                                            recover money owed
                                                            to us.
                                                        </li>
                                                        <li>
                                                            To protect against
                                                            fraud and financial
                                                            crime.
                                                        </li>
                                                        <li>
                                                            To meet our legal
                                                            and regulatory
                                                            obligations.
                                                        </li>
                                                    </ol>
                                                </td>
                                                <td className="px-[24px] py-[12px] lg:!p-[24px] xl:min-w-[256px] whitespace-normal border border-gray-500">
                                                    <ol
                                                        style={{
                                                            listStyleType:
                                                                'lower-alpha',
                                                        }}
                                                        className="pl-[24px]"
                                                    >
                                                        <li>Identity.</li>
                                                        <li>Contact.</li>
                                                        <li>Financial.</li>
                                                        <li>Transaction.</li>
                                                        <li>
                                                            Marketing and
                                                            Communications.
                                                        </li>
                                                    </ol>
                                                </td>
                                                <td className="px-[24px] py-[12px] lg:!p-[24px] xl:min-w-[256px] whitespace-normal border border-gray-500">
                                                    <ol
                                                        style={{
                                                            listStyleType:
                                                                'lower-alpha',
                                                        }}
                                                        className="pl-[24px]"
                                                    >
                                                        <li>
                                                            Performance of a
                                                            contract with you.
                                                        </li>
                                                        <li>
                                                            Necessary for our
                                                            legitimate interests
                                                            (to recover debts
                                                            due to us).
                                                        </li>
                                                        <li>
                                                            Necessary to comply
                                                            with a legal
                                                            obligation.
                                                        </li>
                                                        <li>
                                                            We have obtained
                                                            your prior consent
                                                            to the Processing.
                                                        </li>
                                                    </ol>
                                                </td>
                                            </tr>

                                            <tr>
                                                <td className="px-[24px] py-[12px] lg:!p-[24px] xl:min-w-[256px] whitespace-normal border border-gray-500">
                                                    <p>
                                                        <span>
                                                            Communications
                                                        </span>
                                                    </p>
                                                    <br />
                                                    <p>
                                                        To manage our
                                                        relationship with you
                                                        which will include:
                                                    </p>
                                                    <br />
                                                    <ol
                                                        style={{
                                                            listStyleType:
                                                                'lower-alpha',
                                                        }}
                                                        className="pl-[24px]"
                                                    >
                                                        <li>
                                                            Notifying you about
                                                            changes to our terms
                                                            or privacy policy.
                                                        </li>
                                                        <li>
                                                            Asking you to leave
                                                            a review or take a
                                                            survey.
                                                        </li>
                                                    </ol>
                                                </td>
                                                <td className="px-[24px] py-[12px] lg:!p-[24px] xl:min-w-[256px] whitespace-normal border border-gray-500">
                                                    <ol
                                                        style={{
                                                            listStyleType:
                                                                'lower-alpha',
                                                        }}
                                                        className="pl-[24px]"
                                                    >
                                                        <li>Identity.</li>
                                                        <li>Contact.</li>
                                                        <li>Profile.</li>
                                                        <li>
                                                            Marketing and
                                                            Communications.
                                                        </li>
                                                    </ol>
                                                </td>
                                                <td className="px-[24px] py-[12px] lg:!p-[24px] xl:min-w-[256px] whitespace-normal border border-gray-500">
                                                    <ol
                                                        style={{
                                                            listStyleType:
                                                                'lower-alpha',
                                                        }}
                                                        className="pl-[24px]"
                                                    >
                                                        <li>
                                                            Performance of a
                                                            contract with you.
                                                        </li>
                                                        <li>
                                                            Necessary to comply
                                                            with a legal
                                                            obligation.
                                                        </li>
                                                        <li>
                                                            Necessary for our
                                                            legitimate interests
                                                            (to keep our records
                                                            updated and to study
                                                            how customers use
                                                            our
                                                            products/services).
                                                        </li>
                                                        <li>
                                                            We have obtained
                                                            your prior consent
                                                            to the Processing.
                                                        </li>
                                                    </ol>
                                                </td>
                                            </tr>

                                            <tr>
                                                <td className="px-[24px] py-[12px] lg:!p-[24px] xl:min-w-[256px] whitespace-normal border border-gray-500">
                                                    <p>
                                                        <span>
                                                            Competitions and
                                                            Surveys
                                                        </span>
                                                    </p>
                                                    <br />
                                                    <p>
                                                        To enable you to partake
                                                        in a prize draw,
                                                        competition or complete
                                                        a survey.
                                                    </p>
                                                </td>
                                                <td className="px-[24px] py-[12px] lg:!p-[24px] xl:min-w-[256px] whitespace-normal border border-gray-500">
                                                    <ol
                                                        style={{
                                                            listStyleType:
                                                                'lower-alpha',
                                                        }}
                                                        className="pl-[24px]"
                                                    >
                                                        <li>Identity.</li>
                                                        <li>Contact.</li>
                                                        <li>Profile.</li>
                                                        <li>Usage.</li>
                                                        <li>
                                                            Marketing and
                                                            Communications.
                                                        </li>
                                                    </ol>
                                                </td>
                                                <td className="px-[24px] py-[12px] lg:!p-[24px] xl:min-w-[256px] whitespace-normal border border-gray-500">
                                                    <ol
                                                        style={{
                                                            listStyleType:
                                                                'lower-alpha',
                                                        }}
                                                        className="pl-[24px]"
                                                    >
                                                        <li>
                                                            Performance of a
                                                            contract with you.
                                                        </li>
                                                        <li>
                                                            Necessary for our
                                                            legitimate interests
                                                            (to study how
                                                            customers use our
                                                            products/services,
                                                            to develop them and
                                                            grow our business).
                                                        </li>
                                                    </ol>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className="px-[24px] py-[12px] lg:!p-[24px] xl:min-w-[256px] whitespace-normal border border-gray-500">
                                                    <p>
                                                        <span>
                                                            Protecting our
                                                            Business
                                                        </span>
                                                    </p>
                                                    <br />
                                                    <p>
                                                        To administer and
                                                        protect our business,
                                                        our App and Website
                                                        (including
                                                        troubleshooting, data
                                                        analysis, testing,
                                                        system maintenance,
                                                        support, reporting and
                                                        hosting of data).
                                                    </p>
                                                </td>
                                                <td className="px-[24px] py-[12px] lg:!p-[24px] xl:min-w-[256px] whitespace-normal border border-gray-500">
                                                    <ol
                                                        style={{
                                                            listStyleType:
                                                                'lower-alpha',
                                                        }}
                                                        className="pl-[24px]"
                                                    >
                                                        <li>Identity.</li>
                                                        <li>Contact.</li>
                                                        <li>Technical.</li>
                                                        <li>Usage.</li>
                                                    </ol>
                                                </td>
                                                <td className="px-[24px] py-[12px] lg:!p-[24px] xl:min-w-[256px] whitespace-normal border border-gray-500">
                                                    <ol
                                                        style={{
                                                            listStyleType:
                                                                'lower-alpha',
                                                        }}
                                                        className="pl-[24px]"
                                                    >
                                                        <li>
                                                            Necessary for our
                                                            legitimate interests
                                                            (for running our
                                                            business, provision
                                                            of administration
                                                            and IT services,
                                                            network security, to
                                                            prevent fraud and in
                                                            the context of a
                                                            business
                                                            reorganisation or
                                                            group restructuring
                                                            exercise).
                                                        </li>
                                                        <li>
                                                            Necessary to comply
                                                            with a legal
                                                            obligation.
                                                        </li>
                                                    </ol>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className="px-[24px] py-[12px] lg:!p-[24px] xl:min-w-[256px] whitespace-normal border border-gray-500">
                                                    <p>
                                                        <span>Marketing</span>
                                                    </p>
                                                    <br />
                                                    <p>
                                                        To deliver relevant
                                                        website content and
                                                        advertisements to you
                                                        and measure or
                                                        understand the
                                                        effectiveness of the
                                                        advertising we serve to
                                                        you and make suggestions
                                                        and recommendations to
                                                        you about goods or
                                                        services that may be of
                                                        interest to you.
                                                    </p>
                                                </td>
                                                <td className="px-[24px] py-[12px] lg:!p-[24px] xl:min-w-[256px] whitespace-normal border border-gray-500">
                                                    <ol
                                                        style={{
                                                            listStyleType:
                                                                'lower-alpha',
                                                        }}
                                                        className="pl-[24px]"
                                                    >
                                                        <li>Identity.</li>
                                                        <li>Contact.</li>
                                                        <li>Profile.</li>
                                                        <li>Usage.</li>
                                                        <li>
                                                            Marketing and
                                                            Communications.
                                                        </li>
                                                        <li>Technical.</li>
                                                    </ol>
                                                </td>
                                                <td className="px-[24px] py-[12px] lg:!p-[24px] xl:min-w-[256px] whitespace-normal border border-gray-500">
                                                    <ol
                                                        style={{
                                                            listStyleType:
                                                                'lower-alpha',
                                                        }}
                                                        className="pl-[24px]"
                                                    >
                                                        <li>
                                                            Necessary for our
                                                            legitimate interests
                                                            (to study how
                                                            customers use our
                                                            products/services,
                                                            to develop them, to
                                                            grow our business
                                                            and to inform our
                                                            marketing strategy
                                                            and to develop our
                                                            products/services
                                                            and grow our
                                                            business).
                                                        </li>
                                                    </ol>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className="px-[24px] py-[12px] lg:!p-[24px] xl:min-w-[256px] whitespace-normal border border-gray-500">
                                                    <p>
                                                        <span>
                                                            Management of IT
                                                            systems
                                                        </span>
                                                    </p>
                                                    <br />
                                                    <p>
                                                        To use data analytics to
                                                        improve our website,
                                                        products and or
                                                        services, marketing,
                                                        customer relationships
                                                        and experiences
                                                    </p>
                                                </td>
                                                <td className="px-[24px] py-[12px] lg:!p-[24px] xl:min-w-[256px] whitespace-normal border border-gray-500">
                                                    <ol
                                                        style={{
                                                            listStyleType:
                                                                'lower-alpha',
                                                        }}
                                                        className="pl-[24px]"
                                                    >
                                                        <li>Technical.</li>
                                                        <li>Usage.</li>
                                                    </ol>
                                                </td>
                                                <td className="px-[24px] py-[12px] lg:!p-[24px] xl:min-w-[256px] whitespace-normal border border-gray-500">
                                                    <ol
                                                        style={{
                                                            listStyleType:
                                                                'lower-alpha',
                                                        }}
                                                        className="pl-[24px]"
                                                    >
                                                        <li>
                                                            Necessary for our
                                                            legitimate interests
                                                            (to define types of
                                                            customers for our
                                                            products and
                                                            services, to keep
                                                            our website updated
                                                            and relevant, to
                                                            develop our business
                                                            and to inform our
                                                            marketing strategy).
                                                        </li>
                                                    </ol>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className="px-[24px] py-[12px] lg:!p-[24px] xl:min-w-[256px] whitespace-normal border border-gray-500">
                                                    <p>
                                                        <span>
                                                            Data Statistics
                                                        </span>
                                                    </p>
                                                    <br />
                                                    <p>
                                                        To prepare anonymous
                                                        statistical datasets
                                                        including:
                                                    </p>
                                                    <br />
                                                    <ol
                                                        style={{
                                                            listStyleType:
                                                                'lower-alpha',
                                                        }}
                                                        className="pl-[24px]"
                                                    >
                                                        <li>
                                                            To help us with
                                                            forecasting
                                                            purposes.
                                                        </li>
                                                        <li>
                                                            To understand how
                                                            customer’s use Juno.
                                                        </li>
                                                        <li>
                                                            To comply with
                                                            governmental
                                                            requirements and
                                                            requests.
                                                        </li>
                                                    </ol>
                                                </td>
                                                <td className="px-[24px] py-[12px] lg:!p-[24px] xl:min-w-[256px] whitespace-normal border border-gray-500">
                                                    <ol
                                                        style={{
                                                            listStyleType:
                                                                'lower-alpha',
                                                        }}
                                                        className="pl-[24px]"
                                                    >
                                                        <li>Identity.</li>
                                                        <li>Contact.</li>
                                                        <li>Technical.</li>
                                                        <li>Usage.</li>
                                                        <li>Profile.</li>
                                                        <li>Transaction.</li>
                                                    </ol>
                                                </td>
                                                <td className="px-[24px] py-[12px] lg:!p-[24px] xl:min-w-[256px] whitespace-normal border border-gray-500">
                                                    <ol
                                                        style={{
                                                            listStyleType:
                                                                'lower-alpha',
                                                        }}
                                                        className="pl-[24px]"
                                                    >
                                                        <li>
                                                            Necessary for our
                                                            legitimate interests
                                                            (to define types of
                                                            customers for our
                                                            products and
                                                            services, to keep
                                                            our App, Website and
                                                            services updated and
                                                            relevant, to develop
                                                            our business and to
                                                            inform our business
                                                            strategy).
                                                        </li>
                                                        <li>
                                                            Necessary to comply
                                                            with a legal
                                                            obligation.
                                                        </li>
                                                    </ol>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className="px-[24px] py-[12px] lg:!p-[24px] xl:min-w-[256px] whitespace-normal border border-gray-500">
                                                    <p>
                                                        <span>
                                                            Helping with social
                                                            interactions:
                                                        </span>
                                                    </p>
                                                    <br />
                                                    <ol
                                                        style={{
                                                            listStyleType:
                                                                'lower-alpha',
                                                        }}
                                                        className="pl-[24px]"
                                                    >
                                                        <li>
                                                            Helping with social
                                                            interactions through
                                                            our services;
                                                        </li>
                                                        <li>
                                                            Adding extra
                                                            functions in order
                                                            to provide a better
                                                            customer experience;
                                                        </li>
                                                    </ol>
                                                    <br />
                                                    <p>
                                                        For example, if you give
                                                        us permission, we’ll use
                                                        the contacts list on
                                                        your phone so you can
                                                        easily identify other
                                                        Juno users and make
                                                        payments to your
                                                        contacts using the Juno
                                                        App. We will not pass
                                                        this information to any
                                                        third parties.
                                                    </p>
                                                </td>
                                                <td className="px-[24px] py-[12px] lg:!p-[24px] xl:min-w-[256px] whitespace-normal border border-gray-500">
                                                    <ol
                                                        style={{
                                                            listStyleType:
                                                                'lower-alpha',
                                                        }}
                                                        className="pl-[24px]"
                                                    >
                                                        <li>Identity.</li>
                                                        <li>Contact.</li>
                                                        <li>Technical.</li>
                                                        <li>Usage.</li>
                                                    </ol>
                                                </td>
                                                <td className="px-[24px] py-[12px] lg:!p-[24px] xl:min-w-[256px] whitespace-normal border border-gray-500">
                                                    <ol
                                                        style={{
                                                            listStyleType:
                                                                'lower-alpha',
                                                        }}
                                                        className="pl-[24px]"
                                                    >
                                                        <li>
                                                            Necessary for our
                                                            legitimate interests
                                                            (to provide a better
                                                            customer experience
                                                            for our users, to
                                                            provide new
                                                            functions to our
                                                            customers).
                                                        </li>
                                                        <li>
                                                            We have obtained
                                                            your prior consent
                                                            to the Processing.
                                                        </li>
                                                    </ol>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>

                            <div className="flex flex-col gap-[16px]">
                                <p>
                                    <b>Automated Decisions and Profiling</b>
                                </p>

                                <p>
                                    We may also process personal data for the
                                    purposes of automated decision-making and
                                    profiling as outlined for the following
                                    purposes below.
                                </p>
                            </div>

                            <p>
                                Our legal basis for these is one or more of the
                                following:
                            </p>

                            <ul className="list-disc flex flex-col items-start justify-center gap-[24px] pl-[48px]">
                                <li>Performance of a contract with you.</li>
                                <li>
                                    Necessary to comply with a legal obligation.
                                </li>
                                <li>
                                    Necessary for our legitimate interests (to
                                    develop and improve how we deal with
                                    financial crime and meet our legal and
                                    regulatory requirements).
                                </li>
                            </ul>

                            <div className="flex flex-col gap-[16px]">
                                <p>
                                    <b>Transaction Monitoring</b>
                                </p>
                                <p>
                                    We engage a third party to analyse
                                    transactions to highlight suspicious
                                    behaviour, detect fraud and potentially
                                    block suspicious transactions. This
                                    profiling activity may mean that
                                    transactions are rejected or delayed if the
                                    activity is suspicious we may also report
                                    suspicious activity to the relevant
                                    regulatory bodies or law enforcement
                                    agencies.
                                </p>
                            </div>

                            <div className="flex flex-col gap-[16px]">
                                <p>
                                    <b>Know Your Customer Checks</b>
                                </p>
                                <p>
                                    We may engage third parties to collect
                                    information and analyse it for KYC purposes
                                    such as for anti-money laundering, identity
                                    and sanctions checks, this information is
                                    then removed from their system and stored on
                                    ours. This profiling activity may mean that
                                    sign up is rejected or delayed and we may
                                    also need to provide this information
                                    relevant regulatory bodies or law
                                    enforcement agencies.
                                </p>
                            </div>

                            <div className="flex flex-col gap-[16px]">
                                <p>
                                    <b>Marketing</b>
                                </p>
                                <p>
                                    We strive to provide you with choices
                                    regarding certain personal data uses,
                                    particularly around marketing and
                                    advertising. If you do not wish to receive
                                    marketing and advertising information,
                                    please see below under Opting Out.
                                </p>
                            </div>

                            <p>Our legal basis is:</p>

                            <ul className="list-disc flex flex-col items-start justify-center gap-[24px] pl-[48px]">
                                <li>
                                    Where you have given us your consent, you
                                    have the right to withdraw consent to
                                    marketing at any time by contacting us).
                                </li>
                                <li>
                                    Necessary for our legitimate interests i.e
                                    to send you marketing and provide
                                    information relevant to your interests).
                                </li>
                            </ul>

                            <div className="flex flex-col gap-[16px]">
                                <p>
                                    <b>Promotional offers from us</b>
                                </p>
                                <div className="flex flex-col gap-[24px]">
                                    <p>
                                        We may use your Identity, Contact,
                                        Technical, Usage and Profile Data to
                                        form a view on what we think you may
                                        want or need, or what may be of interest
                                        to you. This is how we decide which
                                        products, services and offers may be
                                        relevant for you (we call this
                                        marketing).
                                    </p>
                                    <p>
                                        You will receive marketing
                                        communications from us if you have
                                        requested information from us or
                                        purchased goods or services from us and
                                        you have not opted out of receiving that
                                        marketing.
                                    </p>
                                </div>
                            </div>

                            <div className="flex flex-col gap-[16px]">
                                <p>
                                    <b>Third-party marketing</b>
                                </p>
                                <p>
                                    We will get your express opt-in consent
                                    before we share your personal data with any
                                    third party for marketing purposes.
                                </p>
                            </div>

                            <div className="flex flex-col gap-[16px]">
                                <p>
                                    <b>Opting out</b>
                                </p>
                                <div className="flex flex-col gap-[24px]">
                                    <p>
                                        You can ask us or third parties to stop
                                        sending you marketing messages at any
                                        time by following the opt-out links on
                                        any marketing message sent to you or by
                                        contacting us at any time.
                                    </p>
                                    <p>
                                        Where you opt out of receiving these
                                        marketing messages, we will not send you
                                        further promotional emails, but in some
                                        circumstances we will continue to
                                        contact you to the extent necessary for
                                        the purposes of any providing our App,
                                        Website or Services you have requested.
                                    </p>
                                </div>
                            </div>

                            <div className="flex flex-col gap-[16px]">
                                <p>
                                    <b>Cookies</b>
                                </p>
                                <p>
                                    You can set your browser to refuse all or
                                    some browser cookies, or to alert you when
                                    websites set or access cookies. If you
                                    disable or refuse cookies, please note that
                                    some parts of this Website may become
                                    inaccessible or not function properly. For
                                    more information about the cookies we use,
                                    please see
                                    <NavLink to="/juno-cookies">
                                        <i>Cookie Policy</i>
                                    </NavLink>
                                    .
                                </p>
                            </div>

                            <div className="flex flex-col gap-[16px]">
                                <p>
                                    <b>Change of purpose</b>
                                </p>
                                <div className="flex flex-col gap-[24px]">
                                    <p>
                                        We will only use your personal data for
                                        the purposes for which we collected it,
                                        unless we reasonably consider that we
                                        need to use it for another reason and
                                        that reason is compatible with the
                                        original purpose. If you wish to get an
                                        explanation as to how the processing for
                                        the new purpose is compatible with the
                                        original purpose, please contact us.
                                    </p>
                                    <p>
                                        If we need to use your personal data for
                                        an unrelated purpose, we will notify you
                                        and we will explain the legal basis
                                        which allows us to do so.
                                    </p>
                                    <p>
                                        Please note that we may process your
                                        personal data without your knowledge or
                                        consent, in compliance with the above
                                        rules, where this is required or
                                        permitted by law.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>

                    <>
                        <div className="self-stretch relative px-[16px] md:px-[24px] xl:px-0">
                            <h2
                                className="text-[36px] font-medium"
                                id="section-5"
                            >
                                5. Disclosures of your personal data.
                            </h2>
                        </div>
                        <div className="self-stretch flex flex-col items-start justify-center gap-[32px] xl:pl-[48px] px-[16px] md:px-[24px]">
                            <p>
                                We may share your personal data with the parties
                                set out below for the purposes set out in the
                                table Purposes for which we will use your
                                personal data above.
                            </p>

                            <ul className="list-disc flex flex-col items-start justify-center gap-[24px] pl-[48px]">
                                <li>
                                    Internal Third Parties as set out in the
                                    <em>Glossary</em>.
                                </li>
                                <li>
                                    External Third Parties as set out in the
                                    <em>Glossary</em>.
                                </li>
                                <li>
                                    Third parties to whom we may choose to sell,
                                    transfer or merge parts of our business or
                                    our assets. Alternatively, we may seek to
                                    acquire other businesses or merge with them.
                                    If a change happens to our business, then
                                    the new owners may use your personal data in
                                    the same way as set out in this privacy
                                    policy.
                                </li>
                            </ul>

                            <p>
                                We require all third parties to respect the
                                security of your personal data and to treat it
                                in accordance with the law. We do not allow our
                                third-party service providers to use your
                                personal data for their own purposes and only
                                permit them to process your personal data for
                                specified purposes and in accordance with our
                                instructions.
                            </p>
                        </div>
                    </>

                    <>
                        <div className="self-stretch relative px-[16px] md:px-[24px] xl:px-0">
                            <h2
                                className="text-[36px] font-medium"
                                id="section-6"
                            >
                                6. International transfers.
                            </h2>
                        </div>
                        <div className="self-stretch flex flex-col items-start justify-center gap-[32px] xl:pl-[48px] px-[16px] md:px-[24px]">
                            <div className="flex flex-col gap-[24px]">
                                <p>
                                    As we provide an international service, we
                                    may share your personal data outside of the
                                    European Economic Area (EEA) to help us
                                    provide our services.
                                </p>
                                <p>
                                    For example, if you make an international
                                    payment, we’ll send the payment to a
                                    recipient outside of the UK or EEA. We might
                                    also send your personal data outside of the
                                    United Kingdom or EEA to keep to global
                                    legal and regulatory requirements, and to
                                    provide ongoing support services.
                                </p>
                            </div>

                            <p>
                                We may send your personal data outside of the UK
                                or EEA to:
                            </p>

                            <ul className="list-disc flex flex-col items-start justify-center gap-[24px] pl-[48px]">
                                <li>
                                    Comply with global legal and regulatory
                                    requirements.
                                </li>
                                <li>Provide ongoing support services.</li>
                                <li>
                                    Law enforcement agencies, fraud prevention
                                    agencies and other international
                                    authorities.
                                </li>
                                <li>
                                    Enable us to provide you with the product or
                                    service you have requested.
                                </li>
                            </ul>

                            <p>
                                Whenever we transfer your personal data out of
                                the EEA, we ensure a sufficient degree of
                                protection is afforded to it by ensuring at
                                least one of the following safeguards is
                                implemented:
                            </p>

                            <ul className="list-disc flex flex-col items-start justify-center gap-[24px] pl-[48px]">
                                <li>
                                    We will only transfer your personal data to
                                    countries that have been deemed to provide
                                    an adequate level of protection for personal
                                    data.
                                </li>
                                <li>
                                    We may use contracts with strict data
                                    protection safeguards as well as specific
                                    contracts (such as the European Commission’s
                                    Standard Contractual Clauses) which give
                                    personal data the sufficient protection
                                    before we transfer your personal data.
                                </li>
                            </ul>

                            <p>
                                Please contact us if you want further
                                information on the specific mechanism used by us
                                when transferring your personal data out of the
                                UK.
                            </p>
                        </div>
                    </>

                    <>
                        <div className="self-stretch relative px-[16px] md:px-[24px] xl:px-0">
                            <h2
                                className="text-[36px] font-medium"
                                id="section-7"
                            >
                                7. Data security.
                            </h2>
                        </div>
                        <div className="self-stretch flex flex-col items-start justify-center gap-[32px] xl:pl-[48px] px-[16px] md:px-[24px]">
                            <div className="flex flex-col gap-[24px]">
                                <p>
                                    We have put in place appropriate security
                                    measures to prevent your personal data from
                                    being accidentally lost, used or accessed in
                                    an unauthorised way, altered or disclosed.
                                    In addition, we limit access to your
                                    personal data to those employees, agents,
                                    contractors and other third parties who have
                                    a business need to know. They will only
                                    process your personal data on our
                                    instructions, and they are subject to a duty
                                    of confidentiality.
                                </p>
                                <p>
                                    We have put in place procedures to deal with
                                    any suspected personal data breach and will
                                    notify you and any applicable regulator of a
                                    breach where we are legally required to do
                                    so.
                                </p>
                            </div>
                        </div>
                    </>

                    <>
                        <div className="self-stretch relative px-[16px] md:px-[24px] xl:px-0">
                            <h2
                                className="text-[36px] font-medium"
                                id="section-8"
                            >
                                8. Data retention.
                            </h2>
                        </div>
                        <div className="self-stretch flex flex-col items-start justify-center gap-[32px] xl:pl-[48px] px-[16px] md:px-[24px]">
                            <div className="flex flex-col gap-[16px]">
                                <p>
                                    <b>
                                        How long will you use my personal data
                                        for?
                                    </b>
                                </p>
                                <div className="flex flex-col gap-[24px]">
                                    <p>
                                        We will only retain your personal data
                                        for as long as reasonably necessary to
                                        fulfil the purposes we collected it for,
                                        including for the purposes of satisfying
                                        any legal, regulatory, tax, accounting
                                        or reporting requirements. We may retain
                                        your personal data for a longer period
                                        in the event of a complaint, if we
                                        reasonably believe there is a prospect
                                        of litigation in respect to our
                                        relationship with you or where we are
                                        required by legal and regulatory
                                        obligations.
                                    </p>
                                    <p>
                                        To determine the appropriate retention
                                        period for personal data, we consider
                                        the amount, nature and sensitivity of
                                        the personal data, the potential risk of
                                        harm from unauthorised use or disclosure
                                        of your personal data, the purposes for
                                        which we process your personal data and
                                        whether we can achieve those purposes
                                        through other means, and the applicable
                                        legal, regulatory, tax, accounting or
                                        other requirements.
                                    </p>
                                    <p>
                                        By law we have to keep basic information
                                        about our customers (including Contact,
                                        Identity, Financial and Transaction
                                        Data) for six years after they cease
                                        being customers.
                                    </p>
                                    <p>
                                        In some circumstances you can ask us to
                                        delete your data: see your legal rights
                                        below for further information.
                                    </p>
                                    <p>
                                        In some circumstances we will anonymise
                                        your personal data (so that it can no
                                        longer be associated with you) for
                                        research or statistical purposes, in
                                        which case we may use this information
                                        indefinitely without further notice to
                                        you.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </>

                    <>
                        <div className="self-stretch relative px-[16px] md:px-[24px] xl:px-0">
                            <h2
                                className="text-[36px] font-medium"
                                id="section-9"
                            >
                                9. Your legal rights.
                            </h2>
                        </div>
                        <div className="self-stretch flex flex-col items-start justify-center gap-[32px] xl:pl-[48px] px-[16px] md:px-[24px]">
                            <p>
                                Under certain circumstances, you have rights
                                under data protection laws in relation to your
                                personal data.
                            </p>

                            <p>You have the right to:</p>

                            <p>
                                <b>Request access</b> to your personal data
                                (commonly known as a "data subject access
                                request"). This enables you to receive a copy of
                                the personal data we hold about you and to check
                                that we are lawfully processing it.
                            </p>

                            <p>
                                <b>Request correction</b> of the personal data
                                that we hold about you. This enables you to have
                                any incomplete or inaccurate data we hold about
                                you corrected, though we may need to verify the
                                accuracy of the new data you provide to us.
                            </p>

                            <p>
                                <b>Request erasure</b> of your personal data.
                                This enables you to ask us to delete or remove
                                personal data where there is no good reason for
                                us continuing to process it. You also have the
                                right to ask us to delete or remove your
                                personal data where you have successfully
                                exercised your right to object to processing
                                (see below), where we may have processed your
                                information unlawfully or where we are required
                                to erase your personal data to comply with local
                                law. Note, however, that we may not always be
                                able to comply with your request of erasure for
                                specific legal reasons which will be notified to
                                you, if applicable, at the time of your request.
                            </p>

                            <p>
                                <b>Object to processing</b> of your personal
                                data where we are relying on a legitimate
                                interest (or those of a third party) and there
                                is something about your particular situation
                                which makes you want to object to processing on
                                this ground as you feel it impacts on your
                                fundamental rights and freedoms. You also have
                                the right to object where we are processing your
                                personal data for direct marketing purposes. In
                                some cases, we may demonstrate that we have
                                compelling legitimate grounds to process your
                                information which override your rights and
                                freedoms.
                            </p>

                            <p>
                                <b>Request restriction</b> of processing of your
                                personal data. This enables you to ask us to
                                suspend the processing of your personal data in
                                the following scenarios:
                            </p>

                            <ul className="list-disc flex flex-col items-start justify-center gap-[24px] pl-[48px]">
                                <li>
                                    If you want us to establish the data's
                                    accuracy.
                                </li>
                                <li>
                                    Where our use of the data is unlawful but
                                    you do not want us to erase it.
                                </li>
                                <li>
                                    Where you need us to hold the data even if
                                    we no longer require it as you need it to
                                    establish, exercise or defend legal claims.
                                </li>
                                <li>
                                    You have objected to our use of your data
                                    but we need to verify whether we have
                                    overriding legitimate grounds to use it.
                                </li>
                            </ul>

                            <p>
                                <b>Request the transfer</b> of your personal
                                data to you or to a third party. We will provide
                                to you, or a third party you have chosen, your
                                personal data in a structured, commonly used,
                                machine-readable format. Note that this right
                                only applies to automated information which you
                                initially provided consent for us to use or
                                where we used the information to perform a
                                contract with you.
                            </p>

                            <p>
                                <b>Withdraw consent at any time</b>
                                where we are relying on consent to process your
                                personal data. However, this will not affect the
                                lawfulness of any processing carried out before
                                you withdraw your consent. If you withdraw your
                                consent, we may not be able to provide certain
                                products or services to you. We will advise you
                                if this is the case at the time you withdraw
                                your consent.
                            </p>

                            <p>
                                If you wish to exercise any of the rights set
                                out above, please contact us.
                            </p>

                            <div className="flex flex-col gap-[16px]">
                                <p>
                                    <b>No fee usually required</b>
                                </p>
                                <p>
                                    You will not have to pay a fee to access
                                    your personal data (or to exercise any of
                                    the other rights). However, we may charge a
                                    reasonable fee if your request is clearly
                                    unfounded, repetitive or excessive.
                                    Alternatively, we could refuse to comply
                                    with your request in these circumstances.
                                </p>
                            </div>

                            <div className="flex flex-col gap-[16px]">
                                <p>
                                    <b>What we may need from you</b>
                                </p>
                                <p>
                                    We may need to request specific information
                                    from you to help us confirm your identity
                                    and ensure your right to access your
                                    personal data (or to exercise any of your
                                    other rights). This is a security measure to
                                    ensure that personal data is not disclosed
                                    to any person who has no right to receive
                                    it. We may also contact you to ask you for
                                    further information in relation to your
                                    request to speed up our response.
                                </p>
                            </div>

                            <div className="flex flex-col gap-[16px]">
                                <p>
                                    <b>Time limit to respond</b>
                                </p>
                                <p>
                                    We try to respond to all legitimate requests
                                    within one month. Occasionally it could take
                                    us longer than a month if your request is
                                    particularly complex or you have made a
                                    number of requests. In this case, we will
                                    notify you and keep you updated
                                </p>
                            </div>
                        </div>
                    </>

                    <>
                        <div className="self-stretch relative px-[16px] md:px-[24px] xl:px-0">
                            <h2
                                className="text-[36px] font-medium"
                                id="section-10"
                            >
                                10. Glossary.
                            </h2>
                        </div>
                        <div className="self-stretch flex flex-col items-start justify-center gap-[32px] xl:pl-[48px] px-[16px] md:px-[24px]">
                            <p>
                                <b>LAWFUL BASIS</b>
                            </p>

                            <p>
                                <b>Legitimate Interest</b> means the interest of
                                our business in conducting and managing our
                                business to enable us to give you the best
                                service/product and the best and most secure
                                experience. We make sure we consider and balance
                                any potential impact on you (both positive and
                                negative) and your rights before we process your
                                personal data for our legitimate interests. We
                                do not use your personal data for activities
                                where our interests are overridden by the impact
                                on you (unless we have your consent or are
                                otherwise required or permitted to by law). You
                                can obtain further information about how we
                                assess our legitimate interests against any
                                potential impact on you in respect of specific
                                activities by contacting us.
                            </p>

                            <p>
                                <b>Performance of Contract</b> means processing
                                your data where it is necessary for the
                                performance of a contract to which you are a
                                party or to take steps at your request before
                                entering into such a contract.
                            </p>

                            <p>
                                <b>Comply with a legal obligation</b>
                                means processing your personal data where it is
                                necessary for compliance with a legal obligation
                                that we are subject to.
                            </p>

                            <p>
                                <b>Where you have given us your consent</b>
                                means where you have agreed to us collecting
                                your personal data, such as when you tick a box
                                to indicate you’re happy for us to use your
                                personal data in a certain way.
                            </p>

                            <p>
                                <b>THIRD PARTIES</b>
                            </p>

                            <p>
                                <b>Internal Third Parties</b>
                            </p>
                            <p>
                                Other companies in the Juno group who help us to
                                provide the best service to you and other
                                customers.
                            </p>

                            <p>
                                <b>External Third Parties</b>
                            </p>

                            <ul className="list-disc flex flex-col items-start justify-center gap-[24px] pl-[48px]">
                                <li>
                                    <div className="flex flex-col gap-[24px]">
                                        <p>Suppliers, such as:</p>

                                        <ul
                                            className="list-none flex flex-col items-start justify-center gap-[24px] pl-[48px]"
                                            style={{
                                                listStyleType: 'circle',
                                            }}
                                        >
                                            <li>
                                                Suppliers who provide us with
                                                payment, IT and delivery
                                                services to help us provide our
                                                services to you.
                                            </li>
                                            <li>
                                                Our banking and financial
                                                service partners and payment
                                                networks (such as Visa and
                                                Mastercard) to help us provide
                                                our services to you.
                                            </li>
                                            <li>
                                                Analytics providers, to help us
                                                monitor and improve our App and
                                                Website.
                                            </li>
                                            <li>
                                                Communication service providers,
                                                to help us provide our services
                                                and to send you emails, push
                                                notifications and text messages.
                                            </li>
                                        </ul>
                                    </div>
                                </li>
                                <li>
                                    Professional advisers acting as processors
                                    including lawyers, bankers, auditors and
                                    insurers who provide consultancy, banking,
                                    legal, insurance and accounting services.
                                </li>
                                <li>
                                    People or Companies you transfer money to,
                                    such as where you make a payment to another
                                    customer or a client we’ll provide the
                                    recipient with your details alongside your
                                    payment. Where a person or company sends
                                    money to you, we may also share personal
                                    data with the sender for example if a
                                    payment was sent to you by mistake, we will
                                    provide your personal data to the sender to
                                    help them try and get the payment back from
                                    you.
                                </li>
                                <li>
                                    Other Juno customers, we may share your
                                    basic contact information (your name as
                                    saved in the synced contacts list and mobile
                                    number) where we allow you or another
                                    customer with your number to sync phone
                                    contacts with us in order to see who in the
                                    contacts list is also a Juno customer. You
                                    can choose to not sync your contact list
                                    with us, in which case you will not see who
                                    in your contacts is also a Juno customer.
                                </li>
                                <li>
                                    <div className="flex flex-col gap-[24px]">
                                        <p>
                                            For legal and regulatory reasons, we
                                            may also share your personal data
                                            with other financial institutions
                                            government authorities, law
                                            enforcement authorities, tax
                                            authorities, companies and fraud
                                            prevention agencies to check your
                                            identity, protect against fraud,
                                            keep to tax laws, anti-money
                                            laundering laws, or any other laws
                                            and confirm that you’re eligible to
                                            use our products and services.
                                        </p>
                                        <ul
                                            className="list-none flex flex-col items-start justify-center gap-[24px] pl-[48px]"
                                            style={{
                                                listStyleType: 'circle',
                                            }}
                                        >
                                            <li>
                                                We may also need to share your
                                                personal data with other third
                                                parties such as if we are
                                                required to under any law or
                                                regulation, in connection with
                                                criminal or fraud
                                                investigations, to enforce our
                                                rights (and the rights of
                                                others) or in connection with
                                                legal claims.
                                            </li>
                                        </ul>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </>
                </div>
            </div>
        </LandingLayout>
    )
}
