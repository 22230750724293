import React from 'react'
import { useSelector } from 'react-redux'

export default function AccountCard({ data, openAccountModal }) {
    const imageBaseUrl = useSelector((state) => state.config.image_base_url)
    const balancesData = useSelector((state) => state.juno.balancesCurrencies)

    const currency = balancesData.find(
        (x) => x.currencyShortName == data.currency,
    )

    return (
        <button
            onClick={() => openAccountModal(data)}
            className="jncAccountCard jncCard"
        >
            <div className="jncAccountCard__grid">
                <div className="jncAccountCard__content">
                    <p
                        className="jncAccountCard__title"
                        data-e2e={data.accountNickName}
                    >
                        {data.accountNickName}
                    </p>
                    <p className="jncAccountCard__name">
                        {data.beneficiaryName}
                    </p>
                    <p className="jncAccountCard__account">{data.iban}</p>
                    {!data.iban && data.beneficiaryAccountNumber && (
                        <p className="jncAccountCard__account">
                            {data.beneficiaryAccountNumber}
                        </p>
                    )}
                </div>
                <div className="">
                    <div className="jncAccountCard__shortName">
                        <svg
                            width="25"
                            height="26"
                            viewBox="0 0 25 26"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                data-e2e="delete-icon"
                                d="M6.05371 11.9644H3.55371V20.7144H6.05371V11.9644ZM13.5537 11.9644H11.0537V20.7144H13.5537V11.9644ZM24.1787 23.2144H0.428711V25.7144H24.1787V23.2144ZM21.0537 11.9644H18.5537V20.7144H21.0537V11.9644ZM12.3037 3.53936L18.8162 6.96436H5.79121L12.3037 3.53936ZM12.3037 0.714355L0.428711 6.96436V9.46436H24.1787V6.96436L12.3037 0.714355Z"
                                fill="black"
                            />
                        </svg>

                        {currency && (
                            <img
                                className="jncAccountCard__curr"
                                src={`${imageBaseUrl}${currency.currencyIcon}`}
                                alt=""
                            />
                        )}
                    </div>
                </div>
            </div>
        </button>
    )
}
