import React from 'react'
import { LinearProgress } from '@mui/material'

const JncLoading = () => {
    const loadingStyle = {
        width: '100%',
        marginTop: '20px',
    }

    return (
        <div style={loadingStyle}>
            <LinearProgress />
        </div>
    )
}

export default JncLoading
