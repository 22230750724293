import ClearIcon from '@mui/icons-material/Clear'
import Dialog from '@mui/material/Dialog'
import qrcode from 'qrcode'
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { apiService } from '../../../../common/apiCallService'
import { checkValueInSS, useHeaderButtonStyle } from '../../../../common/theme'
import { useJunoCommonFunctions } from '../../../helpers'
import { CircularProgress } from '@mui/material'
import JncAuthClose from '../global/auth/JncAuthClose'
import JncAuthBottom from '../global/auth/JncAuthBottom'
import JncAuthTitle from '../global/auth/JncAuthTitle'
import AuthCode from 'react-auth-code-input'

const JunoFaAuthenticator = (props) => {
    const { getSecretKey } = useJunoCommonFunctions()
    const api_url = useSelector((state) => state.config.api_url)
    const [error, setError] = useState('')
    const [verificationCode2fa, change2faVerificationCode] = useState('')
    const [imageData, setImageData] = useState(null)
    const [secret, setSecret] = useState(null)
    const [colorMode, setColorMode] = useState(null)
    const [headerButtonDarkBgColour, setheaderButtonDarkBgColour] =
        useState(null)
    const [headerButtonLightBgColour, setheaderButtonLightBgColour] =
        useState(null)
    const [headerDarkTextColour, setheaderDarkTextColour] = useState(null)
    const [headerLightTextColour, setheaderLightTextColour] = useState(null)
    const headerLogo = sessionStorage.getItem('logo')
    const logoWidth = sessionStorage.getItem('logoWidth')
    const imageBaseURl = useSelector((state) => state.config.image_base_url)

    useEffect(() => {
        const updateSettings = async () => {
            if (checkValueInSS('colorMode')) {
                setColorMode(sessionStorage.getItem('colorMode'))
            }
            if (checkValueInSS('headerButtonDarkBgColour')) {
                setheaderButtonDarkBgColour(
                    sessionStorage.getItem('headerButtonDarkBgColour')
                )
            }
            if (checkValueInSS('headerButtonLightBgColour')) {
                setheaderButtonLightBgColour(
                    sessionStorage.getItem('headerButtonLightBgColour')
                )
            }
            if (checkValueInSS('headerDarkTextColour')) {
                setheaderDarkTextColour(
                    sessionStorage.getItem('headerDarkTextColour')
                )
            }
            if (checkValueInSS('headerLightTextColour')) {
                setheaderLightTextColour(
                    sessionStorage.getItem('headerLightTextColour')
                )
            }

            const data = await getSecretKey()
            if (data) {
                setSecret(data)
            }
        }

        updateSettings()
    }, [])

    useEffect(() => {
        if (secret) {
            generateQRcode()
        }
    }, [secret])

    const generateQRcode = async () => {
        let imageData = null
        await qrcode.toDataURL(secret.otpauth_url, function (err, imgData) {
            if (err) {
                console.error(err)
                alert('Something went wrong!')
            } else if (imgData) {
                imageData = imgData
            }
        })
        setImageData(imageData)
    }

    const onVerifyCode = async () => {
        if (verificationCode2fa && verificationCode2fa.length === 6) {
            props.setLoading(true)
            apiService(
                (window.location.hostname === 'localhost' ? api_url : '') +
                '/restapi/client-save-2fa-secret',
                {
                    email: localStorage.getItem('user_name')
                        ? localStorage.getItem('user_name')
                        : props.merchantEmail,
                    // merchantId: localStorage.getItem('merchantId') ? localStorage.getItem('merchantId') : props.merchantData.merchantId,
                    user_email: localStorage.getItem('user_name')
                        ? localStorage.getItem('user_name')
                        : props.merchantEmail,
                    user2faToken: verificationCode2fa,
                    secret: secret.base32,
                    encoding: 'base32',
                    changeActivation: 'activate',
                },
                (data) => {
                    props.setLoading(false)
                    if (data.success === true) {
                        if (props.component === 'head') {
                            props.onVerificationSucess(
                                props.merchantData,
                                props.merchantEmail
                            )
                        } else {
                            props.setIs2faActivated()
                            localStorage.setItem('2FAStatus', true)
                        }
                        props.onClose()
                    } else {
                        setError('Invalid verification code!')
                    }
                },
                (err) => {
                    props.setLoading(false)
                    alert('Something went wrong!')
                    console.log(err)
                }
            )
        } else {
            setError('Invalid token! 2FA token must have 6-digit only')
        }
    }

    const disabledVerify = verificationCode2fa.length !== 6
    let headerButtonStyle = useHeaderButtonStyle(
        colorMode,
        headerButtonDarkBgColour,
        headerDarkTextColour,
        headerButtonLightBgColour,
        headerLightTextColour
    )

    useEffect(() => {
        if (verificationCode2fa.length === 6) {
            onVerifyCode()
        }
    }, [verificationCode2fa])
    return (
        <Dialog
            open={props.open}
            className="jncAuth"
            fullScreen
            onClose={props.onClose}>
            <div className="jncAuth__body bg-brand-black ">
                <div>
                    <JncAuthClose onClose={props.onClose} />
                    <div className=" jncAuth__container p-8 !max-w-[644px] bg-white rounded-lg">
                        <div className="d-flex justify-center items-center flex-col m-auto">
                            <div className="mb-[20px]">
                                {headerLogo && headerLogo !== 'null' ? (
                                    <img
                                        src={headerLogo}
                                        alt="logo"
                                        width={logoWidth || '80'}
                                        height="auto"
                                    />
                                ) : (
                                    <img
                                        className="w-36"
                                        src={`${imageBaseURl}/landingpage/dark-logo.svg`}
                                        alt="logo"
                                    />
                                )}
                            </div>
                            <div className="max-w-[360px] mb-0">
                                <JncAuthTitle
                                    title="Two-factor authentification"
                                    subtitle="Scan the QR Code in Googles authenticator app and the input verification code."
                                />
                            </div>
                            <div className="theme-modal-authenticator flex justify-center items-center">
                                <img className="!h-48 !w-48" src={imageData} />
                            </div>

                        </div>
                        <form>
                            <div className="authCode">
                                <AuthCode
                                    onChange={(code) => {
                                        setError('')
                                        change2faVerificationCode(code)
                                    }}
                                />
                            </div>
                            {error !== '' && (
                                <div
                                    className="alert alert-danger text-center"
                                    role="alert"
                                >
                                    {error}
                                </div>
                            )}
                            {props.loading && (
                                <div className=" my-2.5 text-sm text-center gap-2 flex items-center justify-center">
                                    Verifying{' '}
                                    <CircularProgress
                                        size={14}
                                        style={{ color: 'black' }}
                                    />
                                </div>
                            )}
                        </form>
                    </div>
                </div>
                <JncAuthBottom />
            </div>
        </Dialog>
    )
}

export default JunoFaAuthenticator
