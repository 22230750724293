import React from 'react'
import JncLayout from '../layouts/JncLayout'
import JncCryptoAddress from '../components/dashboard/JncCryptoAddress'

export const JncCryptoAddressPage = () => {
    return (
        <JncLayout>
            <div className="jncPage">
                <JncCryptoAddress />
            </div>
        </JncLayout>
    )
}
