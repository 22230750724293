import React from 'react'
import JunoClientActionModal from '../../../../components/table/JunoClientActionModal'
import JncButton from '../../../../components/global/JncButton'
import { useJunoCommonFunctions } from '../../../../../helpers'

export default function BalanceFiatDeposit({ onClose, open }) {
    const { useWindowWidth } = useJunoCommonFunctions()
    const windowWidth = useWindowWidth()

    function DialogContentComponent() {
        return (
            <div>
                <p className="jncModal__text">
                    Please contact your Account Director for details of how to
                    deposit funds into your Juno Money account.
                </p>
            </div>
        )
    }

    function DialogActionsComponent() {
        return (
            <div className="jncModal__btns justify-end w-full">
              <JncButton
                onClickCall={onClose}
                isFullOnMobile={true}
                text="Close"
              />
            </div>
        )
    }

    return (
        <JunoClientActionModal
            onClose={onClose}
            title="Deposit"
            open={open}
            dialogContent={DialogContentComponent()}
            dialogActions={DialogActionsComponent()}
            fullScreen = {false}
            isDeposit={true}
            dialogClassName = "deposit"
            actionDivider = {false}
        ></JunoClientActionModal>
    )
}
