import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { apiService } from '../../../common/apiCallService'
import { clearAllValuesInObj } from '../../helpers'
const testEmailRule = (v) =>
    /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
        v,
    ) || false

export default function JunoAmContact() {
    const image_base_url = useSelector((state) => state.config.image_base_url)
    const api_url = useSelector((state) => state.config.api_url)
    const [responseMessage, setResponseMessage] = useState('')
    const [isLoading, setIsLoading] = useState(false)
    const [isSuccess, setIsSuccess] = useState(false)

    const [formData, setFormData] = useState({
        name: '',
        companyName: '',
        junoClientEmail: '',
        phoneNumber: '',
        message: '',
    })

    function onChangeInput(e) {
        const name = e.target.name
        const value = e.target.value
        setFormData((x) => {
            return {
                ...x,
                [name]: value,
            }
        })
    }

    function clearResponseMessage() {
        setTimeout(() => {
            setResponseMessage('')
            setIsSuccess(false)
        }, 4000)
    }

    function submit() {
        if (!formData.junoClientEmail) {
            setResponseMessage('Please fill your email address.')
            clearResponseMessage()
            return
        }
        if (!testEmailRule(formData.junoClientEmail)) {
            setResponseMessage('Please fill your email address correctly.')
            clearResponseMessage()
            return
        }
        if (
            formData.junoClientEmail &&
            testEmailRule(formData.junoClientEmail)
        ) {
            setIsLoading(true)
            apiService(
                (window.location.hostname === 'localhost' ? api_url : '') +
                    '/restapi/client-signup',
                {
                    ...formData,
                },
                async (data) => {
                    if (data) {
                        setIsLoading(false)
                        setIsSuccess(true)
                        setResponseMessage(
                            'Thank you! Your contact request has been successfully sent.',
                        )
                        setFormData((x) => clearAllValuesInObj(x))
                        clearResponseMessage()
                    }
                },
                (err) => {
                    console.log(err)
                    setIsLoading(false)
                    setIsSuccess(false)
                    setResponseMessage('Something went wrong!')
                    clearResponseMessage()
                },
            )
        }
    }

    const isEmailValid =
        formData.junoClientEmail && testEmailRule(formData.junoClientEmail)

    const Spinner = () => (
        <div className="data-loader-block">
            <div className="data-loader"></div>
        </div>
    )

    return (
        <section className="jnAm-section jnAm-contact" id="jnAm-contact">
            <div className="content-container">
                <div className="jnAm-contact-grid">
                    <form className="">
                        <div className="jnAm-contact-title">
                            <img
                                src={`${image_base_url}/juno-am/logo-small.webp`}
                                alt=""
                            />
                            <p>
                                Become a Juno <br />
                                Management Solutions client
                            </p>
                        </div>
                        <div className="jnAm-contact-form">
                            <div className="jnAm-contact-field">
                                <input
                                    type="text"
                                    className={`jnAm-input ${formData.name ? 'has-value' : ''}`}
                                    name="name"
                                    value={formData.name}
                                    onChange={(e) => onChangeInput(e)}
                                />
                                <div className="jnAm-contact-label">Name</div>
                            </div>
                            <div className="jnAm-contact-field">
                                <input
                                    type="text"
                                    className={`jnAm-input ${formData.companyName ? 'has-value' : ''}`}
                                    name="companyName"
                                    value={formData.companyName}
                                    onChange={(e) => onChangeInput(e)}
                                />
                                <div className="jnAm-contact-label">
                                    Company
                                </div>
                            </div>
                            <div className="jnAm-contact-field">
                                <input
                                    type="text"
                                    className={`jnAm-input ${formData.junoClientEmail ? 'has-value' : ''}`}
                                    name="junoClientEmail"
                                    value={formData.junoClientEmail}
                                    onChange={(e) => onChangeInput(e)}
                                />
                                <div className="jnAm-contact-label">Email</div>
                            </div>
                            <div className="jnAm-contact-field">
                                <input
                                    type="text"
                                    className={`jnAm-input ${formData.phoneNumber ? 'has-value' : ''}`}
                                    name="phoneNumber"
                                    value={formData.phoneNumber}
                                    onChange={(e) => onChangeInput(e)}
                                />
                                <div className="jnAm-contact-label">Phone</div>
                            </div>
                            <div className="jnAm-contact-field">
                                <textarea
                                    className={`jnAm-input jnAm-textarea ${formData.message ? 'has-value' : ''}`}
                                    name="message"
                                    value={formData.message}
                                    onChange={(e) => onChangeInput(e)}
                                />
                                <div className="jnAm-contact-label">
                                    Message*
                                </div>
                            </div>
                        </div>
                    </form>
                    <div className="jnAm-contact-block">
                        <div className="jnAm-contact-btn-block">
                            <button
                                onClick={submit}
                                disabled={
                                    !isEmailValid || isLoading || isSuccess
                                }
                                className={`jnAm-contact-btn ${isEmailValid || isLoading ? 'is-valid' : ''} ${isSuccess ? 'is-success' : ''}`}
                            >
                                {isLoading && <Spinner />}
                                {!isLoading && !isSuccess && (
                                    <img
                                        width="40"
                                        height="40"
                                        src={`${image_base_url}/juno-am/contact-arrow.svg`}
                                        alt=""
                                    />
                                )}
                                {isSuccess && (
                                    <img
                                        width="40"
                                        height="40"
                                        src={`${image_base_url}/juno-am/contact-success.svg`}
                                        alt=""
                                    />
                                )}
                            </button>
                            <div className="line"></div>
                        </div>
                        <p
                            className={`jnAm-contact-success ${isSuccess ? 'active' : ''}`}
                        >
                            {responseMessage}
                        </p>
                    </div>
                </div>
            </div>
            <div className="jnAm-bottomDeco"></div>
        </section>
    )
}
