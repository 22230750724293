import React from 'react'
import Header from './landing-pages/Header'
import FadeUp from './landing-pages/FadeUp'
import CustomButton from './landing-pages/CustomButton'

export default function JunoHero(props) {
    const {
        heroContent,
        buttonTitle,
        buttonOnClick,
        image,
        textColor,
        bgColor,
    } = props

    return (
        <section className="relative h-[100vh] md:h-[1033px] lg:h-[800px]">
            <Header />
            <div
                className="flex items-center absolute inset-0 max-w-[1280px] mx-auto !px-3 !pr-5 py-[143px] md:!pl-6 md:!py-[368px] md:!pr-[240px] lg:!pl-[150px] lg:!py-0 lg:!pr-0 xl:!pl-0 xl:!px-0"
                style={{ zIndex: 10 }}
            >
                <FadeUp>

                    <div className="w-[343px] md:w-[480px] lg:w-[619px]">
                        <h1
                            className={`text-${textColor} font-medium text-left pb-[30px] lg:pb-6 text-[56px] lg:text-7xl`}
                            dangerouslySetInnerHTML={{
                                __html: heroContent.title,
                            }}
                        ></h1>

                        <p
                            className={`text-${textColor} font-medium text-[18px] lg:text-[20px] text-left leading-normal`}
                            dangerouslySetInnerHTML={{
                                __html: heroContent.subtitle,
                            }}
                        ></p>
                        <div className="pt-[30px] lg:pt-9">

                            <CustomButton
                                data-e2e="contact-us"
                                className="w-[174px]"
                                title={buttonTitle}
                                onClick={buttonOnClick}
                            />
                        </div>
                    </div>
                </FadeUp>
            </div>
            {image && (
                <div className="absolute inset-0" style={{ zIndex: 5 }}>
                    <div className="absolute inset-0 bg-black opacity-50"></div>
                    <img
                        src={image}
                        alt="Background"
                        className="absolute inset-0 object-cover object-center w-full h-full"
                        style={{ zIndex: -1 }}
                        loading="lazy"
                    />
                </div>
            )}
        </section>
    )
}
