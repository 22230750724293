import * as React from 'react'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import CardMedia from '@mui/material/CardMedia'
import Typography from '@mui/material/Typography'
import { CardActions } from '@mui/material'
import FadeUp from './landing-pages/FadeUp'

export default function JunoHeroCard(props) {
    const { cardDetails } = props

    return (
        <Card
            className="bg-gray-900 min-h-[288px]"
            sx={{
                backgroundColor: cardDetails?.bgColor,
                padding: {
                    xs: 2,
                    lg: 3,
                },
                height: '100%',
                width: '100%',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',
                boxShadow: 'inherit',
            }}
        >
            <FadeUp direction="down">

                {cardDetails?.image && (
                    <div className="flex justify-center items-center">
                        <CardMedia
                            component="img"
                            image={cardDetails?.image}
                            alt="hero card image"
                            className="pb-5 px-0 md:px-6 lg:px-6 md:max-w-[600px] xl:max-w-[468px]"
                        />
                    </div>
                )}
                <div>
                    <Typography variant="h6" color={cardDetails?.textColor}>
                        {cardDetails?.id}
                    </Typography>
                    <Typography
                        variant="h5"
                        component="div"
                        fontWeight="bold"
                        color={cardDetails?.textColor}
                        className="pt-[15px]"
                        style={{fontFamily: 'Stagnan, sans-serif'}}
                    >
                        {cardDetails?.topTitle}
                    </Typography>
                    {cardDetails?.subTitle && (
                        <Typography
                            variant="body1"
                            color={cardDetails?.textColor}
                            className="w-[319px] md:w-[400px] !pt-[15px]"
                            style={{fontFamily: 'Inter, sans-serif'}}
                        >
                            {cardDetails?.subTitle}
                        </Typography>
                    )}
                </div>
                {cardDetails?.subImage && (
                    <div className="flex justify-center items-center py-[36px] lg:py-[32px]">
                        <CardMedia
                            component="img"
                            image={cardDetails?.subImage}
                            alt="hero card image"
                            className="py-[18px] md:py-[25px] lg:px-[18px] lg:py-[54px] md:max-w-[480px] xl:max-w-[480px]"
                        />
                    </div>
                )}
                {cardDetails?.bottomText && (
                    <div>
                        <Typography
                            variant="body1"
                            color={cardDetails?.textColor}
                            className="w-[319px] md:w-[400px]"
                            style={{fontFamily: 'Inter, sans-serif'}}
                        >
                            {cardDetails?.bottomText}
                        </Typography>
                    </div>
                )}
            </FadeUp>
        </Card>
    )
}
