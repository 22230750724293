import { SET_IMAGE_BASE_URL } from "../actions/actionTypes";

export default (
  state = {
    api_url: "http://localhost:8081",
    image_base_url: process.env.IMAGE_BASE_URL+"/images",
    browser: true, 
    env: { ENVIRONMENT: 'BROWSER' }
  },
  action
) => {
  switch (action.type) {
    case SET_IMAGE_BASE_URL:
      return {
        ...state,
        image_base_url: action.payload,
      };
    default:
      return state;
  }
};
