import * as actionTypes from '../actions/actionTypes';

const initialState = {
    loading: false,
    token: null,
}

const setToken = (state, action) => {
    return {
        ...state,
        token: action.data,
    };
};
const setLoading = (state, action) => {
    return {
        ...state,
        loading: action.data,
    };
};

const commonReducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.SET_TOKEN:
            return setToken(state, action);
        case actionTypes.SET_LOADING:
            return setLoading(state, action);
        default:
            return state;
    }
};

export default commonReducer;