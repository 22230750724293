import { FormControlLabel, Switch, styled } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { apiService } from '../../../../common/apiCallService'
import qrcode from 'qrcode'
import * as actions from '../../../../Redux/actions'
import DisableEnableGoogleAuthForJuno from './DisableEnableGoogleAuthForJuno'
import { useJunoCommonFunctions } from '../../../helpers'

const FAComp = () => {
    const { getSecretKey } = useJunoCommonFunctions()
    const clientData = useSelector((state) => state.juno.clientData)
    const readOnly = clientData && clientData.readOnly ? clientData.readOnly : false
    const api_url = useSelector((state) => state.config.api_url)
    const [inputValue, setInputValue] = useState('')
    const [openModal, setOpenModal] = useState(false)
    const [error, setError] = useState('')
    const [isLoading, setIsLoading] = useState(false)
    const [secret, setSecret] = useState(null)
    const [isActivated, setIsActivated] = useState(
        clientData && clientData.twoFA.enabled,
    )

    useEffect(() => {
        if (clientData) {
            setIsActivated(clientData.twoFA.enabled)
        }
    }, [clientData])

    useEffect(() => {
        if (secret) {
            save2FASetting()
        }
    }, [secret])

    const dispatch = useDispatch()
    const [qrCodeData, setQrCodeData] = useState(null)

    const handleModal = async () => {
        setOpenModal((x) => !x);
        setError('')
        const data = isActivated ? null : await getSecretKey()
        if (data) {
            setSecret(data)
        }
    };

    function onInputChange(val) {
        setError('')
        setInputValue(val)
    }

    async function changeActivation() {
        if (inputValue.length === 6) {
            setIsLoading(true)
            if (!isActivated) {
                apiService(
                    (window.location.hostname === 'localhost' ? api_url : '') +
                        '/restapi/client-save-2fa-secret',
                    {
                        secret: secret.base32,
                        encoding: 'base32',
                        clientId: clientData.clientId,
                    },
                    (data) => {
                        if (data.success) {
                            // setOpenModal(true);
                        } else {
                            alert('Something went wrong.')
                        }
                    },
                    (err) => {
                        setIsLoading(false)
                        alert('Something went wrong!')
                        console.log(err)
                    },
                )
            }
            apiService(
                (window.location.hostname === 'localhost' ? api_url : '') +
                    '/restapi/client-verify-2fa-token',
                {
                    user2faToken: inputValue,
                    changeActivation: isActivated ? 'deactivate' : 'activate',
                    user_email: clientData.clientEmail,
                    clientId: clientData.clientId,
                },
                (data) => {
                    setIsLoading(false)
                    if (data.success) {
                        console.log(
                            `2FA ${isActivated ? 'deactivate' : 'activate'}d successfully!`,
                        )
                        setOpenModal(false)
                        dispatch(actions.setClientData(null))
                    } else {
                        setError('Invalid verification token!')
                    }
                },
                (err) => {
                    setIsLoading(false)
                    alert('Something went wrong!')
                    console.log(err)
                },
            )
        } else {
            setError('Invalid token! 2FA token must have 6-digit only')
        }
    }


    const save2FASetting = async () => {
        qrcode.toDataURL(secret.otpauth_url, function (err, imgData) {
            if (imgData) {
                setQrCodeData(imgData)
            }
            if (err) {
                console.error(err)
                alert('Something went wrong!')
            }
        })
        setIsLoading(true)
        apiService(
            (window.location.hostname === 'localhost' ? api_url : '') +
                '/restapi/client-save-2fa-secret',
            {
                secret: secret.base32,
                encoding: 'base32',
                clientId: clientData.clientId,
            },
            (data) => {
                setIsLoading(false)
                if (data.success) {
                    setOpenModal(true)
                    setError("")
                } else {
                    alert('Something went wrong.')
                }
            },
            (err) => {
                setIsLoading(false)
                alert('Something went wrong!')
                console.log(err)
            },
        )
    }
    return (
        <div>
            <FormControlLabel
                control={
                    <IOSSwitch
                        sx={{ m: 1 }}
                        disabled={readOnly}
                        className={`${readOnly && 'jnc-btn-disable'}`}
                        onChange={() => {
                            handleModal()
                        }}
                        checked={isActivated}
                        data-e2e={isActivated}
                    />
                }
            />
            <DisableEnableGoogleAuthForJuno
                open={openModal}
                changeActivation={changeActivation}
                onClose={() => setOpenModal(false)}
                inputValue={inputValue}
                setInputValue={onInputChange}
                isActivated={isActivated}
                error={error}
                qrCodeData={qrCodeData}
                isLoading={isLoading}
            />
        </div>
    )
}

export default FAComp

const IOSSwitch = styled((props) => (
    <Switch
        focusVisibleClassName=".Mui-focusVisible"
        disableRipple
        {...props}
    />
))(({ theme }) => ({
    width: 42,
    height: 26,
    padding: 0,
    marginRight: 0,
    '& .MuiSwitch-switchBase': {
        padding: 0,
        margin: 2,
        transitionDuration: '300ms',
        '&.Mui-checked': {
            transform: 'translateX(16px)',
            color: '#fff',
            '& + .MuiSwitch-track': {
                backgroundColor:
                    theme.palette.mode === 'dark' ? '#2ECA45' : '#12B76A',
                opacity: 1,
                border: 0,
            },
            '&.Mui-disabled + .MuiSwitch-track': {
                opacity: 0.5,
            },
        },
        '&.Mui-focusVisible .MuiSwitch-thumb': {
            color: '#33cf4d',
            border: '6px solid #fff',
        },
        '&.Mui-disabled .MuiSwitch-thumb': {
            color:
                theme.palette.mode === 'light'
                    ? theme.palette.grey[100]
                    : theme.palette.grey[600],
        },
        '&.Mui-disabled + .MuiSwitch-track': {
            opacity: theme.palette.mode === 'light' ? 0.7 : 0.3,
            backgroundColor: "black !important"
        },
    },
    '& .MuiSwitch-thumb': {
        boxSizing: 'border-box',
        width: 22,
        height: 22,
    },
    '& .MuiSwitch-track': {
        borderRadius: 26 / 2,
        backgroundColor: theme.palette.mode === 'light' ? '#000000' : '#39393D',
        opacity: 1,
        transition: theme.transitions.create(['background-color'], {
            duration: 500,
        }),
    },
}))
