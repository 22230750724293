import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { apiService } from '../../../common/apiCallService'
import { testEmailRule } from '../../helpers'
import FadeUp from './landing-pages/FadeUp'
import CustomButton from './landing-pages/CustomButton'

export default function JunoContact(props) {
    const {
        heroContent,
        textAlign,
        textSize,
        textSizeLg,
        subtextSize,
        subtextSizeLg,
        fontWeight,
        customStyleHeader,
        customStyleForm,
        customStyleMdText,
        contactRef,
    } = props
    const apiUrl = useSelector((state) => state.config.api_url)

    const [name, setName] = useState('')
    const [companyName, setCompanyName] = useState('')
    const [junoClientEmail, setJunoClientEmail] = useState('') //* required
    const [phoneNumber, setPhoneNumber] = useState('')
    const [message, setMessage] = useState('')
    const [responseMessage, setResponseMessage] = useState('')

    function clearResponseMessage() {
        setTimeout(() => {
            setResponseMessage('')
        }, 4000)
    }

    function clearInputs() {
        setName('')
        setCompanyName('')
        setJunoClientEmail('')
        setPhoneNumber('')
        setMessage('')
    }

    function submit() {
        if (!junoClientEmail) {
            setResponseMessage('Please fill your email address.')
            clearResponseMessage()
            return
        }
        if (!testEmailRule(junoClientEmail)) {
            setResponseMessage('Please fill your email address correctly.')
            clearResponseMessage()
            return
        }
        if (junoClientEmail && testEmailRule(junoClientEmail)) {
            apiService(
                (window.location.hostname === 'localhost' ? apiUrl : '') +
                '/restapi/client-signup',
                {
                    name,
                    companyName,
                    junoClientEmail,
                    phoneNumber,
                    message,
                },
                async (data) => {
                    if (data) {
                        setResponseMessage(
                            'Thank you! Your contact request has been successfully sent.'
                        )
                        clearInputs()
                        clearResponseMessage()
                    }
                },
                (err) => {
                    console.log(err)
                    setResponseMessage('Something went wrong!')
                    clearResponseMessage()
                }
            )
        }
    }

    return (
        <div
            className={`bg-black max-w-[1280px] mx-auto py-[64px] lg:py-[100px] lg:px-[150px] w-full lg:h-[644px] xl:px-0`}
            ref={contactRef}
        >
            <FadeUp>

                {heroContent?.title && (
                    <div
                        className={`flex items-center lg:justify-center !px-3  ${customStyleMdText ? customStyleMdText : 'md:!px-6'
                            } lg:!px-0 xl:!px-[70px] !pb-[24px] ${customStyleHeader ? customStyleHeader : 'lg:!pb-[20px]'
                            }`}
                    >
                        <h1
                            className={`w-full text-white ${fontWeight ? fontWeight : 'font-normal'
                                } text-${textAlign} lg:text-center xl:text-center ${textSize
                                    ? textSize
                                    : 'text-4xl md:text-[42px] leading-none'
                                }  ${textSizeLg ? textSizeLg : 'lg:text-6xl'}`}
                            dangerouslySetInnerHTML={{
                                __html: heroContent.title,
                            }}
                        ></h1>
                    </div>
                )}
                {heroContent?.subtitle && (
                    <div
                        className={`flex items-center lg:justify-center !px-3  ${customStyleMdText ? customStyleMdText : 'md:!px-6'
                            } lg:!px-0 pt-[6px] pb-[24px] lg:pb-[20px]`}
                    >
                        <p
                            className={`w-full lg:w-[713px] text-white font-normal ${subtextSize
                                ? subtextSize
                                : 'sm:text-base md:text-xl'
                                } ${subtextSizeLg
                                    ? subtextSizeLg
                                    : 'lg:text-xl xl:text-xl'
                                } text-${textAlign} lg:text-center xl:text-center`}
                            dangerouslySetInnerHTML={{
                                __html: heroContent.subtitle,
                            }}
                        ></p>
                    </div>
                )}
            </FadeUp>

            <FadeUp delay={100}>

                <div
                    className={`grid grid-cols-1 gap-8 sm:grid-cols-1 md:grid-cols-1 lg:grid-cols-4 xl:grid-cols-4 !pt-[32px] ${customStyleForm ? customStyleForm : 'lg:!pt-[28px]'
                        }`}
                >
                    <div className="relative mb-6">
                        <input
                            data-e2e="name"
                            type="text"
                            onChange={(e) => setName(e.target.value)}
                            value={name}
                            className={`w-full px-0 py-2 bg-transparent border-b-2 border-white/25 text-white font-medium text-lg focus:outline-none peer ${name ? 'has-value' : ''
                                }`}
                        />
                        <label
                            htmlFor="name"
                            className={`absolute top-0 left-0 px-6 text-white font-medium text-lg transition-all duration-200 pointer-events-none peer-focus:translate-x-0 peer-focus:translate-y-[-30px] peer-focus:scale-75 ${name
                                ? 'translate-x-0 translate-y-[-30px] scale-75'
                                : 'translate-x-0 translate-y-0 scale-100'
                                }`}
                        >
                            Name
                        </label>
                    </div>
                    <div className="relative mb-6">
                        <input
                            data-e2e="company"
                            type="text"
                            onChange={(e) => setCompanyName(e.target.value)}
                            value={companyName}
                            className={`w-full px-0 py-2 bg-transparent border-b-2 border-white/25 text-white font-medium text-lg focus:outline-none peer ${companyName ? 'has-value' : ''
                                }`}
                        />
                        <label
                            htmlFor="company"
                            className={`absolute top-0 left-0 px-6 text-white font-medium text-lg transition-all duration-200 pointer-events-none peer-focus:translate-x-0 peer-focus:translate-y-[-30px] peer-focus:scale-75 ${companyName
                                ? 'translate-x-0 translate-y-[-30px] scale-75'
                                : 'translate-x-0 translate-y-0 scale-100'
                                }`}
                        >
                            Company
                        </label>
                    </div>
                    <div className="relative mb-6">
                        <input
                            data-e2e="email"
                            type="email"
                            onChange={(e) => setJunoClientEmail(e.target.value)}
                            value={junoClientEmail}
                            className={`w-full px-0 py-2 bg-transparent border-b-2 border-white/25 text-white font-medium text-lg focus:outline-none peer ${junoClientEmail ? 'has-value' : ''
                                }`}
                        />
                        <label
                            htmlFor="email"
                            className={`absolute top-0 left-0 px-6 text-white font-medium text-lg transition-all duration-200 pointer-events-none peer-focus:translate-x-0 peer-focus:translate-y-[-30px] peer-focus:scale-75 ${junoClientEmail
                                ? 'translate-x-0 translate-y-[-30px] scale-75'
                                : 'translate-x-0 translate-y-0 scale-100'
                                }`}
                        >
                            Email*
                        </label>
                    </div>
                    <div className="relative mb-6">
                        <input
                            data-e2e="phone"
                            type="text"
                            onChange={(e) => setPhoneNumber(e.target.value)}
                            value={phoneNumber}
                            className={`w-full px-0 py-2 bg-transparent border-b-2 border-white/25 text-white font-medium text-lg focus:outline-none peer ${phoneNumber ? 'has-value' : ''
                                }`}
                        />
                        <label
                            htmlFor="phone"
                            className={`absolute top-0 left-0 px-6 text-white font-medium text-lg transition-all duration-200 pointer-events-none peer-focus:translate-x-0 peer-focus:translate-y-[-30px] peer-focus:scale-75 ${phoneNumber
                                ? 'translate-x-0 translate-y-[-30px] scale-75'
                                : 'translate-x-0 translate-y-0 scale-100'
                                }`}
                        >
                            Phone
                        </label>
                    </div>

                    <div className="col-span-1 lg:col-span-4 xl:col-span-4">
                        <div className="relative mb-6">
                            <textarea
                                data-e2e="message"
                                onChange={(e) => setMessage(e.target.value)}
                                value={message}
                                className={`w-full px-0 py-0 bg-transparent border-b-2 border-white/25 text-white font-medium text-lg focus:outline-none peer ${message ? 'has-value' : ''
                                    }`}
                            />

                            <label
                                htmlFor="message"
                                className={`absolute top-0 left-0 px-6 text-white font-medium text-lg transition-all duration-200 pointer-events-none peer-focus:translate-x-0 peer-focus:translate-y-[-30px] peer-focus:scale-75 ${message
                                    ? 'translate-x-0 translate-y-[-30px] scale-75'
                                    : 'translate-x-0 translate-y-0 scale-100'
                                    }`}
                            >
                                Message
                            </label>
                        </div>
                    </div>
                </div>
                <div className="flex flex-col justify-center items-center">

                    <CustomButton
                        data-e2e="submit-btn"
                        className="w-44"
                        title="get in touch"
                        onClick={submit}
                    />
                    <p
                        data-e2e="error"
                        data-e2e-response={responseMessage}
                        className={`${responseMessage ? 'active' : ''
                            } text-white pt-4`}
                    >
                        {responseMessage}
                    </p>
                </div>
            </FadeUp>

        </div>
    )
}
