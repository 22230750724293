import React, { useEffect } from 'react'
import JncLayout from '../layouts/JncLayout'
import JncPageTitle from '../components/global/JncPageTitle'
import { useSelector } from 'react-redux'
import JncBalances from '../components/dashboard/JncBalances'
import { useJunoCommonFunctions } from '../../helpers'
import { AnimationOnScroll } from 'react-animation-on-scroll'
import JunoClientSnackbar from '../components/global/JunoClientSnackbar'

export default function JncBalancesPage() {
    const { getBalances, getSavedAccounts } = useJunoCommonFunctions()
    const clientData = useSelector((state) => state.juno.clientData)

    useEffect(() => {
        if (clientData) {
            getBalances()
        }
    }, [clientData])

    useEffect(() => {
        getSavedAccounts()
    }, [])

    return (
        <JncLayout>
            <div className="jncPage">
                {/* <AnimationOnScroll animateIn="animate__fadeInUpCustom" duration={1.2}> */}
                <JncPageTitle title="Balances" />
                <JncBalances isBalancesTab={true} />
                <JunoClientSnackbar />
                {/* </AnimationOnScroll> */}
            </div>
        </JncLayout>
    )
}
