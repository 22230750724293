export const individualInfo = {
    title: 'Payment solutions for individual clients',
    text: 'Juno Money provides a complete, end to end solution for all of your global payment needs. We provide a highly personalised service to corporate entities, institutions and high net worth individuals, including secure, competitive and discrete execution of cross-border payments and settlement services.',
    list: [
        'Fund your account by depositing to one of our many banking counterparties. Accept payments into your account from around the world.',
        'Withdraw your funds, utilising either Faster Payments, SEPA, SWIFT.',
        'Exchange in multiple currencies including GBP, EUR, USD, CAD, CHF, AED, AUD, HKD, SGD and many more.',
        'Send funds to other Juno Money users instantly.',
        'Onboarding for an individual account is quick and seamless.',
        'Dedicated Account Manager to support you with your Juno Money journey, available via your preferred communication channel.',
    ],
}

export const individualHeroContent = {
    title: '<span>Tailored, individual</span><br/> payment solutions',
    subtitle:
        'As a high net worth individual, if you’re looking for a completely unique service that offers you secure and discrete execution of all of your financial affairs, then Juno Money has the solution.',
}

export const homePageHeroContent = {
    title: 'Payment services.<br/> <span>Redefined</span>',
    subtitle:
        'Juno Money provides a complete, end to end solution for all of your global payment needs. We provide a highly personalised service to corporate entities, institutions and high net worth individuals, including secure, competitive and discrete execution of cross-border payments and settlement services.',
}

export const corporateHeroContent = {
    title: '<span>Grow beyond borders</span><br/> with a corporate account',
    subtitle:
        'Multi-currency accounts, instant internal transfers and international online payments — all in one platform. Juno Money offers everything your business needs to simplify your global payments and financial operations.',
}

export const bankingHeroContent = {
    title: '<span>Bespoke</span><br/> banking services for all',
    subtitle:
        'Juno Money provides a complete, end to end solution for all of your global baking needs. We provide a highly personalised service to institutions and high net worth individuals, including secure, competitive and discrete execution including deposit accounts, investments, cross-border payments and asset management solutions.',
}

export const everydayHeroContent = {
    title: 'Tailored, individual payment solutions',
    subtitle:
        "As a high net worth individual. If you're looking for a completely unique service that offers you secure and discrete execution of all of your financial affairs, then Juno Money has the solution",
}

export const everydayLoginContent = {
    title: '<span>Time is Money.</span><br/> Save both.',
    subtitle:
        'Juno Money provides a complete, end to end solution for all of your global payment needs. We provide a highly personalised service to corporate entities, institutions and high net worth individuals, including secure, competitive and discrete execution of cross-border payments and settlement services',
}

export const paymentServiceCardContent = {
    title: 'Payment Services and Cash Management',
    subtitle: '',
}

export const paymentServicePrivateBanking = {
    title: 'Private Banking solutions',
    subtitle:
        '<span>Our Private Banking solutions will allow you to benefit from our wealth management services with competent experts. We carry your assets with confidence and expertise. We provide access to domestic and international financial markets while managing your personal savings.</span></br>In addition to investment products such as mutual funds, bonds and bills, we also offer the most suitable solutions for your existence with standard and alternative structured derivative transactions such as options, DCD, forwards, and insurance and pension consultancy services to evaluate return opportunities in volatile market conditions.',
}

export const everydayContactUs = {
    title: 'Contact us to discuss your individual requirements further.',
    subtitle: '',
}

export const corporateContactUs = {
    title: 'Contact us to discuss your corporate requirements further.',
    subtitle: '',
}

export const contactUsContent = {
    title: 'Become a Juno Money client',
    subtitle:
        'Unlock a world of financial possibilities with us, open an account today and start experiencing unparalleled banking solutions tailored just for you.',
}

export const corporateInfo = {
    title: 'Payment solutions for corporate clients',
    text: 'Juno Money provides a complete, end to end solution for all of your global payment needs. We provide a highly personalised service to corporate entities, institutions and high net worth individuals, including secure, competitive and discrete execution of cross-border payments and settlement services.',
    list: [
        'Fund your account by depositing to one of our many banking counterparties. Accept payments into your account from around the world.',
        'Withdraw your funds and make global payments utilizing either Faster Payments, SEPA, SWIFT.',
        'Exchange in multiple currencies including GBP, EUR, USD, CAD, TRY, CHF, AED, AUD, HKD, SGD and many more.',
        'Send funds to other Juno Money users instantly.',
        'Onboarding for a corporate account is quick and seamless.',
        'Dedicated Account Manager to support you with your Juno Money journey, available via your preferred communication channel.',
    ],
}
