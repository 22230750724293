import React from 'react'
import CopyButton from '../../components/global/CopyButton'

export default function AccountsExpanded(props) {
    const row = props.data
    return (
        <div className="jncExpanded">
            {row && (
                <div className="jncExpanded-grid">
                    <div className="jncExpanded-block">
                        <p className="jncExpanded-label">Beneficiary address</p>
                        <div className="jncExpanded-block-copy jncExpanded-text">
                            <p>{row.beneficiaryAddress}</p>
                            <CopyButton copyValue={row.beneficiaryAddress} />
                        </div>
                    </div>
                    <div className="jncExpanded-block">
                        <p className="jncExpanded-label">Bank address</p>
                        <div className="jncExpanded-block-copy jncExpanded-text">
                            <p>{row.bankAddress}</p>
                            <CopyButton copyValue={row.bankAddress} />
                        </div>
                    </div>
                    <div className="jncExpanded-block">
                        <p className="jncExpanded-label">Sort code</p>
                        <div className="jncExpanded-block-copy jncExpanded-text">
                            <p>{row.sortCode}</p>
                            <CopyButton copyValue={row.sortCode} />
                        </div>
                    </div>
                    <div className="jncExpanded-block">
                        <p className="jncExpanded-label">IBAN</p>
                        <div className="jncExpanded-block-copy jncExpanded-text">
                            <p>{row.iban}</p>
                            <CopyButton copyValue={row.iban} />
                        </div>
                    </div>
                    <div className="jncExpanded-block">
                        <p className="jncExpanded-label">SWIFT</p>
                        <div className="jncExpanded-block-copy jncExpanded-text">
                            <p>{row.swift}</p>
                            <CopyButton copyValue={row.swift} />
                        </div>
                    </div>
                    <div className="jncExpanded-block">
                        <p className="jncExpanded-label">
                            Additional bank info
                        </p>
                        <div className="jncExpanded-block-copy jncExpanded-text">
                            <p>{row.additionalBankDetails}</p>
                            <CopyButton copyValue={row.additionalBankDetails} />
                        </div>
                    </div>
                </div>
            )}
        </div>
    )
}
