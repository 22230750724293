import React from 'react'
import localDb from './client/common/localDb'
import { Navigate } from 'react-router-dom';
class ProtectedRoute extends React.Component {
    render() {
        const token = localDb.getVal('user_name')
        const { component: Component, ...rest } = this.props
        if (typeof window === 'undefined') {
            return ''
        }
        return token && token.length > 0 ? <Component {...rest} />
            :
            <>
                {localStorage.setItem('redirectAfterLogin', window.location.pathname)}
                <Navigate to="/" />
            </>
    }
}

export default ProtectedRoute
