import { createStore, compose } from 'redux';
import reducers from '../client/Redux/reducers';
  
export default preloadState => {
  const composeEnhancers =
    (process.env.NODE_ENV === 'development' && typeof window !== 'undefined' && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) || compose;

  const store = createStore(
    reducers,
    preloadState,
    composeEnhancers()
  );
  return store;
};
