import React from 'react'
import ClearIcon from '@mui/icons-material/Clear'

export default function JncAuthClose({ onClose }) {
    return (
        <button type="button" onClick={onClose} className="jncAuth__close">
            <ClearIcon style={{ color: "white" }} className="icon-close" />
        </button>
    )
}
