import React from 'react'
import CopyButton from '../../components/global/CopyButton'
import { toFixedTrunc, getCurrencySymbol, getStatus } from '../../../helpers'
import { ErrorTransactions } from '../../constant'

export default function DepositExpanded(data) {
    const details = data && data.transactionDetails[0]
    const transactionFee = data && data.transactionFee
    const balanceAfterDeduction = data.transactionFee
        ? Number(details.amount) - Number(data.transactionFee)
        : details.amount

    return (
        <div className="jncExpanded jncTableExpandFont">
            <div className="grid grid-cols-2">
                {/* <<<<<< 1st Column >>>>>>>>>>> */}
                <div className="flex flex-col gap-3">
                    {data.transactionId && (
                        <div className="">
                            <p className="jncExpanded-label">Transaction Id</p>
                            <div className="jncExpanded-block-copy jncExpanded-text">
                                <p className="is-ellipsis">
                                    {data.transactionId}
                                </p>
                                <CopyButton copyValue={data.transactionId} />
                            </div>
                        </div>
                    )}
                    {details.USDAmount && (
                        <div className="">
                            <p className="jncExpanded-label">Amount</p>
                            <div className="jncExpanded-block-copy jncExpanded-text">
                                <p>$ {toFixedTrunc(details.USDAmount, 2)}</p>
                                <CopyButton
                                    copyValue={`$ ${toFixedTrunc(
                                        details.USDAmount,
                                        2
                                    )}`}
                                />
                            </div>
                        </div>
                    )}
                    <div className="">
                        <p className="jncExpanded-label">
                            {!transactionFee
                                ? 'Amount'
                                : 'Amount before deduction'}
                        </p>
                        <div className="jncExpanded-block-copy jncExpanded-text">
                            <p>
                                {getCurrencySymbol(details.currency)}{' '}
                                {toFixedTrunc(details.amount, 2) || '-'}{' '}
                            </p>
                        </div>
                    </div>
                    {transactionFee ? (
                        <div className="">
                            <p className="jncExpanded-label">
                                Amount after deduction
                            </p>
                            <div className="jncExpanded-block-copy jncExpanded-text">
                                <p>
                                    {getCurrencySymbol(details.currency)}{' '}
                                    {toFixedTrunc(balanceAfterDeduction, 2) ||
                                        '-'}{' '}
                                </p>
                            </div>
                        </div>
                    ) : (
                        ''
                    )}
                    <div className="">
                        <p className="jncExpanded-label">Currency</p>
                        <div className="jncExpanded-block-copy jncExpanded-text">
                            <p className="jncExpanded-text-icon">
                                {details.currency || '-'}
                            </p>
                        </div>
                    </div>

                    <div className="">
                        <p className="jncExpanded-label">Transaction Fee</p>
                        <div className="jncExpanded-block-copy jncExpanded-text">
                            <p>
                                {getCurrencySymbol(data.currency)}{' '}
                                {toFixedTrunc(transactionFee, 2) || '-'}
                            </p>
                            <CopyButton copyValue={transactionFee} />
                        </div>
                    </div>
                    <div className="">
                        <p className="jncExpanded-label">
                            Balance after transaction
                        </p>
                        {data.currencyType === 'fiat' && (
                            <div className="jncExpanded-block-copy jncExpanded-text">
                                <p>
                                    {getCurrencySymbol(details.currency)}{' '}
                                    {toFixedTrunc(
                                        data.balanceAfterTransaction,
                                        2
                                    ) || '-'}
                                </p>
                                <CopyButton
                                    copyValue={`${toFixedTrunc(
                                        data.balanceAfterTransaction,
                                        2
                                    )}`}
                                />
                            </div>
                        )}
                    </div>
                </div>

                {/* <<<<<< 2nd Column >>>>>>>>>>> */}
                <div className="flex flex-col gap-3">
                    <div className="">
                        <p className="jncExpanded-label">Remitter name</p>
                        <div className="jncExpanded-block-copy jncExpanded-text">
                            <p>{details.remitterName || '-'}</p>
                            <CopyButton copyValue={details.remitterName} />
                        </div>
                    </div>
                    <div className="jncExpanded-block col-span-2">
                        <p className="jncExpanded-label">Reference</p>
                        <div className="jncExpanded-block-copy jncExpanded-text">
                            <p>{details.reference || '-'}</p>
                            <CopyButton copyValue={details.reference} />
                        </div>
                    </div>
                </div>

                {ErrorTransactions.includes(data.status.code) && (
                    <div className="">
                        <p className="jncExpanded-failed">
                            {getStatus(data.status.code)}:
                        </p>
                        <div className="jncExpanded-block-copy jncExpanded-text">
                            <p>{data.status.message}</p>
                        </div>
                    </div>
                )}
            </div>
        </div>
    )
}
