import React, { useEffect, useState } from "react"
import JunoClientActionModal from '../../components/table/JunoClientActionModal'
import { getShortName } from '../../../helpers'
import Icon from '../../icons/Icon'
import CopyButton from "../../components/global/CopyButton"
import InputCopyButton from "../../components/global/InputCopyButton"
import JncActionDelete from "../../components/modals/JncActionDelete"
import ClearIcon from '@mui/icons-material/Clear'
import { useSelector } from 'react-redux'

export const JncCryptoAddressView = (props) => {
    const { onClose, open, setAddressActions, addressActions } = props;
    const [formData, setformData] = useState(props.addressData);
    const imageBaseUrl = useSelector((state) => state.config.image_base_url)
    const balancesData = useSelector((state) => state.juno.balancesCrypto)
    const currency = balancesData.find(
        (x) => x.currencyShortName == props.addressData.cryptoId,
    )
    useEffect(() => {
        setformData(props.addressData)
    }
        , [props.addressData])

    const dialogAddressComponent = () => {
        return (
            <>
                <div className="jn-addressdetails-box1">
                    <div className="jn-addressdetails-selectcrypto">
                        <p className="jn-addressdetails-content"> Select crypto: </p>
                        <p className="jn-addressdetails-title">({formData?.cryptoId})</p>
                    </div>
                    <div className="jn-addressdetails-selectcrypto jn-addressdetails-header">
                        <div className=" overflow-hidden w-10/12">
                            <p className="jn-addressdetails-content"> Wallet address:</p>
                            <p className="jn-addressdetails-title "> {formData.address} </p>
                        </div>
                        <InputCopyButton
                            className="min-516"
                            copyValue={formData.address} />
                        <CopyButton
                            className="max-515 jn-addressdetails-copy"
                            copyValue={formData.address} />
                    </div >
                    <div className="jn-addressdetails-header">
                        <div className=" overflow-hidden w-10/12">
                            <p className="jn-addressdetails-content">Nickname:</p>
                            <p className="jn-addressdetails-title">
                                {formData.nickName}
                            </p>
                        </div>
                        <InputCopyButton
                            className="min-516"
                            copyValue={formData.nickName} />
                        <CopyButton
                            className="max-515 jn-addressdetails-copy"
                            copyValue={formData.nickName} />
                    </div >
                </div>
            </>
        )
    }

    const dialogActionsComponent = () => {
        return (
            <>
                <div className="w-100">
                    <div className="jncModal__actions deleteTwoBtns min-516">
                        <div className="deleteTwoBtns-left">
                            <button
                                onClick={() => {
                                    setAddressActions({ ...addressActions, edit: true })
                                    onClose()
                                }}
                                data-e2e="edit-icon"
                                type="button"
                            >
                                < Icon id="editfill" className="jn-addressdetails-next" />
                            </button>
                            <JncActionDelete
                                onClickCall={() => {
                                    setAddressActions({ ...addressActions, delete: true })
                                    onClose()
                                }}
                            />
                        </div>
                        <div className="deleteTwoBtns-right">
                            <button
                                onClick={() => {
                                    onClose()
                                    setAddressActions({ ...addressActions, viewCrypto: true })
                                }}
                            >
                                < Icon id="transfer" className="jn-addressdetails-next" />
                            </button>
                        </div>

                    </div>
                    <div className="max-515 jn-addressdetails__btns">
                        <div>
                            <button
                                onClick={() => {
                                    onClose()
                                    setAddressActions({ ...addressActions, edit: true })
                                }}
                                data-e2e="edit-icon"
                                type="button"
                                className="jncActionBtn jncHint jncBalanceCard__actions-item  jnc-btn-disable"
                            >
                                < Icon id="editfill" className="jn-addressdetails-next" />
                            </button>
                            <p className="jn-addressdetails__btns_text">Edit</p>
                        </div>
                        <div>
                            <JncActionDelete
                                onClickCall={() => {
                                    onClose()
                                    setAddressActions({ ...addressActions, delete: true })
                                }}
                            />
                            <p className="jn-addressdetails__btns_text mx-0">Remove</p>
                        </div>
                        <div>
                            <>
                                <button
                                    onClick={() => {
                                        onClose()
                                        setAddressActions({ ...addressActions, viewCrypto: true })
                                    }}
                                >
                                    < Icon id="transfer" className="jn-addressdetails-next" />
                                </button>
                                <p className="jn-addressdetails__btns_text">Send</p></>
                        </div>
                    </div>
                </div>
            </>
        )
    }

    const onCloseAddress = () => {
        onClose();
        setAddressActions({ ...addressActions, edit: false, delete: false });
    }

    const viewEditDialogTitle = () => {
        return (
            <>
                <div className="jncAccountModal__header-left" style={{ height: '60px' }}>
                    <div className="jncAccountCard__shortName"
                        data-e2e="delete-icon" >
                        <p>{getShortName(formData.nickName)}</p>
                        {currency && (
                            <img
                                className="jncAccountCard__curr"
                                src={`${imageBaseUrl}${currency.currencyIcon}`}
                                alt=""
                            />
                        )}
                    </div>
                    <div className="overflow-hidden">
                        <p className="jncAccountModal__title">
                            {formData.nickName}
                        </p>
                        {formData.address && (
                            <p className="jncAccountModal__name">
                                {formData.address}
                            </p>
                        )}
                    </div>
                </div>
                <button
                    onClick={onCloseAddress}
                    type="button"
                    className="jncModal__close jn-addressdetails-close-icon">
                    <ClearIcon
                    />
                </button>
            </>
        )
    }

    return (<>
        <JunoClientActionModal
            onClose={onCloseAddress}
            dialogClassName="jn-addressdetails-dialog"
            contentClassName="jn-addressdetails-dialog-content"
            states={{
                start: true,
                confirm: false,
            }}
            formData={formData}
            open={open}
            dialogContent={dialogAddressComponent()}
            dialogActions={dialogActionsComponent()}
            dialogTitle={viewEditDialogTitle()}
        >
        </JunoClientActionModal>
    </>
    )
}