import React, { useLayoutEffect, useState } from 'react'
import DialogTitle from '@mui/material/DialogTitle'
import ClearIcon from '@mui/icons-material/Clear'
import { useSelector } from 'react-redux'
import Icon from '../../icons/Icon'
import { getShortName, useJunoCommonFunctions } from '../../../helpers'

export default function JncDialogTitleAccounts({
    hideIsEditAccount,
    onClose,
    formData,
    isEditAccount,
}) {
    const { useWindowWidth } = useJunoCommonFunctions()
    const imageBaseUrl = useSelector((state) => state.config.image_base_url)
    const balancesData = useSelector((state) => state.juno.balancesCurrencies)
    const currency = balancesData.find(
        (x) => x.currencyShortName == formData.currency,
    )

    const windowWidth = useWindowWidth()

    return (
        <div>
            {!isEditAccount && (
                <DialogTitle
                    id="scroll-dialog-title"
                    className="jncAccountModal__header"
                >
                    <div className="jncAccountModal__header-left">
                        <div
                            className="jncAccountCard__shortName"
                            data-e2e="delete-icon"
                        >
                            <p>{getShortName(formData.accountNickName)}</p>
                            {currency && (
                                <img
                                    className="jncAccountCard__curr"
                                    src={`${imageBaseUrl}${currency.currencyIcon}`}
                                    alt=""
                                />
                            )}
                        </div>
                        <div className="overflow-hidden">
                            <p className="jncAccountModal__title">
                                {formData.accountNickName}
                            </p>
                            {formData.beneficiaryName && (
                                <p className="jncAccountModal__name">
                                    {formData.beneficiaryName}
                                </p>
                            )}
                        </div>
                    </div>
                    <button
                        onClick={onClose}
                        type="button"
                        className="jncModal__close"
                    >
                        <ClearIcon />
                    </button>
                </DialogTitle>
            )}
            {isEditAccount && (
                <DialogTitle
                    id="scroll-dialog-title"
                    className="jncAccountModal__header"
                >
                    <div className="jncAccountModal__header-left edit-mobile">
                        <button onClick={hideIsEditAccount}>
                            <Icon id="arrowLeft" />
                        </button>
                        <p>Edit Account</p>
                    </div>
                    <button
                        onClick={onClose}
                        type="button"
                        className="jncModal__close"
                    >
                        <ClearIcon />
                    </button>
                </DialogTitle>
            )}
        </div>
    )
}
