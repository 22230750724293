import React from 'react'
import { useState } from 'react'
import { useSelector } from 'react-redux'
import JncSectionTitle from '../../components/global/JncSectionTitle'
import MediaQuery from 'react-responsive'
import TableComponent from '../../components/table/TableComponent'
import TableComponentMobile from '../../components/table/TableComponentMobile'
import { apiService } from '../../../../common/apiCallService'
import { useEffect } from 'react'
import JncAdditionalUserModal from './JncAdditionalUserModal'
import { Switch, styled } from '@mui/material'
import AdditionalUserActions from './AdditionalUserActions'

const JncAdditionalUser = ({
    showTablePagination,
    headerAction
}) => {
    const [userModal, setUserModal] = useState(false)
    const [userEditData, setUserEditData] = useState({})
    const [isEdit, setIsEdit] = useState(false)
    const [updateEmail, setUpdateEmail] = useState('')
    const clientData = useSelector((state) => state.juno.clientData)
    const readOnly =
        clientData && clientData.readOnly ? clientData.readOnly : false
    const [isLoading, setIsLoading] = useState(true)
    const [userData, setUserData] = useState([])
    const api_url = useSelector((state) => state.config.api_url)

    const columns = [
        {
            name: 'User Email',
            selector: (row) => row.clientEmail,
            sortable: true,
        },
        {
            name: 'Read only',
            selector: (row) => row.readOnly,
            sortable: true,
            cell: (row) => (
                <div className="jncModal__field">
                    <ReadOnly
                        sx={{ m: 1 }}
                        checked={row.readOnly}
                        onClick={() =>
                            handleUpdateUser(
                                {
                                    updateEmail: row.clientEmail,
                                    readOnly: !row.readOnly,
                                },
                                'update'
                            )
                        }
                    />
                </div>
            ),
        },
        {
            name: 'Actions',
            cell: (row) => (
                <AdditionalUserActions
                    setIsEdit={setIsEdit}
                    setUserEditData={setUserEditData}
                    setUserModal={setUserModal}
                    handleUpdateUser={handleUpdateUser}
                    row={row}
                    setUpdateEmail={setUpdateEmail}
                    readOnly={readOnly}
                />
            ),
        },
    ]

    const getClientUser = async () => {
        if (clientData) {
            setIsLoading(true)
            apiService(
                (window.location.hostname === 'localhost' ? api_url : '') +
                    '/restapi/get-client-associated-user',
                {
                    clientId: clientData.clientId,
                },
                async (data) => {
                    if (data) {
                        setUserData(data.clientsData)
                        setIsLoading(false)
                    }
                },
                (err) => {
                    console.log(err)
                    setIsLoading(false)
                }
            )
        }
    }

    const handleUpdateUser = async (userData, type) => {
        let payload = {
            clientId: clientData.clientId,
            parentClient: clientData._id,
            type,
        }
        if (type === 'update') {
            payload = {
                ...payload,
                updateEmail: updateEmail || userData.updateEmail,
                clientEmail: userData.clientEmail,
                password: userData.password,
                readOnly: userData.readOnly,
            }
        } else {
            payload = {
                ...payload,
                clientEmail: userData.clientEmail,
            }
        }
        apiService(
            (window.location.hostname === 'localhost' ? api_url : '') +
                '/restapi/update-client-associated-user',
            {
                ...payload,
            },
            async (data) => {
                getClientUser()
                setUserModal(false)
                setIsLoading(false)
            },
            (err) => {
                console.log(err)
            }
        )
    }

    useEffect(() => {
        getClientUser()
        if (headerAction) {
            setUserModal(true)
        }
    }, [clientData, headerAction])

    const handleClose = () => {
        setUserModal(false)
        setUserEditData({})
        setIsEdit(false)
        setUpdateEmail('')
    }
    return (
        <div className={`jncStatements jncSection mb`}>
            <div className={`jncTable`}>
                <div className={`min-768`}>
                    <JncSectionTitle title="Additional users">
                        {
                            <button
                                onClick={() => setUserModal(true)}
                                disabled={readOnly}
                                className={`${readOnly && 'jnc-btn-disable'}`}
                                data-e2e="add-additional-users-button"
                            >
                                Add additional users
                            </button>
                        }
                    </JncSectionTitle>
                </div>

                <MediaQuery minWidth={992}>
                    <TableComponent
                        isLoading={isLoading}
                        showTablePagination={showTablePagination}
                        columns={columns}
                        filteredData={userData}
                        noDataText="You don't have any users yet"
                    />
                </MediaQuery>

                <MediaQuery maxWidth={991}>
                    <TableComponentMobile
                        isLoading={isLoading}
                        valuesToDisplay={'Additional Users'}
                        filteredData={userData}
                        showTablePagination={showTablePagination}
                        noDataText="You don't have any users yet"
                        setIsEdit={setIsEdit}
                        setUserEditData={setUserEditData}
                        setUserModal={setUserModal}
                        handleUpdateUser={handleUpdateUser}
                        setUpdateEmail={setUpdateEmail}
                        readOnly={readOnly}
                    />
                </MediaQuery>

                <JncAdditionalUserModal
                    userEditData={userEditData}
                    handleUpdateUser={handleUpdateUser}
                    isEdit={isEdit}
                    open={userModal}
                    getClientUser={getClientUser}
                    onClose={handleClose}
                />
            </div>
        </div>
    )
}

const ReadOnly = styled((props) => (
    <Switch
        focusVisibleClassName=".Mui-focusVisible"
        disableRipple
        {...props}
    />
))(({ theme }) => ({
    width: 42,
    height: 26,
    padding: 0,
    '& .MuiSwitch-switchBase': {
        padding: 0,
        margin: 2,
        transitionDuration: '300ms',
        '&.Mui-checked': {
            transform: 'translateX(16px)',
            color: '#fff',
            '& + .MuiSwitch-track': {
                backgroundColor:
                    theme.palette.mode === 'dark' ? '#2ECA45' : '#12B76A',
                opacity: 1,
                border: 0,
            },
            '&.Mui-disabled + .MuiSwitch-track': {
                opacity: 0.5,
            },
        },
        '&.Mui-focusVisible .MuiSwitch-thumb': {
            color: '#33cf4d',
            border: '6px solid #fff',
        },
        '&.Mui-disabled .MuiSwitch-thumb': {
            color:
                theme.palette.mode === 'light'
                    ? theme.palette.grey[100]
                    : theme.palette.grey[600],
        },
        '&.Mui-disabled + .MuiSwitch-track': {
            opacity: theme.palette.mode === 'light' ? 0.7 : 0.3,
        },
    },
    '& .MuiSwitch-thumb': {
        color: '#FFFFFF !important',
        boxSizing: 'border-box',
        width: 22,
        height: 22,
    },
    '& .MuiSwitch-track': {
        borderRadius: 26 / 2,
        backgroundColor: theme.palette.mode === 'light' ? '#000000' : '#39393D',
        opacity: 1,
        transition: theme.transitions.create(['background-color'], {
            duration: 500,
        }),
    },
}))

export default JncAdditionalUser
