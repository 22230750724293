import React from 'react'
import JunoContact from '../components/JunoHeroContact'
import { contactUsContent } from '../data'
import LandingLayout from '../../client/layouts/LandingLayout'

export default function JunoContactUs() {
    return (
        <LandingLayout>
            <div className="flex items-center bg-black min-h-[1071px] md:min-h-0 h-[100vh] md:h-[1017px] lg:h-[1000px] my-[45px] xs:my-0">
                <JunoContact
                    heroContent={contactUsContent}
                    textAlign="left"
                    textSize="text-[56px]"
                    textSizeLg="lg:text-7xl"
                    subtextSize="text-lg"
                    subtextSizeLg="text-xl"
                    fontWeight="font-medium"
                    customStyleHeader="pt-[34px] md:pt-[50px] lg:pt-0 lg:!pb-[18px]"
                    customStyleForm="lg:!pt-[60px]"
                />
            </div>
        </LandingLayout>
    )
}
