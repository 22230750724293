import React, { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import CheckBoxIcon from '@mui/icons-material/CheckBox'
import ServicesComponent from '../components/landing-pages/ServicesComponent'
import JunoLogin from '../components/global/JunoLogin'
import JunoCard from '../components/JunoCard'
import JunoContact from '../components/JunoHeroContact'
import { contactUsContent } from '../data'
import { useNavigate } from 'react-router-dom'
import { useJunoCommonFunctions } from '../../helpers'
import { setIndividualStateInfo } from '../../../Redux/actions'
import LandingLayout from '../../client/layouts/LandingLayout'
import Reveal from 'react-awesome-reveal'
import { keyframes } from '@emotion/react'
import FadeUp from '../components/landing-pages/FadeUp'
import CustomButton from '../components/landing-pages/CustomButton'

export default function JunoMoneyLandingPage() {
    const imageBaseUrl = useSelector((state) => state.config.image_base_url)
    const [showLoginModal, setShowLoginModal] = useState(false)
    const [isExpanded, setIsExpanded] = useState(true)
    const { useWindowWidth } = useJunoCommonFunctions()

    const windowWidth = useWindowWidth()

    const navigate = useNavigate()
    const contactRef = useRef(null)

    const scrollToContact = () => {
        setTimeout(() => {
            if (contactRef.current) {
                contactRef.current.scrollIntoView({ behavior: 'smooth' })
            }
        }, 0)
    }

    const applicationsOptions1 = [
        { id: 1, text: 'Deposit and receive your payments with ease.' },
        {
            id: 2,
            text: 'Make global payments utilising our bespoke and intuitive platform.',
        },
        { id: 3, text: 'Trade in multiple currencies.' },
    ]

    const applicationsOptions2 = [
        {
            id: 1,
            text: 'Deposit and receive payments through our network of banking partners.',
        },
        { id: 2, text: 'Make global payments on our easy to use platform.' },
        { id: 3, text: 'Trade in a variety of currencies.' },
    ]

    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(setIndividualStateInfo(true))
        document.body.classList.add('juno-money-theme')
        document
            .getElementsByTagName('body')[0]
            .style.setProperty('--btn-background', '#8B66FD')
        return () => {
            document.body.classList.remove('juno-money-theme')
        }
    })

    const flipInXAnimation = keyframes`
  from {
    transform: perspective(800px) rotateX(-20deg);
    opacity: 0;
  }

  to {
    transform: perspective(800px) rotateX(0deg);
    opacity: 1;
  }
`
    return (
        <LandingLayout>
            <div className="h-[100vh] md:h-[1033px] lg:h-[1000px]">
                <div className="flex justify-center items-center h-[100vh] md:h-[1033px] lg:h-[1000px]">
                    <video
                        autoPlay
                        muted
                        loop
                        className="absolute inset-0 object-cover bg-cover bg-center w-full h-[100vh] md:h-[1033px] lg:h-[1000px]"
                    >
                        <source
                            src={`${imageBaseUrl}/landingpage/landing.mp4`}
                            type="video/mp4"
                        />
                    </video>
                </div>
                <div className="flex justify-center items-center absolute top-0 left-0 bg-[#00000080] h-[100vh] md:h-[1033px] lg:h-[1000px] w-full">
                    <div className="h-[100vh] md:h-[1033px] lg:h-[1000px] absolute top-0 inset-x-0 z-10 flex flex-col justify-center lg:justify-start lg:items-center max-w-[1280px] mx-auto">
                        <FadeUp>
                            <div className="sm:w-4/5 lg:w-[800px] lg:mt-[200px] lg:text-center !pl-3 !pr-5 sm:!pl-6 sm:pr-0 lg:p-0">
                                <h2 className="font-medium text-[56px] lg:text-7xl text-white">
                                    Payment services. Redefined
                                </h2>
                                <p className="text-lg lg:text-xl text-white mt-8 lg:mt-6">
                                    Juno Money provides a complete, end-to-end
                                    solution for all of your global payment
                                    needs. We provide a highly personalised
                                    service to corporate entities, institutions
                                    and high net worth individuals. This
                                    includes secure, competitive and discrete
                                    execution of cross-border payments and
                                    settlement services and much more.
                                </p>
                                <CustomButton
                                    className="mt-8 w-36 lg:mx-auto "
                                    title="log in"
                                    onClick={() => {
                                        setShowLoginModal(true)
                                    }}
                                />
                            </div>
                        </FadeUp>

                        <Reveal
                            effect="flipInX" // Use fade up animation
                            duration={1000} // Duration of the animation
                            keyframes={flipInXAnimation}
                        >
                            <div className="hidden w-4/5 xl:w-auto mx-auto lg:block mt-[75px] rounded-lg border border-white bg-gradient-to-b from-white-opacity-16 to-white-opacity-16 shadow-xl filter backdrop-blur-lg p-1">
                                <img
                                    className="z-10 m-auto"
                                    src={`${imageBaseUrl}/landingpage/heroDashboard.svg`}
                                />
                            </div>
                        </Reveal>
                    </div>
                </div>
                {showLoginModal && (
                    <JunoLogin
                        show={true}
                        closeModal={() => setShowLoginModal(false)}
                    />
                )}
            </div>

            <div className="lg:mt-[316px] px-6 pt-16 pb-8 lg:p-20 max-w-[1280px] mx-auto">
                <FadeUp>
                    <h2 className="text-[42px] lg:text-[52px] text-brand-black text-center font-medium">
                        Get the best out of Juno Money
                    </h2>
                </FadeUp>

                <div className="flex flex-col lg:flex-row mt-12 gap-12 lg:gap-5 w-full lg:h-[800px] xl:h-[700px]">
                    <div
                        className={`lg:h-auto max-lg:pt-12 flex w-full flex-col md:flex-row justify-between rounded-xl border border-gray-300 bg-gradient-to-t from-gray-200 to-gray-100 lg:pl-8 lg:pt-8 ${
                            isExpanded && windowWidth >= 1024
                                ? 'w-2/5'
                                : 'lg:!w-2/3'
                        }`}
                        onMouseEnter={() =>
                            windowWidth > 1024 && setIsExpanded(true)
                        }
                    >
                        <div className="md:hidden relative h-[400px]">
                            <img
                                className=" absolute -top-1/4 left-0 right-0 m-auto"
                                src={`${imageBaseUrl}/landingpage/demo-mobile.svg`}
                            />
                        </div>
                        <div className="w-full flex justify-between">
                            <div
                                className={`pb-8 max-lg:p-4 max-lg:pt-0 ${
                                    !isExpanded && 'pr-8'
                                }`}
                            >
                                <div className="flex flex-col justify-between h-full">
                                    <div className="h-4/5">
                                        <h3 className="text-[36px] lg:text-[42px] font-medium text-brand-black">
                                            Tailored, individual payment
                                            solutions
                                        </h3>
                                        <p
                                            className={`text-base lg:text-xl mt-6 text-[#26272B] pb-6 transition-all duration-500 ${
                                                windowWidth < 1024 || isExpanded
                                                    ? 'opacity-100'
                                                    : 'opacity-100 transform translate-y-[250px]'
                                            }`}
                                        >
                                            As a high net worth individual, if
                                            you’re looking for a completely
                                            unique service that offers you
                                            secure and discrete execution of all
                                            of your financial affairs, then Juno
                                            Money has the solution.
                                        </p>
                                        {isExpanded && (
                                            <div className="flex flex-1 flex-col !gap-5">
                                                {applicationsOptions1.map(
                                                    (application) => (
                                                        <div
                                                            key={application.id}
                                                            className="flex gap-[10px]"
                                                        >
                                                            <CheckBoxIcon
                                                                style={{
                                                                    color: '#26272B',
                                                                    width: 20,
                                                                    height: 20,
                                                                    marginTop: 3,
                                                                }}
                                                            />
                                                            <p className="text-base lg:text-xl text-[#26272B]">
                                                                {
                                                                    application.text
                                                                }
                                                            </p>
                                                        </div>
                                                    )
                                                )}
                                            </div>
                                        )}
                                    </div>
                                    <p
                                        onClick={() =>
                                            navigate('/juno-individual')
                                        }
                                        className="underline text-lg lg:text-xl text-brand-black font-medium cursor-pointer pt-5"
                                    >
                                        Learn more
                                    </p>
                                </div>
                            </div>
                            <img
                                className={`hidden ${
                                    isExpanded ? 'md:block' : 'hidden'
                                }`}
                                width="40%"
                                src={`${imageBaseUrl}/landingpage/demo-tablet.svg`}
                            />
                        </div>
                    </div>
                    <div
                        style={{
                            backgroundImage: `url(${imageBaseUrl}/landingpage/texture-metal.svg)`,
                            backgroundRepeat: 'no-repeat',
                            backgroundSize: 'cover',
                        }}
                        className={`lg:h-auto w-full text-[#FCFCFC] flex flex-col md:flex-row justify-between rounded-xl bg-[#282828] border border-gray-300 bg-gradient-to-t from-gray-200 to-gray-100 lg:pl-8 transition-all duration-500 ${
                            !isExpanded && windowWidth >= 1024
                                ? 'w-3/5'
                                : 'lg:w-2/3'
                        }`}
                        onMouseEnter={() =>
                            windowWidth > 1024 && setIsExpanded(false)
                        }
                    >
                        <img
                            className="md:hidden"
                            src={`${imageBaseUrl}/landingpage/corporate-mobile.svg`}
                        />
                        <div className="w-full flex justify-between">
                            <div
                                className={`flex justify-between py-8 max-lg:p-8 ${
                                    isExpanded && 'pr-8'
                                }`}
                            >
                                <div className="flex flex-col justify-between h-full">
                                    <div className="">
                                        <h3 className="text-[36px] lg:text-[42px] font-medium">
                                            Grow beyond borders with a corporate
                                            account
                                        </h3>
                                        <p
                                            className={`text-base lg:text-xl mt-6 pb-6 transition-all duration-500 ${
                                                windowWidth < 1024 ||
                                                !isExpanded
                                                    ? 'opacity-100'
                                                    : 'opacity-100 transform translate-y-full'
                                            }`}
                                        >
                                            Multi-currency accounts, instant
                                            internal transfers and international
                                            online payments — all in one
                                            platform. Juno Money offers
                                            everything your business needs to
                                            simplify your global payments and
                                            financial operations.
                                        </p>
                                        {(windowWidth < 1024 ||
                                            !isExpanded) && (
                                            <div className="flex flex-1 flex-col !gap-5">
                                                {applicationsOptions2.map(
                                                    (application) => (
                                                        <div
                                                            key={application.id}
                                                            className="flex gap-[10px]"
                                                        >
                                                            <CheckBoxIcon
                                                                style={{
                                                                    width: 20,
                                                                    height: 20,
                                                                    marginTop: 3,
                                                                }}
                                                            />
                                                            <p className="text-base lg:text-xl">
                                                                {
                                                                    application.text
                                                                }
                                                            </p>
                                                        </div>
                                                    )
                                                )}
                                            </div>
                                        )}
                                    </div>
                                    <p
                                        onClick={() =>
                                            navigate('/juno-corporate')
                                        }
                                        className="underline text-lg lg:text-xl font-medium cursor-pointer pt-5"
                                    >
                                        Learn more
                                    </p>
                                </div>
                            </div>
                            <img
                                className={`hidden ${
                                    windowWidth < 1024 || !isExpanded
                                        ? 'md:block'
                                        : 'hidden'
                                }`}
                                width="40%"
                                style={{
                                    background: 'rgba(255, 255, 255, 0.10)',
                                }}
                                src={`${imageBaseUrl}/landingpage/corporate-tablet.svg`}
                            />
                        </div>
                    </div>
                </div>
            </div>
            <div className="px-3 sm:px-6 lg:px-20 pt-10 pb-12 md:pt-12 lg:py-[60px] max-w-[1280px] xl:px-0 mx-auto">
                <FadeUp>
                    <div className="lg:w-[720px] m-auto">
                        <h2 className="text-4xl sm:!text-[46px] leading-none lg:text-[52px] font-medium text-[#18181B] md:text-center">
                            Payment solutions for both individual and corporate
                            clients
                        </h2>
                        <p className="mt-7 lg:!mt-5 text-xl md:text-center text-[#3F3F46]">
                            We appreciate that every customer is different, so
                            whether you’re an individual or a corporate client,
                            Juno Money will work with you to develop a
                            customised proposition that suits you.
                        </p>

                        <CustomButton
                            className="mt-8 w-36 md:mx-auto "
                            title="log in"
                            onClick={() => {
                                setShowLoginModal(true)
                            }}
                        />
                    </div>
                </FadeUp>
            </div>

            <JunoCard />
            <div className="flex flex-col gap-8 md:gap-[82px] bg-black">
                <div className="px-3 md:!px-6 lg:px-[150px] pt-16 lg:pt-[120px] bg-black ">
                    <div className="max-w-[1280px] mx-auto">
                        <div
                            className="lg:h-[600px] lg:!relative min-[1340px]:static !bg-no-repeat !bg-right-top lg:!bg-contain lg:py-[99px] xl:pr-[560px]"
                            style={{
                                backgroundImage:
                                    windowWidth > 1099
                                        ? `url(${imageBaseUrl}/landingpage/heroDesk.jpeg)`
                                        : 'black',
                            }}
                        >
                            <FadeUp>
                                <div className="lg:absolute min-[1340px]:static top-[99px] left-0 lg:bg-[#00000080]  min-[1340px]:bg-transparent">
                                    <p className="text-4xl sm:text-[42px] lg:text-[62px] text-[#FCFCFC] leading-none ">
                                        Payment solutions for corporate clients
                                    </p>
                                    <p className="sm:text-lg lg:text-xl text-[#D1D1D6] mt-7 lg:mt-16 mb-7">
                                        Juno Money provides a totally unique
                                        service for all of your global payment
                                        needs. We provide a bespoke and
                                        personalised service to our corporate
                                        clients, to remove the financial
                                        complexities from your business
                                        operations.
                                    </p>
                                    <CustomButton
                                        className="w-[174px]"
                                        title="get in touch"
                                        onClick={scrollToContact}
                                    />
                                </div>
                            </FadeUp>
                        </div>
                    </div>
                </div>
                <div className="flex justify-center">
                    <img
                        className="pt-20 lg:hidden h-[600px] sm:h-[500px] object-cover sm:object-none md:object-cover"
                        src={`${imageBaseUrl}/landingpage/heroDesk.jpeg`}
                    />
                </div>
            </div>
            <ServicesComponent />
            <div className="bg-black">
                <JunoContact
                    heroContent={contactUsContent}
                    textAlign="left"
                    customStyleHeader="lg:!pb-[14px]"
                    contactRef={contactRef}
                />
            </div>
        </LandingLayout>
    )
}
