import React, { useState } from 'react'

export default function InputCopyButton(props) {
    const { copyValue,className } = props
    const [isCopied, setIsCopied] = useState(false)
    function copy() {
        navigator.clipboard.writeText(copyValue)
        setIsCopied(true)
        setTimeout(() => {
            setIsCopied(false)
        }, 1000)
    }

    return (
        <button data-e2e={!isCopied ? 'Copy' : 'Copied'} className={`jncInputView-copy ${className?className:''}`} type="button" onClick={copy}>
            {!isCopied ? 'Copy' : 'Copied'}
        </button>
    )
}
