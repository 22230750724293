import React from 'react'
import Icon from '../../icons/Icon'
import { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import BalanceCryptoBuy from '../../tables/balances/actions/crypto/BalanceCryptoBuy'
import BalanceCryptoSell from '../../tables/balances/actions/crypto/BalanceCryptoSell'
import { BalanceCryptoReceive } from '../../tables/balances/actions/crypto/BalanceCryptoReceive'
import { BalanceCrytoExchange } from '../../tables/balances/actions/crypto/BalanceCrytoExchange'
import { BalanceCryptoSend } from '../../tables/balances/actions/crypto/BalanceCryptoSend'
import {
    getCurrencySymbol,
    toFixedTrunc,
    useJunoCommonFunctions,
} from '../../../helpers'
import { setErrorMessage } from '../../../../Redux/actions/junoAction'

const DashboardCryptoActions = () => {
    const [modalAction, setModalAction] = useState('')
    const [loading, setLoading] = useState(false)

    const { clientData, totalCryptoAmount } = useSelector((state) => state.juno)
    const { getCryptoBalances } = useJunoCommonFunctions()
    const dispatch = useDispatch()
    const readOnly = clientData?.readOnly

    const handleModalOpen = async (modalType) => {
        try {
            setLoading(true)
            const balances = await getCryptoBalances()
            const unfreezeBalances =
                balances?.filter((balance) => !balance.isFreeze) || []

            if (
                unfreezeBalances.length === 0 ||
                (unfreezeBalances.length <= 1 && modalType === 'Exchange')
            ) {
                dispatch(
                    setErrorMessage(
                        'All currencies are currently disabled. Please contact the administrator for assistance.'
                    )
                )
                setLoading(false)
                return
            }

            setModalAction(modalType)
        } catch (err) {
            console.log('Error opening the modal: ', err)
        } finally {
            setLoading(false)
        }
    }

    return (
        <section className="jncSection mb jncActions max-991">
            <div className="jncActions__overflow">
                <div className="jncActions__grid__crypto">
                    <button
                        disabled={loading || readOnly}
                        onClick={() => {
                            handleModalOpen('buy')
                        }}
                        className={`${
                            readOnly ? 'jnc-btn-disable' : ''
                        } jncActions__btn`}
                    >
                        <div className="jncActions__top">
                            <div className="jncActions__icon">
                                <Icon id="buyIcon" />
                            </div>
                            <p className="jncActions__title">Buy</p>
                        </div>
                    </button>
                    <button
                        disabled={loading || readOnly}
                        onClick={() => {
                            handleModalOpen('Sell')
                        }}
                        className={`${
                            readOnly ? 'jnc-btn-disable' : ''
                        } jncActions__btn`}
                    >
                        <div className="jncActions__top">
                            <div className="jncActions__icon">
                                <Icon id="sellIcon" />
                            </div>
                            <p className="jncActions__title">Sell</p>
                        </div>
                    </button>
                    <button
                        disabled={loading || readOnly}
                        onClick={() => {
                            handleModalOpen('Receive')
                        }}
                        className={`${
                            readOnly ? 'jnc-btn-disable' : ''
                        } jncActions__btn`}
                    >
                        <div className="jncActions__top">
                            <div className="jncActions__icon">
                                <Icon id="deposit" />
                            </div>
                            <p className="jncActions__title">Receive</p>
                        </div>
                    </button>
                    <button
                        disabled={loading || readOnly}
                        onClick={() => {
                            handleModalOpen('Exchange')
                        }}
                        className={`${
                            readOnly ? 'jnc-btn-disable' : ''
                        } jncActions__btn`}
                    >
                        <div className="jncActions__top">
                            <div className="jncActions__icon">
                                <Icon id="fx" />
                            </div>
                            <p className="jncActions__title">Exchange</p>
                        </div>
                    </button>
                    <button
                        disabled={loading || readOnly}
                        onClick={() => {
                            handleModalOpen('Send')
                        }}
                        className={`${
                            readOnly ? 'jnc-btn-disable' : ''
                        } jncActions__btn`}
                    >
                        <div className="jncActions__top">
                            <div className="jncActions__icon">
                                <Icon id="transfer" />
                            </div>
                            <p className="jncActions__title">Send</p>
                        </div>
                    </button>
                </div>
            </div>
            <BalanceCryptoBuy
                open={modalAction === 'buy'}
                onClose={() => setModalAction('')}
                row={{}}
            />
            <BalanceCryptoSell
                open={modalAction === 'Sell'}
                onClose={() => setModalAction('')}
                row={{}}
            />
            <BalanceCryptoReceive
                open={modalAction === 'Receive'}
                onClose={() => setModalAction('')}
                row={{}}
            />
            <BalanceCrytoExchange
                open={modalAction === 'Exchange'}
                onClose={() => setModalAction('')}
                row={{}}
            />
            <BalanceCryptoSend
                open={modalAction === 'Send'}
                onClose={() => setModalAction('')}
                row={{}}
            />
        </section>
    )
}

export default DashboardCryptoActions
