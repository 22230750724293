import React from 'react'
import Icon from '../../icons/Icon'

export default function JncButton({
    isFullOnMobile,
    isFullOnTablet,
    type,
    disabled,
    loading,
    onClickCall,
    isOutlinedStyle,
    icon,
    text,
    dataE2e,
    isDangerStyle,
    className
}) {
    return (
        <button
            {...(onClickCall && { onClick: onClickCall })}
            type={!type ? 'button' : type}
            disabled={loading || disabled}
            className={`jncButton ${className} ${isOutlinedStyle ? 'outlined-style' : 'filled-style'} ${isDangerStyle ? 'danger-style' : ''} ${isFullOnMobile ? 'full-on-mobile' : ''} ${isFullOnTablet ? 'full-on-tablet' : ''} ${disabled && 'jnc-btn-disable'}`}
            {...(dataE2e && { 'data-e2e': dataE2e })}
        >
            {icon && !loading && <Icon id={icon} />}
            {loading && <div className="jncButton__loader"></div>}
            <p>{loading ? 'Processing' : text}</p>
        </button>
    )
}
