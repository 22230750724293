import React from 'react'
import DataTable from 'react-data-table-component'
import JncEmptyData from './JncEmptyData'
import { LinearProgress } from '@mui/material'
import { HeadRow } from 'react-day-picker'

const customStyles = {
    headCells: {
        style: {
            color: '#000',
            fontSize: '16px',
            transition: '.15s',
            '&:hover': {
                color: '#12a086',
            },
            '&:first-of-type .rdt_TableCol_Sortable': {
                paddingLeft: '0px',
            },
            '&:first-of-type': {
                paddingLeft: '0px',
            },
            display: 'flex',
            justifyContent: 'space-between',
            placeContent: 'space-between',
            width: '100%',
        },
    },
    HeadRow: {
        style: {
            flexGrow: 0,
        },
    },
    rows: {
        style: {
            placeContent: 'space-between',
        },
    },
    cells: {
        style: {
            flexGrow: 0,
            fontSize: '16px',
            lineHeight: '130%',
            color: '#344054',
            paddingTop: '10px',
            paddingBottom: '10px',
            'span:not(.no-color-change)': {
                color: '#98A2B3',
            },
            '&:first-of-type': {
                paddingLeft: '0px',
            },
            display: 'flex',
            justifyContent: 'space-between',
            placeContent: 'space-between',
            width: '100%',
        },
    },
    expanderCell: {
        style: {
            flex: '0 0 58px',
        },
    },
}

export default function TableComponent(props) {
    const LinearIndeterminate = () => {
        const loadingStyle = {
            width: '100%',
            marginTop: '20px',
        }
        return (
            <div style={loadingStyle}>
                <LinearProgress />
            </div>
        )
    }

    const {
        columns,
        filteredData,
        expandableRows,
        expandableRowsComponent,
        paginationServer = false,
        totalRows,
        handlePageChange,
        isLoading,
        noDataText,
        showTablePagination,
    } = props

    return (
        <div className="jncTable-minHeight">
            <DataTable
                columns={columns}
                progressPending={isLoading}
                progressComponent={<LinearIndeterminate />}
                data={filteredData}
                pagination={showTablePagination}
                paginationServer={paginationServer}
                customStyles={customStyles}
                expandableRows={expandableRows ? expandableRows : false}
                expandableRowsComponent={
                    expandableRowsComponent ? expandableRowsComponent : false
                }
                noDataComponent={<JncEmptyData text={noDataText} />}
                expandableRowsHideExpander={true}
                expandOnRowClicked={true}
                {...(totalRows && { paginationTotalRows: totalRows })}
                {...(paginationServer && { paginationRowsPerPageOptions: [] })}
                onChangePage={
                    paginationServer
                        ? (page) => handlePageChange(page)
                        : undefined
                }
                paginationComponentOptions={{ noRowsPerPage: true }}
            />
        </div>
    )
}
