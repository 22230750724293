import { combineReducers } from 'redux'
import configReducer from './configReducer'
import junoReducer from './junoReducer'
import commonReducer from './commonReducer'

const rootReducer = combineReducers({
    config: configReducer,
    juno: junoReducer,
    common: commonReducer,
})

export default rootReducer
