import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { apiService } from '../../../common/apiCallService';
import * as actions from '../../../Redux/actions';
import { NavLink, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';

const ClientNewPasswordPage = (props) => {
    const { token } = useParams();
    const headerLogo = sessionStorage.getItem("logo")
    const logoWidth = sessionStorage.getItem("logoWidth")
    const imageBaseURl = useSelector((state) => state.config.image_base_url)
    const [whiteLabelImage, setWhiteLabelImage] = useState(localStorage.getItem('logo'));

    useEffect(() => {
        props.setLoading(true);
        verifyToken(token);
        document.getElementsByTagName('body')[0].style.setProperty('--btn-background', '#8B66FD');
    }, [token]);

    const [data, setData] = useState({
        newPassword: {
            value: '',
            validation: '',
            isError: false,
            errorMsg: '',
            isRequired: true,
        },
        confirmNewPassword: {
            value: '',
            validation: '',
            isError: false,
            errorMsg: '',
            isRequired: true,
        },
    });
    const [isPasswordMatch, setPasswordMatch] = useState(true);
    const [email, setEmail] = useState('');
    const [verified, setVerified] = useState(false);
    const [isSuccess, setSuccess] = useState(false);
    const [alertMsg, setAlertMsg] = useState(null);

    const verifyToken = (token) => {
        apiService(
            (window.location.hostname === 'localhost' ? props.api_url : '') + '/restapi/forgetPassword-client',
            {
                verifyToken: token,
                hostname: window.location.hostname,
            },
            (data) => {
                props.setLoading(false);
                console.log(data);
                if (data.success) {
                    setWhiteLabelImage(data.logo);
                    setEmail(data.email);
                    setVerified(true);
                } else {
                    alert(data);
                }
            },
            (err) => {
                props.setLoading(false);
                alert('Something went wrong!');
                console.log(err);
            },
        );
    };

    const validation = (e) => {
        let { name, value } = e.target;
        let stateObj = { ...data };
        let validation = stateObj[name].validation;
        let isRequired = stateObj[name].isRequired;
        let isError = false;
        let RegEx = new RegExp(validation);

        if (isRequired) {
            if (value === '') {
                isError = true;
            } else {
                if (validation !== '') {
                    if (!value.match(RegEx)) {
                        isError = true;
                    }
                }
            }
        } else {
            if (value !== '' && validation !== '') {
                if (!value.match(RegEx)) {
                    isError = true;
                }
            }
        }
        return (stateObj[name].isError = isError);
    };

    const changeHandler = (e) => {
        setAlertMsg(null);
        setPasswordMatch(true);
        let { name, value } = e.target;
        let stateObj = { ...data };
        validation(e);
        stateObj[name].value = value;
        setData({ ...stateObj });
    };

    const submitHandler = (e) => {
        if (!data.newPassword.value || !data.confirmNewPassword.value) {
            setAlertMsg('Please, fill in all fields');
            console.log('please fill in all fields');
            return;
        }

        if (data.newPassword.value !== data.confirmNewPassword.value) {
            setAlertMsg('Passwords do not match');
            return;
        }

        props.setLoading(true);
        apiService(
            (window.location.hostname === 'localhost' ? props.api_url : '') + '/restapi/newPassword-client',
            {
                clientEmail: email,
                newPassword: data.newPassword.value,
                verifyToken: token,
            },
            (data) => {
                setSuccess(true);
                props.setLoading(false);
            },
            (err) => {
                props.setLoading(false);
                alert('Something went wrong!');
                console.log(err);
            },
        );
    };

    return (
        <>
            {verified &&
                <div className="jncAuth__body bg-brand-black">
                    {!isSuccess ? (
                        <div>
                            <div className="jncAuth__container p-8 !max-w-[424px] bg-white rounded-lg">
                                <div className="jncAuth__logo">
                                    {headerLogo && headerLogo !== 'null' ?
                                        <img src={headerLogo} alt="logo" width={logoWidth || "80"} height="auto" />
                                        :
                                        <img
                                            className='w-36'
                                            src= { whiteLabelImage || `${imageBaseURl}/landingpage/dark-logo.svg` }
                                            alt="logo"
                                        />
                                    }
                                </div>
                                <h1 className='text-[#101828] font-semibold text-4xl'>Enter new password</h1>
                                {alertMsg && (
                                    <div className="alert alert-danger" role="alert">
                                        {alertMsg}
                                    </div>
                                )}
                                <form className='pt-10'>
                                    <div className="jncAuth__field mb">
                                        <p className="jncAuth__field-label">Password</p>
                                        <input
                                            type="password"
                                            name="newPassword"
                                            data-e2e="newPassword"
                                            className="jncAuth__field-input"
                                            id=""
                                            value={data.newPassword.value}
                                            placeholder="New Password"
                                            onChange={changeHandler}
                                        />
                                    </div>
                                    <div className="jncAuth__field mb-9">
                                        <p className="jncAuth__field-label">Confirm Password</p>
                                        <input
                                            type="password"
                                            name="confirmNewPassword"
                                            data-e2e="confirmNewPassword"
                                            className="jncAuth__field-input"
                                            id=""
                                            value={data.confirmNewPassword.value}
                                            placeholder="Confirm New Password"
                                            onChange={changeHandler}
                                        />
                                    </div>
                                    <div data-e2e="submit-btn"
                                        className="jncAuth__Button w-full rounded-full flex justify-center h-11 items-center text-white font-semibold cursor-pointer full-width !bg-brand-black hover:bg-brand-gray"
                                        onClick={submitHandler}>
                                        <p>Submit</p>
                                    </div>
                                </form>
                            </div>
                        </div>)
                        :
                        <div className="jncAuth__container p-8 !max-w-[360px] bg-white rounded-lg">
                            <svg
                                className="jncAuth__successIcon"
                                width="60"
                                height="60"
                                viewBox="0 0 60 60"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <rect
                                    x="0.5"
                                    y="0.5"
                                    width="59"
                                    height="59"
                                    rx="29.5"
                                    fill="#ECFDF3"
                                />
                                <circle
                                    cx="30"
                                    cy="30"
                                    r="15"
                                    fill="#62CA7A"
                                />
                                <path
                                    d="M22 30.25L23.5 28.75L27.25 32.5L35.5 24.25L37 25.75L27.25 35.5L22 30.25Z"
                                    fill="white"
                                />
                                <rect
                                    x="0.5"
                                    y="0.5"
                                    width="59"
                                    height="59"
                                    rx="29.5"
                                    stroke="#D1FADF"
                                />
                            </svg>
                            <p className="jncAuth__successTitle">
                                Password updated
                            </p>
                            <p className="jncAuth__subtitle">Password for
                                {/* <span className='font-medium text-[#101828]'>{this.state.emailId}</span> */}
                                has been updated, return and log in to Juno Money.d</p>
                            <NavLink to="/"
                                className="jncAuth__Button w-full rounded-full flex justify-center h-11 items-center text-white font-semibold full-width mt-6  !bg-brand-black hover:bg-brand-gray "
                            >
                                <p data-e2e="back-to-home-page">Return to log in</p>
                            </NavLink>
                        </div>
                    }
                </div>
            }
        </>
    );
};

const mapStateToProps = (state) => {
    return {
        api_url: state.config.api_url,
        image_base_url: state.config.image_base_url,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        setLoading: (data) => dispatch(actions.setLoading(data)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(ClientNewPasswordPage);
