import React from 'react';
import CopyButton from '../../components/global/CopyButton';
import { toFixedTrunc, getCurrencySymbol, getStatus } from '../../../helpers';
import { ErrorTransactions } from '../../constant';
import { CommonCryptoExpanded } from './CommonCryptoExpanded';

export default function SendExpanded({ transactionDetails, transactionFee, balanceAfterTransaction, currencyType, status }) {
  const details = transactionDetails?.[0] || {};

  return (
    <div className="jncExpanded">
      <div className="jncExpanded-grid">
        {CommonCryptoExpanded("From Address", details.fromAddress)}
        {CommonCryptoExpanded("To Address", details.toAddress)}
        {CommonCryptoExpanded("CryptoId", details.cryptoId)}

        <div className="jncExpanded-block">
          <p className="jncExpanded-label">Crypto Amount</p>
          <div className="jncExpanded-block-copy jncExpanded-text">
            <p>
              {getCurrencySymbol(details.cryptoId)}
              {toFixedTrunc(details.cryptoAmount, 6) || "-"}
            </p>
          </div>
        </div>

        {CommonCryptoExpanded("Transaction Fee", transactionFee)}

        {currencyType === 'crypto' && (
          <div className="jncExpanded-block">
            <p className="jncExpanded-label">Balance after transaction</p>
            <div className="jncExpanded-block-copy jncExpanded-text">
              <p>
                {details.cryptoId} {toFixedTrunc(balanceAfterTransaction, 8)}
              </p>
              <CopyButton
                copyValue={`${details.cryptoId} ${toFixedTrunc(balanceAfterTransaction, 8)}`}
              />
            </div>
          </div>
        )}

        {ErrorTransactions.includes(status?.code) && (
          <div className="jncExpanded-block">
            <p className="jncExpanded-failed">{getStatus(status.code)}:</p>
            <div className="jncExpanded-block-copy jncExpanded-text">
              <p>{status.message}</p>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
