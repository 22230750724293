import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'
export default function JunoAmHeader(props) {
    const image_base_url = useSelector((state) => state.config.image_base_url)

    function setFavicon() {
        let link = document.querySelector("link[rel~='icon']")
        if (!link) {
            link = document.createElement('link')
            link.rel = 'icon'
            document.getElementsByTagName('head')[0].appendChild(link)
        }
        link.href = `${image_base_url}/juno-am/favicon.jpg`
    }
    useEffect(() => {
        setFavicon()
    }, [])

    return (
        <header className="jnAm-header">
            <div className="content-container">
                <div className="jnAm-header-row">
                    <img
                        className="jnAm-header-logo"
                        src={`${image_base_url}/juno-am/logo.webp`}
                        alt="logo"
                    />
                    <button
                        onClick={props.scrollToContact}
                        className="jnAm-btn header-style"
                    >
                        Become a Juno client
                    </button>
                </div>
            </div>
        </header>
    )
}
