import React, { useState } from 'react'
import { apiService } from '../../../../common/apiCallService'
import { useSelector } from 'react-redux'
import JncButton from '../global/JncButton'

export default function IsDelete(props) {
    const { onSuccessDelete, setIsDelete, accountId } = props
    const api_url = useSelector((state) => state.config.api_url)
    const [isLoading, setIsLoading] = useState(false)

    function deleteAccount() {
        setIsLoading(true)
        apiService(
            (window.location.hostname === 'localhost' ? api_url : '') +
                '/restapi/delete-client-beneficiary',
            {
                beneficiaryId: accountId,
            },
            async (data) => {
                if (data) {
                    setIsLoading(false)
                    onSuccessDelete()
                }
            },
            (err) => {
                console.log(err)
                setIsLoading(false)
            },
        )
    }

    const cancelIsDelete = () => setIsDelete(false)

    return (
        <div className="jncModal__resp">
            <svg width="80" height="80" viewBox="0 0 80 80" fill="none">
                <rect
                    x="4"
                    y="4"
                    width="72"
                    height="72"
                    rx="36"
                    fill="#FEE4E2"
                />
                <path
                    d="M46.6667 29.9997V28.6663C46.6667 26.7995 46.6667 25.8661 46.3034 25.153C45.9838 24.5258 45.4738 24.0159 44.8466 23.6963C44.1336 23.333 43.2002 23.333 41.3333 23.333H38.6667C36.7998 23.333 35.8664 23.333 35.1534 23.6963C34.5262 24.0159 34.0162 24.5258 33.6966 25.153C33.3333 25.8661 33.3333 26.7995 33.3333 28.6663V29.9997M36.6667 39.1663V47.4997M43.3333 39.1663V47.4997M25 29.9997H55M51.6667 29.9997V48.6663C51.6667 51.4666 51.6667 52.8667 51.1217 53.9363C50.6423 54.8771 49.8774 55.642 48.9366 56.1214C47.8671 56.6663 46.4669 56.6663 43.6667 56.6663H36.3333C33.5331 56.6663 32.1329 56.6663 31.0634 56.1214C30.1226 55.642 29.3577 54.8771 28.8783 53.9363C28.3333 52.8667 28.3333 51.4666 28.3333 48.6663V29.9997"
                    stroke="#D92D20"
                    strokeWidth="2.6"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <rect
                    x="4"
                    y="4"
                    width="72"
                    height="72"
                    rx="36"
                    stroke="#FEF3F2"
                    strokeWidth="8"
                />
            </svg>
            <p className="jncModal__resp-title">Delete bank account</p>
            <p className="jncModal__text">
                Are you sure you want to delete the bank account?
            </p>
            <div className="jncModal__btns top-margin">
                <JncButton
                    dataE2e="cancel-btn"
                    text="Cancel"
                    disabled={isLoading}
                    onClickCall={cancelIsDelete}
                    isOutlinedStyle={true}
                />
                <JncButton
                    dataE2e="delete-btn"
                    text="Delete"
                    loading={isLoading}
                    onClickCall={deleteAccount}
                    isDangerStyle={true}
                />
            </div>
        </div>
    )
}
