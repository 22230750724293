import React, { useState, useRef } from 'react'
import { formatFileSize } from '../../../helpers'

export default function AttachmentsUpload(props) {
    const {
        handleDelete,
        attachmentsError,
        attachments,
        handleFile,
        handleChange,
    } = props

    const [dragActive, setDragActive] = useState(false)
    const inputRef = useRef(null)

    //ok
    const handleDrag = function (e) {
        e.preventDefault()
        e.stopPropagation()
        if (e.type === 'dragenter' || e.type === 'dragover') {
            setDragActive(true)
        } else if (e.type === 'dragleave') {
            setDragActive(false)
        }
    }
    //ok
    const handleDrop = function (e) {
        e.preventDefault()
        e.stopPropagation()
        setDragActive(false)
        if (e.dataTransfer.files && e.dataTransfer.files[0]) {
            handleFile(e.dataTransfer.files)
        }
    }

    return (
        <div className="jncModal__field mt no-grid">
            <p className="jncModal__label">Attachments</p>
            <div className="jn-client-upload">
                <div className="jn-client-upload" onDragEnter={handleDrag}>
                    <input
                        ref={inputRef}
                        type="file"
                        id="input-file-upload"
                        multiple={true}
                        onChange={handleChange}
                    />
                    <label
                        className={`jn-client-upload-label ${dragActive ? 'drag-active' : ''}`}
                        htmlFor="input-file-upload"
                    >
                        <div>
                            <svg
                                className="jn-client-upload-svg"
                                width="46"
                                height="46"
                                viewBox="0 0 46 46"
                                fill="none"
                            >
                                <rect
                                    x="3"
                                    y="3"
                                    width="40"
                                    height="40"
                                    rx="20"
                                    fill="#F2F4F7"
                                />
                                <path
                                    d="M19.667 26.3333L23.0003 23M23.0003 23L26.3337 26.3333M23.0003 23V30.5M29.667 26.9524C30.6849 26.1117 31.3337 24.8399 31.3337 23.4167C31.3337 20.8854 29.2816 18.8333 26.7503 18.8333C26.5682 18.8333 26.3979 18.7383 26.3054 18.5814C25.2187 16.7374 23.2124 15.5 20.917 15.5C17.4652 15.5 14.667 18.2982 14.667 21.75C14.667 23.4718 15.3632 25.0309 16.4894 26.1613"
                                    stroke="#667085"
                                    strokeWidth="1.6"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                />
                                <rect
                                    x="3"
                                    y="3"
                                    width="40"
                                    height="40"
                                    rx="20"
                                    stroke="#F9FAFB"
                                    strokeWidth="6"
                                />
                            </svg>
                            <div className="jn-client-upload-text">
                                <button
                                    data-e2e="upload-btn"
                                    type="button"
                                    onClick={() => inputRef.current.click()}
                                >
                                    Click to upload
                                </button>{' '}
                                or drag and drop
                            </div>
                            <div className="jn-client-upload-text">
                                SVG, PNG, JPG or GIF (max. 800x400px)
                            </div>
                        </div>
                    </label>
                    {dragActive && (
                        <div
                            id="drag-file-element"
                            onDragEnter={handleDrag}
                            onDragLeave={handleDrag}
                            onDragOver={handleDrag}
                            onDrop={handleDrop}
                        ></div>
                    )}
                </div>
                {attachmentsError && (
                    <p className="errorMessage">{attachmentsError}</p>
                )}
                {attachments.length > 0 &&
                    attachments.map((x, index) => (
                        <div className="jn-client-attachment" key={x.name}>
                            <div className="jn-client-attachment-text">
                                <span data-e2e={x.name}>{x.name}</span>
                                <br />
                                {formatFileSize(x.size)}
                            </div>
                            <button
                                type="button"
                                onClick={(e) => handleDelete(e, index)}
                                className="jn-client-attachment-delete"
                            >
                                <svg
                                    width="36"
                                    height="36"
                                    viewBox="0 0 36 36"
                                    fill="none"
                                >
                                    <path
                                        d="M21.3333 13V12.3333C21.3333 11.3999 21.3333 10.9332 21.1517 10.5767C20.9919 10.2631 20.7369 10.0081 20.4233 9.84831C20.0668 9.66666 19.6001 9.66666 18.6667 9.66666H17.3333C16.3999 9.66666 15.9332 9.66666 15.5767 9.84831C15.2631 10.0081 15.0081 10.2631 14.8483 10.5767C14.6667 10.9332 14.6667 11.3999 14.6667 12.3333V13M16.3333 17.5833V21.75M19.6667 17.5833V21.75M10.5 13H25.5M23.8333 13V22.3333C23.8333 23.7335 23.8333 24.4335 23.5608 24.9683C23.3212 25.4387 22.9387 25.8212 22.4683 26.0608C21.9335 26.3333 21.2335 26.3333 19.8333 26.3333H16.1667C14.7665 26.3333 14.0665 26.3333 13.5317 26.0608C13.0613 25.8212 12.6788 25.4387 12.4392 24.9683C12.1667 24.4335 12.1667 23.7335 12.1667 22.3333V13"
                                        stroke="#667085"
                                        strokeWidth="1.6"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                    />
                                </svg>
                            </button>
                        </div>
                    ))}
            </div>
        </div>
    )
}
