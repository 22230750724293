import React from 'react'
import { NavLink } from 'react-router-dom'

export default function JncSectionTitle({
    title,
    seeAllLink,
    isWhiteBg,
    children,
    navClassName
}) {
    return (
        <div
            className={`jncSectionTitle ${seeAllLink || children ? 'see-all' : ''}`}
        >
            <p className="jncProfileOpenSansFont">{title}</p>
            <div className="jncSectionTitle_children">
                {children}
                {seeAllLink && (
                    <NavLink
                        className={`${navClassName} ${isWhiteBg && 'white-bg'}`}
                        data-e2e={`${title}-see-all`}
                        to={seeAllLink}
                    >
                        See all
                    </NavLink>
                )}
            </div>
        </div>
    )
}
