import React from 'react'
import { NavLink } from 'react-router-dom'
import LandingLayout from '../../client/layouts/LandingLayout'

export default function JunoTermsPage() {
    function CustomLi(props) {
        return (
            <div className="flex">
                <p className="min-w-[48px]">
                    <b>{props.counter}</b>
                </p>
                <p dangerouslySetInnerHTML={{ __html: props.text }}></p>
            </div>
        )
    }

    return (
        <LandingLayout>
            <div className="bg-black w-full">
                <div className="flex items-center justify-center max-w-[1280px] xl:mx-auto h-full px-[16px] md:px-[24px] lg:px-[80px] pt-[154px] lg:pt-[206px] pb-[48px] lg:pb-[150px]">
                    <div>
                        <h1 className="text-[36px] lg:text-[52px] font-medium pb-[32px] lg:pb-[64px] xl:pr-[442px]">
                            Terms and Conditions application to Juno Pay Ltd.
                        </h1>
                        <div className="text-[16px] lg:text-[18px] font-normal leading-normal self-stretch flex flex-col items-start justify-center gap-[32px] px-[16px] md:px-[24px] lg:px-0">
                            <div className="flex flex-col gap-[24px]">
                                <p>
                                    Juno Money is a trading name of Juno Pay Ltd
                                    a private limited company incorporated in
                                    Alberta, Canada under company number
                                    2024801421 and with its registered office at
                                    Suite #229, 6030 88ST NW, Edmonton, Alberta,
                                    Canada, T6E6G4. Juno is authorised by
                                    FINTRAC as a Money Service Business (MSB No.
                                    M23335654).
                                </p>

                                <p>
                                    <b>IMPORTANT INFORMATION:</b> These terms
                                    and conditions (<b>"Terms"</b>”) govern the
                                    use of the Services (as defined below)
                                    provided by Juno (<b>we</b>” or <b>us</b>
                                    ”) to any person whose application we
                                    approve (<b>you</b>” or <b>your</b>”).
                                </p>

                                <p>
                                    By activating your eWallet (as defined
                                    below) with us, you confirm that you have
                                    read, understood and agree to these Terms.
                                    We recommend that you print a copy of these
                                    terms for future reference. We may change or
                                    update these Terms from time to time and we
                                    advise you that every time you wish to use
                                    our Services, please check these Terms to
                                    ensure you understand the terms that may
                                    apply at that time. If you are unable to
                                    download or print a copy of these Terms, you
                                    can request a copy of these Terms from us.
                                </p>

                                <p>
                                    These Terms refer to the following
                                    additional terms, which also apply to your
                                    use of the Services and together constitute
                                    the whole agreement between you and us:
                                </p>
                            </div>

                            <ol
                                style={{
                                    listStyleType: 'lower-alpha',
                                }}
                                className="pl-[24px]"
                            >
                                <li>
                                    Our
                                    <NavLink to="/juno-privacy-policy">
                                        <i>Privacy Policy</i>
                                    </NavLink>
                                    which sets out the terms on which we process
                                    your personal data. You agree that any and
                                    all personal data provided by you is
                                    accurate.
                                </li>
                                <li>
                                    Our
                                    <NavLink to="/juno-cookies">
                                        <i>Cookie Policy</i>
                                    </NavLink>
                                    which sets out information about the cookies
                                    used on our website.
                                </li>
                            </ol>

                            <p>
                                The value stored in your eWallet is E-Money (as
                                defined below) only. Your eWallet is not a
                                deposit account, which means that your funds
                                will not be protected by the Canada Deposit
                                Insurance Incorporation. However, we will hold
                                funds received in exchange for E-Money in a
                                designated safekeeping account with a regulated
                                financial institution and will keep those funds
                                separated from our own funds. Your eWallet does
                                not grant any interest or bonuses related to the
                                duration of time that you hold E-Money with us.
                            </p>

                            <p>
                                Any information contained on the Website is for
                                general information and educational purposes
                                only. No information contained on this Website
                                should in any way be considered financial advice
                                in any form or to any extent. Nothing published
                                on this Website is a recommendation for any
                                investments, products or services, and nor
                                should anything published on this website be
                                relied on for any investment or purchasing
                                decisions. Please conduct your own independent
                                research and seek independent financial and
                                investment advice before entering into any
                                financial transaction or any purchase of goods
                                or services.
                            </p>

                            <p>
                                <b>
                                    Latest update: September 2023 Version 1.20
                                </b>
                            </p>
                        </div>
                    </div>
                </div>
            </div>

            <div className="bg-[#F4F4F5]">
                <div className="max-w-[1280px] xl:mx-auto flex flex-col items-start justify-center gap-[40px] text-[#3F3F46] pt-[48px] lg:pt-[72px] pb-[64px] lg:pb-[96px] font-medium text-[16px] lg:text-[18px]">
                    <>
                        <div className="self-stretch relative px-[16px] md:px-[24px] xl:px-0">
                            <h2 className="text-[36px] font-medium text-black">
                                1. Definitions & Interpretation.
                            </h2>
                        </div>
                        <div className="self-stretch flex flex-col items-start justify-center gap-[32px] xl:pl-[48px] px-[16px] md:px-[24px]">
                            <div className="flex">
                                <p className="min-w-[48px]">
                                    <b>1.1.</b>
                                </p>
                                <p>
                                    In these Terms, words and expressions have
                                    the following meanings, unless otherwise
                                    stated:
                                </p>
                            </div>
                            <div className="flex flex-col gap-[24px] pl-[48px]">
                                <p>
                                    <b>
                                        "Account Information Service Provider"
                                    </b>
                                    means a third-party payment service provider
                                    which is authorised to provide an online
                                    service which accesses one or more of your
                                    payment accounts to provide a consolidated
                                    view of such accounts under a separate
                                    agreement with you;
                                </p>
                                <p>
                                    <b>"Applicable Laws"</b> means any
                                    applicable statutes, laws, ordinances,
                                    orders, judgments, decrees, rules or
                                    regulations issued by any government
                                    authority, and any judicial or
                                    administrative interpretation of any of
                                    these;
                                </p>
                                <p>
                                    <b>"App"</b> means the Juno Mobile
                                    Application and any other software (for use
                                    on mobile devices or otherwise) offered by
                                    us in the future offering similar or
                                    additional functionality as the Juno Mobile
                                    Application;
                                </p>
                                <p>
                                    <b>"Authorisation"</b> means consent to
                                    Pre-Approved and Automatic Payments given by
                                    you under these Terms;
                                </p>
                                <p>
                                    <b>"Available Balance"</b> means the amount
                                    of E-Money issued by Juno to you and held in
                                    your eWallet, which you have not yet spent
                                    or redeemed;
                                </p>
                                <p>
                                    <b>"Business Day"</b> means a day (other
                                    than a Saturday or Sunday or a public
                                    holiday) when commercial banks are open for
                                    ordinary banking business in England;
                                </p>
                                <p>
                                    <b>"Chargeback"</b> means a charge that is
                                    returned to a payment method, including but
                                    not limited to a credit card, after a
                                    customer successfully disputes an item on
                                    their account statement or transactions
                                    report;
                                </p>
                                <p>
                                    <b>"Currency Exchange"</b> means exchanging
                                    E-Money within your eWallet from one
                                    currency to a different available currency
                                    or as part of an Outbound Transaction;
                                </p>
                                <p>
                                    <b>"Cut-Off Time"</b> means 10.30am UK time;
                                </p>
                                <p>
                                    <b>"Deposit"</b> means the crediting of
                                    funds to your eWallet by purchasing E-Money;
                                </p>
                                <p>
                                    <b>"E-Money"</b> means electronic money
                                    issued by Juno to you where each unit
                                    represents an equivalent value of E-money in
                                    the currency selected, for use by you in
                                    making Transactions;
                                </p>
                                <p>
                                    <b>"eWallet"</b> means a digital E-Money
                                    account on our systems that is specific to
                                    you, denominated in an available currency of
                                    your choice, which enables you to make
                                    Transactions, where we record your Available
                                    Balance, data relating to your Transactions
                                    and other information from time to time;
                                </p>
                                <p>
                                    <b>"Fees"</b> has the meaning given to it in
                                    Clause 10;
                                </p>
                                <p>
                                    <b>"Force Majeure Event"</b> means any event
                                    or circumstance which is beyond our
                                    reasonable control, including but not
                                    limited to: any act of God, flood,
                                    earthquake or other natural disaster,
                                    terrorist acts, pandemic, riots, war,
                                    sanction or embargo, fire, explosion or
                                    accident, industrial action of any kind
                                    (other than induced by us), interruption or
                                    failure of any utility service or act taken
                                    by any government authority;
                                </p>
                                <p>
                                    <b>"Inbound Transaction"</b> means a Refund
                                    or a payment in E-Money from a Merchant to
                                    you or a transfer of E-Money from another
                                    eWallet holder;
                                </p>
                                <p>
                                    <b>"Merchant"</b> means a third party
                                    provider of online or in-person activities,
                                    goods or services, authorised by us to
                                    accept Outbound Transactions and make
                                    Inbound Transactions;
                                </p>
                                <p>
                                    <b>"Notice"</b> means any information
                                    published or provided to you by Juno via the
                                    App, your email or any other method as Juno
                                    may use from time to time;
                                </p>
                                <p>
                                    <b>"Intellectual Property Rights"</b> means
                                    patents, utility models, trademarks, service
                                    marks, trade and business names, rights in
                                    designs, copyright (including rights in
                                    software), database rights, domain names,
                                    semi-conductor topography rights, rights in
                                    inventions, rights in know-how and
                                    confidential information and other
                                    intellectual property rights which may
                                    subsist in any part of the world, in each
                                    case whether registered or not (and
                                    including applications for registration);
                                </p>
                                <p>
                                    <b>"Losses"</b> means all losses, damages,
                                    claims, liabilities, costs and expenses of
                                    any kind (including reasonable legal fees
                                    and expenses);
                                </p>
                                <p>
                                    <b>"Outbound Transaction"</b> means a
                                    payment by you for goods, services,
                                    privileges or other rights using E-Money or
                                    transfer of E-Money to another eWallet
                                    holder;
                                </p>
                                <p>
                                    <b>"Payment Initiation Service Provider"</b>
                                    means a third-party payment service provider
                                    which is authorised to provide an online
                                    service which accesses your payment account
                                    to initiate the transfer of funds on your
                                    behalf under a separate agreement with you;
                                </p>
                                <p>
                                    <b>"Pre-Approved and Automatic Payments"</b>
                                    means payments authorised by you from your
                                    eWallet in advance whereby you agree to a
                                    Prospective Payment Recipient having the
                                    ability to collect and/or credit payments
                                    from/to your eWallet until you withdraw your
                                    consent;
                                </p>
                                <p>
                                    <b>"Prospective Payment Recipient"</b> means
                                    a third party who may request and receive
                                    Pre-Approved and Automatic Payments from
                                    your Juno eWallet in such amounts as
                                    represented to us by that third party;
                                </p>
                                <p>
                                    <b>"Refund"</b> means a partial or full
                                    reimbursement to you in E-Money from a
                                    Merchant for an earlier Outbound Transaction
                                    with the same Merchant that was either not
                                    redeemed or for which it is otherwise
                                    determined the amount paid, partially or in
                                    full, should be returned;
                                </p>
                                <p>
                                    <b>"Security Credentials"</b> means security
                                    information (such as answers to questions
                                    known only to you, usernames, passwords,
                                    passcodes, PIN, or codes generated through a
                                    multi-factor authentication security device)
                                    that may be used to access your eWallet or
                                    make Transactions;
                                </p>
                                <p>
                                    <b>"Services"</b> has the meaning given to
                                    it in Clause 4;
                                </p>
                                <p>
                                    <b>"TPP"</b> means a third party provider
                                    which is either an Account Information
                                    Service Provider, and/or Payment Initiation
                                    Service Provider;
                                </p>
                                <p>
                                    <b>"Transactions"</b> means either or both
                                    an Outbound Transaction and an Inbound
                                    Transactions;
                                </p>
                                <p>
                                    <b>"UK"</b> means United Kingdom;
                                </p>
                                <p>
                                    <b>"Website"</b> means junomoney.com;
                                </p>
                                <p>
                                    <b>"Withdrawal"</b> – means removing funds
                                    from your eWallet by selecting one of the
                                    withdrawal methods available to you in your
                                    country of residence;
                                </p>
                                <p>
                                    <b>"we"</b>, <b>"us"</b>, <b>"our"</b> means
                                    Juno Pay Ltd.
                                </p>
                            </div>

                            <div className="flex">
                                <p className="min-w-[48px]">
                                    <b>1.2.</b>
                                </p>
                                <p>In these Terms:</p>
                            </div>
                            <div className="pl-[48px]">
                                <ol
                                    style={{
                                        listStyleType: 'lower-alpha',
                                    }}
                                    className="flex flex-col gap-[24px] pl-[24px]"
                                >
                                    <li>
                                        References to <b>“include”</b> or
                                        <b>“including”</b>
                                        do not limit the generality of any
                                        preceding words and are to be construed
                                        without limitation;
                                    </li>
                                    <li>
                                        References to a <b>“person”</b> include
                                        any individual, company, partnership,
                                        joint venture, firm, association, trust,
                                        government authority or other body or
                                        entity (whether or not having separate
                                        legal personality);
                                    </li>
                                    <li>
                                        References to statutory provisions,
                                        regulations or notices shall include
                                        those provisions, regulations, notices
                                        or rules as amended, extended,
                                        consolidated, substituted or re-enacted
                                        from time to time;
                                    </li>
                                    <li>
                                        The headings are inserted for
                                        convenience only and do not affect the
                                        construction of these Terms;
                                    </li>
                                    <li>
                                        Unless the context otherwise requires,
                                        words in the singular include the plural
                                        and vice versa and a reference to any
                                        gender includes all other genders.
                                    </li>
                                </ol>
                            </div>
                        </div>
                    </>

                    <>
                        <div className="self-stretch relative px-[16px] md:px-[24px] xl:px-0">
                            <h2 className="text-[36px] font-medium text-black">
                                2. Changes to these Terms.
                            </h2>
                        </div>
                        <div className="self-stretch flex flex-col items-start justify-center gap-[32px] xl:pl-[48px] px-[16px] md:px-[24px]">
                            <div className="flex">
                                <p className="min-w-[48px]">
                                    <b>2.1.</b>
                                </p>
                                <p>
                                    We may update or amend these Terms from time
                                    to time by giving you two (2) months’ prior
                                    notice before the date on which the updates
                                    or amendments are to take effect. Every time
                                    you wish to use our Services, please check
                                    these Terms to ensure you understand the
                                    terms that apply at that time. Reasons why
                                    we may change these Terms may include,
                                    without limitation changes:
                                </p>
                            </div>
                            <div className="pl-[48px]">
                                <ol
                                    style={{
                                        listStyleType: 'upper-roman',
                                    }}
                                    className="flex flex-col gap-[24px] pl-[24px]"
                                >
                                    <li>To the services we provide;</li>
                                    <li>
                                        In market conditions or operating costs
                                        that affect our business;
                                    </li>
                                    <li>In technology;</li>
                                    <li>In payment methods;</li>
                                    <li>In Applicable Laws;</li>
                                    <li>
                                        To make them clearer or more favourable
                                        to you;
                                    </li>
                                    <li>To our systems.</li>
                                </ol>
                            </div>
                            <CustomLi
                                counter="2.2."
                                text="Notice of updates or amendments to these Terms as outlined in Clause 2.1 will be given on the App or by notification by email. Any updates or amendments to these Terms will be effective from the effective date indicated in the relevant Notice provided. These updates and amendments will apply prospectively to any use of the Services after the effective date stated. If you do not agree to any such updates or amendments, you have the right to terminate these Terms immediately without any charge before the amendments enter into force. If you wish to refuse the proposed amendments and must notify us of the refusal by written notice prior to the amendments coming into force. If you do not notify us of your intention to refuse the amendments, by continuing to use any of our Services after that effective date you will be deemed to have understood and agreed to be bound by the updated Terms and the relationship between you and us shall be governed by the new version of the Terms."
                            />
                            <CustomLi
                                counter="2.3."
                                text="We may make immediate changes to the Terms in the event that we add a new product or service that does not change the terms and conditions applicable to our existing services. We may add any such service immediately and will inform you of the Terms before you use it."
                            />
                        </div>
                    </>

                    <>
                        <div className="self-stretch relative px-[16px] md:px-[24px] xl:px-0">
                            <h2 className="text-[36px] font-medium text-black">
                                3. Registration and Eligibility.
                            </h2>
                        </div>
                        <div className="self-stretch flex flex-col items-start justify-center gap-[32px] xl:pl-[48px] px-[16px] md:px-[24px]">
                            <CustomLi
                                counter="3.1."
                                text="You may register for a Juno eWallet via the App or other means that we may in the future prescribe. If you choose to register for an eWallet, you agree to provide us with true, accurate, current and complete information about yourself, which includes certain biometric data (including a facial scan and a thumbprint/fingerprint scan), and update us if there are any changes to that information or data."
                            />
                            <CustomLi
                                counter="3.2."
                                text="In order to enter these Terms and receive the Services, you warrant that you are legally deemed as having full capacity, are a resident in a jurisdiction where Juno provides services and you are at least 18 years of age. In order for you to prove your capacity, location and/or your age, we reserve the right to request from you any extra information."
                            />
                            <div className="flex">
                                <p className="min-w-[48px]">
                                    <b>3.3.</b>
                                </p>
                                <p>
                                    Your eWallet will not be activated unless we
                                    have been provided with the required
                                    information so that we may identify you and
                                    comply with all applicable
                                    know-your-customer <b>(“KYC”)</b>
                                    requirements. We shall keep records of the
                                    information and documents you provide in
                                    accordance with our
                                    <NavLink to="/juno-privacy-policy">
                                        <i>Privacy Policy</i>
                                    </NavLink>
                                    ; and all applicable legal and regulatory
                                    requirements.
                                </p>
                            </div>
                            <CustomLi
                                counter="3.4."
                                text="We reserve the right to not accept your application for an eWallet and we are not obliged to provide the reason for declining your application. If we have reasonable grounds to suspect that any information you have provided to us is untrue, inaccurate or incomplete, we may suspend or terminate your eWallet and refuse any and all current or future use of the Services by you."
                            />
                            <CustomLi
                                counter="3.5."
                                text="We reserve the right to refuse the creation of duplicate accounts for the same user due to security and KYC requirements. In a case where duplicate accounts are detected, we reserve the right to close or merge these duplicate accounts without notification to you."
                            />
                            <CustomLi
                                counter="3.6."
                                text="The E-Money contained in your eWallet belongs to the individual registered in accordance with the above Clauses 3.1 to 3.3. No other person has any rights in relation to the E-Money held in your eWallet. You may not assign or transfer your eWallet to a third party or grant any third party or equitable interest over it."
                            />
                        </div>
                    </>

                    <>
                        <div className="self-stretch relative px-[16px] md:px-[24px] xl:px-0">
                            <h2 className="text-[36px] font-medium text-black">
                                4. Services.
                            </h2>
                        </div>
                        <div className="self-stretch flex flex-col items-start justify-center gap-[32px] xl:pl-[48px] px-[16px] md:px-[24px]">
                            <div className="flex">
                                <p className="min-w-[48px]">
                                    <b>4.1.</b>
                                </p>
                                <p>
                                    We will provide the following services to
                                    you (collectively, the “<b>Services</b>”) in
                                    accordance with the remainder of these
                                    Terms:
                                </p>
                            </div>

                            <div className="pl-[48px]">
                                <ol
                                    style={{
                                        listStyleType: 'upper-roman',
                                    }}
                                    className="flex flex-col gap-[24px] pl-[24px]"
                                >
                                    <li>
                                        Hosting a digital account (eWallet)
                                        enabling you to store E-Money;
                                    </li>
                                    <li>
                                        Exchanging funds received from you by us
                                        for E-Money and exchanging E-Money
                                        contained in your eWallet for funds to
                                        be paid to you;
                                    </li>
                                    <li>
                                        Exchange E-Money contained in your
                                        eWallet from one currency to another
                                        available currency;
                                    </li>
                                    <li>
                                        Managing Transactions in your eWallet,
                                        including tracking and messaging
                                        additional information;
                                    </li>
                                    <li>
                                        Certain payment services enabling you to
                                        send and receive E-Money;
                                    </li>
                                    <li>
                                        Other means of using our platform to
                                        enable Transactions with Merchants as
                                        may be offered in the future, and
                                        related services;
                                    </li>
                                </ol>
                            </div>
                            <CustomLi
                                counter="4.2."
                                text="Your eligibility to access certain Services may depend on the country in which you reside or are based from time to time. You shall not attempt to access or make use of the Services from or in any jurisdiction where such access or use is prohibited by Applicable Laws or these Terms. You may only open an account with us and use our Services if it is legal to do so in your country of residence and if it is in accordance with these Terms. By opening such account you represent and warrant to us that your opening of the account and use of our Services does not violate any Applicable Law relevant to you or your country of residence or these Terms. It is your responsibility to ensure that you do not use our Services for any purpose that is considered illegal in your jurisdiction or any other jurisdiction you may be located in from time to time. You shall be liable to us for the amount of any losses we incur in connection with your breach of this section."
                            />
                            <CustomLi
                                counter="4.3."
                                text="You are responsible for any taxes which may be applicable to payments you make or receive, and it is your responsibility to collect, report and pay the correct tax to the appropriate tax authority."
                            />
                        </div>
                    </>

                    <>
                        <div className="self-stretch relative px-[16px] md:px-[24px] xl:px-0">
                            <h2 className="text-[36px] font-medium text-black">
                                5. Access to your Juno eWallet and use of the
                                Services.
                            </h2>
                        </div>
                        <div className="self-stretch flex flex-col items-start justify-center gap-[32px] xl:pl-[48px] px-[16px] md:px-[24px]">
                            <CustomLi
                                counter="5.1."
                                text="You may access your eWallet by logging into the App or on the Website. From here, you will be able to see your eWallet history and Transaction activity, and view your personal details. You will need to contact our Support Team if you wish to change or update your personal details. You should check your eWallet history and Transaction activity regularly and contact us immediately in relation to any irregularities or enquiries. You can contact us in accordance with Clause 20. We shall not be liable for any Losses that result from any inaccurate information you provide."
                            />
                            <CustomLi
                                counter="5.2."
                                text="A list of all Transactions on your eWallet will be accessible via the App. A monthly statement of all Transactions is available on request in accordance with Clause 20. A record of financial transaction data will be available on request and will be stored for as long as is necessary or as otherwise required in accordance with Applicable Law."
                            />
                            <CustomLi
                                counter="5.3."
                                text="A notification will be sent to your mobile device or, where notification to your mobile device is not possible for any reason, a notification will be sent to through the App and by email each time an Inbound or Outbound Transaction is made in your eWallet."
                            />
                            <CustomLi
                                counter="5.4."
                                text="To the extent that you are required to separately on-board with any Merchant, you acknowledge and agree that we shall not be required to authorise any Transactions with that Merchant unless and until you are separately on-boarded as required by them. You take full responsibility for ensuring that you are on-boarded with the relevant Merchant prior to attempting to make a Transaction with that Merchant."
                            />
                            <CustomLi
                                counter="5.5."
                                text="You acknowledge and agree that we are not a party to an agreement between you and the Merchant for the supply of goods and/or services by that Merchant. Consequently, we accept no responsibility for any failures, breaches, acts or omissions of Merchants or their goods or services in connection with the Transactions. You are solely responsible for resolving any disputes you may have with Merchants in connection with Transactions or otherwise. We reserve the right, but have no obligation, to monitor or mediate any disputes between you and any Merchants. If we do elect to assist you in a dispute with a Merchant, you hereby acknowledge that such assistance shall not constitute a waiver of any provision of these Terms."
                            />
                            <CustomLi
                                counter="5.6."
                                text="The value of each Outbound Transaction will be deducted from your Available Balance and the value of each Inbound Transaction will be credited to your Available Balance. You must ensure that you have a sufficient Available Balance from time to time to pay for your Outbound Transactions and any Fees relating to those Outbound Transactions. If for any reason an Outbound Transaction that you request, or the deduction of applicable Fees, would exceed your Available Balance, we will not process that Outbound Transaction, and not processing that Outbound Transaction will not constitute a breach of these Terms."
                            />
                            <div className="flex">
                                <p className="min-w-[48px]">
                                    <b>5.7.</b>
                                </p>
                                <p>
                                    We may refuse to execute any Transactions on
                                    your eWallet where:
                                </p>
                            </div>
                            <div className="pl-[48px]">
                                <ol
                                    style={{
                                        listStyleType: 'upper-roman',
                                    }}
                                    className="flex flex-col gap-[24px] pl-[24px]"
                                >
                                    <li>That would breach these Terms</li>
                                    <li>
                                        We are concerned about the security of
                                        or access to your eWallet
                                    </li>
                                    <li>
                                        We have reasonable grounds for
                                        suspecting that you or a third party
                                        have committed or are planning to commit
                                        fraud or any other illegal or
                                        unauthorised use of the Services
                                    </li>
                                    <li>
                                        You have failed to use the
                                        authentication method required to
                                        authorise a transaction or access our
                                        Services
                                    </li>
                                    <li>
                                        We are required to do so to comply with
                                        the Applicable Laws.
                                    </li>
                                </ol>
                            </div>
                            <CustomLi
                                counter="5.8."
                                text="Where we refuse to execute a Transaction we will notify you as soon as reasonably practical provided it would not be unlawful for us to do so. If possible, we will provide the reasons for refusal to execute the Transaction and/or suspending use of the Services and where those reasons relate to factual matters, the procedure for rectifying any factual errors that led to such refusal or suspension. Subject to Applicable Law, we shall not be liable for any losses arising from our refusal to execute a Transaction, or our suspension or restriction of your use of the Services where it is in compliance with these Terms and/or Applicable Law."
                            />
                            <CustomLi
                                counter="5.9."
                                text="If for any reason any Outbound Transaction, or the deduction of applicable Fees related to the Transaction, would exceed your Available Balance on your eWallet, you are required to load your eWallet with sufficient funds to cover that liability before that Outbound Transaction can be actioned. We reserve the right to suspend your use of some or all of the Services until your Available Balance is sufficient to meet any requested Outbound Transaction or applicable Fees, to send you reminders or to take other debt collection measures and we may charge a Fee in connection with any debt collection or enforcement measures."
                            />
                            <CustomLi
                                counter="5.10."
                                text="Your ability to use or access the Services or your eWallet may occasionally be interrupted, including, for example, but not limited to, if we need to carry out maintenance on our systems or due to a Force Majeure Event. Please contact us in accordance with Clause 20 if you wish to report any issues."
                            />
                            <div className="flex">
                                <p className="min-w-[48px]">
                                    <b>5.11.</b>
                                </p>
                                <p>You agree:</p>
                            </div>
                            <div className="pl-[48px]">
                                <ol
                                    style={{
                                        listStyleType: 'upper-roman',
                                    }}
                                    className="flex flex-col gap-[24px] pl-[24px]"
                                >
                                    <li>
                                        To only use the Services for lawful
                                        purposes and to adhere at all times to
                                        all Applicable Laws and these Terms;
                                    </li>
                                    <li>
                                        Not to attempt to discover any source
                                        code included in the App, upload to our
                                        systems any malware, viruses or other
                                        unauthorised object code or source code,
                                        or otherwise disrupt or interfere with
                                        the conduct of the Service;
                                    </li>
                                    <li>
                                        Not to copy, adapt, reverse engineer,
                                        decompile, disassemble, modify, or make
                                        error corrections to the App in whole or
                                        in part, except to the extent permitted
                                        by Applicable Laws.
                                    </li>
                                </ol>
                            </div>

                            <div className="flex">
                                <p className="min-w-[48px]">
                                    <b>5.12.</b>
                                </p>
                                <p>
                                    <b>Pre-approved and Automatic Payments</b>
                                </p>
                            </div>
                            <div className="pl-[48px]">
                                <ol
                                    style={{
                                        listStyleType: 'lower-alpha',
                                    }}
                                    className="flex flex-col gap-[24px] pl-[24px]"
                                >
                                    <li>
                                        <div className="flex flex-col gap-[24px]">
                                            <p>
                                                By providing an Authorisation
                                                for Pre-approved and Automatic
                                                Payments you:
                                            </p>

                                            <ol
                                                style={{
                                                    listStyleType:
                                                        'upper-roman',
                                                }}
                                                className="flex flex-col gap-[24px] pl-[24px]"
                                            >
                                                <li>
                                                    Give the Prospective Payment
                                                    Recipient the ability to
                                                    collect or Refund fixed or
                                                    variable amount payments and
                                                    credits from/to your eWallet
                                                    on a one-time or
                                                    (sporadically or
                                                    periodically) recurring
                                                    basis until you cancel your
                                                    Authorisation for the
                                                    relevant Pre-approved and
                                                    Automatic Payments;
                                                </li>
                                                <li>
                                                    Hereby authorise and
                                                    instruct us to pay the
                                                    Prospective Payment
                                                    Recipient from your Juno
                                                    eWallet amounts as presented
                                                    to us by the Prospective
                                                    Payment Recipient, which may
                                                    arise and accrue from all or
                                                    any of your activities with
                                                    such Prospective Payment
                                                    Recipient. This makes the
                                                    Prospective Payment
                                                    Recipient a trusted
                                                    beneficiary of all your
                                                    payments made under that
                                                    Authorisation, so we will
                                                    not ask you to log in or
                                                    approve the payments when
                                                    they are made and your
                                                    Available Balance may be
                                                    automatically and
                                                    continuously debited
                                                    depending on your activity
                                                    with a Prospective Payment
                                                    Recipient which is not yet
                                                    known at the date of giving
                                                    this consent. You agree that
                                                    Juno is not obligated to
                                                    verify or confirm the amount
                                                    the Prospective Payment
                                                    Recipient presents to us for
                                                    the purpose of processing a
                                                    Pre-approved and Automatic
                                                    Payments. You further
                                                    acknowledge and agree that
                                                    payments made under this
                                                    provision are variable and
                                                    may be made on various
                                                    dates.
                                                </li>
                                            </ol>
                                        </div>
                                    </li>
                                    <li>
                                        When you select Juno as the payment
                                        method when purchasing any goods and/or
                                        services from a relevant Prospective
                                        Payment Recipient, you are instructing
                                        Juno to pay to the Prospective Payment
                                        Recipient the amount, owed by you and
                                        due to the Prospective Payment
                                        Recipient, as is presented to Juno. Juno
                                        will pay to the Prospective Payment
                                        Recipient the amount of any payment
                                        request based on your Authorisation for
                                        the relevant Pre-approved and Automatic
                                        Payments;
                                    </li>
                                    <li>
                                        <div className="flex flex-col gap-[24px]">
                                            <p>
                                                You agree that you cannot
                                                request a refund from Juno for a
                                                Pre-approved and Automatic
                                                Payment unless:
                                            </p>
                                            <ol
                                                style={{
                                                    listStyleType:
                                                        'upper-roman',
                                                }}
                                                className="flex flex-col gap-[24px] pl-[24px]"
                                            >
                                                <li>
                                                    The Authorisation did not
                                                    specify the exact amount of
                                                    the payment transaction when
                                                    the Authorisation was given
                                                    and the applicable amount
                                                    exceeded the amount you
                                                    could have reasonably been
                                                    expected to pay, taking into
                                                    account your previous
                                                    spending patterns and the
                                                    circumstances of the case
                                                    and your consent to the
                                                    making of the third party
                                                    initiated payment was not
                                                    given as set out in section
                                                    5.12;
                                                </li>
                                                <li>
                                                    You notify us of the request
                                                    within 8 weeks from the date
                                                    the payment was made;
                                                </li>
                                                <li>
                                                    You comply with our requests
                                                    to obtain information which
                                                    we reasonably require to
                                                    review the circumstances of
                                                    the case.
                                                </li>
                                            </ol>
                                        </div>
                                    </li>
                                    <li>
                                        We reserve the right to request further
                                        information as is reasonably necessary
                                        to ascertain whether the conditions in
                                        this clause 5.12 have been satisfied.
                                    </li>
                                </ol>
                            </div>
                            <div className="flex">
                                <p className="min-w-[48px]">
                                    <b>5.13.</b>
                                </p>
                                <p>
                                    <b>
                                        Cancelling Pre-Approved and Automatic
                                        Payments
                                    </b>
                                </p>
                            </div>
                            <div className="pl-[48px]">
                                <ol
                                    style={{
                                        listStyleType: 'lower-alpha',
                                    }}
                                    className="flex flex-col gap-[24px] pl-[24px]"
                                >
                                    <li>
                                        You may cancel a Pre-Approved and
                                        Automatic Payment at any time up to one
                                        (1) Business Day prior to the date the
                                        payment is scheduled to be made by
                                        contacting us in accordance with Clause
                                        20.
                                    </li>
                                    <li>
                                        <div className="flex flex-col gap-[24px]">
                                            <p>
                                                A cancellation request received
                                                by Juno:
                                            </p>
                                            <ol
                                                style={{
                                                    listStyleType:
                                                        'upper-roman',
                                                }}
                                                className="flex flex-col gap-[24px] pl-[24px]"
                                            >
                                                <li>
                                                    Before close of business on
                                                    a day that is not a Business
                                                    Day will take effect on the
                                                    next Business Day;
                                                </li>
                                                <li>
                                                    After close of business on a
                                                    Business Day will take
                                                    effect on the next Business
                                                    Day.
                                                </li>
                                            </ol>
                                        </div>
                                    </li>
                                    <li>
                                        If you cancel a Pre-Approved and
                                        Automatic Payment you may still be
                                        liable to the Prospective Payment
                                        Recipient for the payment and/or other
                                        unpaid amounts and be required to pay
                                        the Prospective Payment Recipient
                                        through alternative means, if payments
                                        have not been completed or if payments
                                        are lawfully owed to the Prospective
                                        Payment Recipient.
                                    </li>
                                </ol>
                            </div>
                            <div className="flex">
                                <p className="min-w-[48px]">
                                    <b>5.14.</b>
                                </p>
                                <p>
                                    <b>eWallet Currency</b>
                                </p>
                            </div>
                            <div className="flex flex-col gap-[24px] pl-[48px]">
                                <p>
                                    Your eWallet will be set up in a primary
                                    currency
                                    (https://Juno.com/help-centre/questions/what-is-my-primary-currency-and-why-do-i-have-it/)
                                    based on the country of your verified
                                    address. You may change the primary currency
                                    of your eWallet, by following the guide on
                                    our FAQ.
                                </p>
                                <p>
                                    Your eWallet may be subject to transaction
                                    limits depending on your country of
                                    residence, the verification status of your
                                    eWallet and other factors used by us to
                                    determine such limits from time to time.
                                    Your transaction limits form part of your
                                    account details and can be viewed in your
                                    account via the App and on the Website.
                                </p>
                            </div>
                        </div>
                    </>

                    <>
                        <div className="self-stretch relative px-[16px] md:px-[24px] xl:px-0">
                            <h2 className="text-[36px] font-medium text-black">
                                6. Loading your Juno eWallet.
                            </h2>
                        </div>
                        <div className="self-stretch flex flex-col items-start justify-center gap-[32px] xl:pl-[48px] px-[16px] md:px-[24px]">
                            <CustomLi
                                counter="6.1."
                                text="Following activation of your eWallet, you will be able to send funds to us, and as an E-Money issuer, we will credit your eWallet with an equivalent amount in E-Money once we receive your funds. We may ask you to answer security questions or to complete other activities (including providing your biometric data) that we may reasonably require to ensure proper authorisation of a Deposiit transaction. We will not extend you credit, nor will we assume any responsibility for any extension of credit by Merchants to you."
                            />
                            <CustomLi
                                counter="6.2."
                                text="In order to load your eWallet, you must log into the App or Website by using your Security Credentials and following the relevant instructions. The method of loading you may use will depend on the country in which you reside; please see the Website for a list of the permitted methods for loading your eWallet in your country of residence, which we may change in our sole discretion from time to time. https://Juno.com/help-centre/questions/what-countries-is-Juno-available-in."
                            />
                            <CustomLi
                                counter="6.3."
                                text="The execution of your payments by a TPP in order to load your eWallet with E-Money are not part of the Services. We do not guarantee the ability to use any particular TPP and we may discontinue the availability of any such TPP for loading your eWallet at any time in our sole discretion."
                            />
                            <CustomLi
                                counter="6.4."
                                text="E-Money will be available on your eWallet after we receive the funds which could take several days depending on the method and currency used to load your eWallet. Our processing of all loading transactions will be subject to fraud, money-laundering and other operational and customer service checks. Accordingly, there may be delays in the time required for E-Money to appear in your eWallet once you have performed a loading transaction."
                            />
                            <CustomLi
                                counter="6.5."
                                text="You must not load your eWallet through a payment card or other payment instrument or a payment account if you are not the named holder of that payment instrument or account. Any attempt to use a payment instrument or account of which you are not the named holder will result in the load transaction being rejected and may be considered a fraudulent act."
                            />
                            <CustomLi
                                counter="6.6."
                                text="If you make a loading transaction by a payment card, your payment information will be stored securely in accordance with Applicable Laws."
                            />
                            <CustomLi
                                counter="6.7."
                                text="If you make a loading transaction using a payment method that may be subject to a Chargeback such as (but not limited to) credit or payment cards, you warrant that you will only exercise such Chargeback for an unauthorised use of the payment method or for a breach by us of these Terms which would result in you being entitled to a refund of the same loading transaction. Otherwise, you may not allow Chargeback of any loading transaction for reasons outside of our control including (but not limited to) disputes with a Merchant for non-delivery of goods or services. We reserve the right to charge you for any fees and expenses we incur in connection with a Chargeback including any action taken to challenge a Chargeback."
                            />
                            <CustomLi
                                counter="6.8"
                                text="If for any reason your Available Balance is insufficient to meet a Chargeback, you are required to load your eWallet with sufficient funds to cover that liability immediately. You agree that we may charge the amount which is in excess of your Available Balance against any funds subsequently loaded onto your eWallet. We reserve the right to suspend your use of some or all of the Services until your Available Balance is sufficient to meet any such Chargeback, to send you reminders or to take other debt collection measures and we may charge a Fee in connection with any debt collection or enforcement measures."
                            />
                        </div>
                    </>
                    <>
                        <div className="self-stretch relative px-[16px] md:px-[24px] xl:px-0">
                            <h2 className="text-[36px] font-medium text-black">
                                7. Making Outbound Transactions.
                            </h2>
                        </div>
                        <div className="self-stretch flex flex-col items-start justify-center gap-[32px] xl:pl-[48px] px-[16px] md:px-[24px]">
                            <div className="flex">
                                <p className="min-w-[48px]">
                                    <b>7.1.</b>
                                </p>
                                <p>
                                    An Outbound Transaction will be regarded as
                                    authorised when you (either directly or via
                                    Payment Initiation Service Provider):
                                </p>
                            </div>
                            <div className="pl-[48px]">
                                <ol
                                    style={{
                                        listStyleType: 'upper-roman',
                                    }}
                                    className="flex flex-col gap-[24px] pl-[24px]"
                                >
                                    <li>
                                        Enter a password or other Security
                                        Credentials we have issued to you for
                                        that purpose on the App or the Website;
                                    </li>
                                    <li>
                                        Use your fingerprint scanner or facial
                                        recognition on your mobile device.
                                    </li>
                                </ol>
                            </div>
                            <CustomLi
                                counter="7.2."
                                text="You cannot change or cancel your instruction for an Outbound Transaction after it has been received by us."
                            />
                            <div className="flex">
                                <p className="min-w-[48px]">
                                    <b>7.3.</b>
                                </p>
                                <p>
                                    Payment Transactions and redemptions from
                                    your eWallet will be processed by us in
                                    accordance with the following timescales:
                                </p>
                            </div>
                            <div className="pl-[48px]">
                                <ol
                                    style={{
                                        listStyleType: 'upper-roman',
                                    }}
                                    className="flex flex-col gap-[24px] pl-[24px]"
                                >
                                    <li>
                                        Where we receive the instruction on a
                                        Business Day, on the same Business Day;
                                    </li>
                                    <li>
                                        Where we receive the instruction on a
                                        day that is not a Business Day, on the
                                        next Business Day;
                                    </li>
                                    <li>
                                        Where we receive the instruction after
                                        the Cut-Off Time on a Business Day, the
                                        next Business Day.
                                    </li>
                                </ol>
                            </div>
                            <CustomLi
                                counter="7.4."
                                text="Notwithstanding the timescales set out above, Payment Transactions and redemptions may be delayed due to circumstances outside our control, including the conduct of any third party. Payment Transactions and redemptions may also be delayed where necessary to comply with the Applicable Laws (for example, where further action is necessary to comply with all applicable KYC requirements)."
                            />
                            <div className="flex">
                                <p className="min-w-[48px]">
                                    <b>7.5.</b>
                                </p>
                                <p>
                                    <b>
                                        Transfers to other Juno eWallet holders
                                    </b>
                                </p>
                            </div>
                            <div className="pl-[48px]">
                                <ol
                                    style={{
                                        listStyleType: 'lower-alpha',
                                    }}
                                    className="flex flex-col gap-[24px] pl-[24px]"
                                >
                                    <li>
                                        You can send E-Money to and receive
                                        E-Money from any of your contacts who
                                        are Juno eWallet holders by selecting
                                        from your contacts list in the App. This
                                        function is available where you both
                                        have agreed to provide your contact
                                        details to other Juno eWallet holders.
                                        We will use our reasonable endeavours to
                                        ensure the other Juno eWallet holder
                                        will receive the transfer immediately or
                                        otherwise in a timely manner, subject to
                                        our internal compliance policies,
                                        procedures and Applicable Law.
                                    </li>
                                    <li>
                                        When you instruct us to make an Outbound
                                        Transaction to a Juno eWallet holder,
                                        you must provide us with the recipient’s
                                        Juno eWallet ID and any other
                                        information we ask for. If you provide
                                        us with incorrect or incomplete
                                        information or refuse to provide
                                        information, we may refuse to execute
                                        the Outbound Transaction, the funds
                                        could be lost and irrecoverable or there
                                        could be a delay in the recipient
                                        receiving the payment.
                                    </li>
                                </ol>
                            </div>
                        </div>
                    </>

                    <>
                        <div className="self-stretch relative px-[16px] md:px-[24px] xl:px-0">
                            <h2 className="text-[36px] font-medium text-black">
                                8. Currency Exchange.
                            </h2>
                        </div>
                        <div className="self-stretch flex flex-col items-start justify-center gap-[32px] xl:pl-[48px] px-[16px] md:px-[24px]">
                            <CustomLi
                                counter="8.1."
                                text="When you request a Currency Exchange, we will use a variable exchange spot rate which is linked to foreign exchange markets. Details of the currencies available and the source of the exchange rates used by the App from time to time are as detailed in the FAQ on our Website. While we will provide you with an indicative exchange rate at the time you request a Currency Exchange, you accept that such conversion shall be entirely at your own cost and risk including as a result of any fluctuation or difference between the indicative rate of exchange displayed on the App at the time of a loading transaction and when you request to redeem the E-Money in your eWallet."
                            />
                            <CustomLi
                                counter="8.2."
                                text="Juno may make immediate changes to the exchange rates used to make a Currency Exchange or convert the transfer of funds from or to your eWallet. You will be able to confirm the current applicable exchange rate in the App at the time the transfer is being made. See Clauses 9.1 and 16.7."
                            />
                            <CustomLi
                                counter="8.3."
                                text="Juno may limit the value of Currency Exchange you can carry out at any one time or over a period of time. These limits can change from time to time. Information about these limits is set out in our FAQs."
                            />
                            <CustomLi
                                counter="8.4."
                                text="The eWallet must not be used to trade in foreign currencies for speculative purposes."
                            />
                            <CustomLi
                                counter="8.5."
                                text="Juno are not responsible for any Currency Exchange Losses that arise from any Transactions."
                            />
                            <CustomLi
                                counter="8.6."
                                text="You cannot cancel or reverse a Currency Exchange transaction."
                            />
                        </div>
                    </>

                    <>
                        <div className="self-stretch relative px-[16px] md:px-[24px] xl:px-0">
                            <h2 className="text-[36px] font-medium text-black">
                                9. Fees.
                            </h2>
                        </div>
                        <div className="self-stretch flex flex-col items-start justify-center gap-[32px] xl:pl-[48px] px-[16px] md:px-[24px]">
                            <CustomLi
                                counter="9.1."
                                text="In order to open your eWallet and use the Services, you agree that we may charge you the Fees that shall be set out in the Fees schedule (the “<b>Fees</b>”), and you agree that any Fees and other amounts due and payable to us under these Terms will be deducted from your eWallet without notice."
                            />
                            <CustomLi
                                counter="9.2."
                                text="You acknowledge that it is your responsibility to check the Fees before making a Transaction, a Currency Exchange or using any Services, and by proceeding with a Transaction, Currency Exchange or any Services you are deemed to accept the Fees."
                            />
                            <CustomLi
                                counter="9.3."
                                text="Juno reserves the right to change or amend the fees and these will be updated in the Fee schedule. Such changes will be effective from the effective date indicated in the relevant notice provided and will apply prospectively to any use of the Services after that effective date. By continuing to use our Services after that effective date, you will be deemed to have understood and agreed to be bound by the updated fees."
                            />
                        </div>
                    </>
                    <>
                        <div className="self-stretch relative px-[16px] md:px-[24px] xl:px-0">
                            <h2 className="text-[36px] font-medium text-black">
                                10. Security of your Juno eWallet.
                            </h2>
                        </div>
                        <div className="self-stretch flex flex-col items-start justify-center gap-[32px] xl:pl-[48px] px-[16px] md:px-[24px]">
                            <CustomLi
                                counter="10.1"
                                text="You are responsible for the use of your eWallet, for ensuring that use of the Services or access to your eWallet complies fully with these Terms and for keeping your Security Credentials, mobile and any other device or means to access your eWallet, safe and secure so as to prevent fraud and to protect your eWallet."
                            />
                            <div className="flex">
                                <p className="min-w-[48px]">
                                    <b>10.2.</b>
                                </p>
                                <p>You must not:</p>
                            </div>
                            <div className="pl-[48px]">
                                <ol
                                    style={{
                                        listStyleType: 'upper-roman',
                                    }}
                                    className="flex flex-col gap-[24px] pl-[24px]"
                                >
                                    <li>
                                        Disclose your Security Credentials to
                                        anyone;
                                    </li>
                                    <li>
                                        Allow any other person to use the
                                        eWallet and/or any devices or tools that
                                        may be used to access your eWallet (such
                                        as your mobile).
                                    </li>
                                </ol>
                            </div>
                            <CustomLi
                                counter="10.3."
                                text="If you disclose your Security Credentials to anyone, you are responsible and liable for their access, use or misuse of your eWallet, any losses incurred as a result of their access, use or misuse and their breach of these Terms."
                            />
                            <CustomLi
                                counter="10.4."
                                text="If your Security Credentials or other eWallet details are lost, stolen or compromised, or you believe a third party has unauthorised access to your eWallet (including through lost or stolen devices used to access your eWallet), you must contact us immediately in accordance with Clause 20. We will take reasonable steps to stop any unauthorised use of your eWallet, which may include suspending or cancelling your eWallet or otherwise suspending access to funds or issuing you with replacement Security Credentials."
                            />
                            <CustomLi
                                counter="10.5."
                                text="If we suspect your eWallet has been compromised or we have suffered a security threat, we will contact you only by email, using the latest contact details you have given us. When we contact you about a security threat, we will also give you information on how you can minimise any risk to your eWallet depending on the nature of the security threat."
                            />
                        </div>
                    </>
                    <>
                        <div className="self-stretch relative px-[16px] md:px-[24px] xl:px-0">
                            <h2 className="text-[36px] font-medium text-black">
                                11. Liability for Unauthorised or Incorrect
                                Transactions.
                            </h2>
                        </div>
                        <div className="self-stretch flex flex-col items-start justify-center gap-[32px] xl:pl-[48px] px-[16px] md:px-[24px]">
                            <CustomLi
                                counter="11.1."
                                text="You may be entitled to a refund of unauthorised or incorrectly executed Transactions in accordance with this Clause 12 provided at all times that you have notified us without undue delay of becoming aware of such incorrectly executed or unauthorised Transaction and in any case within 13 months from when it was debited to your eWallet. You can notify us by contacting us in accordance with Clause 20. We reserve the right to charge you in our sole discretion for any investigation into any incorrect or unauthorised Transaction."
                            />
                            <div className="flex">
                                <p className="min-w-[48px]">
                                    <b>11.2.</b>
                                </p>
                                <div className="flex flex-col gap-[16px]">
                                    <p>
                                        <b>Incorrect Transactions.</b>
                                    </p>
                                    <p>
                                        If we make an Outbound Transaction or
                                        execute your redemption request
                                        incorrectly, we will refund you the
                                        amount of incorrectly sent payment as
                                        soon as reasonably practicable, together
                                        with any charges to put you into a
                                        position had the relevant Transaction
                                        not been made, except where:
                                    </p>
                                </div>
                            </div>
                            <div className="pl-[48px]">
                                <ol
                                    style={{
                                        listStyleType: 'upper-roman',
                                    }}
                                    className="flex flex-col gap-[24px] pl-[24px]"
                                >
                                    <li>
                                        The instruction you gave us for the
                                        Transaction was not correct. If so, we
                                        will make reasonable efforts to trace
                                        the payment and recover the amount if
                                        you ask us and notify you of the
                                        outcome. We may charge you a fee to
                                        recover our costs in doing so;
                                    </li>
                                    <li>
                                        We can show that the Transaction was
                                        actually received by the recipient’s
                                        payment services provider (in which case
                                        the recipient’s payment service provider
                                        is liable).
                                    </li>
                                </ol>
                            </div>
                            <CustomLi
                                counter="11.3."
                                text="If you receive a payment to your eWallet by mistake, we are obligated to provide to the payment service provider that sent the incorrect payment certain information about you and the payment to enable them to recover the funds. You must not transfer, spend or redeem any funds you receive by mistake, and we may suspend your use of some or all of the Services during the course of our investigation into the incorrect payment. The amount of any proceeds of a suspected or confirmed unauthorised or incorrect transaction are deemed to be a debt due and payable to us on demand. You agree that we may charge the amount of any such debt against any funds subsequently loaded onto your eWallet. We reserve the right to recover the value or any proceeds of any unauthorised or incorrect transaction related to you by any other legal means."
                            />
                            <div className="flex">
                                <p className="min-w-[48px]">
                                    <b>11.4.</b>
                                </p>
                                <div className="flex flex-col gap-[16px]">
                                    <p>
                                        <b>Unauthorised Transactions.</b>
                                    </p>
                                    <p>
                                        If you notified us of unauthorised
                                        Outbound Transaction in accordance with
                                        Clause 12.1, unless we have reasonable
                                        grounds to believe you are not entitled
                                        to a refund, we will refund the value or
                                        the unauthorised Outbound Transaction,
                                        including any associated Fees and
                                        charges back into the eWallet the
                                        unauthorised Outbound Transaction was
                                        made from. You will not have any further
                                        claim against us in respect of such
                                        unauthorised Outbound Transaction.
                                    </p>
                                </div>
                            </div>
                            <CustomLi
                                counter="11.5."
                                text="If we have reasonable grounds to believe you are not entitled to a refund, we may investigate your claim further before giving you a refund. You agree to provide us such information to assist our investigation as we may reasonably require, including through electronic means. We will provide you with a refund for the Outbound Transaction you claim was unauthorised as soon as reasonably practicable and, in any case, no later than the end of the Business Day after you notified us of an unauthorised Outbound Transaction, unless we have reasonable grounds to suspect you acted fraudulently and we notified the police or other person permitted by law."
                            />
                            <CustomLi
                                counter="11.6."
                                text="If after we have given you a refund our investigation of a disputed Outbound Transaction subsequently discovers that such claimed disputed Outbound Transaction was in fact genuine and authorised by you directly or indirectly, or that you acted fraudulently or with intent or gross negligence, we will deduct the amount of the disputed Outbound Transaction from your Available Balance. In addition, we reserve the right to recover the value of any Outbound Transaction that was refunded to you by any other legal means."
                            />
                            <CustomLi
                                counter="11.7."
                                text="You may be liable up to a maximum of £35 or currency equivalent for unauthorised use of your eWallet before you notified us in accordance with Clause 12.1. The £35 liability limit or currency equivalent is applicable to each instance of loss, theft or misappropriation and not each Transaction."
                            />
                            <CustomLi
                                counter="11.8."
                                text="You will be liable for all Losses incurred in respect of an unauthorised Outbound Transaction and will not be entitled to a refund if you have (a) acted fraudulently or have intentionally or with gross negligence; (b) failed to keep you Security Credentials safe and secure or otherwise failed to comply with these Terms in relation to the safety of your eWallet; or (b) failed to notify us in accordance with Clause 12.1."
                            />
                            <div className="flex">
                                <p className="min-w-[48px]">
                                    <b>11.9.</b>
                                </p>
                                <div className="flex flex-col gap-[16px]">
                                    <p>
                                        <b>
                                            Changes to your Juno eWallet details
                                        </b>
                                    </p>
                                    <p>
                                        You must notify us immediately of any
                                        change in your personal or account
                                        details and we cannot guarantee proper
                                        performance of the Services in the event
                                        the details you have provided are not
                                        accurate or become inaccurate and
                                        disclaim all liability for any Losses
                                        that may result from such inaccuracies.
                                        You can notify us by contacting us in
                                        accordance with Clause 20, and we may
                                        require you to confirm such notification
                                        in writing or through other factors of
                                        authentication (which may include
                                        messages or calls to your mobile phone,
                                        or confirmation mail to your physical
                                        address). You will be liable for any
                                        Losses that directly results from any
                                        failure to notify us of any change. In
                                        order to verify a new address, you must
                                        provide any proof that we require.
                                    </p>
                                </div>
                            </div>
                            <CustomLi
                                counter="11.10."
                                text="We reserve the right at any time to satisfy ourselves as to your identity and address (for example, by requesting relevant original documents), including for the purposes of preventing fraud and/or money laundering. In addition, at the time of your application or at any time in the future, we may perform, in connection with your eWallet, electronic identity verification checks either directly or using relevant third parties. You agree to provide us with the information we request for purposes of identity verification and the detection and prevention of money laundering, terrorist financing, fraud or any other financial crime, and we will keep a record of such information in accordance with our Privacy Policy Juno.com/privacy-policy If you fail to provide the requested information, we reserve the right to suspend your eWallet pending receipt and verification of that information."
                            />
                        </div>
                    </>

                    <>
                        <div className="self-stretch relative px-[16px] md:px-[24px] xl:px-0">
                            <h2 className="text-[36px] font-medium text-black">
                                12. Proprietary Rights.
                            </h2>
                        </div>
                        <div className="self-stretch flex flex-col items-start justify-center gap-[32px] xl:pl-[48px] px-[16px] md:px-[24px]">
                            <CustomLi
                                counter="12.1."
                                text="All rights, title and interest in, and to, the Intellectual Property Rights subsisting in, or embodied by, the App, Website and the Services, any proprietary software or other technology required to operate the App, Website or the Services, and any modifications or improvements thereto, including any derivative works, is retained by us and protected under applicable Intellectual Property Rights."
                            />
                            <CustomLi
                                counter="12.2."
                                text="By activating your eWallet, we grant you a limited permission to use the App and Website solely for the purpose of benefiting from the Services in accordance with these Terms. In particular and without limitation, this permission does not grant you the right to create, author or invent any modifications or improvements to, or derivative works of, the App or Website. We may suspend or terminate this permission in accordance with Clause 16. Notwithstanding the foregoing, nothing in these Terms grants you any permission or right to use our trademarks, service marks, trade dress, slogans, logos or other indicia of origin."
                            />
                            <CustomLi
                                counter="12.3."
                                text="From time to time updates to the App may be issued through App Store or Google Play. Depending on the update, you may not be able to use our Services via the App until you have downloaded the latest version of the App and accepted any new terms."
                            />
                            <CustomLi
                                counter="12.4."
                                text="If you submit any ideas, suggestions or other feedback to us about the App or the Services, you grant to us the right to use or disclose such feedback without any further obligation to you. All rights not expressly granted to you under these Terms are reserved by us."
                            />
                        </div>
                    </>

                    <>
                        <div className="self-stretch relative px-[16px] md:px-[24px] xl:px-0">
                            <h2 className="text-[36px] font-medium text-black">
                                13. Third Party Access.
                            </h2>
                        </div>
                        <div className="self-stretch flex flex-col items-start justify-center gap-[32px] xl:pl-[48px] px-[16px] md:px-[24px]">
                            <CustomLi
                                counter="13.1."
                                text="You may instruct a TPP to access information on your eWallet or to initiate certain payments from your eWallet provided such TPP is authorised by the appropriate regulator in their jurisdiction to provide the relevant service. Some TPPs may (with your permission) choose to access your eWallet without identifying themselves to us and to use your Security Credentials. We will treat any instruction from an authorised TPP as if it was from you. You should always consider the implications of sharing your Security Credentials and personal information."
                            />
                            <CustomLi
                                counter="13.2."
                                text="We may deny a TPP access to your eWallet if we are concerned about unauthorised or fraudulent access by that TPP setting out the reason for such denial. Before doing so, we will tell you that we intend to deny access and give our reasons for doing so, unless it is not reasonably practicable, in which case we will immediately inform you afterwards. In either case, we will tell you in the manner in which we consider most appropriate in the circumstances. We will not tell you if, doing so would compromise our security measures or would otherwise be unlawful."
                            />
                            <CustomLi
                                counter="13.3."
                                text="If you have provided consent to a TPP to access to your eWallet to enable them to provide account information services or payment initiation services on your behalf, you consent to us sharing your information with the TPP as is reasonably required for them to provide the relevant services to you. You must let us know if you withdraw this permission and we recommend you let the TPP know. On notification from you, we will not provide such TPP access to your eWallet."
                            />
                        </div>
                    </>

                    <>
                        <div className="self-stretch relative px-[16px] md:px-[24px] xl:px-0">
                            <h2 className="text-[36px] font-medium text-black">
                                14. Right to Cancel.
                            </h2>
                        </div>
                        <div className="self-stretch flex flex-col items-start justify-center gap-[32px] xl:pl-[48px] px-[16px] md:px-[24px]">
                            <CustomLi
                                counter="14.1."
                                text="You have the right to cancel your eWallet and these Terms for any reason within a ‘cooling-off’ period of fourteen (14) days from the date on which your eWallet is registered by contacting us in accordance with Clause 20. You must contact us within this fourteen (14) day period and inform us that you wish to withdraw from these Terms and you must not make any Transactions. We will then cancel your eWallet and reimburse your Available Balance to you within fourteen (14) Business Days of our receipt of your cancellation request. You will not be refunded for any Outbound Transactions including any associated Fees made using your eWallet up to the date you notify us of your cancellation."
                            />
                            <CustomLi
                                counter="14.2."
                                text="If you fail to exercise your right to cancel under Clause 15.1, then these Terms shall remain in full force and effect and shall continue until terminated. You may only terminate your eWallet and these Terms in accordance with Clauses 2.2 or 16."
                            />
                        </div>
                    </>

                    <>
                        <div className="self-stretch relative px-[16px] md:px-[24px] xl:px-0">
                            <h2 className="text-[36px] font-medium text-black">
                                15. Termination and Suspension.
                            </h2>
                        </div>
                        <div className="self-stretch flex flex-col items-start justify-center gap-[32px] xl:pl-[48px] px-[16px] md:px-[24px]">
                            <CustomLi
                                counter="15.1."
                                text="You can terminate your eWallet and these Terms at any time by informing us through the App or by contacting us in accordance with Clause 20."
                            />
                            <div className="flex">
                                <p className="min-w-[48px]">
                                    <b>15.2.</b>
                                </p>
                                <p>
                                    We may terminate your eWallet or the
                                    provision of the Services and these Terms
                                    for any reason by giving you at least two
                                    (2) months’ prior notice. We may also
                                    terminate or suspend your eWallet, your use
                                    of the Services and/or terminate these Terms
                                    immediately in the event that:
                                </p>
                            </div>
                            <div className="pl-[48px]">
                                <ol
                                    style={{
                                        listStyleType: 'upper-roman',
                                    }}
                                    className="flex flex-col gap-[24px] pl-[24px]"
                                >
                                    <li>
                                        You have used the Services, or allowed
                                        them to be used, in breach of any of
                                        these Terms;
                                    </li>
                                    <li>
                                        We reasonably believe or suspect that
                                        you are in any way involved in any
                                        fraudulent activity, money laundering,
                                        terrorism financing, other criminal
                                        activity, other breach of Applicable
                                        Laws, or if we have any other security
                                        or legal or regulatory compliance
                                        concerns; or we believe that you have
                                        provided false or misleading
                                        information;
                                    </li>
                                    <li>
                                        We believe that you are involved in any
                                        dispute or disagreement with any
                                        Merchant whether that dispute or
                                        disagreement relates to the Services or
                                        otherwise;
                                    </li>
                                    <li>
                                        We are unable to verify your identity or
                                        any other information pertaining to you,
                                        your eWallet or a Transaction;
                                    </li>
                                    <li>
                                        We are required to do so in accordance
                                        with our legal and regulatory
                                        obligations under Applicable Laws;
                                    </li>
                                    <li>
                                        We cease to be authorised to provide the
                                        Services.
                                    </li>
                                </ol>
                            </div>
                            <CustomLi
                                counter="15.3."
                                text="If there have been no Transactions on your eWallet for a period of at least one (1) year, we reserve the right to terminate it and these Terms. We will notify you ahead of any such termination by giving you at least two (2) months’ notice."
                            />
                            <CustomLi
                                counter="15.4."
                                text="Upon termination of these Terms and your eWallet in accordance with this Clause 16, all pending Transactions will be processed and, if your eWallet has a positive Available Balance, we will return your unused funds, minus any applicable Fees, within thirty (30) Business Days’ of the date on which you inform us of your wish to terminate your eWallet or on which termination otherwise occurs in accordance with this Clause 16. Unless otherwise expressly stated in Clause 17, the provisions of Clause 16 shall apply in such circumstances. If any Fees are incurred or a Transaction is made on your eWallet before termination, you will be liable to pay any such sums to us on demand, notwithstanding the subsequent closure of your eWallet."
                            />
                            <CustomLi
                                counter="15.5."
                                text="Upon termination of your eWallet for any reason, we may continue to process your personal data in accordance with the terms of our Privacy Policy Juno.com/privacy-policy and Cookie Policy Juno.com/cookie-policy as applicable."
                            />
                            <CustomLi
                                counter="15.6."
                                text="The Terms in effect as of the date of the termination of your eWallet will survive and continue to apply to any aspects of your prior use of the App or the Services, including your eWallet information, your Available Balance, any outstanding Transactions, and any relationship between you and a Merchant formed with the assistance of the App or the Services. You will remain liable for all outstanding obligations under this Agreement related to your use of the App, Website or the Services prior to termination."
                            />
                        </div>
                    </>

                    <>
                        <div className="self-stretch relative px-[16px] md:px-[24px] xl:px-0">
                            <h2 className="text-[36px] font-medium text-black">
                                16. Redemption.
                            </h2>
                        </div>
                        <div className="self-stretch flex flex-col items-start justify-center gap-[32px] xl:pl-[48px] px-[16px] md:px-[24px]">
                            <CustomLi
                                counter="16.1."
                                text="You are able to redeem, either in part or in full, the monetary value of the Available Balance on your eWallet, at any time, at par value, by providing instructions given to us to do so and subject to your payment of any applicable Fees and the remainder of this Clause 17."
                            />
                            <CustomLi
                                counter="16.2."
                                text="We reserve the right to carry out any necessary money-laundering, terrorist financing, fraud and other illegal activity and operational checks before authorising any redemption or transfer of funds to you, including returning any funds after the termination of these Terms. Accordingly, there may be delays in the time between our receipt of your redemption request and your receipt of the relevant funds."
                            />
                            <CustomLi
                                counter="16.3."
                                text="We do not guarantee the availability of any particular redemption method using a particular payment service provider and may make changes to or discontinue a particular redemption method at any time as long as there is at least one redemption method available to you, unless prohibited by Applicable Laws."
                            />
                            <CustomLi
                                counter="16.4."
                                text="The execution of your payments by a TPP in order to redeem the monetary value of the Available Balance on your eWallet are not part of the Services. We do not guarantee the ability to use any particular third party payment provider and we may discontinue the availability of any such third party payment provider for redeeming the monetary value of the Available Balance on your eWallet at any time in our sole discretion."
                            />
                            <CustomLi
                                counter="16.5."
                                text="We may charge a Fee when you require redemption either in full or in part of the Available Balance on your eWallet, before the termination of these Terms (other than if you cancel during the ‘cooling-off’ period pursuant to Clause 15)."
                            />
                            <CustomLi
                                counter="16.6."
                                text="When you request the redemption of your funds, you must provide us the correct recipient and payment details for the transfer which are accurate and complete and any other information we ask for. For redemptions to UK accounts, this means the recipient’s name, account number and sort code and payment reference. For redemptions to overseas accounts, we may need additional information, such as IBAN. If you provide us with incorrect or incomplete details or refuse to provide information, we may refuse to execute your redemption request, the funds could be lost and irrecoverable or there could be a delay in the recipient account receiving the payment. We will not be held liable for funds sent to an incorrect payment account or instrument where we have acted in accordance with your instructions. We reserve the right to charge you a Fee in that event that you request our assistance in retrieving money from an incorrect payment account or instrument and transferring that money to the correct payment account or instrument."
                            />
                            <CustomLi
                                counter="16.7."
                                text="You must be the named account holder of the account to which you instruct us to transfer the funds in the case of a redemption request. A breach of this requirement may be treated as a fraudulent act without prejudice to claiming further Losses. If we are required to investigate a redemption for the transfer of funds to a payment account or instrument that is not under your name, we reserve the right to charge you a Fee."
                            />
                            <CustomLi
                                counter="16.8."
                                text="You accept that all redemption requests will be made in your primary currency and via a bank transfer to a designated account that you have notifed to us and we have approved in advance. Unless otherwise agreed between us, any designated account must be in your name."
                            />
                            <CustomLi
                                counter="16.9."
                                text="If, following any redemption, Outbound Transactions are found to have been made or Fees incurred using your eWallet that exceed your Available Balance, we will notify you of any such outstanding amounts, and you shall pay any such amounts to us on demand and in accordance with Clause 5.9."
                            />
                        </div>
                    </>

                    <>
                        <div className="self-stretch relative px-[16px] md:px-[24px] xl:px-0">
                            <h2 className="text-[36px] font-medium text-black">
                                17. Liability.
                            </h2>
                        </div>
                        <div className="self-stretch flex flex-col items-start justify-center gap-[32px] xl:pl-[48px] px-[16px] md:px-[24px]">
                            <div className="flex">
                                <p className="min-w-[48px]">
                                    <b>17.1.</b>
                                </p>
                                <p>
                                    Nothing in these Terms shall limit or
                                    exclude our liability:
                                </p>
                            </div>
                            <div className="pl-[48px]">
                                <ol
                                    style={{
                                        listStyleType: 'upper-roman',
                                    }}
                                    className="flex flex-col gap-[24px] pl-[24px]"
                                >
                                    <li>
                                        For fraud or fraudulent
                                        misrepresentation;
                                    </li>
                                    <li>
                                        For death or personal injury caused by
                                        our negligence;
                                    </li>
                                    <li>
                                        Where such limitation or exclusion would
                                        be contrary to the Applicable Laws.
                                    </li>
                                </ol>
                            </div>

                            <div className="flex">
                                <p className="min-w-[48px]">
                                    <b>17.2.</b>
                                </p>
                                <p>
                                    Except as provided in Clause 18.1, we shall
                                    not be liable under or in connection with
                                    these Terms, whether in contract, tort
                                    (including negligence), breach of statutory
                                    duty or otherwise:
                                </p>
                            </div>
                            <div className="pl-[48px]">
                                <ol
                                    style={{
                                        listStyleType: 'lower-alpha',
                                    }}
                                    className="flex flex-col gap-[24px] pl-[24px]"
                                >
                                    <li>
                                        <div className="flex flex-col gap-[24px]">
                                            <p>For any:</p>
                                            <ol
                                                style={{
                                                    listStyleType:
                                                        'upper-roman',
                                                }}
                                                className="flex flex-col gap-[24px] pl-[24px]"
                                            >
                                                <li>
                                                    Indirect, incidental,
                                                    special or consequential
                                                    loss or damage of any kind;
                                                </li>
                                                <li>
                                                    Loss of profits, business,
                                                    revenue or savings (actual
                                                    or anticipated), loss of
                                                    agreements or contracts,
                                                    loss of opportunity, loss of
                                                    data or loss of, or damage
                                                    to, goodwill or reputation
                                                    (including as a result of
                                                    your inability to
                                                    participate in any goods,
                                                    services or offerings of a
                                                    Merchant, to the extent that
                                                    is caused by a failure in
                                                    the App or the Services or
                                                    delays in the time required
                                                    for E-Money to appear in
                                                    your eWallet once you have
                                                    performed a loading
                                                    transaction or a Merchant
                                                    has authorised an Inbound
                                                    Transaction);
                                                </li>
                                            </ol>
                                        </div>
                                    </li>
                                    <li>
                                        For any Losses in connection with the
                                        rights, privileges, licenses, goods or
                                        services that are purchased with your
                                        eWallet from a Merchant or the terms on
                                        which they are provided by a Merchant;
                                    </li>
                                    <li>
                                        If a Merchant refuses to accept an
                                        Outbound Transaction or other payment;
                                    </li>
                                    <li>
                                        Any failure of a Merchant to remit value
                                        to you, in E-Money or otherwise, even if
                                        such failure is a breach of the
                                        Merchant’s terms with you.
                                    </li>
                                </ol>
                            </div>

                            <div className="flex">
                                <p className="min-w-[48px]">
                                    <b>17.3.</b>
                                </p>
                                <p>Subject to Clause 18.1:</p>
                            </div>

                            <div className="pl-[48px]">
                                <ol
                                    style={{
                                        listStyleType: 'lower-alpha',
                                    }}
                                    className="flex flex-col gap-[24px] pl-[24px]"
                                >
                                    <li>
                                        The Services, in whole and in part, are
                                        provided on an <b>“as is”</b> and
                                        <b>“as available”</b> basis, without any
                                        and all warranties, whether express or
                                        implied, including without limitation
                                        warranties of merchantability, fitness
                                        for a particular purpose, title or
                                        non-infringement or warranties arising
                                        from the course of performance, course
                                        of dealing or usage in trade in relation
                                        to the provision of our Services, App
                                        and/or the Website. The Company assumes
                                        no responsibility for errors or
                                        omissions in the any of contents of the
                                        Services, App and/or the Website.
                                    </li>
                                    <li>
                                        <div className="flex flex-col gap-[24px]">
                                            <p>We do not guarantee that:</p>
                                            <ol
                                                style={{
                                                    listStyleType:
                                                        'upper-roman',
                                                }}
                                                className="flex flex-col gap-[24px] pl-[24px]"
                                            >
                                                <li>
                                                    The App or the Services are
                                                    free from any viruses,
                                                    trojan horses, worms,
                                                    software bombs or similar
                                                    items
                                                </li>
                                                <li>
                                                    Access to the App or the
                                                    Services will be free from
                                                    interruptions.
                                                </li>
                                            </ol>
                                        </div>
                                    </li>
                                </ol>
                            </div>
                            <CustomLi
                                counter="17.4."
                                text="Except as provided in Clauses 12 and 18.1 and subject to Clause 18.2, our total aggregate liability under or in connection with these Terms and whether in contract, tort (including negligence), breach of statutory duty or otherwise shall be limited to the lower of (i) €5000 or; (ii) the total amount of Fees received by Juno from you in the twelve (12) months prior to the time of your claim. We shall not be liable for any losses arising from our compliance with any Applicable Law."
                            />
                            <CustomLi
                                counter="17.5."
                                text="Our Services, App and Website can be accessed via the internet, an international network which you may have access to via IT devices, such as a desktop computer, a tablet, phone or other similar device. You are soley responsible for the proper functioning, maintenance and security of your own IT devices and your internet access, for any purposes in connection with our Website, App and/or Services. You are solely liable for any Losses resulting from a failure by you to ensure the proper functioning, maintenance and security of your own IT devices and your internet access."
                            />
                            <CustomLi
                                counter="17.6."
                                text="Juno will perform identity checks in accordance with Applicable Law. However, we do not guarantee the identity of any user, receiver, requestee or other third party and we will have no liability or responsibility for ensuring that the information provided by any other user is accurate and complete."
                            />
                            <CustomLi
                                counter="17.7."
                                text="We have no control over, or liability for, the delivery, quality, safety, legality or any other aspect of any goods or services that you may purchase or receive from, or transfer to, any third party (including Merchants and other users of our Services). We have no control over, or liability for the conduct of any third party."
                            />
                            <CustomLi
                                counter="17.8."
                                text="You agree to indemnify and hold harmless Juno and our directors, officers, employees, agents, affiliates and subcontractors against any and all claims, actions, proceedings, investigations, demands, suits and Losses suffered or incurred by us that arise out of, or in connection with, any breach by you, of any of these Terms or Applicable Law, any enforcement by us of these Terms and/or your violation of any rights of any third party."
                            />
                            <CustomLi
                                counter="17.9."
                                text="We are not liable (and you accept full responsibility) for any Transaction we carry out correctly in accordance with your instructions. You are fully responsible for the instructions you give to us and we will assume your Transaction instructions are accurate, correct and complete. Any Transaction will be deemed to have been correctly executed where it has been carried out in accordance with your instructions."
                            />
                            <CustomLi
                                counter="17.10"
                                text="If you believe or suspect any third party has behaved in a fraudulent, misleading, or inappropriate manner, or if you otherwise cannot adequately resolve a dispute with a third party, you may notify Juno Support at:<br/><br/><b>Support@Juno.com</b>"
                            />
                        </div>
                    </>

                    <>
                        <div className="self-stretch relative px-[16px] md:px-[24px] xl:px-0">
                            <h2 className="text-[36px] font-medium text-black">
                                18. Force Majeure.
                            </h2>
                        </div>
                        <div className="self-stretch flex flex-col items-start justify-center gap-[32px] xl:pl-[48px] px-[16px] md:px-[24px]">
                            <CustomLi
                                counter="18.1."
                                text="We shall not be liable for any default, or be deemed to be in breach of these Terms where the default or breach is due to abnormal or unforeseeable circumstances beyond our control despite our efforts to the contrary, including but not limited to a Force Majeure Event."
                            />
                        </div>
                    </>

                    <>
                        <div className="self-stretch relative px-[16px] md:px-[24px] xl:px-0">
                            <h2 className="text-[36px] font-medium text-black">
                                19. Contact Details.
                            </h2>
                        </div>
                        <div className="self-stretch flex flex-col items-start justify-center gap-[32px] xl:pl-[48px] px-[16px] md:px-[24px]">
                            <CustomLi
                                counter="19.1."
                                text="If you have any questions or concerns about the Services, or to report issues with your eWallet, please contact us in the first instance through the ‘chat’ communication tool within your mobile App.<br/><br/>You can also contact us by email at support@junomoney.com<br/><br/>Alternatively, you can write to us at our registered address: Suite #229, 6030 88ST NW, Edmonton, Alberta, Canada, T6E6G4."
                            />
                        </div>
                    </>

                    <>
                        <div className="self-stretch relative px-[16px] md:px-[24px] xl:px-0">
                            <h2 className="text-[36px] font-medium text-black">
                                20. Complaints.
                            </h2>
                        </div>
                        <div className="self-stretch flex flex-col items-start justify-center gap-[32px] xl:pl-[48px] px-[16px] md:px-[24px]">
                            <CustomLi
                                counter="20.1."
                                text="If You wish to make a complaint about the Services, you can email us at support@junomoney.com with brief details of your complaint and the phone number and email address associated with your eWallet or request our complaint form and send to our service email address on the form."
                            />
                            <CustomLi
                                counter="20.2."
                                text="We will provide you a full response to your complaint by email within fifteen (15) Business Days after the date we receive your complaint. In exceptional circumstances where we are unable to respond in full to your complaint, we will inform you of this giving our reasons for the delay and the timeframe within which you will receive a full reply, which in any event shall be within thirty-five (35) Business Days of the date we received your complaint."
                            />
                        </div>
                    </>

                    <>
                        <div className="self-stretch relative px-[16px] md:px-[24px] xl:px-0">
                            <h2 className="text-[36px] font-medium text-black">
                                21. Miscellaneous.
                            </h2>
                        </div>
                        <div className="self-stretch flex flex-col items-start justify-center gap-[32px] xl:pl-[48px] px-[16px] md:px-[24px]">
                            <CustomLi
                                counter="21.1."
                                text="We may assign or transfer all or any of our rights or obligations under these Terms to any third party (including by way of merger, consolidation or the acquisition of all or substantially all of our business and assets relating to these Terms). Such assignment or transfer will not affect your rights under these Terms."
                            />
                            <CustomLi
                                counter="21.2."
                                text="You may only assign or transfer your rights or your obligations under these Terms to a third party if we give our prior written consent to this."
                            />
                            <CustomLi
                                counter="21.3."
                                text="A person who is not a party to these Terms has no right, express or implied, to enforce any part of these Terms. The rights to terminate, rescind or agree any variation, waiver or settlement under these Terms are not subject to the consent of any person that is not a party to these Terms."
                            />
                            <CustomLi
                                counter="21.4."
                                text="Nothing in these Terms is intended to, or shall be construed so as to, establish or imply any partnership or joint venture or a relationship of principal and agent between you and us or constitute either of you or us as the agent of the other party, or authorise you or us to make or enter into any commitments for or on behalf of the other party."
                            />
                            <CustomLi
                                counter="21.5."
                                text="If any provision of these Terms is held to be invalid, illegal or unenforceable in any respect under the law of any jurisdiction, the validity, legality and enforceability of the remaining provisions shall not in any way be affected or impaired thereby, and such provision shall be deemed to be restated to reflect yours and our original intentions as nearly, as possible in accordance with Applicable Laws."
                            />
                            <CustomLi
                                counter="21.6."
                                text="No waiver of any of our rights under these Terms shall be effective unless in writing. Unless expressly stated otherwise, a waiver shall be effective only in the circumstances for which it is given, and no delay or omission by us in exercising any right or remedy provided by law or under these Terms shall constitute a waiver by us of such right or remedy."
                            />
                            <CustomLi
                                counter="21.7."
                                text="These Terms and the documents referred to in it, constitute the whole agreement between you and us relating to the subject matter of these Terms and supersedes any prior written or oral arrangement, understanding or agreement between them relating to such subject matter."
                            />
                            <CustomLi
                                counter="21.8."
                                text="These Terms are provided to you in the English language. Where we have provided a translation of these Terms to you, you agree that such translation is provided for your convenience only and that the English language version of these Terms will govern your relationship with us. If there is any contradiction between the English language version of these Terms and any translation, the English language version takes precedence."
                            />
                        </div>
                    </>

                    <>
                        <div className="self-stretch relative px-[16px] md:px-[24px] xl:px-0">
                            <h2 className="text-[36px] font-medium text-black">
                                22. Governing Law.
                            </h2>
                        </div>
                        <div className="self-stretch flex flex-col items-start justify-center gap-[32px] xl:pl-[48px] px-[16px] md:px-[24px]">
                            <CustomLi
                                counter="22.1."
                                text="These Terms, and any dispute or claim (including non-contractual disputes or claims) arising out of or in connection with these Terms or their subject matter or formation, are governed by, and shall be construed in accordance with the provincial laws of Alberta."
                            />
                        </div>
                    </>

                    <>
                        <div className="self-stretch relative px-[16px] md:px-[24px] xl:px-0">
                            <h2 className="text-[36px] font-medium text-black">
                                23. Jurisdiction.
                            </h2>
                        </div>
                        <div className="self-stretch flex flex-col items-start justify-center gap-[32px] xl:pl-[48px] px-[16px] md:px-[24px]">
                            <CustomLi
                                counter="23.1."
                                text="Each party irrevocably agrees that the provincial courts of Alberta shall have exclusive jurisdiction to settle any dispute or claim (including non-contractual disputes or claims) arising out of or in connection with these Terms or their subject matter or formation."
                            />
                        </div>
                    </>

                    <>
                        <div className="self-stretch relative px-[16px] md:px-[24px] xl:px-0">
                            <h2 className="text-[36px] font-medium text-black">
                                24. Notices.
                            </h2>
                        </div>
                        <div className="self-stretch flex flex-col items-start justify-center gap-[32px] xl:pl-[48px] px-[16px] md:px-[24px]">
                            <CustomLi
                                counter="24.1."
                                text="You may serve notices to us in writing at the contact details in Clause 20."
                            />
                            <CustomLi
                                counter="24.2."
                                text="You accept and agree that we may serve Notices and other disclosures to you in connection with your eWallet and the Services."
                            />
                        </div>
                    </>
                </div>
            </div>
        </LandingLayout>
    )
}
