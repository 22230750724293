import React from "react";
import App from "./App.js";
import createStore from "./helpers/createStore";
import { Provider } from "react-redux";
import { ErrorBoundary } from "react-error-boundary";
import { createRoot } from 'react-dom/client';

window.global = window;
// @ts-ignore
window.Buffer = window.Buffer || require('buffer').Buffer;

const initialState =
  window && process.env.NODE_ENV && process.env.NODE_ENV !== "development"
    ? {
      config: {
        // These variable must be use for only local testing perpose
        // api_url: "http://localhost:8081",
        // activity_tracking_api_url: "http://localhost:8082",
        image_base_url: window.INITIAL_STATE?.config?.IMAGE_BASE_URL + "/images",
      }
    }
    : {
      config: {
        // These variable must be use for only local testing perpose
        api_url: "http://localhost:8081",
        image_base_url: "https://dev.junomoney.org/images",
      },
    };

const store = createStore(initialState);
const container = document.getElementById('root');
const root = createRoot(container);
root.render(
  <Provider store={store}>
    <ErrorBoundary fallback={<div>Something went wrong</div>}>
      <App />
    </ErrorBoundary>
  </Provider>
);