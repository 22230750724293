import React from 'react'
import { Typeahead } from 'react-bootstrap-typeahead'

export default function FieldsForEdit({
    formData,
    onChangeInput,
    isEditAccount,
    countries,
    errorInputNames,
    setFormData,
    setErrorsInputNames
}) {
    function checkInvalidInput(name) {
        if (errorInputNames) {
            return errorInputNames.includes(name)
        } else return false
    }
    
    return (
        <div>
            <div className="jncModal__field mb">
                <p className="jncModal__label">Beneficiary name  <span className="required">*</span></p>
                <input
                    data-e2e="Beneficiary name"
                    onChange={(e) => onChangeInput(e)}
                    value={formData.beneficiaryName}
                    name="beneficiaryName"
                    className={`jncInput ${checkInvalidInput("beneficiaryName") ? "has-error" : ""}`}
                    type="text"
                    placeholder="Enter Beneficiary name"
                />
                {checkInvalidInput("beneficiaryName") && <div className="jncAlert alert alert-danger" role="alert">* Beneficiary name field is required</div>}
            </div>
            <div className="jncModal__field mb">
                <p className="jncModal__label">Beneficiary address</p>
                <input
                    data-e2e="beneficiary-address-box"
                    onChange={(e) => onChangeInput(e)}
                    value={formData.beneficiaryAddress}
                    name="beneficiaryAddress"
                    className="jncInput"
                    type="text"
                    placeholder="Enter Beneficiary address"
                />
            </div>
            <div className="jncModal__field mb">
                <p className="jncModal__label">
                    Beneficiary Country <span className="required">*</span>
                </p>
                <Typeahead
                    id="basic-typeahead-multiple-currency-type"
                    name="beneficiaryCountry"
                    labelKey={(option) => option.name}
                    options={countries}
                    selected={
                        countries.filter((country) => country.name === formData.beneficiaryCountry)
                    }
                    placeholder="Select a country"
                    clearButton={true}
                    onChange={(e) => {
                        if (errorInputNames.includes('beneficiaryCountry')) {
                            setErrorsInputNames((prev) =>
                                prev.filter((x) => x !== "beneficiaryCountry")
                            );
                        }
                        setFormData((x) => {
                            return {
                                ...x,
                                beneficiaryCountry: e?.[0]?.name,
                            }
                        })
                    }}
                />
                {checkInvalidInput("beneficiaryCountry") && <div className="jncAlert alert alert-danger" role="alert">* Beneficiary country field is required</div>}
            </div>
            <div className="jncModal__field mb">
                <p className="jncModal__label">Bank name</p>
                <input
                    data-e2e="bank-name-box"
                    onChange={(e) => onChangeInput(e)}
                    value={formData.bankName}
                    name="bankName"
                    className="jncInput"
                    type="text"
                    placeholder="Enter Bank name"
                />
            </div>
            <div className="jncModal__field mb">
                <p className="jncModal__label">Bank Address</p>
                <div>
                    <input
                        data-e2e="bank-address-box"
                        onChange={(e) => onChangeInput(e)}
                        value={formData.bankAddress}
                        name="bankAddress"
                        className="jncInput"
                        type="text"
                        placeholder="Enter Bank address"
                    />
                    {!isEditAccount && (
                        <div
                            className={`jncModal__checkbox ${formData.saveBankAccount ? 'checked' : ''}`}
                        >
                            <input
                                onChange={(e) => onChangeInput(e)}
                                name="saveBankAccount"
                                type="checkbox"
                                id="save-account"
                            />
                            <label
                                htmlFor="save-account"
                                className="jncModal__label jncModal__checkbox-label"
                            >
                                <div className="jncModal__checkbox-icon">
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="14"
                                        height="14"
                                        viewBox="0 0 14 14"
                                        fill="none"
                                    >
                                        <path
                                            d="M11.6663 3.5L5.24967 9.91667L2.33301 7"
                                            stroke="#18181b"
                                            strokeWidth="2"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                        />
                                    </svg>
                                </div>
                                Save this Bank Account
                            </label>
                        </div>
                    )}
                </div>
            </div>
            {(isEditAccount || formData.saveBankAccount) && <div className="jncModal__field mb">
                <p className="jncModal__label">Account nickname <span className="required">*</span></p>
                <input data-e2e="nick-name" required onChange={(e) => onChangeInput(e)} name="accountNickName" value={formData.accountNickName} className={`jncInput ${checkInvalidInput("accountNickName") ? "has-error" : ""}`} type="text" placeholder="Name Account" />
                {checkInvalidInput("accountNickName") && <div className="jncAlert alert alert-danger" role="alert">* Name account field is required</div>}
            </div>}
            <div className="jncModal__field mb">
                <p className="jncModal__label">
                    Bank Country <span className="required">*</span>
                </p>
                <Typeahead
                    id="basic-typeahead-multiple-currency-type"
                    name="bankCountry"
                    onChange={(e) => {
                        if (errorInputNames.includes('bankCountry')) {
                            setErrorsInputNames((prev) =>
                                prev.filter((x) => x !== "bankCountry")
                            );
                        }
                        setFormData((x) => {
                            return {
                                ...x,
                                bankCountry: e?.[0]?.name,
                            }
                        })
                    }}
                    labelKey={(option) => option.name}
                    options={countries}
                    placeholder="Select a country"
                    selected={
                        countries.filter((country) => country.name === formData.bankCountry)
                    }
                    clearButton={true}
                />
                {checkInvalidInput("bankCountry") && <div className="jncAlert alert alert-danger" role="alert">* Bank Country field is required</div>}
            </div>
            <div className="jncModal__field mb">
                <p className="jncModal__label">Account Number</p>
                <input
                    data-e2e="account-number"
                    onChange={(e) => onChangeInput(e)}
                    value={formData.beneficiaryAccountNumber}
                    name="beneficiaryAccountNumber"
                    className="jncInput"
                    type="text"
                    placeholder="Enter Account Number"
                />
            </div>
            <div className="jncModal__field mb">
                <p className="jncModal__label">Sort Code</p>
                <input
                    data-e2e="sort-code"
                    onChange={(e) => onChangeInput(e)}
                    value={formData.sortCode}
                    name="sortCode"
                    className="jncInput"
                    type="text"
                    placeholder="Enter Sort Code"
                />
            </div>
            <div className="jncModal__field mb">
                <p className="jncModal__label">Swift</p>
                <input
                    data-e2e="swift-box"
                    onChange={(e) => onChangeInput(e)}
                    value={formData.swift}
                    name="swift"
                    className="jncInput"
                    type="text"
                    placeholder="Enter Swift code"
                />
            </div>
            <div className="jncModal__field mb">
                <p className="jncModal__label">IBAN</p>
                <input
                    data-e2e="iban-box"
                    onChange={(e) => onChangeInput(e)}
                    value={formData.iban}
                    name="iban"
                    className="jncInput"
                    type="text"
                    placeholder="Enter IBAN"
                />
            </div>
            <div className="jncModal__field mb">
                <p className="jncModal__label">
                    Additional bank info <br />
                    <span>Max. 100</span>
                </p>
                <textarea
                    maxLength="100"
                    onChange={(e) => onChangeInput(e)}
                    value={formData.additionalBankDetails}
                    name="additionalBankDetails"
                    className="jncModal__textarea"
                    type="text"
                    placeholder="EG: routing number (optional)"
                />
            </div>
        </div>
    )
}
