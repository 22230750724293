import React from 'react'
import { useSelector } from 'react-redux'

export default function JunoBackgroundVideo(props) {
    const { pageName } = props
    const image_base_url = useSelector((state) => state.config.image_base_url)

    return (
        <div className={`${pageName} jn-video`}>
            <video autoPlay loop muted poster="">
                {pageName == 'homepage' && (
                    <source
                        src={`${image_base_url}/junoclient/hero-video.webm`}
                        type="video/webm"
                    />
                )}
                {pageName == 'individual' && (
                    <source
                        src={`${image_base_url}/junoclient/city-zoom.webm`}
                        type="video/webm"
                    />
                )}
                {pageName == 'corporate' && (
                    <source
                        src={`${image_base_url}/junoclient/bw-city.webm`}
                        type="video/webm"
                    />
                )}
                {pageName == 'banking' && (
                    <source
                        src={`${image_base_url}/junoclient/turkish-city.webm`}
                        type="video/webm"
                    />
                )}
                {pageName == 'ms-homepage' && (
                    <source
                        src={`${image_base_url}/juno-am/hero-bg.webm`}
                        type="video/webm"
                    />
                )}
            </video>
        </div>
    )
}
