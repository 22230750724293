import React, { useEffect, useState } from 'react'
import { cryptoMenuItems, useJunoCommonFunctions } from '../../../helpers'
import { Link, useLocation } from 'react-router-dom'


const JncCryptoSidebar = ({ sidebarState }) => {
    const [hideOnProfile, setHideOnProfile] = useState(false)
    const { pathname } = useLocation()
    const { useWindowWidth } = useJunoCommonFunctions()
    const windowWidth = useWindowWidth()

    useEffect(() => {
        setHideOnProfile(pathname == '/client/profile')
    }, [pathname])

    return (
        <div
            className={`jncSidebar ${sidebarState ? 'opened' : ''} ${hideOnProfile ? 'hide-on-profile' : ''}`}
        >
            <div className="jncSidebar__menu crypto-menu">
                {cryptoMenuItems.slice(0, windowWidth <= 991 ? -1 : undefined).map((item) => (
                    <Link key={item.id} to={item.path} exact className={`jncSidebar__menu-link ${pathname === item.path ? "active" : ""}`}>
                        {item.icon}
                        <p>{item.title}</p>
                    </Link>
                ))}

            </div>
        </div>
    )
}

export default JncCryptoSidebar