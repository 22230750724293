import React from 'react'

export default function JunoAmCounter() {
    return (
        <section className="jnAm-section jnAm-counter pd-y-tb">
            <div className="content-container">
                <p className="jnAm-counter-upperTitle">For our clients </p>
                <p className="jnAm-title white-color">Our Expertise</p>
                <div className="jnAm-counter-grid">
                    <div className="jnAm-counter-block">
                        <p className="jnAm-counter-block-title">Expert Team</p>
                        <p className="jnAm-text">
                            Our expert team have a vast amount of experience in
                            all elements of financial services, both with major
                            institutions and high net worth individuals. Our
                            team’s accumulated experience spans over a hundred
                            years. A single point of contact will be assigned to
                            ensure your portfolio runs smoothly and you are kept
                            up to date of any changes, developments and
                            opportunities.
                        </p>
                    </div>
                    <div className="jnAm-counter-block jnAm-counter-margin">
                        <p className="jnAm-counter-block-title">
                            Personalised Service
                        </p>
                        <p className="jnAm-text">
                            Like the financial markets, our team is completely
                            flexible, our efficient structure and our commitment
                            to our clients means we are perfectly placed to
                            tailor a solution from the whole market, which is in
                            tune with the unique requirements of each customer
                            and we are able to adapt quickly to make sure our
                            solutions remains in keeping with our customer’s
                            changing priorities and lifestyle.
                        </p>
                    </div>
                    <div className="jnAm-counter-block">
                        <p className="jnAm-counter-block-title">
                            Global Coverage
                        </p>
                        <p className="jnAm-text">
                            Juno Management Solution has a truly global
                            footprint with capabilities that span some of the
                            most challenging transactions, jurisdictions and
                            industries. We are highly committed to making the
                            most complex financial challenges, straightforward.
                            We specialise in seamless delivery and execution.
                        </p>
                    </div>
                    <div className="jnAm-counter-block jnAm-counter-margin">
                        <p className="jnAm-counter-block-title">
                            Strategic partnerships
                        </p>
                        <p className="jnAm-text">
                            We have strong, long-standing relationships with the
                            most prestigious, robust and credible financial
                            institutions in the world, these institutions cover
                            a broad range of solutions including treasury, asset
                            management, wealth management. These pivotal
                            partnerships mean we can deliver successfully
                            against your immediate and longer term financial
                            requirements.
                        </p>
                    </div>
                </div>
            </div>
            <div className="jnAm-bottomDeco"></div>
        </section>
    )
}
