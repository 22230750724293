import React from 'react'
import Reveal, { Fade, Flip } from "react-awesome-reveal";
import { keyframes } from '@emotion/react'

const FadeUp = ({ children, direction = "up", ...rest }) => {
    const customAnimation = keyframes`
    from {
        opacity: 0;
        transform: translateY(${direction === "up" ? "50px" : "-50px"}); /* Adjust this value to control the starting position */
      }
    
      to {
        opacity: 1;
        transform: translateY(0);
      }
  `;
    return (
        <Reveal
            effect="fadeInUp" // Use fade up animation
            duration={1000} // Duration of the animation
            keyframes={customAnimation}
            {...rest}
        >
            {children}
        </Reveal>
    )
}

export default FadeUp