import React, { useRef } from 'react'
import { useSelector } from 'react-redux'
import ServicesComponent from '../components/landing-pages/ServicesComponent'

import JunoContact from '../components/JunoHeroContact'
import { corporateContactUs } from '../data'
import { useJunoCommonFunctions } from '../../helpers'
import LandingLayout from '../../client/layouts/LandingLayout'
import FadeUp from '../components/landing-pages/FadeUp'
import CustomButton from '../components/landing-pages/CustomButton'

export default function JunoMoneyCorporatePage() {
    const imageBaseUrl = useSelector((state) => state.config.image_base_url)
    const contactRef = useRef(null)

    const scrollToContact = () => {
        setTimeout(() => {
            if (contactRef.current) {
                contactRef.current.scrollIntoView({ behavior: 'smooth' })
            }
        }, 0)
    }
    const { useWindowWidth } = useJunoCommonFunctions()

    const windowWidth = useWindowWidth()
    return (
        <LandingLayout>
            <div className=" bg-black">
                <div
                    style={{
                        background:
                            windowWidth < 1101
                                ? `url(${imageBaseUrl}/landingpage/heroDesk.jpeg)`
                                : 'none',
                    }}
                    className="flex items-center lg:bg-black !bg-no-repeat !bg-cover !bg-center max-w-[1280px] mx-auto xl:!px-0 "
                >
                    <FadeUp className="z-10 w-full lg:w-fit">
                        <div className="bg-[#00000099] lg:bg-none flex items-center h-[100vh] md:h-[1033px] lg:h-[800px] w-full pl-6 lg:pl-0 ">
                            <div className="sm:w-4/5  lg:w-[619px] z-10 lg:ml-[150px] xl:ml-0 absolute !pr-5 sm:pr-0">
                                <p className="text-[56px] lg:text-[72px] font-medium text-[#FCFCFC] leading-none ">
                                    Grow beyond borders with a corporate account
                                </p>
                                <p className="text-lg lg:text-xl text-[#D1D1D6] mt-6 mb-9">
                                    Multi-currency accounts, instant internal
                                    transfers and international online payments
                                    — all in one platform. Juno Money offers
                                    everything your business needs to simplify
                                    your global payments and financial
                                    operations.
                                </p>

                                <CustomButton
                                    className="w-[174px]"
                                    title="get in touch"
                                    onClick={scrollToContact}
                                />
                            </div>
                        </div>
                    </FadeUp>
                    <img
                        className="hidden lg:block absolute z-0 -right-52 h-[1000px] object-cover -top-7 opacity-50 "
                        src={`${imageBaseUrl}/landingpage/heroDesk.jpeg`}
                    />
                </div>
                <div className="z-10 relative">
                    <ServicesComponent />
                    <div className="bg-black">
                        <JunoContact
                            heroContent={corporateContactUs}
                            textAlign="left"
                            contactRef={contactRef}
                        />
                    </div>
                </div>
            </div>
        </LandingLayout>
    )
}
