import React, { useEffect } from 'react'
import JunoAmHero from './components/JunoAmHero'
import JunoAmHeader from './components/JunoAmHeader'
import JunoAmCounter from './components/JunoAmCounter'
import JunoAmCommitment from './components/JunoAmCommitment'
import JunoAmContact from './components/JunoAmContact'
import JunoAmFooter from './components/JunoAmFooter'
import { ScrollToPlugin } from 'gsap/dist/ScrollToPlugin'
import { gsap } from 'gsap/dist/gsap'
gsap.registerPlugin(ScrollToPlugin)

export default function JunoAmPage() {
    function scrollToContact() {
        gsap.to(window, {
            duration: 0.6,
            ease: 'none',
            scrollTo: '#jnAm-contact',
        })
    }

    return (
        <div className="jnAm junoAm-theme">
            <JunoAmHeader scrollToContact={scrollToContact} />
            <JunoAmHero scrollToContact={scrollToContact} />
            <JunoAmCounter />
            <JunoAmCommitment />
            <JunoAmContact />
            <JunoAmFooter />
        </div>
    )
}
