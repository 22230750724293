import React from 'react'
import RoutesList from './client//Routes.js';
import 'bootstrap/dist/css/bootstrap.min.css';
import './scss/css/style.scss'


const App = () => {


  return <div id="ProjectApp" className="projectApp">
    <RoutesList />
  </div>

}

export default App
