import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
    getCurrencySymbol,
    toFixedTrunc,
    useJunoCommonFunctions,
} from '../../../helpers'
import DashboardCryptoDistribution from './DashboardCryptoDistribution'
import { useState } from 'react'
import BalanceCryptoBuy from '../../tables/balances/actions/crypto/BalanceCryptoBuy'
import BalanceCryptoSell from '../../tables/balances/actions/crypto/BalanceCryptoSell'
import { BalanceCryptoReceive } from '../../tables/balances/actions/crypto/BalanceCryptoReceive'
import { BalanceCrytoExchange } from '../../tables/balances/actions/crypto/BalanceCrytoExchange'
import { BalanceCryptoSend } from '../../tables/balances/actions/crypto/BalanceCryptoSend'
import { setErrorMessage } from '../../../../Redux/actions/junoAction'

const DashboardCryptoHeader = () => {
    const { clientData, totalCryptoAmount } = useSelector((state) => state.juno)
    const { getCryptoBalances } = useJunoCommonFunctions()
    const dispatch = useDispatch()

    const readOnly =
        clientData && clientData.readOnly ? clientData.readOnly : false
    const [modalAction, setModalAction] = useState('')
    const [loading, setLoading] = useState(false)

    const { name } = clientData ? clientData : ''

    const handleModalOpen = async (modalType) => {
        try {
            setLoading(true)
            const balances = await getCryptoBalances()
            const unfreezeBalances =
                balances?.filter((balance) => !balance.isFreeze) || []

            if (
                unfreezeBalances.length === 0 ||
                (unfreezeBalances.length <= 1 && modalType === 'Exchange')
            ) {
                dispatch(
                    setErrorMessage(
                        'All currencies are currently disabled. Please contact the administrator for assistance.'
                    )
                )
                setLoading(false)
                return
            }

            setModalAction(modalType)
        } catch (err) {
            console.log('Error opening the modal: ', err)
        } finally {
            setLoading(false)
        }
    }

    return (
        <div>
            <div className="jncTotalBalance max-991">
                <p className='!text-[#17171b]'>Your total balance is</p>
                <p
                    data-e2e="total-balance"
                    data-e2e-symbol={getCurrencySymbol('USD')}
                    data-e2e-amount={toFixedTrunc(totalCryptoAmount, 2)}
                    className='!font-semibold'
                >
                    {getCurrencySymbol('USD')}{' '}
                    {toFixedTrunc(totalCryptoAmount, 2)}
                </p>
            </div>
            <div className=" jncCard min-992 jncDesktopTotalBalance">
                <div className="jncDesktopTotalBalance__container ">
                    <div>
                        <p
                            className="jncDesktopTotalBalance__welcomeMessage"
                            data-e2e-msg="welcome"
                            data-e2e={`Welcome back, ${name}`}
                        >{`Welcome back, ${name}`}</p>
                        <p className="jncDesktopTotalBalance__subheading">
                            Your total balance is{' '}
                            <span
                                className="jncDesktopTotalBalance__balance"
                                data-e2e={toFixedTrunc(totalCryptoAmount, 2)}
                                data-e2e-amount="amount"
                            >
                                {getCurrencySymbol('USD')}{' '}
                                {toFixedTrunc(totalCryptoAmount, 2)}
                            </span>{' '}
                        </p>
                    </div>
                    <div className="dashboardHeaderActions">
                        <button
                            className="dashboardHeaderActions__button"
                            disabled={loading || readOnly}
                            onClick={() => {
                                handleModalOpen('buy')
                            }}
                        >
                            <p data-e2e="buy-upper-tab">Buy</p>
                        </button>
                        <button
                            className="dashboardHeaderActions__button"
                            disabled={loading || readOnly}
                            onClick={() => {
                                handleModalOpen('Sell')
                            }}
                        >
                            <p data-e2e="sell-upper-tab">Sell</p>
                        </button>
                        <button
                            className="dashboardHeaderActions__button"
                            disabled={loading || readOnly}
                            onClick={() => {
                                handleModalOpen('Receive')
                            }}
                        >
                            <p data-e2e="receive-upper-tab">Receive</p>
                        </button>
                        <button
                            className="dashboardHeaderActions__button"
                            disabled={loading || readOnly}
                            onClick={() => {
                                handleModalOpen('Exchange')
                            }}
                        >
                            <p data-e2e="exchange-upper-tab">Exchange</p>
                        </button>
                        <button
                            className="dashboardHeaderActions__button"
                            disabled={loading || readOnly}
                            onClick={() => {
                                handleModalOpen('Send')
                            }}
                        >
                            <p data-e2e="send-upper-tab">Send</p>
                        </button>
                    </div>
                </div>
                <DashboardCryptoDistribution />
                <BalanceCryptoBuy
                    open={modalAction === 'buy'}
                    onClose={() => setModalAction('')}
                    row={{}}
                />
                <BalanceCryptoSell
                    open={modalAction === 'Sell'}
                    onClose={() => setModalAction('')}
                    row={{}}
                />
                <BalanceCryptoReceive
                    open={modalAction === 'Receive'}
                    onClose={() => setModalAction('')}
                    row={{}}
                />
                <BalanceCrytoExchange
                    open={modalAction === 'Exchange'}
                    onClose={() => setModalAction('')}
                    row={{}}
                />
                <BalanceCryptoSend
                    open={modalAction === 'Send'}
                    onClose={() => setModalAction('')}
                    row={{}}
                />
            </div>
        </div>
    )
}

export default DashboardCryptoHeader
