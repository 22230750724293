import React, { useState, useEffect } from 'react'
import { format } from 'date-fns'
import { apiService } from '../../../../common/apiCallService'
import { useSelector, useDispatch } from 'react-redux'
import TableComponent from '../../components/table/TableComponent'
import MediaQuery from 'react-responsive'
import {
    toFixedTrunc,
    useJunoCommonFunctions,
    getCryptoStatus,
    renderExpandedCryptoComponent,
    getCurrencySymbol,
    renderExpandedComponent,
    getBalanceSign,
    getStatus,
    convertToEndOfDayUTC,
    convertFromStartOfDayUTC
} from '../../../helpers'
import * as actions from '../../../../Redux/actions'
import JncSectionTitle from '../../components/global/JncSectionTitle'
import _ from 'lodash'
import JncTransactionFilters from './JncTransactionFilters'
import JncCryptoTransactionMobile from './JncCryptoTransanctionMobile'
import TransactionMobileDialog from './TransactionMobileDialog'
import TransactionMobileFilters from './TransactionMobileFilters'
import Icon from '../../icons/Icon'
import { NavLink } from 'react-router-dom'
import JncPageTitle from '../../components/global/JncPageTitle'

export default function JncCryptoTransaction({
    isDashboard,
    showTablePagination,
    isBalancesTab,
}) {
    const data = useSelector((state) => state.juno.allTransactions)
    const dispatch = useDispatch()
    const apiUrl = useSelector((state) => state.config.api_url)
    const clientData = useSelector((state) => state.juno.clientData)
    const readOnly =
        clientData && clientData.readOnly ? clientData.readOnly : false
    const { useWindowWidth, getCryptoIcons } = useJunoCommonFunctions()
    const imageBaseUrl = useSelector(state => state.config.image_base_url);

    const [isLoading, setIsLoading] = useState(true)

    const [transactionDialog, setTransactionDialog] = useState(false)
    const [transactionDialogData, setTransactionDialogData] = useState(null)
    const defaultFilter = {
        currency: [],
        type: '',
        from: '',
        currencyType: '',
        to: '',
        status: '',
        page: 1,
        pageSize: window.innerWidth > 991 ? 10 : 5,
    }
    const [filter, setFilter] = useState(defaultFilter)
    const [totalRows, setTotalRows] = useState(0)

    const [mobileFiltersDialog, setMobileFiltersDialog] = useState(false)

    const windowWidth = useWindowWidth()
    const getCurrencyType = () => {
        if (
            clientData.cryptoTab === 'Enable' &&
            clientData.fiatTab === 'Enable'
        ) {
            return filter?.currencyType[0]
                ? filter.currencyType[0].toLowerCase()
                : 'all'
        } else if (clientData.cryptoTab === 'Enable') {
            return 'crypto'
        } else {
            return 'fiat'
        }
    }

    function RepeatActionBtn(row) {
        return (
            <button
                type="button"
                disabled={readOnly}
                onClick={() => openRepeatModal(row)}
                className={`jncRepeatBtn jncHint ${
                    readOnly && 'jnc-btn-disable'
                }`}
            >
                <span>Repeat</span>
                <Icon id="repeatAction" />
            </button>
        )
    }

    const columns = [
        {
            name: 'Transaction type',
            selector: (row) =>
                row.type + (row.transferInOut ? ` (${row.transferInOut})` : ''),
            sortable: true,
            cell: (row) => (
                <div
                    data-tag="allowRowEvents"
                    className="no-wrap jncTable-type"
                    data-e2e-element="transaction-type"
                    data-e2e={
                        row.type +
                        (row.transferInOut ? ` (${row.transferInOut})` : '')
                    }
                >
                    {row.currencyType === 'fiat' ? (
                        <Icon id={_.camelCase(row.type)} />
                    ) : (
                        getCryptoIcons(row.type)
                    )}
                    {row.type +
                        (row.transferInOut ? ` (${row.transferInOut})` : '')}
                </div>
            ),
            width: '160px',
        },
        {
            name: 'Date',
            selector: (row) => row.createdDate,
            sortable: true,
            cell: (row) => (
                <div data-tag="allowRowEvents" className="">
                    {format(new Date(row.createdDate), 'dd/MM/y')}
                    <br />
                    {format(new Date(row.createdDate), 'HH:mm:ss')}
                </div>
            ),
        },
        {
            name: 'Transaction Id',
            selector: (row) => row.transactionId || 'N/A',
            sortable: false,
            cell: (row, index) => (
                <div
                    data-e2e={row.transactionId || 'N/A'}
                    data-e2e-row={index}
                    data-tag="allowRowEvents"
                    className=""
                >
                    {row.transactionId || 'N/A'}
                </div>
            ),
        },
        {
            name: 'Remitter name',
            selector: (row) => row.transactionDetails[0].remitterName || 'N/A',
            sortable: true,
        },
        {
            name: 'Beneficiary name',
            selector: (row) =>
                row.transactionDetails[0].beneficiaryName || 'N/A',
            sortable: true,
        },
        {
            name: 'Currency',
            selector: (row) => {
                if (row.type == 'FX') {
                    return `${row.transactionDetails[0].fromCurrency} - ${row.transactionDetails[0].toCurrency}`
                } else if (row.type === 'Exchange') {
                    return `${row.transactionDetails[0].fromCryptoId} - ${row.transactionDetails[0].toCryptoId}`
                } else {
                    return row.currency
                }
            },
            sortable: true,
            width: '115px',
        },
        {
            name: 'Amount',
            sortable: true,
            width: '210px',
            cell: (row) => {
                let content = 'N/A'
                const transaction = row.transactionDetails[0]

                if (row.currencyType === 'fiat') {
                    if (
                        row.transactionDetails &&
                        row.transactionDetails.length > 0
                    ) {
                        if (
                            row.type === 'FX' &&
                            transaction.fromAmount &&
                            transaction.toAmount
                        ) {
                            content = (
                                <div
                                    data-tag="allowRowEvents"
                                    className="jncTable-amount"
                                >
                                    <p>
                                        {getCurrencySymbol(
                                            transaction.fromCurrency
                                        )}{' '}
                                        {toFixedTrunc(
                                            transaction.fromAmount,
                                            2
                                        )}{' '}
                                        - <br />
                                        {getCurrencySymbol(
                                            transaction.toCurrency
                                        )}{' '}
                                        {toFixedTrunc(transaction.toAmount, 2)}
                                    </p>
                                    {RepeatActionBtn(row)}
                                </div>
                            )
                        } else if (transaction.amount) {
                            const depositAmount =
                                row.type === 'Deposit' &&
                                transaction.amount - row.transactionFee
                            content = (
                                <div
                                    data-tag="allowRowEvents"
                                    className="jncTable-amount"
                                >
                                    {getBalanceSign(row)}{' '}
                                    {getCurrencySymbol(
                                        row.currency || transaction.currency
                                    )}{' '}
                                    {row.type !== 'Deposit'
                                        ? toFixedTrunc(transaction.amount, 2)
                                        : toFixedTrunc(depositAmount, 2)}
                                    {row.type !== 'Deposit' &&
                                        row.transferInOut !== 'In' &&
                                        RepeatActionBtn(row)}
                                </div>
                            )
                        }
                    }
                } else {
                    if (
                        row.type === 'Exchange' &&
                        transaction.fromCryptoAmount &&
                        transaction.toCryptoAmount
                    ) {
                        content = (
                            <div
                                data-tag="allowRowEvents"
                                className="jncTable-amount"
                            >
                                <p>
                                    {getBalanceSign(row)}
                                    {getCurrencySymbol(
                                        row.currency || transaction.fromCryptoId
                                    )}{' '}
                                    {toFixedTrunc(
                                        transaction.fromCryptoAmount,
                                        6
                                    )}{' '}
                                    - <br />
                                    {getBalanceSign(row)}
                                    {getCurrencySymbol(
                                        row.currency || transaction.toCryptoId
                                    )}{' '}
                                    {toFixedTrunc(
                                        transaction.toCryptoAmount,
                                        2
                                    )}
                                </p>
                                {RepeatActionBtn(row)}
                            </div>
                        )
                    } else {
                        content = (
                            <div
                                data-tag="allowRowEvents"
                                className="jncTable-amount"
                            >
                                {getBalanceSign(row)}
                                {getCurrencySymbol(
                                    row.currency || transaction.currency
                                )}{' '}
                                {toFixedTrunc(transaction.cryptoAmount, 8)}
                            </div>
                        )
                    }
                }

                return content
            },
        },
        {
            name: 'Status',
            selector: (row) => row.status.code,
            sortable: true,
            cell: (row) => (
                <div
                    data-tag="allowRowEvents"
                    className={`jncTable-status ${
                        row.currencyType === 'fiat'
                            ? getStatus(row.status.code)
                                  .toLowerCase()
                                  .replace('/', '')
                            : getCryptoStatus(row.status.code)
                                  .toLowerCase()
                                  .replace('/', '')
                    }`}
                >
                    {row.currencyType === 'fiat'
                        ? getStatus(row.status.code)
                        : getCryptoStatus(row.status.code)}
                </div>
            ),
        },
    ]

    const ExpandedComponent = ({ data }) => {
        return data.currencyType === 'fiat'
            ? renderExpandedComponent(data)
            : renderExpandedCryptoComponent(data)
    }

    const refetchData = () => {
        if (clientData) {
            const currencyType = getCurrencyType()
            apiService(
                (window.location.hostname === 'localhost' ? apiUrl : '') +
                    '/restapi/get-client-transactions',
                {
                    client: clientData.clientId,
                    ...filter,
                    from: filter.from
                        ? convertFromStartOfDayUTC(filter.from)
                        : '',
                    to: filter.to
                        ? convertToEndOfDayUTC(filter.to)
                        : '',
                    currencyType,
                    currency: filter.currency.length ? filter.currency[0] : '',
                },
                async (data) => {
                    if (data) {
                        dispatch(
                            actions.setTransactionsData(
                                data.clientsTransactionData
                            )
                        )
                        setTotalRows(data.totalCount)
                        setIsLoading(false)
                    }
                },
                (err) => {
                    console.log(err)
                    setIsLoading(false)
                }
            )
        }
    }

    useEffect(() => {
        refetchData()
    }, [filter, clientData])

    function openTransactionDialog(data) {
        setTransactionDialogData(data)
        setTransactionDialog(true)
    }

    function closeTransactionDialog() {
        setTransactionDialog(false)
        setTransactionDialogData(null)
    }

    const headerBtn = () => {
        return (
            <p className="flex gap-1 items-center">
                <img
                    alt="filterIcon"
                    src={`${imageBaseUrl}/webUI/filterIcon.svg`}
                    className="w-[18px] h-[18px]"
                />
                Filter
            </p>
        )
    }

    return (
        <>
            {!isDashboard && (
                <JncPageTitle
                    title="Transaction history"
                    headerBtn={headerBtn()}
                    headerBtnAction={() => setMobileFiltersDialog(true)}
                />
            )}
            <div
                className={`jncSection jncTransactions ${
                    isDashboard ? 'is-dashboard mb' : ''
                } ${isBalancesTab ? 'hidden' : ''}`}
            >
                <div
                    className={`jncTable ${isDashboard ? 'is-dashboard' : ''} ${
                        data.length < 1 ? 'no-data' : ''
                    }`}
                >
                    {isDashboard && (
                        <JncSectionTitle
                            title="Transaction history"
                            seeAllLink="/client/transactions"
                            navClassName="min-992"
                        />
                    )}
                    {!isDashboard && !isLoading && windowWidth > 991 && (
                        <JncTransactionFilters
                            filter={filter}
                            setFilter={setFilter}
                        />
                    )}
                    <div className="jncTable-hover">
                        <MediaQuery minWidth={992}>
                            <TableComponent
                                columns={columns}
                                filteredData={
                                    isDashboard ? data.slice(0, 5) : data
                                }
                                expandableRows={true}
                                expandableRowsComponent={ExpandedComponent}
                                expandableIcon={{}}
                                expandOnRowClicked={true}
                                showTablePagination={showTablePagination}
                                isLoading={isLoading}
                                noDataText={
                                    data.length === 0
                                        ? "You don't have any transactions yet"
                                        : 'There are no transactions that match your criteria'
                                }
                                paginationServer
                                totalRows={totalRows}
                                handlePageChange={(page) =>
                                    setFilter((pre) => ({ ...pre, page }))
                                }
                            />
                        </MediaQuery>
                        <MediaQuery maxWidth={991}>
                            <JncCryptoTransactionMobile
                                filteredData={data}
                                isLoading={isLoading}
                                noDataText={
                                    data.length === 0
                                        ? "You don't have any transactions yet"
                                        : 'There are no transactions that match your criteria'
                                }
                                showTablePagination={showTablePagination}
                                openTransactionDialog={openTransactionDialog}
                                totalRows={totalRows}
                                handlePageChange={(page) =>
                                    setFilter((pre) => ({ ...pre, page }))
                                }
                            />
                        </MediaQuery>
                        <TransactionMobileDialog
                            onClose={closeTransactionDialog}
                            open={transactionDialog && windowWidth < 992}
                            data={transactionDialogData}
                        />
                        <TransactionMobileFilters
                            onClose={() => setMobileFiltersDialog(false)}
                            open={mobileFiltersDialog && windowWidth < 992}
                            filter={filter}
                            setFilter={setFilter}
                        />
                        {isDashboard && (
                            <NavLink
                                className="jncDashbTabs__seeAll max-991"
                                to={'/client/transactions'}
                            >
                                See All
                            </NavLink>
                        )}
                    </div>
                </div>
            </div>
        </>
    )
}
