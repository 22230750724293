import React from 'react';
import InputCopyButton from '../../components/global/InputCopyButton';
import { toFixedTrunc, getCurrencySymbol, getCryptoStatus } from '../../../helpers';
import { ErrorTransactions } from '../../constant';

export default function CryptoTxnMobileBuyAndSell({ data }) {
  const details = data?.transactionDetails?.[0] || {};
  const transactionFee = toFixedTrunc(data?.transactionFee, 2) || '-';
  const balanceAfterTransaction = toFixedTrunc(data?.balanceAfterTransaction, 6);

  const renderTransactionGroup = (label, value, copyValue = value) => (
    value && (
      <div className="jncTransactionModal__group">
        <p className="jncTransactionModal__group-text">{label}:</p>
        <div className="jncTransactionModal__group-grid">
          <p className="jncTransactionModal__group-text">{value}</p>
          <InputCopyButton copyValue={copyValue} />
        </div>
      </div>
    )
  );

  return (
    <div>
      {renderTransactionGroup('Transaction Id', data?.transactionId)}
      {renderTransactionGroup('CryptoId', details.cryptoId)}
      {renderTransactionGroup('CryptoAmount', toFixedTrunc(details.cryptoAmount, 6))}
      {renderTransactionGroup('FiatCurrency', details.fiatCurrency)}
      {renderTransactionGroup('FiatAmount', toFixedTrunc(details.fiatAmount, 2))}
      {renderTransactionGroup('Transaction Fee', transactionFee, `$ ${transactionFee}`)}

      <div className="jncTransactionModal__group">
        <p className="jncTransactionModal__group-text">Total Amount:</p>
        <div className="jncTransactionModal__group-grid">
          <p className="jncTransactionModal__group-text">
            {getCurrencySymbol(details.cryptoId)}{' '}
            {toFixedTrunc(details.totalAmount, 6) || '-'}
          </p>
          <InputCopyButton copyValue={`$ ${toFixedTrunc(details.totalAmount, 6)}`} />
        </div>
      </div>

      {data.balanceAfterTransaction && (
        <div className="jncTransactionModal__group">
          <p className="jncTransactionModal__group-text">Balance after transaction:</p>
          <div className="jncTransactionModal__group-grid">
            <p className="jncTransactionModal__group-text">
              {getCurrencySymbol(details.currency)} {balanceAfterTransaction}
            </p>
            <InputCopyButton
              copyValue={`${getCurrencySymbol(details.currency)} ${balanceAfterTransaction}`}
            />
          </div>
        </div>
      )}

      {ErrorTransactions.includes(data?.status?.code) && (
        <div className="jncTransactionModal__group">
          <p className="jncTransactionModal__group-text">
            {getCryptoStatus(data.status.code)}:
          </p>
          <div className="jncTransactionModal__group-grid">
            <p className="jncTransactionModal__group-text">{data.status.message}</p>
            <InputCopyButton copyValue={data.status.message} />
          </div>
        </div>
      )}
    </div>
  );
}
