import { useSelector } from 'react-redux'
import { apiService } from '../../../../common/apiCallService'
import JunoClientActionModal from '../table/JunoClientActionModal'
import Icon from '../../icons/Icon'
import JncButton from './JncButton'
import { useState } from 'react'


export const JncDelete = (props) => {
    const { onClose, open, title,content, DELETE_URL,request, callback,dialogClassName,actionClassName,onDelete} = props
    const apiUrl = useSelector((state) => state.config.api_url)

    const [isLoading,setIsLoading] = useState(false)
    const deleteContent = () => {
        setIsLoading(true)
        apiService(
            (window.location.hostname === 'localhost' ? apiUrl : '') +
            DELETE_URL,
            request,
            async (data) => {
                if (data) {
                    setIsLoading(false);
                    onClose();
                    onDelete();
                    callback();
                }
            },
            (err) => {
                console.log(err)
                setIsLoading(false)
            },
        )
    }

    const dialogDeleteContent = () => {
        return(
        <>
            <div className="jncModal__resp pt-1.5 pb-2.5">
                <Icon id="deleteIcon"></Icon>
                <p className="jncModal__resp-title">{title}</p>
                <p className="jncModal__text">{content}</p>
            </div>
        </>
    )}

    const dialogDeleteAction = () => {
        return (
            <>
            <div className="jncModal__resp w-full" >       
                <div className="jncModal__btns">
                <JncButton
                    dataE2e="cancel-btn"
                    text="Cancel"
                    disabled={isLoading}
                    onClickCall={() => onClose()}
                    isOutlinedStyle={true}
                />
                <JncButton
                    dataE2e="delete-btn"
                    text="Delete"
                    loading={isLoading}
                    open={dialogDeleteContent}
                    onClickCall={()=>deleteContent()}
                    isDangerStyle={true}
                />
                </div>
            </div>
            </>
        )}
    return (
        <JunoClientActionModal
        dialogClassName={dialogClassName}
            onClose={onClose}
            states={{
                start: true,
                confirm: false,
            }}
            open={open}
            dialogContent={dialogDeleteContent()}
            dialogActions={dialogDeleteAction()}
            actionClassName={actionClassName}
        ></JunoClientActionModal>
    )
}