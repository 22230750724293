import React from 'react'
import CopyButton from '../../components/global/CopyButton'
import { toFixedTrunc, getCurrencySymbol, getStatus } from '../../../helpers'
import { ErrorTransactions } from '../../constant'

export default function TransferExpanded(data) {
    const details = data && data.transactionDetails[0]
    const transactionFee = data && data.transactionFee
    const balanceBeforeDeduction = data.transactionFee
        ? Number(details.amount) + Number(data.transactionFee)
        : details.amount

    return (
        <div className="jncExpanded">
            <div className="grid grid-cols-2">
                {/* <<<<<< 1st Column >>>>>>>>>>> */}
                <div className="flex flex-col gap-3">
                    <div className="jncExpanded-block">
                        <p className="jncExpanded-label">Transaction Id</p>
                        <div className="jncExpanded-block-copy jncExpanded-text">
                            <p className="is-ellipsis">
                                {data.transactionId || '-'}
                            </p>
                            <CopyButton copyValue={data.transactionId} />
                        </div>
                    </div>
                    <div className="jncExpanded-block">
                        <p className="jncExpanded-label">
                            {!transactionFee
                                ? 'Amount'
                                : 'Amount before deduction'}
                        </p>
                        <div className="jncExpanded-block-copy jncExpanded-text">
                            <p>
                                {getCurrencySymbol(details.currency)}{' '}
                                {toFixedTrunc(balanceBeforeDeduction, 2)}{' '}
                            </p>
                            <CopyButton
                                copyValue={`${toFixedTrunc(balanceBeforeDeduction, 2)}`}
                            />
                        </div>
                    </div>
                    {transactionFee ? (
                        <div className="jncExpanded-block">
                            <p className="jncExpanded-label">
                                Amount after deduction
                            </p>
                            <div className="jncExpanded-block-copy jncExpanded-text">
                                <p>
                                    {getCurrencySymbol(data.currency)}{' '}
                                    {toFixedTrunc(details.amount, 2) ||
                                        '-'}
                                </p>
                                <CopyButton
                                    copyValue={`$ ${toFixedTrunc(
                                        details.amount,
                                        2
                                    )}`}
                                />
                            </div>
                        </div>
                    ) : (
                        ''
                    )}
                    <div className="">
                        <p className="jncExpanded-label">
                            Balance after transaction
                        </p>
                        <div className="jncExpanded-block-copy jncExpanded-text">
                            <p>
                                {getCurrencySymbol(details.currency)}{' '}
                                {toFixedTrunc(data.balanceAfterTransaction, 2)}
                            </p>
                            <CopyButton
                                copyValue={data.balanceAfterTransaction}
                            />
                        </div>
                    </div>
                    {data.transferInOut === 'Out' && (
                        <div className="">
                            <p className="jncExpanded-label">Transaction Fee</p>
                            <div className="jncExpanded-block-copy jncExpanded-text">
                                <p>
                                    {getCurrencySymbol(data.currency)}{' '}
                                    {toFixedTrunc(transactionFee, 2) || 0}
                                </p>
                                <CopyButton copyValue={transactionFee} />
                            </div>
                        </div>
                    )}

                    {data.transferInFee ? (
                        <div className="">
                            <p className="jncExpanded-label">Merchant Fee</p>
                            <div className="jncExpanded-block-copy jncExpanded-text">
                                <p>
                                    {getCurrencySymbol(data.currency)}{' '}
                                    {toFixedTrunc(data.transferInFee, 2) || 0}
                                </p>
                                <CopyButton copyValue={data.transferInFee} />
                            </div>
                        </div>
                    ) : null}
                </div>

                {/* <<<<<< 2nd Column >>>>>>>>>>> */}
                <div className="flex flex-col gap-3">
                    <div className="jncExpanded-block">
                        <p className="jncExpanded-label">Juno Money Email</p>
                        <div className="jncExpanded-block-copy jncExpanded-text">
                            <p>{details.email || '-'}</p>
                            <CopyButton copyValue={details.email} />
                        </div>
                    </div>

                    <div className="jncExpanded-block">
                        <p className="jncExpanded-label">
                            Juno Money Account Name
                        </p>
                        <div className="jncExpanded-block-copy jncExpanded-text">
                            <p>{details.accountName || '-'}</p>
                            <CopyButton copyValue={details.accountName} />
                        </div>
                    </div>
                    <div className="jncExpanded-block col-span-2">
                        <p className="jncExpanded-label">Reference</p>
                        <div className="jncExpanded-block-copy jncExpanded-text">
                            <p>{details.reference || '-'}</p>
                            <CopyButton copyValue={details.reference} />
                        </div>
                    </div>
                </div>

                {ErrorTransactions.includes(data.status.code) && (
                    <div className="jncExpanded-block mt-3">
                        <p className="jncExpanded-failed">
                            {getStatus(data.status.code)}:
                        </p>
                        <div className="jncExpanded-block-copy jncExpanded-text">
                            <p>{data.status.message}</p>
                        </div>
                    </div>
                )}
            </div>
        </div>
    )
}
