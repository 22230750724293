import React from 'react';
import CopyButton from '../../components/global/CopyButton';
import { toFixedTrunc, getCurrencySymbol, getStatus } from '../../../helpers';
import { ErrorTransactions } from '../../constant';
import { CommonCryptoExpanded } from './CommonCryptoExpanded';

export default function ExchangeExpanded({ transactionDetails, transactionFee, balanceAfterTransaction, currencyType, status }) {
  const details = transactionDetails?.[0] || {};

  return (
    <div className="jncExpanded">
      <div className="jncExpanded-grid">
        {CommonCryptoExpanded("FromCryptoId", details.fromCryptoId)}
        {CommonCryptoExpanded("FromCryptoAmount", details.fromCryptoAmount)}
        {CommonCryptoExpanded("ToCryptoId", details.toCryptoId)}
        
        <div className="jncExpanded-block">
          <p className="jncExpanded-label">toCryptoAmount</p>
          <div className="jncExpanded-block-copy jncExpanded-text">
            <p>
              {getCurrencySymbol(details.toCryptoId)}
              {toFixedTrunc(details.toCryptoAmount, 6) || "-"}
            </p>
          </div>
        </div>

        {CommonCryptoExpanded("Transaction Fee", transactionFee)}
        {CommonCryptoExpanded("Total Amount", details.totalAmount)}

        {currencyType === 'crypto' && (
          <div className="jncExpanded-block">
            <p className="jncExpanded-label">Balance after transaction</p>
            <div className="jncExpanded-block-copy jncExpanded-text">
              <p>{`${details.fromCryptoId} ${toFixedTrunc(balanceAfterTransaction, 8)}`}</p>
              <CopyButton copyValue={`${details.cryptoId} ${toFixedTrunc(balanceAfterTransaction, 8)}`} />
            </div>
          </div>
        )}

        {ErrorTransactions.includes(status?.code) && (
          <div className="jncExpanded-block">
            <p className="jncExpanded-failed">{getStatus(status.code)}:</p>
            <div className="jncExpanded-block-copy jncExpanded-text">
              <p>{status.message}</p>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
