import React, { useEffect, useRef, useState } from 'react'
import { usePopper } from 'react-popper'
import {
    fiatCurrencyList,
    getDateTextPicker,
    getStatus,
    useJunoCommonFunctions,
} from '../../../helpers'
import { useDispatch, useSelector } from 'react-redux'
import { Typeahead } from 'react-bootstrap-typeahead'
import FocusTrap from 'focus-trap-react'
import { DayPicker } from 'react-day-picker'
import ClearIcon from '@mui/icons-material/Clear'
import { CryptoTransactionTypes, CurrencyTypes, TransactionTypes, ValidStatus } from '../../constant'
import { isSameDay } from 'date-fns'
import { displayCurrencyPage } from '../../../../Redux/actions/junoAction'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'

export default function JncTransactionFilters({ filter, setFilter }) {
    const dispatch = useDispatch()
    const imageBaseUrl = useSelector((state) => state.config.image_base_url)
    const activeCurrencyPage = useSelector((state) => state.juno.activeCurrencyPage)
    const clientData = useSelector((state) => state.juno.clientData)
    const balancesFiatData = useSelector((state) => state.juno.balancesCurrencies)
    const balancesCryptoData = useSelector((state) => state.juno.balancesCrypto)
    const balancesData = {
        fiat: balancesFiatData,
        crypto: balancesCryptoData,
    }
    const filteredBalancesData = (clientData.cryptoTab === "Enable" && clientData.fiatTab === "Enable")
        ? [...balancesFiatData, ...balancesCryptoData] : balancesData[activeCurrencyPage];
    const currencyListOptions = filteredBalancesData.map((x) => x.currencyShortName)
    const { getCryptoBalances, getBalances, useWindowWidth} = useJunoCommonFunctions()
    const windowWidth = useWindowWidth()

    const popperRef = useRef(null)
    const buttonRef = useRef(null)
    const [popperElement, setPopperElement] = useState(null)
    const popper = usePopper(popperRef.current, popperElement, {
        placement: 'bottom-start',
    })
    const [isPopperOpen, setIsPopperOpen] = useState(false)

    const getCurrencyTypes = () => {
        const types = []
        if (clientData.cryptoTab === "Enable") {
            types.push('Crypto')

        }
        if (clientData.fiatTab === "Enable") {
            types.push('Fiat')
        }
        return types
    }
    useEffect(() => {
        getCryptoBalances()
        getBalances()
    }, [])
    const closePopper = () => setIsPopperOpen(false)
    const handleDateClick = () => setIsPopperOpen(true)

    const handleDateSelect = (range) => {
        if (!range) return
        if (isSameDay(range.from, range.to)) {
            // Selected a single date
            setFilter((prev) => ({ ...prev, from: range.from, to: '' }))
        } else {
            // Selected a date range
            setFilter((prev) => ({
                ...prev,
                from: range.from,
                to: range.to
            }))
        }
    }

    const handleClearFilters = () => {
        const defaultFilter = {
            currency: [],
            currencyType: [''],
            type: '',
            from: '',
            to: '',
            status: '',
            page: 1,
            pageSize: window.innerWidth > 991 ? 10 : 5,
        }
        setFilter(defaultFilter)
    }

    function getCurrencyIcon(name) {
        const currency = filteredBalancesData.find((x) => x.currencyShortName === name)
        return currency ? currency.currencyIcon : null
    }

    const getTransactionTypes = () => {
        let options = [];
        const storedSettings = JSON.parse(localStorage.getItem("tabSettings")) || {};
        const { cryptoTab, fiatTab } = storedSettings;
        
        if (cryptoTab === "Enable" && fiatTab === "Enable") {
            options = [...TransactionTypes, ...CryptoTransactionTypes];
        } else if (cryptoTab === "Enable") {
            options = [...CryptoTransactionTypes];
        } else if (fiatTab === "Enable") {
            options = [...TransactionTypes];
        }
    
        return options;
    };

    return (
        <div className="jncTable-inputs">
            <div className="jncTransactions__filters">
                <div className="jncResetInput">
                    <label className="jncResetLabel">Time & Date:</label>
                    <div
                        className="jncTable-inputs-group"
                        style={{ width: windowWidth <= 767 ? '100%' : '75%' }}
                    >
                        <div
                            ref={popperRef}
                            className="jncTable-inputs-group jncTable-inputs-btnGroup jncTable-inputs-group-text"
                        >
                            <button
                                data-e2e="date"
                                type="button"
                                className="theme-modal-field-input jncTable-inputs-btn"
                                aria-label="Pick a date"
                                onClick={handleDateClick}
                                ref={buttonRef}
                            >
                                {getDateTextPicker({
                                    from: filter.from,
                                    to: filter.to,
                                })}
                            </button>
                            {getDateTextPicker({
                                from: filter.from,
                                to: filter.to,
                            }) == 'Date' ? (
                                <ExpandMoreIcon
                                    className="jncTable-inputs-group-icon"
                                    width="12"
                                    height="7"
                                />
                            ) : (
                                <ClearIcon
                                    className="jncTable-inputs-group-icon clear-icon"
                                    onClick={() =>
                                        setFilter((pre) => ({
                                            ...pre,
                                            from: '',
                                            to: '',
                                        }))
                                    }
                                />
                            )}
                            {isPopperOpen && (
                                <FocusTrap
                                    active
                                    focusTrapOptions={{
                                        initialFocus: false,
                                        allowOutsideClick: true,
                                        clickOutsideDeactivates: true,
                                        onDeactivate: closePopper,
                                        fallbackFocus: buttonRef.current,
                                    }}
                                >
                                    <div
                                        tabIndex={-1}
                                        style={popper.styles.popper}
                                        className="dialog-sheet"
                                        {...popper.attributes.popper}
                                        ref={setPopperElement}
                                        role="dialog"
                                        aria-label="DayPicker calendar"
                                    >
                                        <DayPicker
                                            initialFocus={isPopperOpen}
                                            mode="range"
                                            selected={{
                                                from: filter.from,
                                                to: filter.to,
                                            }}
                                            onSelect={handleDateSelect}
                                            fromYear={2022}
                                            toYear={new Date().getFullYear()}
                                            captionLayout="dropdown"
                                        />
                                    </div>
                                </FocusTrap>
                            )}
                        </div>
                    </div>
                </div>
                <div className="jncResetInput">
                    <label className="jncResetLabel">Currency Type:</label>
                    <Typeahead
                        id="basic-typeahead-multiple-currency-type"
                        labelKey={(option) => `${option}`}
                        onChange={(val) =>
                            setFilter((pre) => ({ ...pre, currencyType: val }))
                        }
                        options={getCurrencyTypes()}
                        placeholder="Currency Type"
                        selected={filter.currencyType}
                        clearButton={true}
                        filterBy={(option, props) => {
                            return option != ''
                        }}
                        style={{ width: windowWidth <= 767 ? '100%' : '75%' }}
                    />
                </div>
                <div className="jncResetInput">
                    <label className="jncResetLabel">Currencies:</label>
                    <Typeahead
                        id="basic-typeahead-multiple-currency"
                        data-e2e="currency"
                        labelKey={(option) => `${option}`}
                        onChange={(val) => {
                            setFilter((pre) => ({ ...pre, currency: val }))
                        }}
                        options={currencyListOptions}
                        placeholder="Currencies"
                        selected={filter.currency}
                        clearButton={true}
                        filterBy={(option, props) => {
                            return option != ''
                        }}
                        style={{ width: windowWidth <= 767 ? '100%' : '75%' }}
                        renderMenuItemChildren={(option) => (
                            <div className="jncTypeahead-item">
                                {getCurrencyIcon(option) && (
                                    <img
                                        src={`${imageBaseUrl}${getCurrencyIcon(option)}`}
                                    />
                                )}
                                <p data-e2e={option}>{option}</p>
                            </div>
                        )}
                    />
                </div>
                <div className="jncResetInput">
                    <label className="jncResetLabel">Status:</label>
                    <Typeahead
                        id="basic-typeahead-multiple-status"
                        labelKey={(option) => `${getStatus(option)}`}
                        onChange={(val) =>
                            setFilter((pre) => ({ ...pre, status: val }))
                        }
                        options={ValidStatus}
                        placeholder="Status"
                        selected={filter.status || []}
                        clearButton={true}
                        filterBy={(option, props) => {
                            return option != ''
                        }}
                        style={{ width: windowWidth <= 767 ? '100%' : '75%' }}
                    />
                </div>
                <div className="jncResetInput" data-e2e="type">
                    <label className="jncResetLabel">Type:</label>
                    <Typeahead
                        id="basic-typeahead-multiple-type"
                        labelKey={(option) => `${option}`}
                        onChange={(val) =>
                            setFilter((pre) => ({ ...pre, type: val }))
                        }
                        options={getTransactionTypes()}
                        placeholder="Type"
                        selected={filter.type || []}
                        clearButton={true}
                        filterBy={(option, props) => {
                            return option != ''
                        }}
                        style={{ width: windowWidth <= 767 ? '100%' : '75%' }}
                    />
                </div>
            </div>
            {windowWidth > 991 && (
                <button
                    data-e2e="clear-filter"
                    className="jncTransactions__clearFilter jncTable-inputs-group-text min-991"
                    onClick={handleClearFilters}
                >
                    <img
                        alt="resetIcon"
                        src={`${imageBaseUrl}/webUI/resetIcon.svg`}
                        className="w-5 h-5"
                    />
                    Clear filters
                </button>
            )}
        </div>
    )
}
