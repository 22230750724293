import React, { useState } from 'react'
import CheckTwoToneIcon from '@mui/icons-material/CheckTwoTone'

export default function CopyButton(props) {
    const { copyValue, className, text } = props
    const [isCopied, setIsCopied] = useState(false)
    function copy() {
        navigator.clipboard.writeText(copyValue)
        setIsCopied(true)
        setTimeout(() => {
            setIsCopied(false)
        }, 1000)
    }

    return (
        <button
            className={`jncExpanded-copy ${className}`}
            type="button"
            onClick={copy}
        >
            {!isCopied ? (
                <>
                    <svg
                        className="copy-icon"
                        width="18"
                        height="18"
                        viewBox="0 0 18 18"
                        fill="none"
                    >
                        <rect
                            x="6.59961"
                            y="6.59912"
                            width="10.4008"
                            height="10.4008"
                            rx="2"
                            stroke="#667085"
                            strokeWidth="1.6"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                        />
                        <path
                            d="M3.40019 11.4008H2.60012C1.7164 11.4008 1 10.6844 1 9.80069V2.60012C1 1.7164 1.7164 1 2.60012 1H9.80069C10.6844 1 11.4008 1.7164 11.4008 2.60012V3.40019"
                            stroke="#667085"
                            strokeWidth="1.6"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                        />
                    </svg>
                    {text}
                </>
            ) : (
                <>
                    <CheckTwoToneIcon />
                    {text}
                </>
            )}
        </button>
    )
}
