import React, { useState, useEffect } from 'react'
import {
    getStatus,
    getCurrencySymbol,
    toFixedTrunc,
    renderExpandedComponent,
} from '../../../helpers'
import { format } from 'date-fns'
import { useSelector } from 'react-redux'
import BalancesTableActions from '../../tables/balances/BalancesTableActions'
import StatementsTableActions from '../../tables/statements/StatementsTableActions'
import Pagination from '../pagination/Pagination'
import AccountsTableActions from '../../tables/accounts/AccountsTableActions'
import Icon from '../../icons/Icon'
import _ from 'lodash'
import JncEmptyData from './JncEmptyData'
import AdditionalUserActions from '../../tables/AdditionalUser/AdditionalUserActions'
import { LinearProgress } from '@mui/material'

let PageSize = 5

export default function TableComponentMobile(props) {
    const {
        actions,
        valuesToDisplay,
        setTransferModalState,
        setFxModalState,
        setPaymentOutModalState,
        setWithdrawModalState,
        setDepositModalState,
        filteredData,
        expand,
        setfixedCurrency,
        fixedCurrency,
        setBankAccountRowToEdit,
        openEditStatementModal,
        isLoading,
        showTablePagination,
        noDataText,
        setUserModal,
        setUserEditData,
        setUpdateEmail,
        handleUpdateUser,
        setIsEdit,
        readOnly,
    } = props
    const image_base_url = useSelector((state) => state.config.image_base_url)

    const [currentPage, setCurrentPage] = useState(1)
    const [paginationData, setPaginationData] = useState([])

    const LinearIndeterminate = () => {
        const loadingStyle = {
            width: '100%',
            marginTop: '20px',
        }
        return (
            <div style={loadingStyle}>
                <LinearProgress />
            </div>
        )
    }

    useEffect(() => {
        const firstPageIndex = (currentPage - 1) * PageSize
        const lastPageIndex = firstPageIndex + PageSize
        const res = filteredData.slice(firstPageIndex, lastPageIndex)
        setPaginationData(res)
    }, [currentPage])

    useEffect(() => {
        setCurrentPage(1)
        const firstPageIndex = 0
        const lastPageIndex = firstPageIndex + PageSize
        const res = filteredData.slice(firstPageIndex, lastPageIndex)
        setPaginationData(res)
    }, [filteredData])

    function expandRow(e) {
        const target = e.currentTarget
        const parent = target.parentElement
        target.classList.toggle('active')
        parent.classList.toggle('active')

        const expandEl = target.nextSibling
        expandEl.classList.toggle('active')

        const iconEl = target.getElementsByClassName('jncTableM-expand-icon')[0]
        iconEl.classList.toggle('active')
    }

    function ExpandArrowIcons() {
        return (
            <div className="jncTableM-expand-icon">
                <svg
                    className="arrow"
                    width="7"
                    height="12"
                    viewBox="0 0 7 12"
                    fill="none"
                >
                    <path
                        d="M0.700048 11.3C0.516715 11.1167 0.425049 10.8834 0.425049 10.6C0.425049 10.3167 0.516715 10.0834 0.700048 9.90005L4.60005 6.00005L0.700048 2.10005C0.516715 1.91672 0.425049 1.68338 0.425049 1.40005C0.425049 1.11672 0.516715 0.883382 0.700048 0.700048C0.883382 0.516715 1.11671 0.425049 1.40005 0.425049C1.68338 0.425049 1.91672 0.516715 2.10005 0.700048L6.70005 5.30005C6.80005 5.40005 6.87105 5.50838 6.91305 5.62505C6.95505 5.74172 6.97572 5.86672 6.97505 6.00005C6.97505 6.13338 6.95405 6.25838 6.91205 6.37505C6.87005 6.49172 6.79938 6.60005 6.70005 6.70005L2.10005 11.3C1.91672 11.4834 1.68338 11.575 1.40005 11.575C1.11671 11.575 0.883382 11.4834 0.700048 11.3Z"
                        fill="#344054"
                    />
                </svg>
                <svg
                    className="close"
                    width="14"
                    height="14"
                    viewBox="0 0 14 14"
                    fill="none"
                >
                    <path
                        d="M6.9998 8.40005L2.0998 13.3C1.91647 13.4834 1.68314 13.575 1.3998 13.575C1.11647 13.575 0.883138 13.4834 0.699804 13.3C0.516471 13.1167 0.424805 12.8834 0.424805 12.6C0.424805 12.3167 0.516471 12.0834 0.699804 11.9L5.5998 7.00005L0.699804 2.10005C0.516471 1.91672 0.424805 1.68338 0.424805 1.40005C0.424805 1.11672 0.516471 0.883382 0.699804 0.700048C0.883138 0.516715 1.11647 0.425049 1.3998 0.425049C1.68314 0.425049 1.91647 0.516715 2.0998 0.700048L6.9998 5.60005L11.8998 0.700048C12.0831 0.516715 12.3165 0.425049 12.5998 0.425049C12.8831 0.425049 13.1165 0.516715 13.2998 0.700048C13.4831 0.883382 13.5748 1.11672 13.5748 1.40005C13.5748 1.68338 13.4831 1.91672 13.2998 2.10005L8.3998 7.00005L13.2998 11.9C13.4831 12.0834 13.5748 12.3167 13.5748 12.6C13.5748 12.8834 13.4831 13.1167 13.2998 13.3C13.1165 13.4834 12.8831 13.575 12.5998 13.575C12.3165 13.575 12.0831 13.4834 11.8998 13.3L6.9998 8.40005Z"
                        fill="#344054"
                    />
                </svg>
            </div>
        )
    }

    function renderCurrencyIcon(x) {
        return (
            <div className="jncTableM-Nstatus-left">
                <img src={`${image_base_url + x.currencyIcon}`} />
                <div className="jncTableM-text">
                    {x.currencyShortName ? x.currencyShortName : x.currency}
                </div>
            </div>
        )
    }

    function renderStatus(x) {
        return (
            <div className="d-flex">
                <div
                    className={`jncTable-status ${getStatus(x.status.code).toLowerCase().replace('/', '')}`}
                >
                    {getStatus(x.status.code)}
                </div>
            </div>
        )
    }

    return (
        <div className="jncTableM-wrap">
            {isLoading ? (
                <LinearIndeterminate />
            ) : (
                <div className="jncTableM">
                    {paginationData.length < 1 && (
                        <JncEmptyData text={noDataText} />
                    )}
                    {paginationData.map((x, index) => {
                        return (
                            <div key={index} className="jncTableM-block">
                                {valuesToDisplay === 'balances-fiat' && (
                                    <button
                                        type="button"
                                        className="jncTableM-btn jncTableM-Nstatus pd-x"
                                        onClick={(e) => expandRow(e)}
                                    >
                                        {renderCurrencyIcon(x)}
                                        <div className="jncTableM-Nstatus-right">
                                            <div className="jncTableM-Nstatus-text">
                                                <div className="jncTableM-text">
                                                    <p>
                                                        {getCurrencySymbol(
                                                            x.currencyShortName,
                                                        )}{' '}
                                                        {toFixedTrunc(
                                                            x.balanceAmount,
                                                            2,
                                                        )}
                                                    </p>
                                                </div>
                                            </div>
                                            <ExpandArrowIcons />
                                        </div>
                                    </button>
                                )}
                                {valuesToDisplay === 'balances-crypto' && (
                                    <button
                                        type="button"
                                        className="jncTableM-btn jncTableM-Nstatus pd-x"
                                        onClick={(e) => expandRow(e)}
                                    >
                                        {renderCurrencyIcon(x)}
                                        <div className="jncTableM-Nstatus-right">
                                            <div className="jncTableM-Nstatus-text">
                                                <div className="jncTableM-text">
                                                    <p>
                                                        {x.currencyShortName}{' '}
                                                        {toFixedTrunc(
                                                            x.balanceAmount,
                                                            8,
                                                        )}
                                                    </p>
                                                </div>
                                                <p className="jncTableM-text">
                                                    <span>
                                                        {toFixedTrunc(
                                                            x.holdingPercentage,
                                                            2,
                                                        )}
                                                        %
                                                    </span>
                                                </p>
                                            </div>
                                            <ExpandArrowIcons />
                                        </div>
                                    </button>
                                )}
                                {valuesToDisplay === 'transactions-fiat' && (
                                    <button
                                        type="button"
                                        className="jncTableM-btn transactions-style pd-x"
                                        onClick={(e) => expandRow(e)}
                                    >
                                        <div className="jncTable-type transactions-style-row">
                                            <Icon id={_.camelCase(x.type)} />
                                            <div className="jncTableM-text">
                                                <p>{x.type}</p>
                                                <span className="transaction-date">
                                                    {format(
                                                        new Date(
                                                            x.createdDate.toString(),
                                                        ),
                                                        'dd/MM/y',
                                                    )}{' '}
                                                    <br />{' '}
                                                    {format(
                                                        new Date(
                                                            x.createdDate.toString(),
                                                        ),
                                                        'HH:mm:ss',
                                                    )}
                                                </span>
                                            </div>
                                        </div>
                                        <div className="transactions-style-amount">
                                            {x.type == 'FX' && (
                                                <p className="jncTableM-text">
                                                    {getCurrencySymbol(
                                                        x.transactionDetails[0]
                                                            .fromCurrency,
                                                    )}{' '}
                                                    {toFixedTrunc(
                                                        x.transactionDetails[0]
                                                            .fromAmount,
                                                        2,
                                                    )}
                                                    &ensp;-&ensp;
                                                    {getCurrencySymbol(
                                                        x.transactionDetails[0]
                                                            .toCurrency,
                                                    )}{' '}
                                                    {toFixedTrunc(
                                                        x.transactionDetails[0]
                                                            .toAmount,
                                                        2,
                                                    )}
                                                </p>
                                            )}
                                            {x.type != 'FX' && (
                                                <p className="jncTableM-text">
                                                    {getCurrencySymbol(
                                                        x.transactionDetails[0]
                                                            .currency ||
                                                        x.currency,
                                                    )}{' '}
                                                    {toFixedTrunc(
                                                        x.transactionDetails[0]
                                                            .amount,
                                                        2,
                                                    )}
                                                </p>
                                            )}
                                            {renderStatus(x)}
                                        </div>
                                    </button>
                                )}
                                {valuesToDisplay === 'transactions-crypto' && (
                                    <button
                                        type="button"
                                        className="jncTableM-btn pd-x"
                                        onClick={(e) => expandRow(e)}
                                    >
                                        <div>
                                            {renderStatus(x)}
                                            <p className="jncTableM-text">
                                                {format(
                                                    new Date(
                                                        x.createdDate.toString(),
                                                    ),
                                                    'dd/MM/y',
                                                )}{' '}
                                                {format(
                                                    new Date(
                                                        x.createdDate.toString(),
                                                    ),
                                                    'HH:mm:ss',
                                                )}
                                                &ensp;|&ensp;
                                                {x.type}
                                            </p>
                                            <p className="jncTableM-text">
                                                <span>
                                                    {
                                                        x.transactionDetails[0]
                                                            .cryptoId
                                                    }{' '}
                                                    {toFixedTrunc(
                                                        x.transactionDetails[0]
                                                            .cryptoAmount,
                                                        8,
                                                    )}{' '}
                                                </span>
                                            </p>
                                        </div>
                                        <div className="jncTableM-status-right">
                                            <ExpandArrowIcons />
                                        </div>
                                    </button>
                                )}
                                {valuesToDisplay === 'statements' && (
                                    <button
                                        type="button"
                                        className="jncTableM-btn pd-x"
                                        onClick={(e) => expandRow(e)}
                                    >
                                        <div>
                                            <p className="jncTableM-text">
                                                {format(
                                                    new Date(
                                                        x.createdAt.toString(),
                                                    ),
                                                    'dd/MM/y',
                                                )}{' '}
                                                {format(
                                                    new Date(
                                                        x.createdAt.toString(),
                                                    ),
                                                    'HH:mm:ss',
                                                )}
                                                &ensp;|&ensp;
                                                {x.currencyShortName}
                                            </p>
                                            <p className="jncTableM-text">
                                                <span>
                                                    {x.currencyType === 'crypto'
                                                        ? x.currencyShortName
                                                        : getCurrencySymbol(
                                                            x.currencyShortName,
                                                        )}
                                                </span>
                                                &ensp;
                                                <span>
                                                    {toFixedTrunc(
                                                        x.openingBalance,
                                                        x.currencyType ===
                                                            'crypto'
                                                            ? 8
                                                            : 2,
                                                    )}
                                                </span>
                                                <span>&ensp;-&ensp;</span>
                                                <span>
                                                    {x.currencyType === 'crypto'
                                                        ? x.currencyShortName
                                                        : getCurrencySymbol(
                                                            x.currencyShortName,
                                                        )}
                                                </span>
                                                &ensp;
                                                <span>
                                                    {toFixedTrunc(
                                                        x.closingBalance,
                                                        x.currencyType ===
                                                            'crypto'
                                                            ? 8
                                                            : 2,
                                                    )}
                                                </span>
                                            </p>
                                        </div>
                                        <ExpandArrowIcons />
                                    </button>
                                )}
                                {valuesToDisplay === 'Additional Users' && (
                                    <div className="jncTableM-btn pd-x">
                                        <p className="jncTableM-text">
                                            {x.clientEmail}
                                        </p>
                                        <AdditionalUserActions
                                            setUserModal={setUserModal}
                                            setUserEditData={setUserEditData}
                                            setUpdateEmail={setUpdateEmail}
                                            handleUpdateUser={handleUpdateUser}
                                            setIsEdit={setIsEdit}
                                            readOnly={readOnly}
                                            row={x}
                                        />
                                    </div>
                                )}
                                {valuesToDisplay === 'bank-accounts' && (
                                    <button
                                        type="button"
                                        className="jncTableM-btn pd-x"
                                        onClick={(e) => expandRow(e)}
                                    >
                                        <div>
                                            <p className="jncTableM-text">
                                                {x.beneficiaryName}&ensp;|&ensp;
                                                {x.bankName}
                                            </p>
                                            <p className="jncTableM-text">
                                                <span>
                                                    {format(
                                                        new Date(
                                                            x.createdAt.toString(),
                                                        ),
                                                        'dd/MM/y',
                                                    )}{' '}
                                                    {format(
                                                        new Date(
                                                            x.createdAt.toString(),
                                                        ),
                                                        'HH:mm:ss',
                                                    )}
                                                </span>
                                            </p>
                                        </div>
                                        <ExpandArrowIcons />
                                    </button>
                                )}
                                <div
                                    className={`${expand ? 'status-style' : ''} jncTableM-expand`}
                                >
                                    {expand &&
                                        renderExpandedComponent(
                                            x,
                                            valuesToDisplay,
                                        )}
                                    <div className="jncExpanded">
                                        {valuesToDisplay ===
                                            'balances-fiat' && (
                                                <div className="jncTableM-group">
                                                    <p>Balance:</p>
                                                    <p>
                                                        {getCurrencySymbol(
                                                            x.currencyShortName,
                                                        )}{' '}
                                                        {toFixedTrunc(
                                                            x.balanceAmount,
                                                            2,
                                                        )}
                                                    </p>
                                                </div>
                                            )}
                                        {valuesToDisplay ===
                                            'balances-crypto' && (
                                                <div>
                                                    <div className="jncTableM-group">
                                                        <p>Balance:</p>
                                                        <p>
                                                            {toFixedTrunc(
                                                                x.balanceAmount,
                                                                8,
                                                            )}
                                                        </p>
                                                    </div>
                                                    <div className="jncTableM-group">
                                                        <p>Holding (%):</p>
                                                        <p>
                                                            {toFixedTrunc(
                                                                x.holdingPercentage,
                                                                2,
                                                            )}
                                                            %
                                                        </p>
                                                    </div>
                                                    <div className="jncTableM-group">
                                                        <p>
                                                            <div className="jncTable-custom-heading">
                                                                <button>
                                                                    Price
                                                                </button>
                                                                <select
                                                                    defaultValue={
                                                                        fixedCurrency
                                                                    }
                                                                    value={
                                                                        fixedCurrency
                                                                    }
                                                                    onChange={(e) =>
                                                                        setfixedCurrency(
                                                                            e.target
                                                                                .value,
                                                                        )
                                                                    }
                                                                >
                                                                    <option value="USD">
                                                                        USD
                                                                    </option>
                                                                    <option value="GBP">
                                                                        GBP
                                                                    </option>
                                                                </select>
                                                                :
                                                            </div>
                                                        </p>
                                                        <p>
                                                            {fixedCurrency === 'USD'
                                                                ? '$'
                                                                : '£'}{' '}
                                                            {toFixedTrunc(
                                                                x.fixedRate,
                                                                2,
                                                            )}
                                                        </p>
                                                    </div>
                                                </div>
                                            )}
                                        {valuesToDisplay === 'statements' && (
                                            <div>
                                                <div className="jncTableM-group">
                                                    <p>Created at:</p>
                                                    <p>
                                                        {format(
                                                            new Date(
                                                                x.createdAt.toString(),
                                                            ),
                                                            'dd/MM/y',
                                                        )}{' '}
                                                        {format(
                                                            new Date(
                                                                x.createdAt.toString(),
                                                            ),
                                                            'HH:mm:ss',
                                                        )}
                                                    </p>
                                                </div>
                                                <div className="jncTableM-group">
                                                    <p>Currency:</p>
                                                    <p>{x.currency}</p>
                                                </div>
                                                <div className="jncTableM-group">
                                                    <p>Opening balance:</p>
                                                    <p>
                                                        {x.currencyType ===
                                                            'fiat' &&
                                                            getCurrencySymbol(
                                                                x.currencyShortName,
                                                            )}
                                                        &ensp;
                                                        {toFixedTrunc(
                                                            x.openingBalance,
                                                            x.currencyType ===
                                                                'crypto'
                                                                ? 8
                                                                : 2,
                                                        )}
                                                    </p>
                                                </div>
                                                <div className="jncTableM-group">
                                                    <p>Closing balance:</p>
                                                    <p>
                                                        {x.currencyType ===
                                                            'fiat' &&
                                                            getCurrencySymbol(
                                                                x.currencyShortName,
                                                            )}
                                                        &ensp;
                                                        {toFixedTrunc(
                                                            x.closingBalance,
                                                            x.currencyType ===
                                                                'crypto'
                                                                ? 8
                                                                : 2,
                                                        )}
                                                    </p>
                                                </div>
                                            </div>
                                        )}
                                    </div>

                                    {actions == 'statements' && (
                                        <StatementsTableActions
                                            openEditStatementModal={
                                                openEditStatementModal
                                            }
                                            row={x}
                                        />
                                    )}
                                    {actions == 'balances' && (
                                        <BalancesTableActions
                                            setDepositModalState={() => {
                                                setDepositModalState(x)
                                            }}
                                            setWithdrawModalState={() => {
                                                setWithdrawModalState(x)
                                            }}
                                            setPaymentOutModalState={() => {
                                                setPaymentOutModalState(x)
                                            }}
                                            setFxModalState={() => {
                                                setFxModalState(x)
                                            }}
                                            setTransferModalState={() => {
                                                setTransferModalState(x)
                                            }}
                                            row={x}
                                        />
                                    )}
                                    {actions == 'bank-accounts' && (
                                        <AccountsTableActions
                                            setRowToEdit={
                                                setBankAccountRowToEdit
                                            }
                                            row={x}
                                        />
                                    )}
                                </div>
                            </div>
                        )
                    })}
                    {showTablePagination && (
                        <Pagination
                            className="pagination-bar"
                            currentPage={currentPage}
                            totalCount={filteredData.length}
                            pageSize={PageSize}
                            onPageChange={(page) => setCurrentPage(page)}
                        />
                    )}
                </div>
            )}
        </div>
    )
}
