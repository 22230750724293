import React from 'react'
import { getStatus } from '../../../helpers'
import InputCopyButton from '../../components/global/InputCopyButton'
import { toFixedTrunc, getCurrencySymbol } from '../../../helpers'
import { ErrorTransactions } from '../../constant'

export default function TransactionMobileDialogDeposit({ data }) {
    const details = data && data.transactionDetails[0]
    const transactionFee = data && data.transactionFee
    const balanceBeforeDeduction = data.transactionFee
        ? Number(details.amount) + Number(data.transactionFee)
        : details.amount

    return (
        <div>
            <div className="jncTransactionModal__group">
                <p className="jncTransactionModal__group-text">Status:</p>
                <div className="d-flex">
                    <div
                        className={`jncTransactionsList__status ${getStatus(
                            data.status.code
                        )
                            .toLowerCase()
                            .replace('/', '')}`}
                    >
                        {getStatus(data.status.code)}
                    </div>
                </div>
            </div>
            {data.transactionId && (
                <div className="jncTransactionModal__group">
                    <p className="jncTransactionModal__group-text">
                        Transaction Id:
                    </p>
                    <div className="jncTransactionModal__group-grid">
                        <p className="jncTransactionModal__group-text">
                            {data.transactionId}
                        </p>
                        <InputCopyButton copyValue={data.transactionId} />
                    </div>
                </div>
            )}
            {details.fromAddress && (
                <div className="jncTransactionModal__group">
                    <p className="jncTransactionModal__group-text">From:</p>
                    <div className="jncTransactionModal__group-grid">
                        <p className="jncTransactionModal__group-text">
                            {details.fromAddress}
                        </p>
                        <InputCopyButton copyValue={details.fromAddress} />
                    </div>
                </div>
            )}
            {details.toAddress && (
                <div className="jncTransactionModal__group">
                    <p className="jncTransactionModal__group-text">To:</p>
                    <div className="jncTransactionModal__group-grid">
                        <p className="jncTransactionModal__group-text">
                            {details.toAddress}
                        </p>
                        <InputCopyButton copyValue={details.toAddress} />
                    </div>
                </div>
            )}
            {details.Txid && (
                <div className="jncTransactionModal__group">
                    <p className="jncTransactionModal__group-text">Txid:</p>
                    <div className="jncTransactionModal__group-grid">
                        <p className="jncTransactionModal__group-text">
                            {details.Txid}
                        </p>
                        <InputCopyButton copyValue={details.Txid} />
                    </div>
                </div>
            )}
            {details.USDAmount && (
                <div className="jncTransactionModal__group">
                    <p className="jncTransactionModal__group-text">Amount:</p>
                    <div className="jncTransactionModal__group-grid">
                        <p className="jncTransactionModal__group-text">
                            $ {toFixedTrunc(details.USDAmount, 2)}
                        </p>
                        <InputCopyButton
                            copyValue={`$ ${toFixedTrunc(
                                details.USDAmount,
                                2
                            )}`}
                        />
                    </div>
                </div>
            )}
            {details.remitterName && (
                <div className="jncTransactionModal__group">
                    <p className="jncTransactionModal__group-text">
                        Remitter name:
                    </p>
                    <div className="jncTransactionModal__group-grid">
                        <p className="jncTransactionModal__group-text">
                            {details.remitterName}
                        </p>
                        <InputCopyButton copyValue={details.remitterName} />
                    </div>
                </div>
            )}
            {details.amount && (
                <div className="jncTransactionModal__group">
                    <p className="jncTransactionModal__group-text">
                        {!transactionFee ? 'Amount' : 'Amount before deduction'}
                        :
                    </p>
                    <div className="jncTransactionModal__group-grid">
                        <p className="jncTransactionModal__group-text">
                            {getCurrencySymbol(details.currency)}{' '}
                            {toFixedTrunc(balanceBeforeDeduction, 2)}
                        </p>
                        <InputCopyButton
                            copyValue={
                                getCurrencySymbol(details.currency) +
                                ' ' +
                                toFixedTrunc(balanceBeforeDeduction, 2)
                            }
                        />
                    </div>
                </div>
            )}
            {details.amount && (
                <div className="jncTransactionModal__group">
                    <p className="jncTransactionModal__group-text">Amount after deduction:</p>
                    <div className="jncTransactionModal__group-grid">
                        <p className="jncTransactionModal__group-text">
                            {getCurrencySymbol(details.currency)}{' '}
                            {toFixedTrunc(details.amount, 2)}
                        </p>
                        <InputCopyButton
                            copyValue={
                                getCurrencySymbol(details.currency) +
                                ' ' +
                                toFixedTrunc(details.amount, 2)
                            }
                        />
                    </div>
                </div>
            )}
            {details.currency && (
                <div className="jncTransactionModal__group">
                    <p className="jncTransactionModal__group-text">Currency:</p>
                    <div className="jncTransactionModal__group-grid">
                        <p className="jncTransactionModal__group-text">
                            {details.currency}
                        </p>
                        <InputCopyButton copyValue={details.currency} />
                    </div>
                </div>
            )}
            {data.balanceAfterTransaction && (
                <div className="jncTransactionModal__group">
                    <p className="jncTransactionModal__group-text">
                        Balance after transaction:
                    </p>
                    <div className="jncTransactionModal__group-grid">
                        <p className="jncTransactionModal__group-text">
                            {getCurrencySymbol(details.currency)}{' '}
                            {toFixedTrunc(data.balanceAfterTransaction, 2)}
                        </p>
                        <InputCopyButton
                            copyValue={`${getCurrencySymbol(
                                details.currency
                            )} ${toFixedTrunc(
                                data.balanceAfterTransaction,
                                2
                            )}`}
                        />
                    </div>
                </div>
            )}
            <div className="jncTransactionModal__group">
                <p className="jncTransactionModal__group-text">
                    Transaction Fee:
                </p>
                <div className="jncTransactionModal__group-grid">
                    <p className="jncTransactionModal__group-text">
                        {getCurrencySymbol(data.currency)}{' '}
                        {toFixedTrunc(transactionFee, 2) || '-'}
                    </p>
                    <InputCopyButton
                        copyValue={`$ ${toFixedTrunc(transactionFee, 2)}`}
                    />
                </div>
            </div>
            {details.reference && (
                <div className="jncTransactionModal__group">
                    <p className="jncTransactionModal__group-text">
                        Reference:
                    </p>
                    <div className="jncTransactionModal__group-grid">
                        <p className="jncTransactionModal__group-text">
                            {details.reference}
                        </p>
                        <InputCopyButton copyValue={details.reference} />
                    </div>
                </div>
            )}
            {ErrorTransactions.includes(data.status.code) && (
                <div className="jncTransactionModal__group">
                    <p className="jncTransactionModal__group-text">
                        {getStatus(data.status.code)}:
                    </p>
                    <div className="jncTransactionModal__group-grid">
                        <p className="jncTransactionModal__group-text">
                            {data.status.message}
                        </p>
                        <InputCopyButton copyValue={data.status.message} />
                    </div>
                </div>
            )}
        </div>
    )
}
