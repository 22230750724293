import React from 'react'
import { useSelector } from 'react-redux'
import { toFixedTrunc } from '../../../helpers'

export default function FxConfirm({
    fromAmount,
    fromCurrency,
    toAmount,
    toCurrency,
    fixedRate,
}) {
    const image_base_url = useSelector((state) => state.config.image_base_url)
    const balancesData = useSelector((state) => state.juno.balancesCurrencies)
    const toCurrencyObj = balancesData.find(
        (x) => x.currencyShortName === toCurrency[0],
    )
    const fromCurrencyObj = balancesData.find(
        (x) => x.currencyShortName === fromCurrency[0],
    )

    return (
        <div className="fx-confirm">
            <div className="fx-confirm-exchange">
                <div className="fx-confirm-group">
                    <p className="max-767 fx-confirm-group-label">
                        You’re converting:
                    </p>
                    <div className="fx-confirm-group-value">
                        <img
                            src={`${image_base_url}${fromCurrencyObj && fromCurrencyObj.currencyIcon}`}
                        />
                        <p>
                            {toFixedTrunc(fromAmount, 2)}{' '}
                            {fromCurrencyObj &&
                                fromCurrencyObj.currencyShortName}
                        </p>
                    </div>
                    <svg
                        className="min-768"
                        width="20"
                        height="20"
                        viewBox="0 0 20 20"
                        fill="none"
                    >
                        <path
                            d="M3.33301 5.83333L16.6663 5.83333M16.6663 5.83333L13.333 9.16667M16.6663 5.83333L13.333 2.5M16.6663 14.1667L3.33301 14.1667M3.33301 14.1667L6.66634 17.5M3.33301 14.1667L6.66634 10.8333"
                            stroke="#667085"
                            strokeWidth="1.6"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                        />
                    </svg>
                    <p className="max-767 fx-confirm-group-label mt">To:</p>
                    <div className="fx-confirm-group-value end">
                        <img
                            src={`${image_base_url}${toCurrencyObj && toCurrencyObj.currencyIcon}`}
                        />
                        <p>
                            {toFixedTrunc(toAmount, 2)}{' '}
                            {toCurrencyObj && toCurrencyObj.currencyShortName}
                        </p>
                    </div>
                </div>
                <div className="divider min-768"></div>
                <p className="max-767 fx-confirm-group-label mt">Rate:</p>
                <p className="fx-confirm-rate">
                    1 {fromCurrencyObj && fromCurrencyObj.currencyShortName} ={' '}
                    {toFixedTrunc(fixedRate, 4)}{' '}
                    {toCurrencyObj && toCurrencyObj.currencyShortName}
                </p>
            </div>
        </div>
    )
}
