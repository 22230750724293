import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { apiService } from '../../../../common/apiCallService'

export default function AccountsTableActions(props) {
    const { row, setRowToEdit } = props
    const api_url = useSelector((state) => state.config.api_url)

    function onEdit() {
        setRowToEdit(row)
    }

    return (
        <React.Fragment>
            <div className="jncTable-actions">
                <button onClick={onEdit} className="jncTable-actions-btn">
                    <span>Edit</span>
                    <svg width="18" height="18" viewBox="0 0 18 18" fill="none">
                        <path
                            d="M13.9999 7.33326L10.6666 3.99993M1.08325 16.9166L3.90356 16.6032C4.24813 16.5649 4.42042 16.5458 4.58146 16.4937C4.72433 16.4474 4.86029 16.3821 4.98566 16.2994C5.12696 16.2062 5.24954 16.0836 5.49469 15.8385L16.4999 4.83326C17.4204 3.91279 17.4204 2.4204 16.4999 1.49993C15.5795 0.579452 14.0871 0.579451 13.1666 1.49992L2.16136 12.5052C1.91621 12.7503 1.79363 12.8729 1.70045 13.0142C1.61778 13.1396 1.55243 13.2755 1.50618 13.4184C1.45405 13.5794 1.43491 13.7517 1.39662 14.0963L1.08325 16.9166Z"
                            stroke="#344054"
                            strokeWidth="1.6"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                        />
                    </svg>
                </button>
            </div>
        </React.Fragment>
    )
}
