import React from 'react'
import Icon from '../../icons/Icon'
export default function JncEmptyData({ text }) {
    return (
        <div className="jncEmptyData">
            <Icon id="emptyData" />
            <p className="jncEmptyData__text">{text}</p>
        </div>
    )
}
