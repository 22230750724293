import React from 'react'
import JncButton from '../global/JncButton'

export default function IsFailed(props) {
    const { text, reset, failedErr } = props
    return (
        <div className="jncModal__resp">
            <svg width="80" height="80" viewBox="0 0 80 80" fill="none">
                <rect
                    x="4"
                    y="4"
                    width="72"
                    height="72"
                    rx="36"
                    fill="#FEE4E2"
                />
                <path
                    d="M39.9997 33.3335V40.0002M39.9997 46.6668H40.0163M56.6663 40.0002C56.6663 49.2049 49.2044 56.6668 39.9997 56.6668C30.7949 56.6668 23.333 49.2049 23.333 40.0002C23.333 30.7954 30.7949 23.3335 39.9997 23.3335C49.2044 23.3335 56.6663 30.7954 56.6663 40.0002Z"
                    stroke="#F04438"
                    strokeWidth="2.6"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <rect
                    x="4"
                    y="4"
                    width="72"
                    height="72"
                    rx="36"
                    stroke="#FEF3F2"
                    strokeWidth="8"
                />
            </svg>
            <p className="jncModal__resp-title">Failed</p>
            {/* <p className="jncModal__text">{text}</p> */}
            <p className="jncModal__text">
                We were unable to complete your transaction. <br /> Please
                contact the support team
            </p>
            <p className="jncModal__text">[ {failedErr} ]</p>
            <div className="jncModal__btns full-width justify-end top-margin">
                <JncButton
                    onClickCall={reset}
                    isFullOnMobile={true}
                    text="Return"
                />
            </div>
        </div>
    )
}
