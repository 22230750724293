import React, { useRef, useState } from 'react'
import { useSelector } from 'react-redux'
import JunoHero from '../components/JunoHeroPage'
import JunoContact from '../components/JunoHeroContact'
import JunoLogin from '../components/global/JunoLogin'
import JunoCard from '../components/JunoCard'
import { everydayHeroContent, everydayContactUs } from '../data'
import LandingLayout from '../../client/layouts/LandingLayout'

export default function JuneEverydayBankingPage() {
    const [showLoginModal, setShowLoginModal] = useState(false)
    const [passwordChangedSuccessful, setPasswordChangedSuccessful] =
        useState(false)

    const contactRef = useRef(null)

    const scrollToContact = () => {
        setTimeout(() => {
            if (contactRef.current) {
                contactRef.current.scrollIntoView({ behavior: 'smooth' })
            }
        }, 0)
    }

    const imageBaseUrl = useSelector((state) => state.config.image_base_url)

    return (
        <LandingLayout>
            <JunoHero
                pageName={'everyday'}
                heroContent={everydayHeroContent}
                buttonTitle="get in touch"
                buttonOnClick={scrollToContact}
                showModal={() => {
                    setShowLoginModal(!showLoginModal)
                }}
                image={`${imageBaseUrl}/landingpage/heroMobile.jpeg`}
                textSize="text-6xl"
                bgColor="bg-[#FFFFFF09]"
            />
            <div className="xl:px-0">
                <JunoCard />
            </div>
            <div className="bg-black xl:px-0">
                <JunoContact
                    heroContent={everydayContactUs}
                    textAlign="left"
                    contactRef={contactRef}
                />
            </div>
            {showLoginModal && (
                <JunoLogin
                    show={true}
                    closeModal={() => setShowLoginModal(false)}
                    passwordChangedSuccessful={passwordChangedSuccessful}
                />
            )}
        </LandingLayout>
    )
}
