import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import validator from 'validator';
import Dialog from '@mui/material/Dialog';
import { CircularProgress } from '@mui/material';
import * as actions from '../../../../Redux/actions';
import { apiService } from '../../../../common/apiCallService';
import JncAuthClose from './auth/JncAuthClose';
import JncAuthTitle from './auth/JncAuthTitle';
import JncAuthBottom from './auth/JncAuthBottom';
import { useJunoCommonFunctions } from '../../../helpers';

const JunoForgotPassword = (props) => {
    const dispatch = useDispatch();
    const loader = useSelector((state) => state.common.loading);
    const api_url = useSelector((state) => state.config.api_url);
    const imageBaseURl = useSelector((state) => state.config.image_base_url);

    const [emailId, setEmailId] = useState('');
    const [alertMsg, setAlertMsg] = useState(null);
    const [showSuccessAlertModal, setShowSuccessAlertModal] = useState(false);
    const [emailError, setEmailError] = useState('');
    const { useWindowWidth } = useJunoCommonFunctions();
    const windowWidth = useWindowWidth();
    const headerLogo = sessionStorage.getItem("logo");
    const logoWidth = sessionStorage.getItem("logoWidth");

    const forgotPasswordEmail = async () => {
        if (emailId && validator.isEmail(emailId)) {
            setAlertMsg(null);
            dispatch(actions.setLoading(true));
            apiService(
                (window.location.hostname === 'localhost'
                    ? api_url
                    : '') + '/restapi/forgetPassword-client',
                {
                    clientEmail: emailId,
                    hostname: window.location.hostname,
                },
                (data) => {
                    dispatch(actions.setLoading(false));
                    if (data && data.success) {
                        setShowSuccessAlertModal(true);
                    } else if (data && data.msg) {
                        setAlertMsg(data.msg);
                        setEmailError(data.msg);
                    } else {
                        setAlertMsg('Something Went Wrong!');
                    }
                },
                (err) => {
                    dispatch(actions.setLoading(false));
                    setEmailError(err);
                    console.error(err);
                },
            );
        } else {
            setEmailError('Please enter valid email address!');
        }
    };

    const handleInputChange = (event) => {
        setEmailId(event.target.value);
        setEmailError('');
    };

    return (
        <Dialog
            className="jncAuth"
            fullScreen
            open={props.show}
            onClose={props.closeModal}
        >
            <div className="jncAuth__body bg-brand-black">
                <div>
                    <JncAuthClose onClose={props.closeModal} />
                    {!showSuccessAlertModal ? (
                        <div className="jncAuth__container p-8 !max-w-[424px] bg-white rounded-lg">
                            <div className="jncAuth__logo">
                                {headerLogo && headerLogo !== 'null' ? (
                                    <img src={headerLogo} alt="logo" width={logoWidth || "80"} height="auto" />
                                ) : (
                                    <img className='w-36' src={`${imageBaseURl}/landingpage/dark-logo.svg`} alt="logo" />
                                )}
                            </div>
                            <JncAuthTitle
                                title="Forgot Password"
                                subtitle="Enter your email and we’ll send a reset link."
                            />
                            {alertMsg && (
                                <div className="alert alert-danger" role="alert">
                                    {alertMsg}
                                </div>
                            )}
                            {emailError && (
                                <div className="alert alert-danger" role="alert">
                                    {emailError}
                                </div>
                            )}
                            <form>
                                <div className="jncAuth__field">
                                    <p className="jncAuth__field-label">Email</p>
                                    <input
                                        type="email"
                                        value={emailId}
                                        data-e2e="enter-your-email"
                                        placeholder="Enter your email"
                                        onChange={handleInputChange}
                                        name="emailId"
                                    />
                                </div>
                                <button
                                    type="button"
                                    disabled={emailError !== ''}
                                    className={`jncAuth__Button w-full rounded-full flex justify-center h-11 items-center text-white font-semibold full-width mt-6 !bg-brand-black hover:bg-brand-gray ${emailError === '' ? '' : 'disabled'}`}
                                    onClick={forgotPasswordEmail}
                                >
                                    <div data-e2e="send-recovery-link-btn">
                                        {loader ? <CircularProgress size={windowWidth > 640 ? 16 : 12} className='text-white' /> : "Send recovery link"}
                                    </div>
                                </button>
                                <div className="jncAuth__underBtn">
                                    Remembered password?&nbsp;
                                    <button
                                        type="button"
                                        className="jncAuth__link"
                                        onClick={props.openLoginModal}
                                    >
                                        Log In
                                    </button>
                                </div>
                            </form>
                        </div>
                    ) : (
                        <div className="jncAuth__container p-8 !max-w-[360px] bg-white rounded-lg">
                            <img className='jncAuth__successIcon' src={`${imageBaseURl}/adminUi/successTick.svg`} alt="success" />
                            <p className="jncAuth__successTitle">
                                Check your email
                            </p>
                            <p className="jncAuth__subtitle">
                                Please check the email address <span className='font-medium text-[#101828]'>{emailId}</span> for instructions to reset your password
                            </p>
                            <button
                                type="button"
                                className="jncAuth__Button w-full rounded-full flex justify-center h-11 items-center text-white font-semibold full-width mt-6 !bg-brand-black hover:bg-brand-gray"
                                onClick={props.openLoginModal}
                            >
                                <p>Continue</p>
                            </button>
                        </div>
                    )}
                </div>
                <JncAuthBottom />
            </div>
        </Dialog>
    );
};

export default JunoForgotPassword;
