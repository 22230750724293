import React from 'react'
import CopyButton from '../../components/global/CopyButton'
import { toFixedTrunc, getCurrencySymbol, getStatus } from '../../../helpers'
import { ErrorTransactions } from '../../constant'

export default function FxExpanded(data) {
    const details = data && data.transactionDetails[0]
    return (
        <div className="jncExpanded">
            <div className="jncExpanded-grid">
                <div className="jncExpanded-block">
                    <p className="jncExpanded-label">Transaction Id</p>
                    <div className="jncExpanded-block-copy jncExpanded-text">
                        <p className="is-ellipsis">
                            {data.transactionId || '-'}
                        </p>
                        <CopyButton copyValue={data.transactionId} />
                    </div>
                </div>

                <div className="jncExpanded-block">
                    <p className="jncExpanded-label">Currency exchanged from</p>
                    <div className="jncExpanded-block-copy jncExpanded-text">
                        <p>{details.fromCurrency}</p>
                    </div>
                </div>
                <div className="jncExpanded-block">
                    <p className="jncExpanded-label">Currency exchanged to</p>
                    <div className="jncExpanded-block-copy jncExpanded-text">
                        <p>{details.toCurrency}</p>
                    </div>
                </div>
                <div className="jncExpanded-block">
                    <p className="jncExpanded-label">FX rates</p>
                    <div className="jncExpanded-block-copy jncExpanded-text">
                        <p className="jncExpanded-fxRate">
                            {toFixedTrunc(details.fxrate, 6)}
                            <svg
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    d="M17 4V20M17 20L13 16M17 20L21 16M7 20V4M7 4L3 8M7 4L11 8"
                                    stroke="#667085"
                                    strokeWidth="1.6"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                />
                            </svg>
                        </p>
                    </div>
                </div>
                <div className="jncExpanded-block">
                    <p className="jncExpanded-label">
                        Amount (exchanged from currency)
                    </p>
                    <div className="jncExpanded-block-copy jncExpanded-text">
                        <p>
                            {getCurrencySymbol(details.fromCurrency)}{' '}
                            {toFixedTrunc(details.fromAmount, 2)}
                        </p>
                        <CopyButton
                            copyValue={`${toFixedTrunc(details.fromAmount, 2)}`}
                        />
                    </div>
                </div>
                <div className="jncExpanded-block">
                    <p className="jncExpanded-label">
                        Amount (exchanged to currency)
                    </p>
                    <div className="jncExpanded-block-copy jncExpanded-text">
                        <p>
                            {getCurrencySymbol(details.toCurrency)}{' '}
                            {toFixedTrunc(details.toAmount, 2)}{' '}
                        </p>
                        <CopyButton
                            copyValue={`${toFixedTrunc(details.toAmount, 2)}`}
                        />
                    </div>
                </div>
                <div className="jncExpanded-block">
                    <p className="jncExpanded-label">
                        Balance after transaction
                    </p>
                    <div className="jncExpanded-block-copy jncExpanded-text">
                        <p>
                            {getCurrencySymbol(details.fromCurrency)}{' '}
                            {toFixedTrunc(data.balanceAfterTransaction, 2)}
                        </p>
                        <CopyButton copyValue={data.balanceAfterTransaction} />
                    </div>
                </div>
                {ErrorTransactions.includes(data.status.code) && (
                    <div className="jncExpanded-block">
                        <p className="jncExpanded-failed">
                            {getStatus(data.status.code)}:
                        </p>
                        <div className="jncExpanded-block-copy jncExpanded-text">
                            <p>{data.status.message}</p>
                        </div>
                    </div>
                )}
            </div>
        </div>
    )
}
