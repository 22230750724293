import React from 'react'
import AuthCode from 'react-auth-code-input'
import JncButton from '../global/JncButton'
import { DialogActions, DialogContent, Dialog } from '@mui/material'
import JncDialogTitle from '../modals/JncDialogTitle'

function DisableEnableGoogleAuthForJuno(props) {
    const {
        changeActivation,
        onClose,
        inputValue,
        setInputValue,
        isActivated,
        error,
        qrCodeData,
    } = props

    let disabled = inputValue.length !== 6 || error

    function onChangeActivation() {
        if (!error) {
            changeActivation()
        }
    }

    const Spinner = () => (
        <div className="data-loader-block">
            <div className="data-loader"></div>
        </div>
    )

    return (
        <div>
            <Dialog onClose={onClose} open={props.open} className="jncModal">
                <JncDialogTitle
                    title={`${!isActivated ? 'Enable' : 'Disable'} 2FA Authenticator`}
                    onClose={onClose}
                />
                <DialogContent dividers>
                    <div className="jncModal__body">
                        {!isActivated && qrCodeData && (
                            <div className="theme-modal-authenticator">
                                <img src={qrCodeData}></img>
                            </div>
                        )}
                        <p className="jncModal__text">
                            {!isActivated
                                ? 'Scan the QR code in Google Authenticator and paste the generated code into the input field'
                                : 'Please, paste the generated code from Google Authenticator into the input field'}
                        </p>
                        <div className="theme-modal-field">
                            <p className="jncModal__text">Verification Code</p>
                            <div className="authCode mt-5">
                                <AuthCode
                                    containerClassName="authCode-container"
                                    onChange={setInputValue}
                                />
                            </div>
                        </div>
                        {error && (
                            <div
                                data-e2e={error}
                                className="jncAlert alert alert-danger"
                                role="alert"
                            >
                                {error}
                            </div>
                        )}
                    </div>
                </DialogContent>
                <DialogActions>
                    <div className="w-100 jncModal__btns justify-end">
                        <JncButton
                            onClickCall={onChangeActivation}
                            disabled={disabled}
                            isFullOnMobile={true}
                            text={!isActivated ? 'Verify' : 'Confirm'}
                        />
                    </div>
                </DialogActions>
            </Dialog>
        </div>
    )
}

export default DisableEnableGoogleAuthForJuno
