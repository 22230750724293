import React from 'react'
import JunoBackgroundVideo from '../../JunoMoney/components/JunoBackgroundVideo'

export default function JunoAmHero(props) {
    return (
        <section className="jnAm-section jnAm-hero">
            <JunoBackgroundVideo pageName={'ms-homepage'} />
            <svg
                preserveAspectRatio="none"
                className="jnAm-hero-rect"
                width="641"
                height="970"
                viewBox="0 0 641 970"
                fill="none"
            >
                <path
                    d="M0 0H264.5L641 970H0V0Z"
                    fill="#141D29"
                    fillOpacity="0.8"
                />
            </svg>
            <div className="content-container pd-y-tb">
                <div className="jnAm-hero-content">
                    <p className="jnAm-title accent-color">
                        Bespoke Financial Solutions
                    </p>
                    <div className="line"></div>
                    <p className="jnAm-text">
                        Juno Management Solutions AG provides a highly
                        personalised, industry leading service to corporate
                        entities, institutions, trusts, family offices and high
                        net worth individuals that require bespoke, innovative
                        solutions to their complex financial needs.
                    </p>
                    <p className="jnAm-text mt-3">
                        We pride ourselves on delivering a secure, compliant,
                        and discrete service to our clients.
                    </p>
                    <button
                        onClick={props.scrollToContact}
                        className="jnAm-btn"
                    >
                        Become a Juno client
                    </button>
                </div>
            </div>
            <div className="jnAm-bottomDeco"></div>
        </section>
    )
}
