import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux';
import { COLORS } from '../../constant';
import { toFixedTrunc } from '../../../helpers';

const DashboardCryptoDistribution = () => {
    const balancesFiatData = useSelector((state) => state.juno.balancesCurrencies)
    const balancesCryptoData = useSelector((state) => state.juno.balancesCrypto)
    const activeCurrencyPage = useSelector((state) => state.juno.activeCurrencyPage)
    const filteredBalancesData = activeCurrencyPage === 'fiat' ? balancesFiatData : balancesCryptoData;

    const [transformedBalances, setTransformedBalances] = useState([])
    const transformBalances = () => {
        // Sort the array based on holdingPercentage in  order
        const sortedData = filteredBalancesData.sort((a, b) => b.holdingPercentage - a.holdingPercentage);
        const top3 = sortedData.slice(0, 3);
        const totalHoldingPercentage = top3.reduce((total, curr) => total + curr.holdingPercentage, 0);

        // Calculate the holding percentage for "Others"
        const othersHoldingPercentage = 100 - totalHoldingPercentage;

        // Create a new array with top 3 currencies and "Others" i
        const result = [
            ...top3.map((item, index) => ({ currency: item.currencyShortName, holdingPercentage: item.holdingPercentage, color: COLORS[index] })),
            { currency: "Others", holdingPercentage: othersHoldingPercentage, color: COLORS[3] } // Assign color for "Others"
        ];
        setTransformedBalances(result)
    }

    useEffect(() => {
        transformBalances()
    }, [filteredBalancesData])

    return (
        <div>
            {filteredBalancesData.length > 0 ?
                <div>
                    <div className='jncDesktopTotalBalance__progressBar' >
                        {transformedBalances.map((balance) => <span style={{ width: `${balance.holdingPercentage}%`, background: balance.color }}></span>)}
                    </div>
                    <div className='jncDesktopTotalBalance__contribution'>
                        {transformedBalances.map((balance) =>
                            <div className="jncDesktopTotalBalance__currencies">
                                <span className='jncDesktopTotalBalance__dot' style={{ background: balance.color }}></span>
                                <span className='jncDesktopTotalBalance__value' data-e2e={`${toFixedTrunc(balance.holdingPercentage, 2)}`} data-e2e-cur={balance.currency} >{`${toFixedTrunc(balance.holdingPercentage, 2)}% ${balance.currency} `}</span>
                            </div>)}
                    </div>
                </div> : null}
        </div>
    )
}

export default DashboardCryptoDistribution
