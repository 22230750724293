import React from 'react'
import JunoClientActionModal from '../table/JunoClientActionModal'
import JncButton from '../global/JncButton'
import DoneIcon from '@mui/icons-material/Done';
import { getCurrencySymbol } from '../../../helpers';

export const JncSuccessModal = (props) => {
    const { open, onClose, amount, currency, title, subtitle, transactionType } = props

    const dialogContentComponent = () => {
        return (
            <>
                <div className="flex justify-center items-center flex-col validationModal">
                    <div className="rounded-full bg-[#ECFDF3] p-3">
                        <div className=" bg-[#D1FADF] rounded-full p-3 ">
                            <div className="w-10 h-10 bg-[#039855] rounded-full flex items-center justify-center">
                                <DoneIcon className='!w-10 !h-10 text-[#FFFFFF] m-0' />
                            </div>
                        </div>
                    </div>
                    <p className="validationModal-title">{title}</p>
                    {transactionType != "Exchange" && (
                        <p className="my-4 text-[#18181B] text-xl font-medium">{getCurrencySymbol(currency)} {amount} {currency}</p>
                    )}
                    {transactionType === "Exchange" && (
                        <div className='my-4 text-center'>
                            <p className="text-[#18181B] text-xl font-semibold">{getCurrencySymbol(currency?.fromCurrency)} {amount.fromAmount} {currency?.fromCurrency}</p>
                            <p className="text-[#18181B] text-base font-medium">({getCurrencySymbol(currency?.toCurrency)} {amount.toAmount} {currency?.toCurrency})</p>
                        </div>
                    )}
                    <p className="validationModal-subtitle" data-e2e-err="error-msg" data-e2e={subtitle}>{subtitle}</p>
                </div>
            </>
        )
    }

    const dialogActionsComponent = () => {
        return (
            <>
                <div className="jncModal__resp w-full" >
                    <JncButton
                        text="Close"
                        dataE2e="close-btn"
                        onClickCall={onClose}
                        className={"w-full bg-[#12B76A]"}
                        isOutlinedStyle={true}
                    />
                </div>
            </>
        )
    }
    return (
        <JunoClientActionModal
            dialogClassName={"jn-addressdetails-success"}
            onClose={onClose}
            states={{
                start: true,
                confirm: false,
            }}
            open={open}
            dialogContent={dialogContentComponent()}
            dialogActions={dialogActionsComponent()}
        />
    )
}
