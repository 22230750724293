import React, { useEffect, useState } from 'react'
import ScrollIntoView from '../../../common/scrollIntoView'
import JncHeader from '../components/header/JncHeader'
import { useDispatch, useSelector } from 'react-redux'
import { apiService } from '../../../common/apiCallService'
import JncSidebar from '../components/header/JncSidebar'
import { Navigate, useParams } from 'react-router-dom'
import { setClientData } from '../../../Redux/actions'
import { useJunoCommonFunctions } from '../../helpers'
import JncCryptoSidebar from '../components/header/JncCryptoSidebar'
import { displayCurrencyPage } from '../../../Redux/actions/junoAction'

function JncLayout(props) {
    const [token, setToken] = useState(null)
    const [isLoading, setIsLoading] = useState(true)
    const dispatch = useDispatch()
    const clientData = useSelector((state) => state.juno.clientData)
    const apiUrl = useSelector((state) => state.config.api_url)
    const { getWhitelistData } = useJunoCommonFunctions()
    const [sidebarState, setSidebarState] = useState(
        JSON.parse(sessionStorage.getItem('sidebarToggled')) || false,
    )
    const { id } = useParams();
    const activeCurrencyPage = useSelector((state) => state.juno.activeCurrencyPage)
    const [selectedLogo, setSelectedLogo] = useState([]);
    const imageBaseURl = useSelector((state) => state.config.image_base_url)



    const handleToggle = () => {
        setSidebarState((prev) => !prev)
    }

    useEffect(() => {
        sessionStorage.setItem('sidebarToggled', JSON.stringify(sidebarState))
    }, [sidebarState])

    useEffect(() => {
        const activeCurrencyPage = localStorage.getItem("activeCurrencyPage")
        dispatch(displayCurrencyPage(activeCurrencyPage))
    }, [])

    const fetchData = async () => {
        try {
            document.body.classList.add('juno-client-theme');
            setIsLoading(false);
            const token = localStorage.getItem('user_name');
            setToken(token);
            if (!clientData) {
                let clientData
                const whiteLabelData = await getWhitelistData()
                await apiService(
                    (window.location.hostname === 'localhost' ? apiUrl : '') + '/restapi/get-logedIn-client',
                    {
                        verifyToken: token,
                    },
                    async (data) => {
                        clientData = data;
                    },
                    (err) => {
                        console.log(err)
                    },
                );
                const tabSettings = { cryptoTab: clientData.cryptoTab, fiatTab: clientData.fiatTab }
                localStorage.setItem("tabSettings", JSON.stringify(tabSettings));
                const activePage = localStorage.getItem("activeCurrencyPage")
                const CurrencyPage = tabSettings.fiatTab === "Enable" ? "fiat" : "crypto"
                
                if (!activePage) {
                    dispatch(displayCurrencyPage(CurrencyPage))
                    localStorage.setItem("activeCurrencyPage", CurrencyPage)
                }

                if (whiteLabelData) {
                    const whitelabelId = clientData && clientData.whiteLabelId || id
                    const whiteLabel = whiteLabelData && whiteLabelData?.whitelabelList.find(e => e.whitelabelId === whitelabelId);
                    sessionStorage.setItem('logo', `${imageBaseURl}/landingpage/dark-logo.svg`);
                    sessionStorage.setItem('cryptoLogo', `${imageBaseURl}/junoclient/juno-crypto-logo.svg`);
                    if (whiteLabel) {
                        const themeColor = whiteLabel.primaryColor || '#18181b';
                        sessionStorage.setItem('primaryColor', themeColor);
                        if (whiteLabel.logo !== undefined) {
                            sessionStorage.setItem('logo', whiteLabel.logo);
                        }
                        if (whiteLabel.cryptoLogo !== undefined) {
                            sessionStorage.setItem('cryptoLogo', whiteLabel.cryptoLogo);
                        }
                        sessionStorage.setItem("whitelabelId", whiteLabel.whitelabelId);
                        sessionStorage.setItem('whitelableName', whiteLabel.whitelableName || '');
                        sessionStorage.setItem('logoWidth', whiteLabel.logoWidth || '');
                        document.documentElement.style.setProperty('--theme-color', themeColor);
                        document.documentElement.style.setProperty('--btn-background-hover', themeColor);
                    }
                }

                if (clientData) {
                    dispatch(setClientData(clientData));
                }
            }
        } catch (error) {
            console.log(error);
        }
    };

    useEffect(() => {
        fetchData();
    }, []);

    return (
        <div>
            {!isLoading && token && (
                <ScrollIntoView>
                    <JncHeader toggleSidebar={handleToggle} setSelectedLogo={setSelectedLogo} selectedLogo={selectedLogo} />
                    <div
                        className={`jncLayout ${sidebarState ? 'sidebar-opened' : ''}`}
                    >
                        {
                            activeCurrencyPage === 'fiat' ?
                                <JncSidebar sidebarState={sidebarState} />
                                :
                                <JncCryptoSidebar sidebarState={sidebarState} />
                        }
                        <div className="jncLayout-main">{props.children}</div>
                    </div>
                </ScrollIntoView>
            )}
            {!isLoading && !token && <Navigate to="/" />}
        </div>
    )
}


export default JncLayout
