import React, { useEffect, useRef, useState } from 'react'
import MenuIcon from '@mui/icons-material/Menu';
import ClearIcon from '@mui/icons-material/Clear';
import { useDispatch, useSelector } from 'react-redux';
import JunoLogin from '../global/JunoLogin'
import { useNavigate } from 'react-router-dom';
import { clearStorageData } from '../../../../common/getIP';
import * as actions from '../../../../Redux/actions'
import Reveal from "react-awesome-reveal";
import { keyframes } from '@emotion/react'

const Header = () => {
    const token = useSelector((state) => state.common.token)
    const dispatch = useDispatch()
    const isLoggedIn = token && token != 'null'
    const imageBaseUrl = useSelector((state) => state.config.image_base_url)
    const [isOpen, setIsOpen] = useState(false);
    const [showLoginModal, setShowLoginModal] = useState(false)
    const headerLogo = sessionStorage.getItem("logo")

    const navigate = useNavigate()
    const toggleMenu = () => {
        setIsOpen(!isOpen);
    };

    const Menu = [
        { id: 1, title: "Individual account", link: "/juno-individual" },
        { id: 2, title: "Corporate account", link: "/juno-corporate" },
        // { id: 3, title: "Banking Services", link: "/juno-banking-services" },
        { id: 4, title: "Contact us", link: "/contact-us" },
        { id: 5, title: "Log in" },
    ]

    const handleNavigate = (link) => {
        if (link) {
            navigate(link);
            toggleMenu()
        } else {
            setShowLoginModal(true)
            toggleMenu()
        }
    }


    const intervalRef = useRef()

    function checkForInactivity() {
        const expireTime = localStorage.getItem('expireTime')
        if (expireTime < Date.now()) {
            console.log('User has been logged out due to inactivity.')
            logout()
            clearInterval(intervalRef)
        } else {
            console.log('user is active')
        }
    }
    function updateExpireTime() {
        const expireTime = Date.now() + 15 * 60 * 1000
        localStorage.setItem('expireTime', expireTime)
    }

    useEffect(() => {
        setFavicon()
        window.addEventListener('click', updateExpireTime)
        window.addEventListener('keypress', updateExpireTime)
        window.addEventListener('scroll', updateExpireTime)
        window.addEventListener('mousemove', updateExpireTime)
        return () => {
            window.removeEventListener('click', updateExpireTime)
            window.removeEventListener('keypress', updateExpireTime)
            window.removeEventListener('scroll', updateExpireTime)
            window.removeEventListener('mousemove', updateExpireTime)
        }
    }, [])

    useEffect(() => {
        let interval = null
        if (isLoggedIn) {
            checkForInactivity()
            interval = setInterval(() => {
                checkForInactivity()
            }, 20000)
            intervalRef.current = interval
        }
        return () => clearInterval(interval)
    }, [isLoggedIn])

    function setFavicon() {
        let link = document.querySelector("link[rel~='icon']")
        if (!link) {
            link = document.createElement('link')
            link.rel = 'icon'
            document.getElementsByTagName('head')[0].appendChild(link)
        }
        const api_url = localStorage.getItem('api_url')
        link.href = `${api_url}/images/junoclient/junomoney-logo-icon.svg`
    }

    function logout() {
        const activity_tracking_token = localStorage.getItem(
            'activity_tracking_token',
        )
        const activity_tracking_api_url = localStorage.getItem(
            'activity_tracking_api_url',
        )
        const api_url = localStorage.getItem('api_url')
        dispatch(actions.setToken(null))
        dispatch(actions.setClientData(null))
        clearStorageData()
        localStorage.setItem('activity_tracking_token', activity_tracking_token)
        localStorage.setItem(
            'activity_tracking_api_url',
            activity_tracking_api_url,
        )
        localStorage.setItem('api_url', api_url)

    }

    const customAnimation = keyframes`
    from {
        opacity: 0;
        transform: translateX(-20px); /* Adjust this value to control the starting position */
      }
    
      to {
        opacity: 1;
        transform: translateY(0);
      }
  `;
    return (
        <div className='!bg-black fixed top-0 left-0 right-0 z-50 border-b border-white  '>

            <div className={`!px-3 md:!px-6 lg:!px-[30px] flex justify-between h-[60px] flex-shrink-0  items-center  max-w-[1280px] mx-auto xl:!px-0`}>

                <div className='flex gap-3 lg:w-16' >
                    {isOpen ? <ClearIcon onClick={toggleMenu} className='h-6 w-6 cursor-pointer' data-e2e="menu-icon" /> : <MenuIcon onClick={toggleMenu} className='h-6 w-6 cursor-pointer' data-e2e="menu-icon" />}
                    {headerLogo && headerLogo !== 'null' ?
                        <img onClick={() => navigate("/")} src={headerLogo} alt="logo" className='lg:hidden cursor-pointer' width={"110"} />
                        :
                        <img onClick={() => navigate("/")} className='lg:hidden cursor-pointer' src={`${imageBaseUrl}/landingpage/light-logo.svg`} alt="logo" />
                    }
                </div>
                <div onClick={() => navigate("/")} className='hidden lg:flex cursor-pointer'>
                    {headerLogo && headerLogo !== 'null' ?
                        <img src={headerLogo} alt="logo" data-e2e="logo" className='cursor-pointer' width={"120"} />
                        :
                        <img className='cursor-pointer' data-e2e="logo" src={`${imageBaseUrl}/landingpage/light-logo.svg`} alt="logo" />
                    }

                    {/* <span className='font-light ml-1 hidden lg:inline-block'>| payment services: redefined</span> */}
                </div>

                <button
                    className='bg-[#FFFFFF29] text-white rounded-full px-3 py-2 font-medium text-sm'
                    onClick={() => {
                        setShowLoginModal(true)
                    }}
                    data-e2e="LogIn"
                >
                    log in
                </button>

                {isOpen && (
                    <div className=' w-full  !bg-black absolute top-[62px] z-50 left-0 right-0 h-[calc(100vh-62px)] !bg-opacity-90'>
                        <div className="max-w-[1280px] mx-auto xl:!px-0">
                            <ul className='pl-6 lg:pl-20 xl:pl-0 mt-2'>
                                {Menu.map((item, index) =>
                                    <li
                                        key={item.id}
                                        onClick={() => handleNavigate(item.link)}
                                        className={`text-white py-4 lg:px-6 text-[28px] lg:text-[32px] cursor-pointer ${index === 4 ? "mt-4" : ""}`}>
                                        <Reveal
                                            effect="fadeInUp" // Use fade up animation
                                            duration={1000} // Duration of the animation
                                            keyframes={customAnimation}
                                            delay={index * 100}

                                        >
                                            <span data-e2e={item.title}>

                                                {item.title}
                                            </span>
                                        </Reveal>
                                    </li>
                                )}
                            </ul>
                        </div>
                    </div>
                )}
                {showLoginModal &&
                    <JunoLogin
                        show={true}
                        closeModal={() => setShowLoginModal(false)}
                    />
                }
            </div>
        </div>
    )
}

export default Header