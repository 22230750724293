import { AnimationOnScroll } from 'react-animation-on-scroll'
import DashboardCryptoHeader from '../components/dashboard/DashboardCryptoHeader'
import JunoClientSnackbar from '../components/global/JunoClientSnackbar'
import JncCryptoBalances from '../components/dashboard/JncCryptoBalances'
import { useState } from 'react'
import JncCryptoTransaction from '../tables/transactions/JncCryptoTransaction'
import { useSelector } from 'react-redux'
import JncStatements from '../tables/statements/JncStatements'
import DashboardCryptoActions from '../components/dashboard/DashboardCryptoActions'
import DashboardCryptoDistribution from '../components/dashboard/DashboardCryptoDistribution'

const JncCryptoDashboardPage = () => {
    const [isBalancesTab, setIsBalanceTab] = useState(true)
    const statementsData = useSelector((state) => state.juno.statements)

    return (
        <div className="jncPage">
            <AnimationOnScroll
                animateIn="animate__fadeInUpCustom"
                duration={1.2}
            >
                <DashboardCryptoHeader />
                <div className="jncPage__order">
                    <DashboardCryptoActions />
                    <div className='max-991 pb-3'>
                        <DashboardCryptoDistribution />
                    <hr className='mx-[-20px] mt-3' />

                    </div>
                    <div className="max-991 jncDashbTabs savedAccounts">
                        <div className="savedAccounts-tabs">
                            <button
                                type="button"
                                className={`${isBalancesTab && 'active'}`}
                                onClick={() => setIsBalanceTab(true)}
                            >
                                Balances
                            </button>
                            <button
                                type="button"
                                className={`${!isBalancesTab && 'active'}`}
                                onClick={() => setIsBalanceTab(false)}
                            >
                                Transaction history
                            </button>
                        </div>
                    </div>
                    <JncCryptoBalances
                        isDashboardPage={true}
                        isBalancesTab={isBalancesTab}
                    />
                    <JncCryptoTransaction
                        showTablePagination={false}
                        isDashboard={true}
                        isBalancesTab={isBalancesTab}
                    />
                    <hr className='mx-[-20px] mt-3 my-3' />
                    <JncStatements
                        showTablePagination={false}
                        isDashboardPage={true}
                    />
                </div>
            </AnimationOnScroll>
            <JunoClientSnackbar />
        </div>
    )
}

export default JncCryptoDashboardPage
