import React, { useEffect, useRef } from 'react'
import Header from '../../JunoMoney/components/landing-pages/Header'
import Footer from '../../JunoMoney/components/landing-pages/Footer'
import { useLocation } from 'react-router-dom'

const LandingLayout = ({ children }) => {
    const { pathname } = useLocation()

    const topDivRef = useRef(null)

    const scrollToTop = () => {

        if (topDivRef.current) {
            topDivRef.current.scrollIntoView({ behavior: 'smooth' })
        }

    }

    useEffect(() => {
        scrollToTop()
    }, [pathname])

    return (

        <div ref={topDivRef} className="w-screen h-screen flex flex-col ">
            <Header />

            {/* Content */}
            <div className="flex-1 relative">
                {children}
            </div>

            <Footer />
        </div>

    )
}

export default LandingLayout