import React, { useState } from 'react';
import EastIcon from '@mui/icons-material/East';
import Reveal from "react-awesome-reveal";
import { keyframes } from '@emotion/react'

const CustomButton = ({ title, onClick, className, ...rest }) => {
    const [hover, setHover] = useState(false)
    const customAnimation = keyframes`
    from {
        opacity: 0;
        transform: translateX(-50px); /* Adjust this value to control the starting position */
      }
    
      to {
        opacity: 1;
        transform: translateY(0);
      }
  `;
    return (
        <button
            onMouseEnter={() =>
                setHover(true)
            } onMouseLeave={() => setHover(false)}
            className={`h-11 px-6 hover:px-4 hover:scale-x-110 scale-y-105 rounded-full text-lg font-semibold flex justify-center gap-2 items-center text-[#18181B] bg-white border border-white group ${className}`}
            onClick={onClick}
            {...rest}>
            <span className='flex-shrink-0 w-3 h-3 flex justify-center items-center bg-brand-black rounded-full transition-all group-hover:w-7 group-hover:h-7'>
                {hover && <Reveal
                    duration={300}
                    keyframes={customAnimation}
                >
                    <EastIcon style={{ color: "white", width: 16 }} />
                </Reveal>}
            </span>
            <span className='flex-shrink-0'>{title}</span>
        </button>
    )
}

export default CustomButton