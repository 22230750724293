import { DialogTitle } from '@mui/material'
import ClearIcon from '@mui/icons-material/Clear'
import { useSelector } from 'react-redux'
import Icon from '../../icons/Icon'
import { useJunoCommonFunctions } from '../../../helpers'
import JncDialogTitle from './JncDialogTitle'

export default function JncDialogTitleCustom({
    states,
    titles,
    backTo,
    onClose,
    formData,
}) {
    const { useWindowWidth } = useJunoCommonFunctions()
    const imageBaseUrl = useSelector((state) => state.config.image_base_url)
    const balancesData = useSelector((state) => state.juno.balancesCurrencies)
    const currency = formData
        ? balancesData.find((x) => x.currencyShortName == formData.currency)
        : null

    const windowWidth = useWindowWidth()
    return (
        <div>
            {(states.start || states.new) && (
                <JncDialogTitle onClose={onClose} title={titles.start}
                className={titles.className}
                />
            )}

            {(states.edit || states.chosen || states.confirm) && (
                <DialogTitle
                    id="scroll-dialog-title"
                    className="jncModal__header back-step"
                >
                    <div className="jncModal__header-left jncModal__title">
                        <button
                            onClick={
                                states.edit || states.chosen
                                    ? backTo.start
                                    : states.confirm && backTo.account
                            }
                        >
                            <Icon id="arrowLeft" />
                        </button>
                        <p>
                            {states.edit && titles.edit}
                            {states.chosen && titles.start}
                            {states.confirm && titles.confirm}
                        </p>
                    </div>
                    <button
                        onClick={onClose}
                        type="button"
                        className="jncModal__close"
                    >
                        <ClearIcon />
                    </button>
                </DialogTitle>
            )}
        </div>
    )
}
