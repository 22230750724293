import Icon from '../../icons/Icon'
import { useJunoCommonFunctions } from '../../../helpers'
import { NavLink } from 'react-router-dom'

export default function JncPageTitle({
    title,
    subtitle,
    backLink,
    resetTab,
    activeTab,
    headerBtn,
    headerBtnAction,
}) {
    const { useWindowWidth } = useJunoCommonFunctions()
    const windowWidth = useWindowWidth()

    return (
        <div>
            <div className="jncPage-heading min-992">
                <div className="jncPage-title">
                    <div data-e2e={title} className="line"></div>
                    {title}
                </div>
                {subtitle && <p className="jncPage-subtitle">{subtitle}</p>}
            </div>
            <div className="w-full grid grid-cols-2 max-991">
                <div className="col-span-1 text-left">
                    {(backLink && windowWidth > 767) ||
                    (backLink && !activeTab && windowWidth < 768) ? (
                        <NavLink className="jncPage-backLink" to={backLink}>
                            <Icon id="arrowLeft" className="d-block" />
                        </NavLink>
                    ) : null}
                    {activeTab && windowWidth < 768 && (
                        <button
                            type="button"
                            onClick={resetTab}
                            className="jncPage-backLink"
                        >
                            <Icon id="arrowLeft" />
                        </button>
                    )}
                </div>
                <div className="col-span-1 text-right">
                    {headerBtn && (
                        <button
                            type="button"
                            onClick={headerBtnAction}
                            className="rounded-full w-auto px-[18px] py-[6px] border border-[#D1D1D6] text-xs font-medium text-[#18181B]"
                        >
                            {headerBtn}
                        </button>
                    )}
                </div>
            </div>
            <hr className="w-full absolute left-0 max-991 top-16" />

            <div
                className={`jncPage-heading max-991 ${
                    headerBtn && 'mt-[20px]'
                }`}
            >
                <div className="jncPage-title">
                    {(activeTab && windowWidth < 768 && activeTab) ||
                        (title !== 'Profile' && title)}
                </div>
            </div>
        </div>
    )
}
