import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'
import { createBrowserHistory } from 'history';
import JunoClientTransactionsPage from './Juno/client/pages/JunoClientTransactionsPage';
import JncDashboardPage from './Juno/client/pages/JncDashboardPage';
import JncProfilePage from './Juno/client/pages/JncProfilePage';
import JncAccountsPage from './Juno/client/pages/JncAccountsPage';
import JncBalancesPage from './Juno/client/pages/JncBalancesPage';
import JunoAmPage from './Juno/JunoAm/JunoAmPage';
import ClientNewPasswordPage from './Juno/JunoMoney/pages/ClientNewPasswordPage';
import JunoTermsPage from './Juno/JunoMoney/pages/JunoTermsPage';
import JunoPolicyPage from './Juno/JunoMoney/pages/JunoPolicyPage';
import JunoCookiesPage from './Juno/JunoMoney/pages/JunoCookiesPage';
import ProtectedRoute from "../ProtectedRoutes";
import JunoLogin from './Juno/JunoMoney/components/global/JunoLogin';
import JuneEverydayBankingPage from './Juno/JunoMoney/pages/JuneEverydayBankingPage'
import JunoContactUs from './Juno/JunoMoney/pages/JunoContactUs'
import JunoMoneyCorporatePage from './Juno/JunoMoney/pages/JunoMoneyCorporatePage'
import JunoMoneyLandingPage from './Juno/JunoMoney/pages/JunoMoneyLandingPage'
import JunoMoneyBankingServicesPage from './Juno/JunoMoney/pages/JunoMoneyBankingServicesPage'
import { JncCryptoAddressPage } from './Juno/client/pages/JncCryptoAddressPage';
import NotFound from './Juno/client/pages/NotFound';
import MaintenancePage from './Juno/JunoMoney/pages/MaintenancePage';

const history = createBrowserHistory();

const RoutesList = () => {
  return (
    <Router history={history}>
      <Routes>
        <Route
          exact
          path="/juno-management-solutions"
          element={<JunoAmPage />}
        />
        <Route
          exact
          path="/"
          element={<JunoMoneyLandingPage />}
        />
        <Route
          exact
          path="/login/:id"
          element={<JunoLogin />}
        />
        <Route
          exact
          path="/client"
          element={<JncDashboardPage />}
        />
        <Route
          exact
          path="/juno-terms"
          element={<JunoTermsPage />}
        />
        <Route
          exact
          path="/juno-privacy-policy"
          element={<JunoPolicyPage />}
        />
        <Route
          exact
          path="/juno-cookies"
          element={<JunoCookiesPage />}
        />
        <Route
          exact
          path="/maintenance"
          element={<MaintenancePage />}
        />
        {/* TODO: Will be added later, skipping for current release */}
        {/* <Route
          exact
          path="/juno-banking-services"
          element={<JunoMoneyBankingServicesPage />}
        /> */}
        <Route
          exact
          path="/juno-individual"
          element={<JuneEverydayBankingPage />}
        />
        <Route
          exact
          path="/juno-corporate"
          element={<JunoMoneyCorporatePage />}
        />
        <Route
          exact
          path="/client/transactions"
          element={<ProtectedRoute component={JunoClientTransactionsPage} />}
        />
        <Route
          exact
          path="/client/accounts"
          element={<ProtectedRoute component={JncAccountsPage} />}
        />
        <Route
          exact
          path="/client/profile"
          element={<ProtectedRoute component={JncProfilePage} />}
        />
        <Route
          exact
          path="/client/balances"
          element={<ProtectedRoute component={JncBalancesPage} />}
        />
        <Route
          exact
          path="/client/address"
          element={<ProtectedRoute component={JncCryptoAddressPage} />}
        />
        <Route
          exact
          path="/client-forgot-password/:token"
          element={<ClientNewPasswordPage />}
        />

        <Route exact path="/contact-us" element={<JunoContactUs />} />
        <Route exact path="*" element={<NotFound />} />

      </Routes>
    </Router>
  )
}

export default RoutesList
