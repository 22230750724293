import { Alert, Snackbar } from '@mui/material'
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { closeSnackbar } from '../../../../Redux/actions/junoAction'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'

const JunoClientSnackbar = () => {
    const dispatch = useDispatch()
    const { open, message, severity } = useSelector(
        (state) => state.juno.snackbar,
    )

    useEffect(() => {
        if (open) {
            const timeoutId = setTimeout(() => {
                dispatch(closeSnackbar())
            }, 3000)

            return () => {
                clearTimeout(timeoutId)
            }
        }
    }, [open, dispatch])

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return
        }

        dispatch(closeSnackbar())
    }
    return (
        <Snackbar
            open={open}
            className="juno-snackbar"
            autoHideDuration={3000}
            onClose={handleClose}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
            sx={{ background: '#101828' }}
        >
            <Alert
                onClose={handleClose}
                severity={severity || 'info'}
                className="juno-snackbar-msg"
                iconMapping={{
                    success: (
                        <CheckCircleIcon
                            fontSize="inherit"
                            sx={{ color: '#12B76A' }}
                        />
                    ),
                    error: (
                        <CheckCircleIcon
                            fontSize="inherit"
                            sx={{ color: 'red' }}
                        />
                    ),
                }}
            >
                {message}
            </Alert>
        </Snackbar>
    )
}

export default JunoClientSnackbar
